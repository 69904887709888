import axios from "axios";
import { getCookie, setCookie } from "./useCookie";

let aTokenX = getCookie("access");

const url = process.env.REACT_APP_BACKEND_URL;
console.log('url',url);




const axiosInstance = axios.create({
  baseURL: url,
  headers: aTokenX ? { Authorization: `Bearer ${aTokenX}` } : {},
});

// Global function to redirect to login page
window.redirectToLogin = () => {
  window.location.href = "/login"; // or your login page URL
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Response interceptor for handling errors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.includes('/api/v1/login/')
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = getCookie("refresh");
      if (!refreshToken) {
        localStorage.clear();
        window.redirectToLogin();
        return Promise.reject(error);
      }

      try {
        const response = await axios.post(`${url}/api/v1/login/refresh/`, {
          refresh: refreshToken,
        });
        const newAccessToken = response.data.access;
        setCookie("access",newAccessToken);

        axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + newAccessToken;
        originalRequest.headers["Authorization"] = "Bearer " + newAccessToken;

        processQueue(null, newAccessToken);

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        localStorage.clear();
        window.redirectToLogin();
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    if (error.response?.status === 401 && originalRequest.url.includes('/api/v1/login/')) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
