import React from 'react'
import { Text, TextInput } from '@mantine/core'
import styled from 'styled-components'
const LabelInput = ({value}) => {
  return (
    <InputContainer>
    <Text mb={-6} ml={12} size={11} weight={400} color='#00000099' >Title</Text>
    <TextInput className='label-input' value={value || ''} disabled placeholder="Lorem ipsum dolor sit amet" />
    </InputContainer>
  )
}

export default LabelInput


const InputContainer = styled.div`
background-color : #fff;
border-radius : 10px;
margin : 0x 0 20px;
padding :5px 1px 5px 0;
 .label-input {
    input {
        border : none;
        font-size : 16px;
        font-weight : 400;
        color : #202939 !important;
        background-color : transparent;
        &::placeholder {
            font-size : 16px;
            font-weight : 500;
            color : #202939 !important;
        }
    }
    input[disabled] {
        color : #202939 !important;
        font-weight : 400;
         opacity :1;
    }
 }
`