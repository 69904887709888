import React, { useState, useEffect } from "react";
import {
  Accordion,
  Button,
  Select,
  Stack,
  TextInput,
  Radio,
  ActionIcon,
  Group,
  Text,
} from "@mantine/core";
import styled from "styled-components";
import { IconMinus } from "@tabler/icons-react";
import { Plus, Trash } from "tabler-icons-react";
import SaveButton from "../../Common/SaveButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showNotification } from "@mantine/notifications";
import axios from "../../../../Helpers/Axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const TaskContent = ({
  activeSubUnitTab,
  handleSubUnitContent,
  currentValue,
  handleDeleteSubUnitContent,
  programId,
  unitId,
  subUnitId,
  link,
  pUnit,
  pSubUnit,
  setIsContentSave
}) => {
  const queryClient = useQueryClient();
  const [taskData, setTaskData] = useState({
    task_name: "",
    task_description: "",
    task_type: "",
    is_quiz: false,
    is_question: false,
    questions: [
      {
        question_title: "", // Keep this field but don't use it for input
        question_text: "",
        correct_answer: "",
        answers: [{ answer_text: "" }],
      },
    ],
  });

  const [openedAccordions, setOpenedAccordions] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleTaskPreviewData = (key, value) => {
    setTaskData((prevTaskData) => {
      const updatedData = { ...prevTaskData, [key]: value };
      if (key === "task_type") {
        updatedData.is_quiz = value === "Quiz";
        updatedData.is_question = value === "Question";
      }
      
      // Check if updatedData is different from prevTaskData
      const hasChanges = JSON.stringify(updatedData) !== JSON.stringify(prevTaskData);
      if (hasChanges) {
        setHasUnsavedChanges(true);
      }

      return updatedData;
    });
  };

  const handleQuestionChange = (index, key, value) => {
    setTaskData((prevTaskData) => {
      const updatedQuestions = [...prevTaskData.questions];
      updatedQuestions[index] = { ...updatedQuestions[index], [key]: value };
      
      // Check if updatedQuestions is different from prevTaskData.questions
      const hasChanges = JSON.stringify(updatedQuestions) !== JSON.stringify(prevTaskData.questions);
      if (hasChanges) {
        setHasUnsavedChanges(true);
      }

      return {
        ...prevTaskData,
        questions: updatedQuestions,
      };
    });
  };

  const handleAnswerChange = (questionIndex, answerIndex, value) => {
    setTaskData((prevTaskData) => {
      const updatedQuestions = [...prevTaskData.questions];
      const updatedAnswers = [...updatedQuestions[questionIndex].answers];

      if (prevTaskData.task_type === "Question") {
        updatedAnswers[0] = { answer_text: value };
        updatedQuestions[questionIndex] = {
          ...updatedQuestions[questionIndex],
          answers: updatedAnswers,
          correct_answer: value,
        };
      } else {
        updatedAnswers[answerIndex] = {
          ...updatedAnswers[answerIndex],
          answer_text: value,
        };
        updatedQuestions[questionIndex] = {
          ...updatedQuestions[questionIndex],
          answers: updatedAnswers,
        };
      }
      // setHasUnsavedChanges(true);
      const hasChanges = JSON.stringify(updatedQuestions) !== JSON.stringify(prevTaskData.questions);
      if (hasChanges) {
        setHasUnsavedChanges(true);
      }

      return { ...prevTaskData, questions: updatedQuestions };
    });
  };

  const handleCorrectAnswerChange = (questionIndex, selectedAnswer) => {
    setTaskData((prevTaskData) => {
      const updatedQuestions = [...prevTaskData.questions];
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        correct_answer: selectedAnswer,
      };
      // setHasUnsavedChanges(true);
      const hasChanges = JSON.stringify(updatedQuestions) !== JSON.stringify(prevTaskData.questions);
      if (hasChanges) {
        setHasUnsavedChanges(true);
      }

      return { ...prevTaskData, questions: updatedQuestions };
    });
  };

  const addQuestion = () => {
    setTaskData((prevTaskData) => {
      const newQuestions = [
        ...prevTaskData.questions,
        {
          question_title: "",
          question_text: "",
          correct_answer: "",
          answers: [{ answer_text: "" }],
        },
      ];
      setHasUnsavedChanges(true);
      
      setOpenedAccordions(`question-${newQuestions.length - 1}`);
      return { ...prevTaskData, questions: newQuestions };
    });
  };

  const addAnswer = (questionIndex) => {
    setTaskData((prevTaskData) => {
      const updatedQuestions = [...prevTaskData.questions];
      const updatedAnswers = [
        ...updatedQuestions[questionIndex].answers,
        { answer_text: "" },
      ];
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        answers: updatedAnswers,
      };
      // setHasUnsavedChanges(true);
      const hasChanges = JSON.stringify(updatedQuestions) !== JSON.stringify(prevTaskData.questions);
      if (hasChanges) {
        setHasUnsavedChanges(true);
      }
      return { ...prevTaskData, questions: updatedQuestions };
    });
  };

  const deleteQuestion = (questionIndex, questionId) => {
    if (taskData?.questions?.length > 1) {
      setTaskData((prevTaskData) => {
        const updatedQuestions = prevTaskData.questions.filter(
          (_, index) => index !== questionIndex
        );
        // setHasUnsavedChanges(true);

        return { ...prevTaskData, questions: updatedQuestions };
      });

      if (questionId) {
        delQuestion(questionId);
      }
      setOpenedAccordions(`question-${questionIndex}`);
    }
  };

  const deleteAnswer = (questionIndex, answerIndex, questionId, answerId) => {
    setTaskData((prevTaskData) => {
      const updatedQuestions = [...prevTaskData.questions];
      const updatedAnswers = updatedQuestions[questionIndex].answers.filter(
        (_, index) => index !== answerIndex
      );
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        answers: updatedAnswers,
      };
      // setHasUnsavedChanges(true);
      const hasChanges = JSON.stringify(updatedQuestions) !== JSON.stringify(prevTaskData.questions);
      if (hasChanges) {
        setHasUnsavedChanges(true);
      }
      return { ...prevTaskData, questions: updatedQuestions };
    });
    if (questionId && answerId) {
      delAnswer({ questionId, answerId });
    }
  };

  const finalizeTaskData = () => {
    const updatedQuestions = taskData.questions.map((question, index) => {
      return {
        ...question,
        question_title: `Question ${index + 1}`, // Auto-generate question title
      };
    });

    setTaskData((prevTaskData) => ({
      ...prevTaskData,
      questions: updatedQuestions,
    }));

    handleSubUnitContent(
      "tasks",
      {
        ...taskData,
        questions: updatedQuestions,
      },
      activeSubUnitTab
    );

    setHasUnsavedChanges(false); // Reset unsaved changes flag
    setIsContentSave(true)
  };

  const { mutate: deleteTask, isLoading } = useMutation({
    mutationFn: async () => {
      const response = await axios.delete(
        `/${link.program}/${programId}/${pUnit}/${unitId}/${pSubUnit}/${subUnitId}/task/${taskData.id}/`
      );
      return response.data;
    },
    onSuccess: () => {
      showNotification({
        title: "Success",
        message: "Task deleted successfully",
        color: "green",
      });
      queryClient.invalidateQueries(["steam"], programId);
      //  handleDeleteDayContent(activeDayTab,[], "tasks"); // Call the parent function to delete the image
    },
    onError: (error) => {
      showNotification({
        title: "Error",
        message: "Failed to delete Task: " + error.message,
        color: "red",
      });
    },
  });
  const { mutate: delQuestion, isLoading: questionLoading } = useMutation({
    mutationFn: async (questionId) => {
      const response = await axios.delete(
        `/${link.program}/${programId}/${pUnit}/${unitId}/${pSubUnit}/${subUnitId}/task/${taskData.id}/question/${questionId}/`
      );
      return response.data;
    },
    onSuccess: () => {
      showNotification({
        title: "Success",
        message: "Task deleted successfully",
        color: "green",
      });
      queryClient.invalidateQueries(["program"], programId);
      setHasUnsavedChanges(false);
      //  handleDeleteDayContent(activeDayTab,[], "tasks"); // Call the parent function to delete the image
    },
    onError: (error) => {
      showNotification({
        title: "Error",
        message: "Failed to delete Task: " + error.message,
        color: "red",
      });
    },
  });
  const { mutate: delAnswer, isLoading: answerLoading } = useMutation({
    mutationFn: async ({ questionId, answerId }) => {
      const response = await axios.delete(
        `/${link.program}/${programId}/${pUnit}/${unitId}/${pSubUnit}/${subUnitId}/task/${taskData.id}/question/${questionId}/answer/${answerId}/`
      );
      return response.data;
    },
    onSuccess: () => {
      showNotification({
        title: "Success",
        message: "Task deleted successfully",
        color: "green",
      });
      queryClient.invalidateQueries(["program"], programId);
      setHasUnsavedChanges(false);
      //  handleDeleteDayContent(activeDayTab,[], "tasks"); // Call the parent function to delete the image
    },
    onError: (error) => {
      showNotification({
        title: "Error",
        message: "Failed to delete Task: " + error.message,
        color: "red",
      });
    },
  });

  const deleteContent = () => {
    deleteTask();
  };

  useEffect(() => {
    if (currentValue && currentValue.length > 0 && currentValue[0] !== null) {
      setTaskData(currentValue[0]);
      setHasUnsavedChanges(false);
    }
  }, [currentValue]);

  const isTaskInfoComplete =
    taskData?.task_name && taskData?.task_description && taskData?.task_type;

  useEffect(() => {
    if (taskData?.questions?.length > 0) {
      setOpenedAccordions(`question-${taskData.questions.length - 1}`);
    }
  }, [taskData.questions?.length]);

  

  return (
    <ContentStyle>
      <div style={{ marginTop: "15px" }} className="content-style">
        <div className="content-container">
          <Group align="center" position="apart">
            <Text mb={10} size={16} weight={500} color="#121926">
              Task
            </Text>
            <ActionIcon onClick={() => deleteContent()} color="red">
              <IconMinus size={16} />
            </ActionIcon>
          </Group>
          <div
            style={{
              border: "1px solid #E3E8EF",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <Stack mb={20} spacing={6}>
              <Label>Task Name</Label>
              <TextInput
                size="md"
                radius={8}
                placeholder="Task name"
                withAsterisk
                value={taskData?.task_name || ""}
                onChange={(e) =>
                  handleTaskPreviewData("task_name", e.target.value)
                }
              />
            </Stack>

            <Stack mb={20} spacing={6}>
              <Label>Task Description</Label>
              <CKEditor
                editor={ClassicEditor}
                data={taskData?.task_description || ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleTaskPreviewData("task_description", data);
                }}
              />
            </Stack>

            <Stack spacing={6}>
              <Label>Task Type</Label>
              <Select
                size="md"
                radius={8}
                placeholder="Pick one"
                value={taskData?.task_type || ""}
                onChange={(e) => handleTaskPreviewData("task_type", e)}
                data={[
                  { value: "Quiz", label: "Quiz" },
                  { value: "Question", label: "Question" },
                ]}
              />
            </Stack>
          </div>
        </div>
      </div>
      {isTaskInfoComplete && (
        <>
          <Accordion value={openedAccordions} onChange={setOpenedAccordions}>
            {taskData.questions.map((question, qIndex) => (
              <Accordion.Item key={qIndex} value={`question-${qIndex}`}>
                <Accordion.Control>
                  <Group position="apart">
                    {taskData.task_type === "Question"
                      ? `Task ${qIndex + 1}`
                      : `Question ${qIndex + 1}`}
                    <Group style={{ zIndex: 100 }} align="center" spacing={5}>
                      <Circle backgroundColor="#D6F4F6" onClick={addQuestion}>
                        <Plus size={20} color="#029199" strokeWidth={1.5} />
                      </Circle>
                      <Circle
                        backgroundColor="#FFE0E0"
                        onClick={() => deleteQuestion(qIndex, question?.id)}
                      >
                        <Trash size={16} color="#E41D1D" strokeWidth={1.5} />
                      </Circle>
                    </Group>
                  </Group>
                </Accordion.Control>
                <Accordion.Panel>
                  <Stack mb={20} spacing={5}>
                    <div
                      style={{ marginTop: "15px" }}
                      className="content-style"
                    >
                      <div className="content-container">
                        <Stack spacing={6}>
                          <Label>
                            {taskData.task_type === "Question"
                              ? "Task Text"
                              : "Question Text"}
                          </Label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={question.question_text}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              handleQuestionChange(
                                qIndex,
                                "question_text",
                                data
                              );
                            }}
                          />
                        </Stack>
                        {taskData.task_type === "Quiz" && (
                          <div style={{ marginTop: "20px" }}>
                            <Label>Options</Label>
                            <OptionWrapper>
                              {question.answers.map((answer, aIndex) => (
                                <Stack key={aIndex} mb={10} mt={10} spacing={1}>
                                  <OptionItem>
                                    <Radio
                                      value={answer.answer_text}
                                      label=""
                                      checked={
                                        question.correct_answer ===
                                        answer.answer_text
                                      } // Check if it's the correct answer
                                      onChange={() =>
                                        handleCorrectAnswerChange(
                                          qIndex,
                                          answer.answer_text
                                        )
                                      } // Update correct answer
                                    />
                                    <TextInput
                                      value={answer.answer_text}
                                      radius={10}
                                      style={{ flexGrow: 1 }}
                                      className="text-input"
                                      onChange={(e) =>
                                        handleAnswerChange(
                                          qIndex,
                                          aIndex,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <ActionIcon
                                      style={{
                                        border: "1px solid #CDD5DF",
                                        width: "50px",
                                        height: "46px",
                                        borderRadius: "10px",
                                      }}
                                      color="red"
                                      onClick={() =>
                                        deleteAnswer(
                                          qIndex,
                                          aIndex,
                                          question?.id,
                                          answer?.id
                                        )
                                      }
                                    >
                                      <IconMinus
                                        style={{ color: "#202939" }}
                                        size={16}
                                      />
                                    </ActionIcon>
                                  </OptionItem>
                                </Stack>
                              ))}
                            </OptionWrapper>
                            <Button
                              mt={10}
                              variant="light"
                              onClick={() => addAnswer(qIndex)}
                            >
                              Add option
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>

          {hasUnsavedChanges && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}
            >
              <SaveButton onClick={finalizeTaskData} />
            </div>
          )}
        </>
      )}
    </ContentStyle>
  );
};

export default TaskContent;

const ContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .ck-editor__editable {
    min-height: 200px;
  }
  .ck-editor__editable_inline {
    padding: 0 10px;
  }
  .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border-radius: 10px;
  }
  .ck.ck-editor__main > .ck-editor__editable {
    border-radius: 0 0 10px 10px;
  }
  .ck.ck-toolbar {
    border-radius: 10px 10px 0 0;
  }
  .mantine-RichTextEditor-root.mantine-1qycoz3 {
    display: flex;
    flex-direction: column-reverse;
    border-radius: 10px;
  }
  .mantine-RichTextEditor-toolbar.mantine-bdajhj.ql-toolbar.ql-snow {
    border-bottom: 0px;
    border-radius: 10px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      width: 95%;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      background: #e1e1e1;
    }
  }
  .mantine-Accordion-item mantine-v4lv9f {
    border: none !important;
  }
  .mantine-UnstyledButton-root.mantine-Accordion-control.mantine-xizyzk {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
  }
`;

const Label = styled.label`
  color: black;
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
  color: #121926;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OptionItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    .text-input{
    input {
    height: 46px;
  `;

const Circle = styled.div`
  width: 27px;
  height: 28px;
  border-radius: 100%;
  background: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 26px;
    height: 26px;
  }
`;
