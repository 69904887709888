// import { Input,} from "@mantine/core";
// import { RichTextEditor } from '@mantine/rte';

// export function ExisitingContent({ props }){

//   const { type, setNothingToChange, fetchSyllabus, updatedSections, setUpdatedSections, syllabus, setSyllabus, axios } = props;

//   // Edit existing section
//   function handleExistingChange(e, index, label){
//     setNothingToChange(false);
//     setSyllabus(
//       syllabus.map((item, i) => {
//         if(i === index){
//           if(!updatedSections.includes(item.id)){
//             setUpdatedSections([...updatedSections, item.id]);
//           }
//           return {
//             ...item,
//             [label]: label === "title" ? e.target?.value : e,
//             [label]: label === "content_video" ? e.target?.files[0] : e,
//           }
//         }
//         return item;
//       }
//     ));
//   }

//   // Delete section from syllabus
//   async function deleteExisting(item){
//     const response = await axios.delete(type === "course" ? `/syllabus/coursesyllabus/${item?.id}/` : `/syllabus/internshipsyllabus/${item?.id}/`);

//     setSyllabus(syllabus.filter(section => section.id !== item.id));
//     setUpdatedSections(updatedSections.filter(section => section !== item.id));

//     if(response.status === 204){
//       fetchSyllabus();
//     }
//   }

//   return(
//     syllabus.map((item, index) => {
//       return(
//         <Input.Wrapper label={`Section ${index + 1}`} className={"xxx2"} key={item.id}>
//           <button className="removeSection" onClick={() => deleteExisting(item)}>remove</button>
//           <div style={{marginBottom:"2rem", borderRadius:"0.25rem", alignItems:"center", padding:".75rem", backgroundColor:"#eee"}}>
//             <Input.Wrapper label="Description">
//               <RichTextEditor
//                 value={item?.content}
//                 onChange={(e) => {handleExistingChange(e, index, 'content')}}
//               />
//             </Input.Wrapper>
//             <div className="videoDiv">
//               <Input.Wrapper label="Replace preview video">
//                 <br />
//                 <input type="file" accept="video/*"
//                   onChange={(e) => {handleExistingChange(e, index, 'content_video')}}
//                   />
//               </Input.Wrapper>
//               {
//                 item?.content_video &&
//                 <video width="240px" controls>
//                   <source src={item?.content_video} type="video/mp4" />
//                 </video>
//               }
//             </div>
//           </div>
//         </Input.Wrapper>
//       )
//     })
//   )
// }

import { Input } from "@mantine/core";
import { RichTextEditor } from "@mantine/rte";

export function ExisitingContent({ props }) {
  const {
    type,
    setNothingToChange,
    fetchSyllabus,
    updatedSections,
    setUpdatedSections,
    syllabus,
    setSyllabus,
    axios,
  } = props;

  function handleExistingChange(e, index, label) {
    setNothingToChange(false);
    setSyllabus(prevSyllabus => (
      prevSyllabus.map((item, i) => {
        if (i === index) {
          if (!updatedSections.includes(item.id)) {
            setUpdatedSections([...updatedSections, item.id]);
          }
          return {
            ...item,
            [label]: label === "title" ? e.target?.value : e,
            [label]: label === "content_video" ? e.target?.files[0] : e,
          };
        }
        return item;
      })
    ));
  }

  // Delete section from syllabus
  async function deleteExisting(item) {
    const response = await axios.delete(
      type === "course"
        ? `/syllabus/coursesyllabus/${item?.id}/` :
        type==="steam" ?
        `/steam/syllabus/${item?.id}/` 
        : `/syllabus/internshipsyllabus/${item?.id}/`
    );

    setSyllabus(syllabus.filter((section) => section.id !== item.id));
    setUpdatedSections(
      updatedSections.filter((section) => section !== item.id)
    );

    if (response.status === 204) {
      fetchSyllabus();
    }
  }

  return syllabus.map((item, index) => {
    return (
      <Input.Wrapper
        label={`Section ${index + 1}`}
        className={"xxx2"}
        key={item.id}
      >
        <button className="removeSection" onClick={() => deleteExisting(item)}>
          remove
        </button>
        <div
          style={{
            marginBottom: "2rem",
            borderRadius: "0.25rem",
            alignItems: "center",
            padding: ".75rem",
            backgroundColor: "#eee",
          }}
        >
          <Input.Wrapper label="Description">
            <RichTextEditor
              value={item?.content ? item?.content : item.description}
              onChange={(e) => {
                handleExistingChange(e, index, "content");
              }}
            />
          </Input.Wrapper>
          <div className="videoDiv">
            <Input.Wrapper label="Replace preview video">
              <br />
              <input
                type="file"
                accept="video/*"
                onChange={(e) => {
                  handleExistingChange(e, index, "content_video");
                }}
              />
            </Input.Wrapper>
            {item?.content_video && (
              <video width="240px" controls>
                <source src={item?.content_video} type="video/mp4" />
              </video>
            )}
          </div>
        </div>
      </Input.Wrapper>
    );
  });
}
