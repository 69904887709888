export const iconDatas = [
    {
      src: "data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2224%22%20height=%2224%22%20viewBox=%220%200%2024%2024%22%20fill=%22none%22%20stroke=%22%23CDCDCD%22%20stroke-width=%222%22%20stroke-linecap=%22round%22%20stroke-linejoin=%22round%22%20class=%22icon%20icon-tabler%20icons-tabler-outline%20icon-tabler-book%22%3E%3Cpath%20stroke=%22none%22%20d=%22M0%200h24v24H0z%22%20fill=%22none%22/%3E%3Cpath%20d=%22M3%2019a9%209%200%200%201%209%200a9%209%200%200%201%209%200%22%20/%3E%3Cpath%20d=%22M3%206a9%209%200%200%201%209%200a9%209%200%200%201%209%200%22%20/%3E%3Cpath%20d=%22M3%206l0%2013%22%20/%3E%3Cpath%20d=%22M12%206l0%2013%22%20/%3E%3Cpath%20d=%22M21%206l0%2013%22%20/%3E%3C/svg%3E",
    },
    {
      src: "data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2224%22%20height=%2224%22%20viewBox=%220%200%2024%2024%22%20fill=%22none%22%20stroke=%22%23CDCDCD%22%20stroke-width=%222%22%20stroke-linecap=%22round%22%20stroke-linejoin=%22round%22%20class=%22icon%20icon-tabler%20icons-tabler-outline%20icon-tabler-pencil%22%3E%3Cpath%20stroke=%22none%22%20d=%22M0%200h24v24H0z%22%20fill=%22none%22/%3E%3Cpath%20d=%22M4%2020h4l10.5%20-10.5a2.828%202.828%200%201%200%20-4%20-4l-10.5%2010.5v4%22%20/%3E%3Cpath%20d=%22M13.5%206.5l4%204%22%20/%3E%3C/svg%3E",
    },
    {
      src: "data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2224%22%20height=%2224%22%20viewBox=%220%200%2024%2024%22%20fill=%22none%22%20stroke=%22%23CDCDCD%22%20stroke-width=%222%22%20stroke-linecap=%22round%22%20stroke-linejoin=%22round%22%20class=%22icon%20icon-tabler%20icons-tabler-outline%20icon-tabler-device-desktop%22%3E%3Cpath%20stroke=%22none%22%20d=%22M0%200h24v24H0z%22%20fill=%22none%22/%3E%3Cpath%20d=%22M3%205a1%201%200%200%201%201%20-1h16a1%201%200%200%201%201%201v10a1%201%200%200%201%20-1%201h-16a1%201%200%200%201%20-1%20-1v-10z%22%20/%3E%3Cpath%20d=%22M7%2020h10%22%20/%3E%3Cpath%20d=%22M9%2016v4%22%20/%3E%3Cpath%20d=%22M15%2016v4%22%20/%3E%3C/svg%3E",
    },
    {
      src: "data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2224%22%20height=%2224%22%20viewBox=%220%200%2024%2024%22%20fill=%22none%22%20stroke=%22%23CDCDCD%22%20stroke-width=%222%22%20stroke-linecap=%22round%22%20stroke-linejoin=%22round%22%20class=%22icon%20icon-tabler%20icons-tabler-outline%20icon-tabler-eyeglass-2%22%3E%3Cpath%20stroke=%22none%22%20d=%22M0%200h24v24H0z%22%20fill=%22none%22/%3E%3Cpath%20d=%22M8%204h-2l-3%2010v2.5%22%20/%3E%3Cpath%20d=%22M16%204h2l3%2010v2.5%22%20/%3E%3Cpath%20d=%22M10%2016l4%200%22%20/%3E%3Cpath%20d=%22M17.5%2016.5m-3.5%200a3.5%203.5%200%201%200%207%200a3.5%203.5%200%201%200%20-7%200%22%20/%3E%3Cpath%20d=%22M6.5%2016.5m-3.5%200a3.5%203.5%200%201%200%207%200a3.5%203.5%200%201%200%20-7%200%22%20/%3E%3C/svg%3E",
    },
    {
      src: "data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2224%22%20height=%2224%22%20viewBox=%220%200%2024%2024%22%20fill=%22none%22%20stroke=%22%23CDCDCD%22%20stroke-width=%222%22%20stroke-linecap=%22round%22%20stroke-linejoin=%22round%22%20class=%22icon%20icon-tabler%20icons-tabler-outline%20icon-tabler-school%22%3E%3Cpath%20stroke=%22none%22%20d=%22M0%200h24v24H0z%22%20fill=%22none%22/%3E%3Cpath%20d=%22M22%209l-10%20-4l-10%204l10%204l10%20-4v6%22%20/%3E%3Cpath%20d=%22M6%2010.6v5.4a6%203%200%200%200%2012%200v-5.4%22%20/%3E%3C/svg%3E",
    },
    {
      src: "data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2224%22%20height=%2224%22%20viewBox=%220%200%2024%2024%22%20fill=%22none%22%20stroke=%22%23CDCDCD%22%20stroke-width=%222%22%20stroke-linecap=%22round%22%20stroke-linejoin=%22round%22%20class=%22icon%20icon-tabler%20icons-tabler-outline%20icon-tabler-building-bank%22%3E%3Cpath%20stroke=%22none%22%20d=%22M0%200h24v24H0z%22%20fill=%22none%22/%3E%3Cpath%20d=%22M3%2021l18%200%22%20/%3E%3Cpath%20d=%22M3%2010l18%200%22%20/%3E%3Cpath%20d=%22M5%206l7%20-3l7%203%22%20/%3E%3Cpath%20d=%22M4%2010l0%2011%22%20/%3E%3Cpath%20d=%22M20%2010l0%2011%22%20/%3E%3Cpath%20d=%22M8%2014l0%205%22%20/%3E%3Cpath%20d=%22M12%2014l0%205%22%20/%3E%3Cpath%20d=%22M16%2014l0%205%22%20/%3E%3C/svg%3E",
    },
  ];
  