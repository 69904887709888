import React from 'react'
import {FileInput,Button} from '@mantine/core'
import { Paperclip } from 'tabler-icons-react';

const UploadFileInput = ({id,label,name,setValue}) => {
    const handleFileChange = (event) => {
        const file = event;
        if (name === 'images') {
          setValue(name, file); // Handle multiple files for "images"
        } else {
          setValue(name, file); // Handle single file for other inputs
        }
      };
  return (
      <FileInput
        radius={10}
        size="sm"
        placeholder="Media"
        id={id}
        label={label}
        withAsterisk
        style={{ fontSize: "16px",width : "100%" }}
        onChange={handleFileChange}
        multiple={name === 'images'} 
        
        rightSection={
          <Button
            variant="outline"
            size="sm"
            radius={10}
            className="upload-btn"
            rightIcon={
              <Paperclip
                style={{ rotate: "-45deg" }}
                size={16}
                strokeWidth={1.5}
                color={"black"}
              />
            }
            onClick={(e) => {
              e.preventDefault();
                document.querySelector(`#${id}`).click();
            }}
          >
            Upload file
          </Button>} />
  )
}

export default UploadFileInput