import parse from "html-react-parser";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import {
  Center,
  Grid,
  Group,
  Image,
  Spoiler,
  Text,
  ThemeIcon,
} from "@mantine/core";
import {
  Calendar,
  Layout2,
  Clock,
  Language,
  Flag,
  MilitaryRank,
  Star,
  Location,
  CurrencyDollar,
  CurrencyManat,
} from "tabler-icons-react";
import axios from "../../Helpers/Axios";
import { useEffect, useState } from "react";
export function ProgramSpecs({ props }) {
  const [steamLang, setSteamLang] = useState();
  const { entryData, type } = props;
  const {
    video,
    image,
    language,
    requirement,
    speciality,
    category,
    duration,
    duration_time,
    course_deadline,
    start_date,
    description,
    logo,
    country,
    raiting,
    location,
    bootcamp_image_file,
    steam_image_file,
    lang_id,
    price
  } = entryData;

  const {
    data: languages,
    isLaoding,
    refetch,
  } = useQuery({
    queryKey: ["languageList"],
    queryFn: async () => await axios.get(`/api/languages/`),
    enabled: !!lang_id,
  });

  useEffect(() => {
    if (lang_id && languages) {
      const currentLang = languages?.data.find(
        (l) => l.id === lang_id
      )?.language;
      setSteamLang(currentLang);
    }
  }, [lang_id, languages]);

  function Item({ props }) {
    const { icon, text } = props;

    return (
      <Center>
        <Group noWrap>
          <ThemeIcon color={"gray.7"} variant="outline" size="xl">
            {icon}
          </ThemeIcon>
          <Text>{text}</Text>
        </Group>
      </Center>
    );
  }

  return (
    <ProgramSpecsStyle>
      <div className="thumbnail">
        {video?.includes("youtube.com") ? (
          <iframe
            src={video?.replace("watch?v=", "embed/")}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        ) : (
          <Image
            withPlaceholder
            fit="contain"
            src={
              image 
                ? image 
                : type === "steam" 
                  ? steam_image_file 
                  : type === "bootcamp"
                    ? bootcamp_image_file
                    : logo
            }
          />
        )}
      </div>

      <div className="gridx">
        <Grid >
          {country && (
            <Grid.Col span={6}>
              <div className="item">
                <Item props={{ icon: <Flag />, text: country.name }} />
              </div>
            </Grid.Col>
          )}

          {raiting && (
            <Grid.Col span={4}>
              <div className="item">
                <Item props={{ icon: <Star />, text: raiting }} />
              </div>
            </Grid.Col>
          )}
          {location && (
            <Grid.Col span={4}>
              <div className="item">
                <Item props={{ icon: <Location />, text: location }} />
              </div>
            </Grid.Col>
          )}

          {price && (
            <Grid.Col span={4}>
              <div className="item">
                <Item
                  props={{ icon: <CurrencyManat />, text: price }}
                />
              </div>
            </Grid.Col>
          )}

          {steamLang && (
            <Grid.Col span={4}>
              <div className="item">
                <Item props={{ icon: <Language />, text: steamLang }} />
              </div>
            </Grid.Col>
          )}

          {speciality && (
            <Grid.Col span={4}>
              <div className="item">
                <Item
                  props={{ icon: <Layout2 />, text: speciality[0]?.content }}
                />
                {category && (
                  <Item props={{ icon: <Layout2 />, text: category?.title }} />
                )}
              </div>
            </Grid.Col>
          )}
          {duration && (
            <Grid.Col span={4}>
              <div className="item">
                <Item
                  props={{
                    icon: <Clock />,
                    text:
                      duration > 1 ? duration + " hours" : duration + " hour",
                  }}
                />
              </div>
            </Grid.Col>
          )}
          {duration_time && (
            <Grid.Col span={4}>
              <div className="item">
                <Item
                  props={{
                    icon: <Clock />,
                    text:
                      duration_time > 1
                        ? duration_time + " weeks"
                        : duration_time + " week",
                  }}
                />
              </div>
            </Grid.Col>
          )}
          {course_deadline && (
            <Grid.Col span={4}>
              <div className="item">
                <Item
                  props={{
                    icon: <Calendar />,
                    text: course_deadline?.replaceAll("-", "-"),
                  }}
                />
              </div>
            </Grid.Col>
          )}

          {start_date && (
            <Grid.Col span={4}>
              <div className="item">
                <Item
                  props={{
                    icon: <Calendar />,
                    text: start_date?.replaceAll("-", "-"),
                  }}
                />
              </div>
            </Grid.Col>
          )}
        </Grid>
      </div>

      <div className="aboutMobile">
        <hr />
        <Spoiler maxHeight={150} showLabel="Show more" hideLabel="Hide">
          {requirement && parse(requirement)}
        </Spoiler>
      </div>
    </ProgramSpecsStyle>
  );
}

const ProgramSpecsStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.75rem;
  overflow: hidden;
  .gridx {
    padding: 1rem;
    .item {
      display: flex;
      justify-content: flex-start;
    }
  }
  .aboutMobile {
    display: none;
  }
  .about {
    display: none;
  }
  .thumbnail {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    iframe {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 338px;
      margin: 0 auto;
      border: none;
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 0;
    max-width: 100%;
    margin-top: -3rem;
    .aboutMobile {
      display: block;
      div {
        padding-bottom: 0.5rem;
      }
      p {
        font-size: 16px;
        line-height: 1.6rem;
        padding-bottom: 1rem;
      }
      button {
        background: none;
        text-decoration: none;
        margin: 0;
        margin-top: 0rem;
        padding: 0.5rem 0 0 0;
      }
    }
    .thumbnail {
      display: flex;
      justify-content: center;
      position: relative;
      margin: 0;
      margin-bottom: 1rem;
      iframe {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 200px;
        margin: 0 auto;
      }
    }
    hr {
      width: 100%;
      margin: 1.5rem 0;
    }
    .authoredFeeMobile {
      display: block;
      font-weight: 500;
      text-align: center;
      margin-bottom: 1.75rem;
    }
    .authoredFee {
      display: none;
    }
    button {
      width: 100%;
    }
  }
`;
