import React from "react";
import {
  Accordion,
  Button,
  Checkbox,
  Divider,
  FileInput,
  Grid,
  Group,
  Modal,
  NumberInput,
  Space,
  Stack,
  Table,
  Text,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { RichTextEditor } from "@mantine/rte";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { Plus } from "tabler-icons-react";
const initialValue =
  "<p>İmtahan növü: <b>IELTs</b>;</p>" +
  "<p>İmtahan balı: <b>6.5</b>;</p>" +
  "<p>Tələb: mövcuddur, lakin əksəriyyət dəyişikliklərə məruz qalmışdır</p>" +
  "<p>Tələb: İnternetdə generatorlar</p>";
const AcademicPrograms = ({ props }) => {
  const { id: universityId } = props;
  const [openFacultyModal, setOpenFacultyModal] = useState(false);
  const [openProfessionModal, setOpenProfessionModal] = useState(false);
  const [openRequirementModal, setOpenRequirementModal] = useState(false);
  const [selectedFacultyId, setSelectedFacultyId] = useState(null);
  const [selectedProfessionId, setSelectedProfessionId] = useState(null);
  const [accordionItemValue, setAccordionItemValue] = useState(null);
  const [requirement, setRequirement] = useState(null);
  const [type, setType] = useState(null);
  const [professionType, setProfessionType] = useState(null);
  const queryClient = useQueryClient();

  const { setValue, handleSubmit, watch } = useForm();

  const { data: faculties } = useQuery({
    queryKey: ["faculties"],
    queryFn: async () =>
      await axios
        .get(`/university/faculties/${universityId}/`)
        .then((res) => res?.data),
    enabled: !!universityId,
  });

  const { data: professions } = useQuery({
    queryKey: ["professions", accordionItemValue],
    queryFn: async () =>
      await axios
        .get(`/university/professions/${accordionItemValue}/`)
        .then((res) => res?.data),
    enabled: !!accordionItemValue,
  });

  // faculty
  const { mutate: createFaculty } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(`/university/faculty/create/`, data);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: "faculties" });
      setOpenFacultyModal(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { data: faculty } = useQuery({
    queryKey: ["faculty", selectedFacultyId],
    queryFn: async () =>
      await axios
        .get(`/university/facultybyid/${selectedFacultyId}/`)
        .then((res) => res?.data),
    enabled: !!selectedFacultyId && !!openFacultyModal,
  });

  const { mutate: editFaculty } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.patch(
        `/university/faculty/edit/${selectedFacultyId}/`,
        data
      );
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: "faculties" });
      setOpenFacultyModal(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { mutate: deleteFaculty } = useMutation({
    mutationFn: async (id) => {
      const response = await axios.delete(`/university/faculty/edit/${id}/`);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: "faculties" });
    },
    onError: (error) => {
      console.log(error);
    },
  });
  // faculty

  // profession
  const { mutate: createProfession } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(`/university/profession/create/`, data);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: "profession" });
      setOpenProfessionModal(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { data: profession } = useQuery({
    queryKey: ["profession", selectedProfessionId],
    queryFn: async () =>
      await axios
        .get(`/university/professionbyid/${selectedProfessionId}/`)
        .then((res) => res?.data),
    enabled: !!selectedProfessionId && !!openProfessionModal,
  });

  const { mutate: editProfession } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.patch(
        `/university/profession/edit/${selectedProfessionId}/`,
        data
      );
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["professions", accordionItemValue]);
      setOpenProfessionModal(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { mutate: deleteProfession } = useMutation({
    mutationFn: async (id) => {
      const response = await axios.delete(`/university/profession/edit/${id}/`);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["professions", accordionItemValue]);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  // profession



  const onSubmitFaculty = (data) => {
    data.university = universityId;
    type === "create" ? createFaculty(data) : editFaculty(data);
  };

  const onSubmitProfession = (data) => {
    const formData = new FormData();
    data.description && formData.append("description", data.description);
    data.profession && formData.append("profession", data.profession);
    data.duration && formData.append("duration", data.duration);
    data.curriculum && formData.append("curriculum", data.curriculum);
    data.cost && formData.append("cost", data.cost);
    formData.append(
      "is_scholarship",
      data.is_scholarship ? data.is_scholarship : false
    );
    formData.append("faculty", selectedFacultyId);
    professionType === "create" ?  createProfession(formData) : editProfession(formData);
  };

  const handleDownload = async (file) => {
    try {
      const link = document.createElement("a");
      link.href = file;
      document.body.appendChild(link);
      link.target = "_blank";
      link.click();
    } catch (error) {
      console.error("Dosya indirilemedi:", error);
    }
  };

  const rows = professions?.map((profession) => (
    <tr key={profession.id}>
      <td>{profession.profession}</td>
      <td>
        {" "}
        <Button
          variant="white"
          onClick={() => handleDownload(profession?.curriculum)}
        >
          Curriculum
        </Button>
      </td>
      <td>{profession.duration} il</td>
      <td>{profession.cost}</td>
      <td>{profession.is_scholarship ? "Var" : "yox"}</td>
      <td>
        <Stack spacing={2}>
          <Button
            color="orange"
            onClick={() => {
              setSelectedProfessionId(profession.id);
              setSelectedFacultyId(profession.faculty)
              setOpenProfessionModal(true);
              setProfessionType("edit");
            }}
          >
            Edit
          </Button>
          <Button
           onClick={() => {
            deleteProfession(profession.id)
          }}
          >Delete</Button>
        </Stack>
      </td>
    </tr>
  ));

  return (
    <div style={{ paddingBottom: "20px" }}>
      <Group position="apart">
        <Button color="gray.9" size="sm">
          Faculties
        </Button>

        <Button
          variant="white"
          color="orange"
          leftIcon={<Plus size={16} />}
          onClick={() => {
            setOpenFacultyModal(true);
            setType("create");
          }}
        >
          Fakultə əlavə et
        </Button>
      </Group>
      <Space my="xs" />
      <Divider orientation="horizontal" />
      <Space my="xs" />

      <Accordion value={accordionItemValue} onChange={setAccordionItemValue}>
        {faculties
          ? faculties.map((faculty, index) => (
              <Accordion.Item
                key={index}
                value={faculty ? `${faculty.id}` : "initial"}
              >
                <Accordion.Control>
                  <Grid justify="space-between">
                    <Grid.Col span={7}>
                      <Group position="apart">
                        <Group>
                          {index + 1}.
                          <Title size={14} weight={600} color="#202939">
                            {faculty.faculty}
                          </Title>
                        </Group>
                        <Button
                          style={{
                            backgroundColor: "#E6E7E8",
                            color: "#2C2D4D",
                            fontWeight: 400,
                            marginLeft: "40px",
                          }}
                          onClick={() => {
                            setRequirement(faculty.description);
                            setOpenRequirementModal(true);
                          }}
                        >
                          Tələblər
                        </Button>
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={5}>
                      <Group position="right">
                        <Group>
                          <Button
                            variant="white"
                            onClick={() => {
                              setOpenFacultyModal(true);
                              setSelectedFacultyId(faculty.id);
                              setType("edit");
                            }}
                          >
                            {" "}
                            Edit{" "}
                          </Button>
                          <Button
                            onClick={() => {
                              deleteFaculty(faculty.id);
                            }}
                          >
                            {" "}
                            Delete{" "}
                          </Button>

                          <Button
                            variant="white"
                            color="orange"
                            leftIcon={<Plus size={16} />}
                            onClick={() => {
                              setOpenProfessionModal(true);
                              setSelectedFacultyId(faculty.id);
                              setProfessionType("create");
                            }}
                          >
                            Ixtisas əlavə et
                          </Button>
                        </Group>
                      </Group>
                    </Grid.Col>
                  </Grid>
                </Accordion.Control>
                <Accordion.Panel>
                  <Table verticalSpacing="md">
                    <thead style={{ backgroundColor: "#F9E9D4" }}>
                      <tr>
                        <th>İxtisas adı |</th>
                        <th>Tədris proqramı |</th>
                        <th>Tədris müddəti |</th>
                        <th>Ödəniş |</th>
                        <th>Təqaüd |</th>
                        <th>Əməliyyatlar |</th>
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                  </Table>
                </Accordion.Panel>
              </Accordion.Item>
            ))
          : []}
      </Accordion>

      <Modal
        opened={openFacultyModal}
        onClose={() => setOpenFacultyModal(false)}
        title="Add Faculty Form"
      >
        <form onSubmit={handleSubmit(onSubmitFaculty)}>
          <Stack>
            <TextInput
              label="Fakultə"
              placeholder="İKT Fakultəsi"
              defaultValue={faculty && type === "edit" ? faculty.faculty : ""}
              onChange={(event) =>
                setValue("faculty", event.currentTarget.value)
              }
            />
            <Stack spacing={5}>
              <Title size={14} weight={600} color="#202939">
                Fakultənin tələbləri
              </Title>
              <RichTextEditor
                value={
                  faculty && type === "edit"
                    ? faculty.description
                    : initialValue
                }
                onChange={(e) => setValue("description", e)}
                id="rte"
              />
            </Stack>
            <Button color="orange" type="submit">
              {faculty && type === "edit" ? "Edit" : "Create"}
            </Button>
          </Stack>
        </form>
      </Modal>

      <Modal
        opened={openProfessionModal}
        onClose={() => setOpenProfessionModal(false)}
        title="Add Faculty Form"
      >
        <form onSubmit={handleSubmit(onSubmitProfession)}>
          <Stack spacing="xs">
            <TextInput
              label="İxtisas"
              placeholder="Komputer Elmləri"
              defaultValue={
                professionType === "edit" && profession
                  ? profession.profession
                  : ""
              }
              onChange={(event) =>
                setValue("profession", event.currentTarget.value)
              }
            />
            <Textarea
              label="Məlumat"
              placeholder="İxtisas haqqında məlumat daxil edin..."
              autosize
              minRows={4}
              maxRows={8}
              defaultValue={
                professionType === "edit" && profession
                  ? profession.description
                  : ""
              }
              onChange={(event) =>
                setValue("description", event.currentTarget.value)
              }
            />
            <TextInput
              label="Tədris müddəti"
              placeholder="4"
              defaultValue={
                professionType === "edit" && profession
                  ? profession.duration
                  : ""
              }
              onChange={(event) =>
                setValue("duration", event.currentTarget.value)
              }
            />
            <Stack spacing={3}>
              <Title size={14} weight={600} color="#202939">
                Tədris proqramı
              </Title>
              <FileInput
                placeholder="Tədris proqramını yükləyin (PDF)"
                onChange={(e) => setValue("curriculum", e)}
              />
            </Stack>

            <TextInput
              label="Qiymət"
              placeholder="100000"
              defaultValue={
                professionType === "edit" && profession ? profession.cost : ""
              }
              onChange={(e) => setValue("cost", e.target.value)}
            />
            <Space my={4} />
            <Group noWrap spacing={4}>
            <input
            type="checkbox"
              defaultChecked={
                professionType === "edit" && profession
                  ? profession.is_scholarship
                  : false
              }
              onChange={(event) =>
                setValue("is_scholarship", event.currentTarget.checked)
              }
            />
              <Title size={14} weight={500}  >
                Təqaüd var
              </Title>
            </Group>
           
            <Space my={4} />

            <Button color="orange" type="submit">
              {professionType === "edit" && profession ? "Edit" : "Create"}
            </Button>
          </Stack>
        </form>
      </Modal>

      <Modal
        opened={openRequirementModal}
        onClose={() => setOpenRequirementModal(false)}
        title="Fakultənin tələbləri"
      >
        <Text>
          <div dangerouslySetInnerHTML={{ __html: requirement }} />
        </Text>
      </Modal>
    </div>
  );
};

export default AcademicPrograms;
