
import {useQuery} from "@tanstack/react-query"
import axios from '../Helpers/Axios'
const useChoicesData = () => {
    const { data: schools } = useQuery(
        ["schools"],
        async () => await axios.get("/school/school_list/").then((res) => res?.data)
      );
    
      const { data: languages } = useQuery(
        ["languages"],
        async () => await axios.get("api/languages/").then((res) => res?.data)
      );
    
      const { data: categories } = useQuery(
        ["categories"],
        async () => await axios.get("/api/categories/  ").then((res) => res?.data)
      );
    
      const { data: specialities } = useQuery(
        ["specialities"],
        async () =>
          await axios.get("/api/v1/speciality/  ").then((res) => res?.data)
      );
    
      const { data: programGroups } = useQuery(
        ["groups"],
        async () =>
          await axios.get("/api/program_group_list/").then((res) => res?.data)
      );
    
      const { data: partnersList } = useQuery(
        ["partners"],
        async () => await axios.get("/api/partner_list/").then((res) => res?.data)
      );

      const { data: skills } = useQuery(
        ["skills"],
        async () => await axios.get("/api/skill_list/").then((res) => res?.data)
      );
      return {schools , languages, categories, specialities, programGroups, partnersList,skills}
}

export default useChoicesData
