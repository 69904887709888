import React, { useState } from "react";
import { ActionIcon, Grid, Group, Tabs, Text, Modal, Button } from "@mantine/core";
import styled from "styled-components";
import Tools from "./Tools";
import { Plus } from "tabler-icons-react";
import DayContent from "./Contents/DayContent";
import FileContent from "./Contents/FileContent";
import PhotoContent from "./Contents/PhotoContent";
import VideoContent from "./Contents/VideoContent";
import LinkContent from "./Contents/LinkContent";
import SkillContent from "./Contents/SkillContent";
import TaskContent from "./Contents/TaskContent";
import { IconMinus } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from '../../../Helpers/Axios'

const DaySection = ({
  type,
  handleSubUnitContent,
  activeSubUnitTab,
  setActiveSubUnitTab,
  currentUnit,
  currentSubUnit,
  handleAddSubUnit,
  handleCreateContent,
  handleDeleteSubUnitContent,
  handleDeleteSubUnit,
  id,
  createLoading,
  updateLoading,
  toolBtnClick,
  pSubUnits,
  pSubUnit,
  pUnits,
  pUnit,
  link,
  isContentSave,
  setIsContentSave

}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [subUnitToDelete, setSubUnitToDelete] = useState(null);
  const [subUnitFromDelete, setSubUnitFromDelete] = useState()
  const queryClient = useQueryClient();

  const deleteSubUnitMutation = useMutation(
    () => {
      return axios.delete(`/${link.program}/${id}/${pUnit}/${currentUnit?.id}/${pSubUnit}/${subUnitToDelete}/`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['program',id]); // Adjust the query key as needed
        handleDeleteSubUnit(null);
        setDeleteModalOpen(false);
      },
    }
  );

  const handleDeleteClick = (subUnitId,type) => {
    setSubUnitToDelete(subUnitId);
    setSubUnitFromDelete(type)
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    if (subUnitFromDelete === 'server') {
      deleteSubUnitMutation.mutate(subUnitToDelete);
      setDeleteModalOpen(false);

    } else {
      handleDeleteSubUnit(subUnitToDelete);
      setDeleteModalOpen(false);
    }
  };


  const hasSubUnitPhoto = currentSubUnit && `${pSubUnit}_photo` in currentSubUnit;
  const hasSubUnitFiles = currentSubUnit && "files" in currentSubUnit && currentSubUnit.files?.length > 0;
  const hasSubUnitLink = currentSubUnit && `${pSubUnit}_link` in currentSubUnit && currentSubUnit[`${pSubUnit}_link`] !=="";
  const hasSubUnitVideoLink = currentSubUnit && `${pSubUnit}_video_link` in currentSubUnit && currentSubUnit[`${pSubUnit}_video_link`] !== "";
  const hasSubUnitTask = currentSubUnit && "tasks" in currentSubUnit && (currentSubUnit.tasks === null || currentSubUnit.tasks.length > 0);
  const isUpdate = id ? true : false;
  console.log('activeSubUnitTab',activeSubUnitTab)
  return (
    <SectionStyle>
      <Text mb={15} size={20} weight={500} color="#121926">
        {`${pSubUnits.charAt(0).toUpperCase() + pSubUnits.slice(1)}`}
      </Text>

      <div className="days-container">
        <Grid sx={{ padding: "10px" }}>
          <Grid.Col span={8}>
            <Tabs
              value={activeSubUnitTab}
              onTabChange={setActiveSubUnitTab}
              variant="pills"
              defaultValue="gallery"
            >
              <Tabs.List className="day-tab-list">
                {currentUnit && currentUnit[`${pSubUnits}`] && currentUnit[`${pSubUnits}`]?.length > 0 ? (
                  currentUnit[`${pSubUnits}`]?.map((subUnit, subUnitIndex) => (
                    <Tabs.Tab
                      key={`${pSubUnit}-${subUnitIndex + 1}`}
                      value={`${pSubUnit}-${subUnitIndex + 1}`}
                      className="custom-tab"
                    >
                      <Group align="center" position="apart"> 
                        {pSubUnit.charAt(0).toUpperCase() + pSubUnit.slice(1)} {subUnitIndex + 1}
 
                          <IconMinus 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(subUnit.id ? subUnit.id :`${pSubUnit}-${subUnitIndex + 1}`,subUnit.id ? 'server' : 'local');
                          }}
                          size={16} />
                      </Group>
                    </Tabs.Tab>
                  ))
                ) : (
                  <Tabs.Tab
                    key={`${pSubUnit}-1`}
                    value={`${pSubUnit}-1`}
                    className="custom-tab"
                  >
                    {pSubUnit.charAt(0).toUpperCase() + pSubUnit.slice(1)} 1
                  </Tabs.Tab>
                )}
                <Tabs.Tab value="plus" onClick={handleAddSubUnit}>
                  <Plus />
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel
                className="day-tab-panel"
                value={activeSubUnitTab}
                pt="xs"
              >
                { currentSubUnit &&  currentSubUnit[`${pSubUnit}_content`] && (
                  <div className="content-style">
                    <div className="content-container">
                      <DayContent
                        pSubUnit={pSubUnit}
                        contentValue={currentSubUnit[`${pSubUnit}_content`]}
                        activeSubUnitTab={activeSubUnitTab}
                        setActiveSubUnitTab={setActiveSubUnitTab}
                        handleSubUnitContent={handleSubUnitContent}
                        setIsContentSave={setIsContentSave}
                      />
                    </div>
                  </div>
                )}

                {currentSubUnit?.skills_ids.length >= 0 && (
                  <div style={{ marginTop: "15px" }} className="content-style">
                    <div className="content-container">
                      <SkillContent
                        contentValue={currentSubUnit?.skills_ids}
                        activeSubUnitTab={activeSubUnitTab}
                        handleSubUnitContent={handleSubUnitContent}
                        setIsContentSave={setIsContentSave}
                      />
                    </div>
                  </div>
                )}

                {hasSubUnitFiles && (
                  <div style={{ marginTop: "15px" }} className="content-style">
                    <div className="content-container">
                      <FileContent
                        type={type}
                        link={link}
                        pUnit={pUnit}
                        pSubUnit={pSubUnit}
                        pSubUnits={pSubUnits}
                        contentValue={currentSubUnit?.files}
                        activeSubUnitTab={activeSubUnitTab}
                        handleSubUnitContent={handleSubUnitContent}
                        handleDeleteSubUnitContent={handleDeleteSubUnitContent}
                        programId={id}
                        unitId={currentUnit?.id}
                        subUnitId ={currentSubUnit?.id}
                        setIsContentSave={setIsContentSave}
                      />
                    </div>
                  </div>
                )}

                {hasSubUnitPhoto && (
                  <div style={{ marginTop: "15px" }} className="content-style">
                    <div className="content-container">
                      <PhotoContent
                        pSubUnit={pSubUnit}
                        contentValue={currentSubUnit && currentSubUnit[`${pSubUnit}_photo`]}
                        activeSubUnitTab={activeSubUnitTab}
                        handleSubUnitContent={handleSubUnitContent}
                        setIsContentSave={setIsContentSave}
                      />
                    </div>
                  </div>
                )}

                {hasSubUnitVideoLink && (
                  <div style={{ marginTop: "15px" }} className="content-style">
                    <div className="content-container">
                      <VideoContent
                        pSubUnit={pSubUnit}
                        contentValue={currentSubUnit && currentSubUnit[`${pSubUnit}_video_link`]}
                        activeSubUnitTab={activeSubUnitTab}
                        handleSubUnitContent={handleSubUnitContent}
                        handleDeleteSubUnitContent={handleDeleteSubUnitContent}
                        setIsContentSave={setIsContentSave}
                      />
                    </div>
                  </div>
                )}

                {hasSubUnitLink && (
                  <div style={{ marginTop: "15px" }} className="content-style">
                    <div className="content-container">
                      <LinkContent
                        pSubUnit={pSubUnit}
                        contentValue={currentSubUnit[`${pSubUnit}_link`]}
                        activeSubUnitTab={activeSubUnitTab}
                        handleSubUnitContent={handleSubUnitContent}
                        handleDeleteSubUnitContent={handleDeleteSubUnitContent}
                        setIsContentSave={setIsContentSave}
                      />
                    </div>
                  </div>
                )}

                {hasSubUnitTask && (
                  <TaskContent
                    link={link}
                    pUnit={pUnit}
                    pSubUnit={pSubUnit}
                    activeSubUnitTab={activeSubUnitTab}
                    handleSubUnitContent={handleSubUnitContent}
                    currentValue={currentSubUnit?.tasks}
                    handleDeleteSubUnitContent={handleDeleteSubUnitContent}
                    programId={id}
                    unitId={currentUnit?.id}
                    subUnitId ={currentSubUnit?.id}
                    setIsContentSave={setIsContentSave}
                  />
                )}
              </Tabs.Panel>
            </Tabs>
          </Grid.Col>
          <Grid.Col  span={4}>
            <Tools
              pSubUnit={pSubUnit}
              activeSubUnitTab={activeSubUnitTab}
              handleSubUnitContent={handleSubUnitContent}
              handleCreateContent={handleCreateContent}
              handleAddSubUnit={handleAddSubUnit}
              isUpdate={isUpdate}
              createLoading={createLoading}
              updateLoading={updateLoading}
              currentSubUnit={currentSubUnit}
              isContentSave={isContentSave}
              setIsContentSave={setIsContentSave}
            />
          </Grid.Col>
        </Grid>
      </div>

      <Modal
        opened={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="Confirm Deletion"
      >
        <Text>Are you sure you want to delete this day?</Text>
        <Group position="right" mt="md">
          <Button onClick={() => setDeleteModalOpen(false)} variant="outline">Cancel</Button>
          <Button onClick={confirmDelete} color="red">Delete</Button>
        </Group>
      </Modal>
    </SectionStyle>
  );
};

export default DaySection;

const SectionStyle = styled.div`
  margin-top: 20px;
  .days-container {
    .content-style {
      background-color: #fff;
      border-radius: 10px;
      .content-container {
        padding: 20px;
      }
    }
    background-color: #fff;
    border-radius: 10px;
    .day-tab-panel {
      background-color: #eff3f4;
      padding: 20px;
      border-radius: 0px 16px 16px 16px;
    }
    .day-tab-list {
      border-radius: 10px;
      gap: 0;
      button {
        background-color: #fff;
        border: 1px solid #e5e6ea;
        font-weight: 500;
        font-size: 16px;
        color: #c6c6c6;
        border-radius: 0px;
        height: 40px;
      }
      button[data-active] {
        background-color: #eff3f4;
        border: 1px solid #eff3f4;
        color: #121926;
      }
    }
    .day-tab-list > *:first-child {
      border-top-left-radius: 10px;
    }
    .day-tab-list > *:last-child {
      border-top-right-radius: 10px;
    }
  }
`;
