import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextInput, Select, Textarea, Text } from "@mantine/core";
import styled from "styled-components";
import useChoicesData from "../../Hooks/useChoicesData";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { ProgramContext } from "../../Helpers/context/ProgramContext";
import { useNavigate } from "react-router-dom";
import { convertToBase64 } from "../../Helpers";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { showNotification } from "@mantine/notifications";
import Loader from "../../Components/Common/Loader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomFileInput from "../../Components/Common/CustomFileInput";
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";

const getValidationSchema = (type) => Yup.object().shape({
  [`${type}_name`]: Yup.string().required(`${type === 'steam' ? 'STEAM' : 'Bootcamp'} name is required`),
  author_name: Yup.string().required("Author name is required"),
  [`${type}_about`]: Yup.string().required(`${type === 'steam' ? 'STEAM' : 'Bootcamp'} about is required`),
  author_about: Yup.string().required("Author about is required"),
  lang_id: Yup.string().required("Language is required"),
  duration_time: Yup.string().required("Duration is required"),
  price: Yup.number()
    .typeError("Price must be a number")
    .required("Price is required"),
  syllabus: Yup.mixed().required("Syllabus is required"),
  [`${type}_image`]: Yup.mixed().required(`${type === 'steam' ? 'STEAM' : 'Bootcamp'} image is required`),
  author_image: Yup.mixed().required("Author image is required"),
});

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #f0f4f8;
  padding: 2rem;
  border-radius: 8px;
  label {
    color: #364152;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 6px;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 1rem;
`;

const FullWidth = styled.div`
  flex: 1;
  .ck-editor__editable {
    min-height: 200px;
    
  }
  .ck-editor__editable_inline {
    padding: 0 10px;
  }
  .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border-left: none;
    border-right: none;
  }
  .ck.ck-editor__main > .ck-editor__editable {
    border-radius: 10px;
  }
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused,
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    padding-bottom: 40px;
  }
  .ck.ck-toolbar {
    border-radius: 10px 10px 0 0;
  }
  .ck.ck-editor__top.ck-reset_all {
    position: absolute;
    bottom: 5px;
    width: 99%;
    left: 0.5%;
    z-index: 1;
  }
  .ck-placeholder {
    color: #697586;
    font-size: 16px;
    font-weight: 400;
  }
  textarea {
    &::placeholder {
      color: #697586;
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

const HalfWidth = styled.div`
  flex: 1;

  input {
    &::placeholder {
      color: #697586;
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

export const ProgramForm = ({ props }) => {
  const { type, formType } = props;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getValidationSchema(type)),
    defaultValues: {
      [`${type}_name`]: "",
      author_name: "",
      [`${type}_about`]: "",
      author_about: "",
      lang_id: "",
      duration_time: "",
      price: "",
      syllabus: null,
      [`${type}_image`]: null,
      author_image: null,
    },
  });
  const { id } = useParams();
  const { languages } = useChoicesData();
  const { updateProgramData } = useContext(ProgramContext);
  const navigate = useNavigate();
  const [syllabusPreview, setSyllabusPreview] = useState(null);
  const [programImagePreview, setProgramImagePreview] = useState(null);
  const [authorImagePreview, setAuthorImagePreview] = useState(null);

  const link = type === 'steam' ?
  {
    program: "steam/steam",
  }:
  {
    program: "bootcamp",
  }

  const {
    data: program,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: ["program", id],
    queryFn: async () => {
      const res = await axios.get(`${link.program}/${id}/`);
      return res.data;
    },
    enabled: !!id,
  });

  const { mutate: updateProgram, isLoading: updateLoading } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.put(`${link.program}/${id}/`, data);
      return response;
    },
    onSuccess: ({ data }) => {
      navigate(`/${type === "bootcamp" ? "bootcamps" : "steam"}/${id}`);
      showNotification({
        title: "Success",
        message: "Content updated successfully",
        color: "teal",
      });
    },
    onError: (error) => {
      console.error("Error updating", error);
    },
    enabled: !!id,
  });

  const onSubmit = async (data) => {
    console.log('data',data)
    if (id) {
      const sendObj = {
        ...data,
        id: program.id,
        ...(type === 'steam' ? {
          weeks: program.weeks_output.map((week) => ({
            ...week,
            days: week.days_output.map((day) => ({
              ...day,
              day_photo: day.day_photo_output,
              files: day.files.map((file) => ({
                id: file.id,
                file_name: file.file_name,
                file_field: file.file_field_output, // eski key 'file_field_output' yeni key 'file_field'
              })),
            })),
          })),
        } : {
          sections: program.sections_output.map((section) => ({
            ...section,
            lessons: section.lessons_output.map((lesson) => ({
              ...lesson,
              lesson_photo: lesson.lesson_photo_output,
              files: lesson.files.map((file) => ({
                id: file.id,
                file_name: file.file_name,
                file_field: file.file_field_output, // eski key 'file_field_output' yeni key 'file_field'
              })),
            })),
          })),
        }),
      };
      // Dosya değişikliklerini kontrol et ve gerekiyorsa base64'e çevir
      if (data.syllabus !== syllabusPreview) {
        sendObj.syllabus = await convertToBase64(data.syllabus);
      }
      if (data[`${type}_image`] !== programImagePreview) {
        sendObj[`${type}_image`] = await convertToBase64(data[`${type}_image`]);
      }
      if (data.author_image !== authorImagePreview) {
        sendObj.author_image = await convertToBase64(data.author_image);
      }
      updateProgram(sendObj);
    } else {
      const fieldsToConvert = ["syllabus", "author_image", [`${type}_image`]];
      const conversions = await Promise.all(
        fieldsToConvert.map((field) => convertToBase64(data[field]))
      );
      fieldsToConvert.forEach((field, index) => {
        data[field] = conversions[index];
      });
      updateProgramData(data);
      localStorage.setItem("programData", JSON.stringify(data));
      navigate(`/${type}/content-create`);
    }
  };

  useEffect(() => {
    if (id && program) {
      reset({
        [`${type}_name`]: program[`${type}_name`] || "",
        author_name: program.author_name || "",
        [`${type}_about`]: program[`${type}_about`] || "",
        author_about: program.author_about || "",
        lang_id: program.lang_id || "",
        duration_time: program.duration_time || "",
        price: program.price || "",
        syllabus: program.syllabus_file || null,
        [`${type}_image`]: program[`${type}_image_file`] || null,
        author_image: program.author_image_file || null,
      });
      if (program.syllabus_file) {
        setSyllabusPreview(program.syllabus_file);
      }
      if (program[`${type}_image_file`]) {
        setProgramImagePreview(program[`${type}_image_file`]);
      }
      if (program.author_image_file) {
        setAuthorImagePreview(program.author_image_file);
      }
    }
  }, [id, reset, program, formType,type]);

  const handleFileChange = async (file, setPreview) => {
    if (file instanceof File) {
      try {
        const base64 = await convertToBase64(file);
        setPreview(base64);
      } catch (error) {
        console.error("Error converting file to base64:", error);
        setPreview(null);
      }
    } else {
      setPreview(file);
    }
  };
  useSetCurrentRoute(
    formType === 'create' 
      ? [
          {
            title: type === 'steam' ? "STEAM" : "Bootcamp",
            to: type === 'steam' ? "/steams" : "/bootcamps"
          },
          {
            title: "Create",
            to: "/create"
          }
        ]
      : [
          {
            title: type === 'steam' ? "STEAM" : "Bootcamp",
            to: type === 'steam' ? "/steams" : "/bootcamps"
          },
          {
            title: `${program && program[`${type}_name`]}`,
            to: `/${type === 'steam' ? 'steam' : 'bootcamps'}/${id}`
          },
          {
            title: "Update",
            to: `/${type === 'steam' ? 'steam' : 'bootcamp'}/edit/${id}`
          }
        ]
  );

  if (updateLoading) {
    return <Loader isLoading={isLoading || updateLoading} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <Row>
          <HalfWidth>
            <Controller
              name={`${type}_name`}
              control={control}
              render={({ field }) => (
                <TextInput
                  size="md"
                  radius={10}
                  {...field}
                  label={`${type === 'steam' ? 'STEAM' : 'Bootcamp'} name`}
                  placeholder="Name"
                  error={errors[`${type}_name`]?.message}
                />
              )}
            />
          </HalfWidth>

          <HalfWidth>
            <Controller
              name="author_name"
              control={control}
              render={({ field }) => (
                <TextInput
                  size="md"
                  radius={10}
                  {...field}
                  label="Author name"
                  placeholder="Name"
                  error={errors.author_name?.message}
                />
              )}
            />
          </HalfWidth>
        </Row>

        <FullWidth>
          <Text size={16} weight={400} color="#364152" mb={6}>
            {type === 'steam' ? 'STEAM' : 'Bootcamp'} about
          </Text>
          <Controller
            name={`${type}_about`}
            control={control}
            render={({ field }) => (
              <CKEditor
                editor={ClassicEditor}
                data={field.value}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  field.onChange(data);
                }}
                config={{
                  placeholder: `Enter ${type === 'steam' ? 'STEAM' : 'bootcamp'} about here...`, // Placeholder text
                }}
              />
            )}
          />
          {errors[`${type}_about`] && (
            <p
              style={{
                color: "red",
                fontSize: "14px",
                fontWeight: 300,
                marginTop: "6px",
              }}
            >
              {errors[`${type}_about`].message}
            </p>
          )}
        </FullWidth>
        <FullWidth>
          <Controller
            name="author_about"
            control={control}
            render={({ field }) => (
              <Textarea
                size="md"
                radius={10}
                {...field}
                label="Author about"
                placeholder="About"
                autosize
                minRows={4}
                error={errors.author_about?.message}
              />
            )}
          />
        </FullWidth>

        <Row>
          <HalfWidth>
            <Controller
              name="lang_id"
              control={control}
              render={({ field }) => (
                <Select
                  size="md"
                  radius={10}
                  {...field}
                  label="Language"
                  placeholder="Language"
                  data={
                    languages
                      ? languages?.map((language) => ({
                          value: language.id,
                          label: language.language,
                        }))
                      : []
                  }
                  error={errors.lang_id?.message}
                />
              )}
            />
          </HalfWidth>
          <HalfWidth>
            <Controller
              name="duration_time"
              control={control}
              render={({ field }) => (
                <TextInput
                  size="md"
                  radius={10}
                  {...field}
                  label="Duration"
                  placeholder="Duration"
                  type="number"
                  error={errors.duration_time?.message}
                />
              )}
            />
          </HalfWidth>
          <HalfWidth>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextInput
                  size="md"
                  radius={10}
                  {...field}
                  label="Price"
                  placeholder="Price"
                  type="number"
                  error={errors.price?.message}
                />
              )}
            />
          </HalfWidth>
        </Row>
        <FullWidth>
          <Controller
            name="syllabus"
            control={control}
            render={({ field }) => (
              <CustomFileInput
                label="Syllabus"
                placeholder="Upload file"
                accept=".pdf"
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  handleFileChange(e, setSyllabusPreview);
                }}
                error={errors.syllabus?.message}
              />
            )}
          />
        </FullWidth>
        <Row>
          <HalfWidth>
            <Controller
              name={`${type}_image`}
              control={control}
              render={({ field }) => (
                <CustomFileInput
                  label={`${type === 'steam' ? 'STEAM' : 'Bootcamp'} image`}
                  placeholder="Upload image"
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    handleFileChange(e, setProgramImagePreview);
                  }}
                  error={errors[`${type}_image`]?.message}
                  required
                />
              )}
            />
          </HalfWidth>
          <FullWidth>
            <Controller
              name="author_image"
              control={control}
              render={({ field }) => (
                <CustomFileInput
                  label="Author image"
                  placeholder="Upload image"
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    handleFileChange(e, setAuthorImagePreview);
                  }}
                  error={errors.author_image?.message}
                  required
                />
              )}
            />
          </FullWidth>
        </Row>
        <Button
          styles={() => ({
            root: {
              background: "#FE6027",
              height: "50px",
              borderRadius: "10px",
              color: "#fff",
              fontWeight: 500,
              fontSize: "16px",
              "&:hover": {
                background: "#FE6027",
              },
            },
          })}
          type="submit"
        >
          {id ? "Update" : "Next"}
        </Button>
      </FormContainer>
    </form>
  );
};
