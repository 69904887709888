import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Grid,
  Group,
  MultiSelect,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mantine/dates";
import { RichTextEditor } from "@mantine/rte";
import linkIcon from "../../Assets/Svgs/university/LinkIcon.svg";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import styled from "styled-components";
import { Calendar, Confetti } from "tabler-icons-react";
import CustomCheckbox from "../../Components/CustomCheckbox";
import { showNotification } from "@mantine/notifications";
import { CurrentRouteContext } from "../../Helpers/HeaderContext";
import { formatDate } from "../../Helpers";

const ProfessionForm = ({ type }) => {
  const { uId: universityId, pId: professionId } = useParams();
  const { setIsModalOpen } = useContext(CurrentRouteContext);

  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      degree: "",
      profession: "",
      link: "",
      language: [],
      cost: "",
      is_scholarship: false,
      start_date: null,
      end_date: null,
      deadline: null,
      speciality: "",
      fee: "",
      description: "",
    },
  });

  const [isScholarship, setIsScholarship] = useState(false);

  const { data: specialities } = useQuery({
    queryKey: ["specialities"],
    queryFn: async () =>
      await axios.get("/api/v1/speciality/").then((res) => res?.data),
  });

  const { data: languages } = useQuery({
    queryKey: ["languages"],
    queryFn: async () =>
      await axios.get("/api/languages/").then((res) => res?.data),
  });

  const {data : degrees} = useQuery({
    queryKey: ["degrees"],
    queryFn: async () =>
      await axios.get("/university/degrees/").then((res) => res?.data),
  })
  console.log('degerees',degrees);

  const { data: profession } = useQuery({
    queryKey: ["profession", professionId],
    queryFn: async () =>
      await axios
        .get(`/university/professionbyid/${professionId}/`)
        .then((res) => res?.data),
    enabled: !!professionId,
  });

  const { mutate: createProfession } = useMutation({
    mutationKey: ["createProfession"],
    mutationFn: (data) => {
      return axios.post("/university/profession/create/", data);
    },
    onSuccess: (data) => {
      showNotification({
        icon: <Confetti />,
        title: "Congratulations!",
        message: "You have successfully created",
        color: "teal",
      });
    },
    onError: (error) => {
      console.error("Create profession error:", error);
    },
  });

  const { mutate: editProfession } = useMutation({
    mutationKey: ["editProfession"],
    mutationFn: (data) => {
      return axios.patch(`/university/profession/edit/${professionId}/`, data);
    },
    onSuccess: (data) => {
      showNotification({
        icon: <Confetti />,
        title: "Congratulations!",
        message: "You have successfully updated",
        color: "teal",
      });
    },
    onError: (error) => {
      console.error("Edit profession error:", error);
    },
  });

  console.log('profession',profession);

  useEffect(() => {
    if (type === "edit" && profession) {
      setValue("degree", profession.degree || "");
      setValue("profession", profession.profession || "");
      setValue("link", profession.link || "");
      setValue("language", profession.language || []);
      setValue("cost", profession.cost || "");
      setIsScholarship(profession.is_scholarship || false);
      setValue(
        "start_date",
        profession.start_date ? new Date(profession.start_date) : null
      );
      setValue(
        "end_date",
        profession.end_date ? new Date(profession.end_date) : null
      );
      setValue(
        "deadline",
        profession.deadline ? new Date(profession.deadline) : null
      );
      setValue("speciality", profession.speciality || "");
      setValue("fee", profession.fee || "");
      setValue("description", profession.description || "");
    }
  }, [type, profession, setValue]);

  const onSubmit = (data) => {
    data.is_scholarship = isScholarship;
    data.university = universityId;
    data.active = true;
    data.is_shared = true;
    data.start_date = formatDate(data.start_date)
    data.end_date = formatDate(data.end_date)
    data.deadline = formatDate(data.deadline)
    type === "edit" ? editProfession(data) : createProfession(data);
  };

  useEffect(() => {
    setIsModalOpen(false);
  }, []);

  return (
    
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Col span={12}>
          <Controller
            name="degree"
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                size="sm"
                className="form-input"
                placeholder="Degree"
                radius={10}
                label="Program degree"
                data={
                  degrees
                    ? degrees.map((degree) => ({
                        value: degree.id,
                        label: degree.name,
                      }))
                    : []
                }
              />
            )}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <TextInput
            placeholder="Program"
            size="sm"
            className="form-input"
            radius={10}
            label="Program"
            {...register("profession")}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <TextInput
            placeholder="Program link"
            size="sm"
            className="form-input"
            radius={10}
            label="Program link"
            {...register("link")}
            icon={<img width={19} height={19} src={linkIcon} alt="link" />}
          />
        </Grid.Col>
        <Grid.Col sm={4}>
          <Controller
            name="language"
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                placeholder="Language"
                size="sm"
                className="form-input"
                radius={10}
                label="Language"
                data={
                  languages
                    ? languages.map((language) => ({
                        value: language.id,
                        label: language.language,
                      }))
                    : []
                }
              />
            )}
          />
        </Grid.Col>
        <Grid.Col sm={4}>
          <TextInput
            placeholder="Program cost"
            size="sm"
            className="form-input"
            radius={10}
            label="Program cost"
            {...register("cost")}
          />
        </Grid.Col>
        <Grid.Col sm={4}>
          <Group mt={31} noWrap spacing={5}>
            <CustomCheckbox
              checked={isScholarship}
              onChange={() => setIsScholarship(!isScholarship)}
            />
            <div
              style={{
                height: "40px",
                width: "100%",
                border: "1px solid #D6D5D5",
                display: "flex",
                alignItems: "center",
                background: "#fff",
                padding: "0 10px",
                borderRadius: "10px",
              }}
            >
              Government Azerbaijan Scholarship
            </div>
          </Group>
        </Grid.Col>
        <Grid.Col sm={4}>
          <Controller
            name="start_date"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                placeholder="Pick start date"
                icon={<Calendar size={18} />}
                size="sm"
                className="form-input"
                radius={10}
                label="Start date"
                inputFormat="YYYY-MM-DD"
                labelFormat="YYYY-MM"
              />
            )}
          />
        </Grid.Col>
        <Grid.Col sm={4}>
          <Controller
            name="end_date"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                placeholder="Pick end date"
                icon={<Calendar size={18} />}
                size="sm"
                className="form-input"
                radius={10}
                label="End date"
                inputFormat="YYYY-MM-DD"
                labelFormat="YYYY-MM"
              />
            )}
          />
        </Grid.Col>
        <Grid.Col sm={4}>
          <Controller
            name="deadline"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                placeholder="Pick deadline date"
                icon={<Calendar size={18} />}
                size="sm"
                className="form-input"
                radius={10}
                label="Deadline"
                inputFormat="YYYY-MM-DD"
                labelFormat="YYYY-MM"
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Controller
            name="speciality"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Pick one specialty"
                size="sm"
                className="form-input"
                radius={10}
                label="Specialty"
                data={
                  specialities?.map((speciality) => ({
                    value: speciality?.id,
                    label: speciality?.field,
                  })) ?? []
                }
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Textarea
            size="sm"
            minRows={4}
            autosize
            radius={10}
            className="form-input"
            placeholder="Education Fee and Scholarship"
            label="Education Fee and Scholarship"
            {...register("fee")}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Stack spacing={0}>
            <Text className="editor-label">Admission requirements:</Text>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <RichTextEditor {...field} radius={10} label="About" />
              )}
            />
          </Stack>
        </Grid.Col>
      </Grid>

      <StyledButton type="submit">{type === "edit" ? "Update" : "Create"}</StyledButton>
    </FormWrapper>
  );
};

export default ProfessionForm;

const FormWrapper = styled.form`
  max-width: 100%;
  margin: auto;
  padding: 20px 100px 20px 20px;

  .form-input {
    textarea::placeholder {
      color: #697586;
      font-size: 16px;
      font-weight: 400;
    }
    input {
      height: 40px;
    }
    input::placeholder {
      color: #697586;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .editor-label,
  label {
    color: #364152;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 6px;
  }
`;

const StyledButton = styled(Button)`
  background-color: #fe6027;
  width: 100%;
  height: 56px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  margin-top: 20px;
`;
