import React, { useContext } from "react";
import styled from "styled-components";
import Navbar from "./Navbar";
import Header from "./Header";
import { CurrentRouteContext } from "../../Helpers/HeaderContext";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const { currentRoute, isModalOpen } = useContext(CurrentRouteContext);
  const { pathname } = useLocation();

  return (
    <LayoutStyle pathname={pathname} >
      <NavbarContainer>
        <Navbar />
      </NavbarContainer>
      <ContentContainer>
        {pathname !== "/olympic/created" && (
          <HeaderContainer isModalOpen={isModalOpen}>
            <Header currentRoute={currentRoute} />
          </HeaderContainer>
        )}

        <MainContainer>{children}</MainContainer>
      </ContentContainer>
    </LayoutStyle>
  );
};

export default Layout;

const LayoutStyle = styled.div`
  display: flex;
  background-color: ${props => props.pathname.includes('user-list') ? "#eff3f4" : "#f2f2f2" } ;
`;

const NavbarContainer = styled.div`
  background: #fff;
  height: 100vh;
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  border-right: 1px solid #e6e6e6;
  position: sticky;
  top: 0;
`;

const HeaderContainer = styled.div`
  background: #fff;
  height: 85px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #e6e6e6;
  position: sticky;
  top: 0;
  z-index: ${(props) => (props.isModalOpen ? 0 : 10)};
`;

const MainContainer = styled.div`
  padding: 20px;
`;

const ContentContainer = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
`;
