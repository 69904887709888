import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { iconDatas } from "./data";
import { Button, Text } from "@mantine/core";
import { Check } from "tabler-icons-react";

const IconList = ({pUnit, handleUnitContent, activeTab, iconSrc, isContentSave, setIsContentSave }) => {
  const [selectedIconIndex, setSelectedIconIndex] = useState(null);
  const [selectedIconSrc, setSelectedIconSrc] = useState(""); // Tıklanan ikonun src'ini saklamak için state

  const getIconSrc = (icon, isSelected, replacedColor) => {
    if (isSelected) {
      return icon.src.replace("%23CDCDCD", replacedColor); // stroke rengini değiştir
    }
    return icon.src;
  };

  const handleClick = (index) => {
    setSelectedIconIndex(index);
    setSelectedIconSrc(iconDatas[index].src);
    setIsContentSave(true)
  };

  useEffect(() => {
    if (selectedIconSrc) {
      const index = activeTab.split("-")[1] - 1;
      handleUnitContent(`${pUnit}_icon`, selectedIconSrc, index); // Tıklanan ikonun src'ini ve index'ini handleWeekContent'a gönder
    }
  }, [selectedIconSrc]);

  useEffect(() => {
    if(iconSrc) {
      setSelectedIconSrc(iconSrc);
    } else {
      setSelectedIconSrc(null);
    }
  }, [iconSrc, activeTab]);

  return (
    <>
      <Text mb={10} mt={15} size={20} weight={500} color="#121926">
        {pUnit.charAt(0).toUpperCase() + pUnit.slice(1)} Icons
      </Text>
      <ListContainer>
        <ListStyled>
          {iconDatas.map((icon, index) => (
            <StyledIconButton
              key={index}
              className="icon-btn"
              onClick={() => handleClick(index)}
              isSelected={iconDatas[index].src === selectedIconSrc}
            >
              <img
                src={getIconSrc(icon, iconDatas[index].src === selectedIconSrc, "%23029199")}
                alt={`icon-${index}`}
              />
              {iconDatas[index].src === selectedIconSrc && (
                <CheckIcon>
                  <Check size={14} color="#fff" />
                </CheckIcon>
              )}
            </StyledIconButton>
          ))}
        </ListStyled>
      </ListContainer>
    </>
  );
};

export default IconList;

const ListContainer = styled.div`
  border: 1px solid #e3e8ef;
  border-radius: 10px;
  background-color: #fff;
`;

const ListStyled = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const StyledIconButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 7px;
  border: ${(props) =>
    props.isSelected ? "1.7px solid #029199" : "1.6px solid #cdcdcd"};
  position: relative;
  background-color: transparent;
  padding: 0;
  &:hover {
    background-color: transparent;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1.7px solid #029199;
    `}
`;

const CheckIcon = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #029199;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
