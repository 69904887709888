import styled from "styled-components";
import { AboutProgram } from "./AboutProgram";
import { ProgramSpecs } from "./ProgramSpecs";

import AcademicPrograms from "./AcademicPrograms";

export function ProgramInfo({ props }) {
  const { entryData, user, type,id } = props;

  return (
    <ProgramInfoStyle>
      {/* <CurrentLocation props={{ entryData, type }} /> */}

      <div className="wrapper">
        <AboutProgram props={{ type,entryData }} />
        <div className="right">
          <ProgramSpecs props={{ type,entryData }} />
          {/* <Payment props={{ user, entryData, type }} /> */}
        </div>

      </div>
      {type === "university" && (<AcademicPrograms props={{id}} />)}

    </ProgramInfoStyle>
  );
}

const ProgramInfoStyle = styled.div`
  width: 95%;
  margin: 0 auto;
  padding: 0.5rem auto 5rem auto;
  .wrapper {
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    grid-gap: 1.5rem;
    margin: 0.5rem 0;
  }
  @media screen and (max-width: 1024px) {
    width: 90%;
    margin: 0 auto;
    padding: 0rem;
    .wrapper {
      grid-template-columns: 1fr;
      margin-top: 0rem;
      margin-bottom: 3rem;
    }
  }
`;


