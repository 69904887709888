import React from "react";
import { Group, Text } from "@mantine/core";
import styled from "styled-components";
import CustomButton from "../Common/CustomButton";
import { X } from "tabler-icons-react";
import axios from "../../Helpers/Axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const ProgramCard = ({ applied, name, id, onDeleteClick,type }) => {


  return (
    <Container>
      <DeleteButton onClick={() => onDeleteClick(id)}>
        <X size={18} />
      </DeleteButton>
      <div className="styled">
        <Text size={18} weight={500} color="#364152">{name}</Text>
        <div className="line"></div>
        <Text size={16} weight={400} color="#676767">Applied Students: {applied}</Text>
        <Group mt={5} noWrap align="center" position="center" >
          <CustomButton
            text="View"
            bgColor="transparent"
            color="#676767"
            height="45px"
            border="1px solid #676767"
            width = "48%"
            isLink={true}
            href={`/${type === "bootcamp" ? "bootcamps" : "steam"}/${id}/user-list`}
          />
          <CustomButton
            text="Edit"
            bgColor="#FE6027"
            color="#fff"
            height="48px"
            border="1px solid #FE6027"
            width = "50%"
            isLink={true}
            href={`/${type === "bootcamp" ? "bootcamps" : "steam"}/${id}`}
          />
        </Group>
      </div>
    </Container>
  );
};

// ... existing styled components ...

const DeleteButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #676767;
  &:hover {
    color: #FE6027;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export default ProgramCard;

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0px 1px 10px 0px #0000001a;
  border-radius: 11px;
  position: relative;
  min-height: 200px;
  .styled {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
  }
`;
