import { Book, Briefcase, Building, CaretDown, Chalkboard, ChevronDown, LayoutDashboard, Medal, Package, ReportAnalytics, School, Steam, UserCircle, Users } from "tabler-icons-react";

export const NavLinks = [
    {
        "title" : "Dashboard",
        "to" : "/",
        "icon" : <LayoutDashboard strokeWidth={1.75}  />,
        "isDropdown" : false
    },
    {
        "title" : "Package",
        "to" : "/packages",
        "icon" : <Package strokeWidth={1.75}  />,
        "isDropdown" : false
    },
    {
        "title" : "Students",
        "tabOne" : "/contacted-students",
        "tabTwo" : "/admitted-students",
        "pathOne" : "/student-profile",
        "icon" : <School strokeWidth={1.75}  />,
        "iconChevron" : <CaretDown fill="#364152" strokeWidth={1.75} size={17} />,
        "isDropdown" : true
    },
];

export const UniversitiesNavLinks = [
    {
        "title" : "Dashboard",
        "to" : "/",
        "icon" : <LayoutDashboard strokeWidth={1.75}  />,
        "isDropdown" : false
    },
    {
        "title" : "Study Abroad",
        "tabOne" : "/universities",
        "tabTwo" : "/admitted-students",
        "pathOne" : "/universities",
        "icon" : <School strokeWidth={1.75}  />,
        "iconChevron" : <CaretDown fill="#364152" strokeWidth={1.75} size={17} />,
        "isDropdown" : true
    },
    {
        "title" : "Steams",
        "to" : "/steams",
        "icon" : <Steam strokeWidth={1.75}  />,
        "isDropdown" : false
    },
    {
        "title" : "Bootcamps",
        "to" : "/bootcamps",
        "icon" : <Book strokeWidth={1.75}  />,
        "isDropdown" : false
    },
]

export const AdminNavLinks = [
    {
        "title" : "Dashboard",
        "to" : "/",
        "icon" : <LayoutDashboard strokeWidth={1.75}  />,
        "isDropdown" : false
    },
    {
        "title" : "Talants",
        "to" : "/talants",
        "icon" : <Users strokeWidth={1.75}  />,
        "isDropdown" : false
    },
    {
        "title" : "Reports",
        "to" : "/reports",
        "icon" : <ReportAnalytics strokeWidth={1.75}  />,
        "isDropdown" : false
    },
    {
        "title" : "Users",
        "to" : "/users",
        "icon" : <UserCircle strokeWidth={1.75}  />,
        "isDropdown" : false
    },
    {
        "title" : "Olympics",
        "to" : "/olympic/created",
        "icon" : <Medal strokeWidth={1.75}  />,
        "isDropdown" : false
    },
    {
        "title" : "Steams",
        "to" : "/steams",
        "icon" : <Steam strokeWidth={1.75}  />,
        "isDropdown" : false
    },
    {
        "title" : "Internship",
        "to" : "/internship/created",
        "icon" : <Briefcase strokeWidth={1.75}  />,
        "isDropdown" : false
    },
    {
        "title" : "Courses",
        "to" : "/course/created",
        "icon" : <Chalkboard strokeWidth={1.75}  />,
        "isDropdown" : false
    },
]