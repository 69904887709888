import { useContext } from "react";
import { AppContext } from "./Context";
import { Routes, Route, BrowserRouter } from "react-router-dom";

// Auth
import { Login } from "../Pages/Authentication/Login/Login";
import Register from "../Pages/Authentication/Register/_Register";
import ResetPasswordSuccess from "../Pages/Authentication/ResetPassword/_ResetPassword";

import PublicProfilePage from "../Pages/PublicProfile/_Profile";

import { Dashboard } from "../Components/Dashboard";
import { Reports } from "../Pages/Reports/Reports";
import { People } from "../Pages/People/People";

// Programs
import { ProgramForm } from "../Pages/Programs/ProgramForm";
import { CreatedPrograms } from "../Components/CreatedPrograms";
import { CreateProgram } from "../Components/CreateProgram/_UploadCourse";
import { Program } from "../Components/ProgramInfo/_Program";
import { EditProgramInfo } from "../Components/ProgramInfo/EditProgramInfo/EditProgramInfo";
import { ContentEditor } from "../Components/ContentEditor/_ContentEditor";
import { EmptyDash } from "../Components/EmptyDash";
import { Olympics } from "../Pages/Olympic/Olympics";
import { Events } from "../Pages/Event/Events";
import Layout from "../Components/Layout";
import ContactedStudents from "../Pages/Agents/ContactedStudents";
import AdmittedStudents from "../Pages/Agents/AdmittedStudents";
import AdmittedUserProfile from "../Pages/Agents/AdmittedUserProfile";
import Profile from "../Pages/Agents/Profile";
import Package from "../Pages/Agents/Package";
import Universities from "../Pages/University/Universities";
import ProfessionForm from "../Pages/University/ProfessionForm";
import UniversityForm from "../Pages/University/UniversityForm";
import PackageForm from "../Components/Agents/Package/PackageForm";
import { ForgotPassword } from "../Pages/Authentication/ForgotPassword";
import CreateContent from "../Pages/Steam/CreateContent";
import ProgramList from "../Pages/Steam/ProgramList";
import StudentDashboard from "../Pages/Steam/UserList";
import AnswerToTask from "../Pages/Steam/AnswerToTask";

export function RouteLogic() {
  let { user } = useContext(AppContext);

  return (
    <BrowserRouter>
      {user?.id ? (
        <Layout>
          <PrivatePages props={{ user }} />
        </Layout>
      ) : (
        <PublicPages />
      )}
    </BrowserRouter>
  );
}

function PublicPages() {
  return (
    <Routes>
      {/* Site Auth */}
      <Route path="/" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/password-reset/:uuid/:token"
        element={<ResetPasswordSuccess />}
      />
      <Route path="forgot-password" element={<ForgotPassword />} />
    </Routes>
  );
}

function PrivatePages({ props }) {
  const { user } = props;
  const userType = user?.user_type.type;

  return (
    <Routes>
      <Route path="/" element={<EmptyDash />} />
      <Route path="/profile" element={<Profile />} />

      {userType === "Company" && (
        <>
          <Route path="/universities" element={<Universities />} />
          <Route
            path="/create-university"
            element={<UniversityForm type="create" />}
          />
          <Route
            path="/edit-university/:universityId"
            element={<UniversityForm type="edit" />}
          />
          <Route
            path="/admitted-students"
            element={<AdmittedStudents type="university" />}
          />
          <Route
            path="/student-profile/:uId"
            element={<AdmittedUserProfile type="university" />}
          />

          <Route
            path="/create-profession/:uId"
            element={<ProfessionForm type="create" />}
          />
          <Route
            path="/edit-profession/university/:uId/profession/:pId"
            element={<ProfessionForm type="edit" />}
          />
          {/* 
          <Route
            path="/steam/created"
            element={<CreatedPrograms props={{ type: "steam" }} />}
          /> */}

          {/* <Route
            path="/steam/add/:id"
            element={<ContentEditor props={{ entryType: "steam" }} />}
          /> */}

          <Route
            path="/steam/:id"
            element={<Program props={{ isTaskool: true, type: "steam" }} />}
          />
          <Route
            path="/steam/create"
            element={
              <ProgramForm props={{ type: "steam", formType: "create" }} />
            }
          />
          <Route
            path="/steam/edit/:id"
            element={
              <ProgramForm props={{ type: "steam", formType: "update" }} />
            }
          />
          <Route
            path="/steam/content-create"
            element={<CreateContent props={{ type: "steam" }} />}
          />
          <Route
            path="/steam/:id/content-update"
            element={<CreateContent props={{ type: "steam" }} />}
          />
          <Route
            path="/steams"
            element={<ProgramList props={{ type: "steam" }} />}
          />
          <Route path="/steam/:id/user-list" element={<StudentDashboard type="steam" />} />
          <Route path="/bootcamps/:id/user-list" element={<StudentDashboard type="bootcamp" />} />
          <Route
            path="/steam/:id/task/:taskId/user/:userId"
            element={<AnswerToTask type="steam" />}
          />
            <Route
            path="/bootcamp/:id/task/:taskId/user/:userId"
            element={<AnswerToTask type="bootcamp" />}
          />

          {/* <Route
            path="/steam/edit/:id"
            element={<EditProgramInfo props={{ type: "steam" }} />}
          /> */}

          {/* bootcamp */}
          <Route
            path="/bootcamps"
            element={<ProgramList props={{ type: "bootcamp" }} />}
          />

          <Route
            path="/bootcamps/:id"
            element={<Program props={{ isTaskool: true, type: "bootcamp" }} />}
          />

          <Route
            path="/bootcamp/create"
            element={
              <ProgramForm props={{ type: "bootcamp", formType: "create" }} />
            }
          />

          <Route
            path="/bootcamp/edit/:id"
            element={
              <ProgramForm props={{ type: "bootcamp", formType: "update" }} />
            }
          />

          <Route
            path="/bootcamp/content-create"
            element={<CreateContent props={{ type: "bootcamp" }} />}
          />
          <Route
            path="/bootcamp/:id/content-update"
            element={<CreateContent props={{ type: "bootcamp" }} />}
          />
        </>
      )}

      {userType === "Agent" && (
        <>
          <Route path="/contacted-students" element={<ContactedStudents />} />
          <Route
            path="/admitted-students"
            element={<AdmittedStudents type="agent" />}
          />
          <Route
            path="/student-profile/:uId"
            element={<AdmittedUserProfile type="agent" />}
          />
          <Route path="/packages" element={<Package />} />
          <Route
            path="/packages/create-form"
            element={<PackageForm type="create" />}
          />
          <Route
            path="/packages/edit-form/:pId"
            element={<PackageForm type="edit" />}
          />
        </>
      )}

      {user?.id === 56 && (
        <>
          <Route
            path="/talants"
            element={<People props={{ program: "taskool" }} />}
          />
          <Route
            path="/reports"
            element={
              <Reports props={{ program: "taskool", type: "reports" }} />
            }
          />
          <Route
            path="/users"
            element={<Reports props={{ program: "taskool", type: "users" }} />}
          />
          <Route path="/olympic/created" element={<Olympics />} />
          {/* steam */}
          {/* <Route
            path="/steam/created"
            element={<CreatedPrograms props={{ type: "steam" }} />}
          /> */}

          {/* <Route
            path="/steam/add/:id"
            element={<ContentEditor props={{ entryType: "steam" }} />}
          /> */}
          {/* new Steam route */}
          <Route
            path="/steam/:id"
            element={<Program props={{ isTaskool: true, type: "steam" }} />}
          />
          <Route
            path="/steam/create"
            element={<ProgramForm props={{ type: "steam" }} />}
          />
          <Route
            path="/steam/edit/:id"
            element={<ProgramForm props={{ type: "update" }} />}
          />
          <Route
            path="/steam/content-create"
            element={<CreateContent props={{ type: "steam" }} />}
          />

          <Route
            path="/steam/:id/content-update"
            element={<CreateContent props={{ type: "steam" }} />}
          />

          <Route
            path="/steams"
            element={<ProgramList props={{ type: "steam" }} />}
          />

          <Route
            path="/steam/:id/user/:userId"
            element={<StudentDashboard />}
          />

          <Route
            path="/steam/:id/task/:taskId/user/:userId"
            element={<AnswerToTask />}
          />

          {/* new Steam route */}

          <Route
            path="/bootcamps"
            element={<ProgramList props={{ type: "bootcamp" }} />}
          />

          {/* <Route
            path="/steam/edit/:id"
            element={<EditProgramInfo props={{ type: "steam" }} />}
          /> */}
          {/* steam */}

          {/* internsips */}
          {/* Internships */}
          <Route
            path="/internship/:id"
            element={
              <Program props={{ isTaskool: true, type: "internship" }} />
            }
          />
          <Route
            path="/internship/edit/:id"
            element={<EditProgramInfo props={{ type: "internship" }} />}
          />
          <Route
            path="/internship/add/:id"
            element={<ContentEditor props={{ entryType: "internship" }} />}
          />
          {/* <Route
            path="/internship/create"
            element={<CreateProgram props={{ type: "internship" }} />}
          />
          <Route
            path="/internship/created"
            element={<CreatedPrograms props={{ type: "internship" }} />}
          /> */}
          {/* internships */}

          {/* courses */}

          {/* Courses */}
          <Route
            path="/course/:id"
            element={<Program props={{ isTaskool: true, type: "course" }} />}
          />
          <Route
            path="/course/edit/:id"
            element={<EditProgramInfo props={{ type: "course" }} />}
          />
          <Route
            path="/course/add/:id"
            element={<ContentEditor props={{ entryType: "course" }} />}
          />
          <Route
            path="/course/create"
            element={<CreateProgram props={{ type: "course" }} />}
          />
          <Route
            path="/course/created"
            element={<CreatedPrograms props={{ type: "course" }} />}
          />

          {/* courses */}
        </>
      )}

      {/* <Route
        path="/profile/:id"
        element={<PublicProfilePage props={{ isTaskool: true }} />}
      /> */}

      <Route path="/event/created" element={<Events />} />

      {/* STEAM */}

      {/* University */}
      <Route
        path="/university/create"
        element={<UniversityForm props={{ type: "create" }} />}
      />
      <Route
        path="/university/edit/:universityId"
        element={<UniversityForm props={{ type: "edit" }} />}
      />

      <Route
        path="/university/created"
        element={<CreatedPrograms props={{ type: "university" }} />}
      />

      <Route
        path="/university/:id"
        element={<Program props={{ isTaskool: true, type: "university" }} />}
      />

      <Route
        path="/password-reset/:uuid/:token"
        element={<ResetPasswordSuccess />}
      />
    </Routes>
  );
}
