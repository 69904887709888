import React, { useContext, useState } from "react";
import { Group, Menu, Stack, Text, Title } from "@mantine/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  BellRinging,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  Language,
} from "tabler-icons-react";
import userImg from "../../../Assets/Svgs/header/user.png";
import logOut from "../../../Assets/Svgs/header/logOut.svg";
import userIcon from "../../../Assets/Svgs/header/userIcon.svg";
import { Link } from "react-router-dom";
import { AppContext } from "../../../Helpers/Context";
import Cookies from "universal-cookie";


const Header = ({currentRoute}) => {
  const [currentNav, setCurrentNav] = useState();
  const { user } = useContext(AppContext);
  const cookie = new Cookies();
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("tsklng") || "en"
  );

  const { t, i18n } = useTranslation();

  const langs = [
    {
      iso: "Az",
      click: () => changeLang("az"),
    },
    {
      iso: "En",
      click: () => changeLang("en"),
    },
    {
      iso: "Ru",
      click: () => changeLang("ru"),
    },
    {
      iso: "Tr",
      click: () => changeLang("tr"),
    },
  ];

  function changeLang(lng) {
    setSelectedLang(lng);
    localStorage.setItem("tsklng", lng);
  }

  const handleLogout = () => {
    cookie.remove("access", { path: "/" });
    cookie.remove("refresh", { path: "/" });
    localStorage.setItem("taskooluser", null);
    window.location = "/";
  };

  return (
    <HeaderStyle>
      <Group noWrap spacing={6} align="center">
        {currentRoute?.map((route, index) => (
          <div style={{display : "flex", alignItems : "center"}} key={index}>
            <Link style={{ textDecoration: "none" }} to={route.to} key={index}>
              <Text size={18} weight={500} color="#364152">
                {route.title}
              </Text>
            </Link>
            {index + 1 !== currentRoute.length && (
              <ChevronRight style={{marginTop : "2px",marginLeft : "5px"}} size={18} weight={400} color="#364152" />
            )}
          </div>
        ))}
      </Group>
      <Group spacing={10}>
        <Group noWrap spacing={8}>
          <Menu offset={-4} trigger="hover" openDelay={100} closeDelay={200}>
            <Menu.Target>
              <Group
                className="language"
                noWrap
                spacing={4}
                position="right"
                onMouseEnter={() => setCurrentNav("language")}
                onMouseLeave={() => setCurrentNav("")}
                sx={
                  currentNav === "language" && {
                    backgroundColor: "#029199",
                    transition: "all 1s linear",
                    boxShadow: "0 4px 15px 0 rgba(0,0,0,0.2)",
                  }
                }
              >
                <Language
                  style={
                    currentNav === "language"
                      ? { color: "#CCF075" }
                      : { color: "#364152" }
                  }
                  size={16}
                  strokeWidth={2}
                />
                <Title
                  style={
                    currentNav === "language"
                      ? {
                          color: "#CCF075",
                          textTransform: "capitalize",
                        }
                      : {
                          color: "#364152",
                          textTransform: "capitalize",
                        }
                  }
                  size={18}
                  weight={500}
                  color="#364152"
                >
                  {selectedLang}
                </Title>
                {currentNav === "language" ? (
                  <ChevronDown
                    style={
                      currentNav === "language"
                        ? { color: "#CCF075", marginTop: "4px" }
                        : { color: "#364152", marginTop: "4px" }
                    }
                    size={18}
                    strokeWidth={2.5}
                  />
                ) : (
                  <ChevronUp
                    style={
                      currentNav === "language"
                        ? { color: "#CCF075", marginTop: "4px" }
                        : { color: "#364152", marginTop: "4px" }
                    }
                    size={18}
                    strokeWidth={2.5}
                  />
                )}
              </Group>
            </Menu.Target>

            <Menu.Dropdown
              onMouseEnter={() => setCurrentNav("language")}
              onMouseLeave={() => setCurrentNav("")}
              sx={{ border: "none" }}
              className="language-dropdown"
            >
              <Stack spacing={10} align="center">
                {langs.map((item, index) => (
                  <React.Fragment key={index}>
                    <Text
                      key={index}
                      sx={{ cursor: "pointer" }}
                      size={16}
                      weight={500}
                      color="#fff"
                      onClick={item.click}
                    >
                      {item.iso}
                    </Text>
                    {index !== langs.length - 1 && (
                      <div
                        style={{
                          width: "150%",
                          height: `${item.iso === "en" ? "0.5px" : "1.5px"}`,
                          backgroundColor: "#CCF075",
                        }}
                      ></div>
                    )}
                  </React.Fragment>
                ))}
              </Stack>
            </Menu.Dropdown>
          </Menu>
          <div
            style={{
              width: "1px",
              height: "18px",
              background: "#364152",
            }}
          ></div>
        </Group>

        <div
          style={{
            border: "1px solid #D6D5D5",
            width: "40px",
            height: "40px",
            borderRadius: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BellRinging strokeWidth={1.5} />
        </div>

        {/* profile */}
        <Menu  offset={-4} trigger="hover" openDelay={100} closeDelay={200} zIndex={1}>
          <Menu.Target>
            <Group
              className="profile"
              noWrap
              position="apart"
              onMouseEnter={() => setCurrentNav("profile")}
              onMouseLeave={() => setCurrentNav("")}
              sx={
                currentNav === "profile" && {
                  boxShadow: "0 4px 15px 0 rgba(0,0,0,0.2)",
                  transition: "all 0.3s ease-in-out",
                  padding: "10px 10px",
                  background: "#029199",
                }
              }
            >
              <Group spacing={8}>
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50px",
                  }}
                  src={userImg}
                  alt="User-Img"
                />
                <Stack spacing={0}>
                  <Text
                    sx={currentNav === "profile" && { color: "#fff" }}
                    className="user-field"
                    size={18}
                    weight={500}
                    color="#364152"
                  >
                    {user?.first_name} {user?.last_name}
                  </Text>
                  <Text
                    sx={currentNav === "profile" && { color: "#fff" }}
                    className="user-field"
                    size={14}
                    weight={400}
                    color="#565656"
                  >
                    {user?.user_type.type}
                  </Text>
                </Stack>
              </Group>

              {currentNav === "profile" ? (
                <ChevronDown
                  style={
                    currentNav === "profile"
                      ? { color: "#fff", marginTop: "4px" }
                      : { color: "#364152", marginTop: "4px" }
                  }
                  size={18}
                  strokeWidth={2.5}
                />
              ) : (
                <ChevronUp
                  style={
                    currentNav === "profile"
                      ? { color: "#fff", marginTop: "4px" }
                      : { color: "#364152", marginTop: "4px" }
                  }
                  size={18}
                  strokeWidth={2.5}
                />
              )}
            </Group>
          </Menu.Target>

          <Menu.Dropdown
            onMouseEnter={() => setCurrentNav("profile")}
            onMouseLeave={() => setCurrentNav("")}
            sx={{ border: "none" }}
            className="profile-dropdown"
          >
            <Stack spacing={10} align="baseline">
              <Link style={{ textDecoration: "none" }} to="/profile">
                <Group>
                  <img
                    style={{ width: "19px", height: "19px" }}
                    src={userIcon}
                    alt="user"
                  />
                  <Text color="#364152">My Profile</Text>
                </Group>
              </Link>

              <div
                style={{
                  width: "100%",
                  height: "1px",
                  background: "#eeeeee",
                }}
              ></div>
              <Group onClick={handleLogout} style={{ cursor: "pointer" }}>
                <img
                  style={{ width: "19px", height: "19px" }}
                  src={logOut}
                  alt="log-out"
                />
                <Text color="#364152">Log out</Text>
              </Group>
            </Stack>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </HeaderStyle>
  );
};

export default Header;

const HeaderStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  .mantine-Menu-dropdown {
    background-color: #00a3ac;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 14px 14px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
  }

  .language-dropdown {
    padding: ${(props) =>
      props.selectedLang === "tr" ? "14px 26px" : "14px 28px"};
  }

  .profile {
    height: 58px;
    border-radius: 14px;
    z-index: 9;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    min-width: 200px;
    &:hover {
      background : #029199;
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease-in-out;
      padding: 10px 10px;
      .user-field{
        color: #fff;
      }
    }
  }
  .language {
    cursor: pointer;
    padding: 8px 10px;
    border-radius: 12px;
    z-index: 9;
    transition: all 0.6s linear;
    &:hover {
      background-color: #029199
      transition: all 0.6s linear;
      .title {
        color: #fff;
      }
    }
  }

  .profile-dropdown {
    background-color: #fff !important;
    padding: 16px 0px;
    width: 100%;
    min-width: 200px;
    
  }

`;
