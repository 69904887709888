import { React } from "react";
import { AppContextProvider } from "./Helpers/Context";
import { RouteLogic } from "./Helpers/RouterLogic";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SuspenseSpinner } from "./Components/Loaders/SuspenseSpinner";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CurrentRouteProvider } from "./Helpers/HeaderContext";
import { NotificationsProvider } from "@mantine/notifications";
import { ProgramProvider } from "./Helpers/context/ProgramContext";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  });

  return (
    <AppContextProvider>
      <ProgramProvider>
        <CurrentRouteProvider>
          <QueryClientProvider client={queryClient}>
            <SuspenseSpinner>
              <NotificationsProvider position="bottom">
                <RouteLogic />
              </NotificationsProvider>
            </SuspenseSpinner>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </CurrentRouteProvider>
      </ProgramProvider>
    </AppContextProvider>
  );
}

export default App;
