import React, { useContext, useEffect, useState } from "react";
import { Avatar, Button, Card, Group, Stack, Text, Modal } from "@mantine/core";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Edit, Plus, Trash, Eye, X } from "tabler-icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { CurrentRouteContext } from "../../Helpers/HeaderContext";
import CustomButton from "../Common/CustomButton";

const UniversityCard = ({ handleDeleteUniversity, university }) => {
  const [selectedUniversityId, setSelectedUniversityId] = useState();
  const [filterProfessions, setFilterProfessions] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const { setIsModalOpen } = useContext(CurrentRouteContext);
  const queryClient = useQueryClient();

  const { data: professions } = useQuery({
    queryKey: ["allProfessions"],
    queryFn: async () => {
      const response = await axios.get(
        `/university/professtionlistbyuniversityId/`
      );
      return response.data;
    },
  });

  const handleFilterProfession = () => {
    const filteredData = professions.filter(
      (profession) => profession.university === selectedUniversityId
    );
    setFilterProfessions(filteredData);
    if (filteredData.length > 0) {
      setModalOpened(true);
      setIsModalOpen(true);
    }
  };

  const { mutate: handleDeleteProfession } = useMutation({
    mutationKey: ["deleteProfession"],
    mutationFn: async (id) => {
      await axios.delete(`/university/profession/edit/${id}/`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["allProfessions"]);
    },
  });

  useEffect(() => {
    selectedUniversityId && handleFilterProfession();
  }, [selectedUniversityId, professions]);

  return (
    <>
      <Container>
        <DeleteButton onClick={() => handleDeleteUniversity(university?.id)}>
          <X size={18} />
        </DeleteButton>
        <div className="styled">
          <Text size={18} weight={500} color="#364152">
            {university?.title}
          </Text>
          <div className="line"></div>
          <Group>
            <Text size={16} weight={400} color="#676767">
              Active Students: {university?.active_students}
            </Text>
            <Text size={16} weight={400} color="#676767">
              Foreign Students: {university?.foreign_students}
            </Text>
          </Group>
          <Group mt={5} wrap align="center" position="center">
            <CustomButton
              text="Show Professions"
              bgColor="transparent"
              color="#676767"
              height="45px"
              border="1px solid #676767"
              width="48%"
              onClick={() => {
                setSelectedUniversityId(university.id);
                setModalOpened(true);
                setIsModalOpen(true);
              }}
            />
            <CustomButton
              text="Add Profession"
              bgColor="transparent"
              color="#676767"
              height="48px"
              border="1px solid #676767"
              width="48%"
              isLink={true}
              href={`/create-profession/${university?.id}`}
            />
            <CustomButton
              text="Edit"
              bgColor="#FE6027"
              color="#fff"
              height="48px"
              border="1px solid #FE6027"
              width="100%"
              isLink={true}
              href={`/edit-university/${university?.id}`}
            />
          </Group>
        </div>
        <Modal
          opened={modalOpened}
          onClose={() => {
            setModalOpened(false);
            setIsModalOpen(false);
          }}
          title="Professions"
        >
          {filterProfessions.map((profession) => (
            <ProfessionItem key={profession.id}>
              <Text size={16} weight={500}>
                {profession.profession}
              </Text>
              <ButtonGroup>
                <Button
                  leftIcon={<Edit size={16} />}
                  radius={10}
                  size="xs"
                  variant="light"
                  component={Link}
                  to={`/edit-profession/university/${university?.id}/profession/${profession.id}`}
                >
                  Edit
                </Button>
                <Button
                  leftIcon={<Trash size={16} />}
                  radius={10}
                  size="xs"
                  variant="light"
                  onClick={() => handleDeleteProfession(profession.id)}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </ProfessionItem>
          ))}
        </Modal>
      </Container>
    </>
  );
};

export default UniversityCard;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding: 20px;
  position: relative;
  box-shadow: 0px 1px 10px 0px #0000001a;
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 200px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ProfessionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #676767;
  &:hover {
    color: #fe6027;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0px 1px 10px 0px #0000001a;
  border-radius: 11px;
  position: relative;
  min-height: 200px;
  .styled {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
  }
`;
