import React, { useState, useEffect } from "react";
import { Modal, Select, TextInput, Grid, Box } from "@mantine/core";
import { DateRangePicker, DatePicker } from "@mantine/dates";
import CustomButton from "../../Common/CustomButton";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";

const FilterModal = ({type,opened, onClose, onSearch }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [specificDate, setSpecificDate] = useState(null);
  const [filters, setFilters] = useState({
    [`${type}`]: "",
    language: "",
    grade: "",
    country: "",
    city: "",
    is_completed: null,
    school_type: "",
    promocode: "",
  });

  const [selectedCountry, setSelectedCountry] = useState(null);

  const { data: languages, isLoading: isLanguagesLoading } = useQuery({
    queryKey: ["languages"],
    queryFn: async () => {
      const res = await axios.get("/api/languages");
      return res.data.map((lang) => ({
        value: lang.id.toString(),
        label: lang.language,
      }));
    },
  });

  const { data: grades, isLoading: isGradesLoading } = useQuery({
    queryKey: ["grades"],
    queryFn: async () => {
      const res = await axios.get("/api/v1/student-grades/");
      return res.data.map((grade) => ({
        value: grade.id.toString(),
        label: grade.name,
      }));
    },
  });

  const { data: countries, isLoading: isCountriesLoading } = useQuery({
    queryKey: ["countries"],
    queryFn: async () => {
      const res = await axios.get("/api/countries/");
      return res.data.map((country) => ({
        value: country.id.toString(),
        label: country.name,
      }));
    },
  });

  const { data: cities, isLoading: isCitiesLoading } = useQuery({
    queryKey: ["cities", selectedCountry],
    queryFn: async () => {
      if (!selectedCountry) return [];
      const res = await axios.get(`/api/cities/${selectedCountry}`);
      return res.data.map((city) => ({
        value: city.id.toString(),
        label: city.name,
      }));
    },
    enabled: !!selectedCountry,
  });

  useEffect(() => {
    if (selectedCountry) {
      setFilters((prev) => ({ ...prev, city: "" }));
    }
  }, [selectedCountry]);

  const handleFilterChange = (field, value) => {
    setFilters({ ...filters, [field]: value });
  };

  const handleSearch = () => {
    const formatDate = (date) => {
      return date ? date.toLocaleDateString("en-GB").replace(/\//g, "-") : "";
    };

    const searchParams = {
      ...filters,
      data_range:
        dateRange.filter(Boolean).length === 2
          ? `${formatDate(dateRange[0])} to ${formatDate(dateRange[1])}`
          : undefined,
      specific_date: specificDate ? formatDate(specificDate) : undefined,
    };

    // Remove undefined, null, empty string values, and "null" string
    Object.keys(searchParams).forEach((key) => {
      if (
        searchParams[key] === undefined ||
        searchParams[key] === null ||
        searchParams[key] === "" ||
        searchParams[key] === "null"
      ) {
        delete searchParams[key];
      }
    });

    onSearch(searchParams);
  };

  const selectStyles = {
    label: {
      marginBottom: "4px",
      color: "#364152",
      fontSize: "15px",
      fontWeight: "400",
    },
    input: {
      "&::placeholder": {
        fontSize: "14px",
        color: "#697586",
      },
    },
  };

  const { data: programs, isLoading } = useQuery({
    queryKey: ["programs",type],
    queryFn: async () => {
      const res = await axios.get(`/${type === "steam" ? "steam/steam" : "bootcamp"}/own_${type}s`);
      return res.data;
    },
  });

  return (
    <Modal opened={opened} onClose={onClose} title="Filter" size="xl">
      <Grid style={{ borderTop: "1px solid #e1e1e1" }} gutter="md">
        <Grid.Col span={6}>
          <Select
            label={`${type === "steam" ? "STEAM" : "Bootcamp"}`}
            placeholder={`Select ${type === "steam" ? "STEAM" : "Bootcamp"}`}
            data={programs?.results?.map((program) => ({
              value: program.id.toString(),
              label: program[`${type}_name`],
            }))}
            value={filters[`${type}`]}
            onChange={(value) => handleFilterChange(`${type}`, value)}
            size="md"
            radius={10}
            styles={() => selectStyles}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="Language"
            placeholder="Select Language"
            data={languages || []}
            value={filters.language}
            onChange={(value) => handleFilterChange("language", value)}
            size="md"
            radius={10}
            styles={() => selectStyles}
            loading={isLanguagesLoading}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="Grade"
            placeholder="Select Grade"
            data={
              grades?.map((grade) => ({
                value: grade.value.toString(),
                label: grade.value == 13 ? "Məzun" : grade.value,
              })) || []
            }
            value={filters.grade}
            onChange={(value) => handleFilterChange("grade", value)}
            size="md"
            radius={10}
            styles={() => selectStyles}
            loading={isGradesLoading}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="School Type"
            placeholder="Select School Type"
            data={[
              { value: "0", label: "Private" },
              { value: "1", label: "Public" },
            ]}
            value={filters.school_type}
            onChange={(value) => handleFilterChange("school_type", value)}
            size="md"
            radius={10}
            styles={() => selectStyles}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="Country"
            placeholder="Select Country"
            data={countries || []}
            value={filters.country}
            onChange={(value) => {
              handleFilterChange("country", value);
              setSelectedCountry(value);
            }}
            size="md"
            radius={10}
            searchable
            styles={() => selectStyles}
            loading={isCountriesLoading}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="City"
            placeholder="Select City"
            searchable
            data={cities || []}
            value={filters.city}
            onChange={(value) => handleFilterChange("city", value)}
            size="md"
            radius={10}
            styles={() => selectStyles}
            loading={isCitiesLoading}
            disabled={!selectedCountry}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Promocode"
            placeholder="Enter Promocode"
            value={filters.promocode}
            onChange={(e) => handleFilterChange("promocode", e.target.value)}
            size="md"
            radius={10}
            styles={() => selectStyles}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="STEAM Status"
            placeholder="Select Status"
            data={[
              { value: false, label: "Ongoing" },
              { value: true, label: "Completed" },
            ]}
            value={filters.is_completed}
            onChange={(value) => handleFilterChange("is_completed", value)}
            size="md"
            radius={10}
            styles={() => selectStyles}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <DateRangePicker
            label="Date Range"
            placeholder="Pick a date range"
            value={dateRange}
            onChange={setDateRange}
            disabled={specificDate !== null}
            size="md"
            radius={10}
            styles={() => selectStyles}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <DatePicker
            label="Specific Date"
            placeholder="Pick a date"
            value={specificDate}
            onChange={setSpecificDate}
            disabled={dateRange[0] !== null || dateRange[1] !== null}
            size="md"
            radius={10}
            styles={() => selectStyles}
          />
        </Grid.Col>
      </Grid>
      <Box
        mt="md"
        style={{
          paddingTop: "14px",
          borderTop: "0.85px solid #E1E1E1",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CustomButton
          text="Reset"
          border="1px solid #CDD5DF"
          color="#323922"
          variant="outline"
          width="49%"
          radius="10px"
          onClick={() => {
            setDateRange([null, null]);
            setSpecificDate(null);
            setFilters({
              steam: "",
              language: "",
              grade: "",
              country: "",
              city: "",
              is_completed: null,
              school_type: "",
              promocode: "",
            });
          }}
        />
        <CustomButton
          width="49%"
          text="Search"
          bgColor="#364152"
          color="#fff"
          radius="10px"
          onClick={handleSearch}
        />
      </Box>
    </Modal>
  );
};

export default FilterModal;
