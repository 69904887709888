import axios from '../../Helpers/Axios'
import styled from 'styled-components'
import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { Button, TextInput } from '@mantine/core'
import { ArrowLeft, ChevronLeft } from 'tabler-icons-react'

export function ForgotPassword(props){
  console.log('props',props);

  // const { setForgotPassword } = props.props;

  const [ email, setEmail ] = useState("");
  const [ userDoesNotExist, setUserDoesNotExist ] = useState("");
  const [ emailSent, setEmailSent ] = useState("");

  const loginScheme = yup.object().shape({
    email: yup.string().email("The email was entered correctly").required("Email address must be entered"),
    password: yup.string().required("The password must be entered").min(8,"The password must be at least 8 characters long").max(32, "The password cannot exceed 32 characters"),
  })

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(loginScheme),
  });

  async function formSubmit(e) {
    e.preventDefault()

    try {
      const response = await axios.post("request-reset-email/", {
        "email": email
      });


      if (response.status === 200) {
        setEmailSent(true)
        setUserDoesNotExist(false)
      }
    } catch (error) {

      if (error.response.status === 401) {
        setUserDoesNotExist(true)
        setEmailSent(false)
      }
    }
  }

  return(
    <ForgotPasswordStyle>
      {/* <Button leftIcon={<ArrowLeft size={20}/>} fullWidth variant='subtle' color="dark" onClick={() => setForgotPassword(false)}>Go back</Button> */}
      <form onSubmit={(e) => handleSubmit(formSubmit(e))}>
        <TextInput label="E-mail" required size="md" placeholder="E-mail adress" id="email" {...register('email')}
          onChange={(e) => setEmail(e.target.value)}
          error={userDoesNotExist || errors.email}
        />
        {errors?.email && <p className='errorForgot'>{errors.email?.message}</p>}
        {userDoesNotExist && <p className='errorForgot'>This user does not exist. Please enter your email correctly.</p>}
        {emailSent && <p className='errorForgot' style={{color:"green"}}>We have sent you a link. Please check your email.</p>}
        <Button size='md' variant='light' type="submit">Send</Button>
      </form>
    </ForgotPasswordStyle>
  )
}

const ForgotPasswordStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  button{
    font-weight: 500;
    &:hover{
      background-color: #eee;
    }
  }
  form{
    margin-top: 1rem;
    width: 100%;
    .errorForgot{
      margin: 0;
      margin-top: 1rem;
      padding: 0;
      display: flex;
      color: red;
    }
    button{
      background-color: #ffe01b;
      color: #000;
      &:hover{
        background-color: #f4d71b;
      }
    }
  }
`