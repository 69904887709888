import { useContext, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import {
  Briefcase,
  BuildingBank,
  ChevronDown,
  DoorExit,
  Id,
  Map,
  ReportAnalytics,
  School,
  Settings,
  Shape2,
  Users,
  Backpack,
  ListDetails,
  CalendarEvent,
  DeviceAnalytics,
  Database,
  UserSearch,
  AppWindow,
  FileDatabase,
  BuildingEstate,
} from "tabler-icons-react";
import {
  Accordion,
  Button,
  Divider,
  Navbar,
  Popover,
  ScrollArea,
  Text,
  UnstyledButton,
  Group,
  Avatar,
} from "@mantine/core";
import taskoolShadowLogo from "../Assets/Images/taskoolShadowLogo.png";
import { AppContext } from "../Helpers/Context";

export function SideBar() {
  const [accordionValue, setAccordionValue] = useState(null);

  const { user, setUser } = useContext(AppContext);
  console.log("user", user);
  const type = user?.user_type.type;

  const location = useLocation();

  function logout() {
    localStorage.setItem("taskooluser", null);
    setUser(null);
    location.pathname = "/";
    // remove cookies
    document.cookie =
      "refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  return (
    <SideBarStyle>
      <Navbar className="sidebarWrapper" width={{ base: 280 }} height={"100%"}>
        <Navbar.Section mt={10} mb={10}>
          <Button
            to="/"
            component={Link}
            variant="subtle"
            fullWidth
            color="dark"
            className="logo"
          >
            <img width={200} src={taskoolShadowLogo} alt="Logo" />
          </Button>
        </Navbar.Section>

        <Navbar.Section grow component={ScrollArea} className="growComp">
          <Divider color="#ddd" />

          {/* Manage */}

          <Accordion
            chevron={<ChevronDown size={22} />}
            value={accordionValue}
            onChange={setAccordionValue}
            variant="filled"
            styles={{
              control: {
                padding: ".5rem .75rem",
                fontSize: "17px",
                fontWeight: 600,
                color: "#202939",
              },
              content: { padding: "0 1.5rem", fontSize: "14px" },
            }}
          >
            <Accordion.Item value="manage">
              <Accordion.Control icon={<ListDetails />}>
                Manage
              </Accordion.Control>
              <Accordion.Panel>
                <Button
                  disabled={type === "Company"}
                  color={
                    location.pathname === "/steam/created" ? "yellow" : "dark"
                  }
                  leftIcon={
                    <Map
                    // color={
                    //   location.pathname === "/internship/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/steam/created"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  STEAM Layihələr
                </Button>
                {/* <Button
                  // disabled
                  color={
                    location.pathname === "/internship/created"
                      ? "yellow"
                      : "dark"
                  }
                  leftIcon={
                    <Map
                    // color={
                    //   location.pathname === "/internship/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/internship/created"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Internships
                </Button> */}
                <Button
                  disabled={type === "Company"}
                  color={
                    location.pathname === "/course/created" ? "yellow" : "dark"
                  }
                  leftIcon={
                    <Shape2
                    // color={
                    //   location.pathname === "/course/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/course/created"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Courses
                </Button>
                <Button
                  disabled={type !== "Company"}
                  color={
                    location.pathname === "/university/created"
                      ? "yellow"
                      : "dark"
                  }
                  leftIcon={
                    <BuildingEstate
                    // color={
                    //   location.pathname === "/internship/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/university/created"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Universitetlər
                </Button>
                <Button
                  disabled
                  color={
                    location.pathname === "/event/created" ? "yellow" : "dark"
                  }
                  leftIcon={
                    <CalendarEvent
                    // color={
                    //   location.pathname === "/event/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/event/created"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Events
                </Button>
                <Button
                  disabled={user?.id !== 56}
                  // disabled
                  color={
                    location.pathname === "/olympic/created" ? "yellow" : "dark"
                  }
                  leftIcon={
                    <Backpack
                    // color={
                    //   location.pathname === "/hackathon/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/olympic/created"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Olympics
                </Button>
                <Button
                  color={
                    location.pathname === "/vacancy/created" ? "yellow" : "dark"
                  }
                  disabled
                  leftIcon={
                    <Briefcase
                    // color={
                    //   location.pathname === "/vacancy/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/vacancy/created"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Vacancies
                </Button>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="analytics">
              <Accordion.Control icon={<DeviceAnalytics />}>
                Analytics
              </Accordion.Control>
              <Accordion.Panel>
                <Button
                  disabled={user?.id !== 56}
                  color={location.pathname === "/reports" ? "yellow" : "dark"}
                  leftIcon={
                    <ReportAnalytics
                    // color={
                    //   location.pathname === "/internship/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/reports"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Reports
                </Button>
                <Button
                  disabled={user?.id !== 56}
                  color={location.pathname === "/talants" ? "yellow" : "dark"}
                  leftIcon={
                    <Database
                    // color={
                    //   location.pathname === "/course/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/talants"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Talant Data
                </Button>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="staff">
              <Accordion.Control icon={<UserSearch />}>Staff</Accordion.Control>
              <Accordion.Panel>
                <Button
                  disabled
                  color={location.pathname === "/users" ? "yellow" : "dark"}
                  leftIcon={
                    <Users
                    // color={
                    //   location.pathname === "/internship/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/users"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Users
                </Button>
                <Button
                  disabled
                  color={location.pathname === "/mentors" ? "yellow" : "dark"}
                  leftIcon={
                    <School
                    // color={
                    //   location.pathname === "/course/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/mentors"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Mentors
                </Button>
                <Button
                  disabled
                  color={location.pathname === "/schools" ? "yellow" : "dark"}
                  leftIcon={
                    <BuildingBank
                    // color={
                    //   location.pathname === "/course/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/schools"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Schools
                </Button>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="application">
              <Accordion.Control icon={<AppWindow />}>
                Application
              </Accordion.Control>
              <Accordion.Panel>
                <Button
                  disabled
                  color={location.pathname === "/details" ? "yellow" : "dark"}
                  leftIcon={
                    <FileDatabase
                    // color={
                    //   location.pathname === "/internship/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/details"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Personal details
                </Button>
                <Button
                  disabled
                  color={location.pathname === "/settings" ? "yellow" : "dark"}
                  leftIcon={
                    <Settings
                    // color={
                    //   location.pathname === "/course/created"
                    //     ? "#FFD43B"
                    //     : "#000"
                    // }
                    />
                  }
                  component={Link}
                  to="/settings"
                  fullWidth
                  variant="subtle"
                  // color="dark"
                >
                  Settings
                </Button>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Navbar.Section>

        <Popover
          position="top"
          withArrow
          shadow="md"
          closeOnClickOutside
          closeOnEscape
        >
          <Popover.Target>
            {/* <Button
              leftIcon={<Id size={24} />}
              m={15}
              color="gray.9"
              variant="outline"
            >
              <Text align="center">
                {user?.first_name} {user?.last_name !== "." && user?.last_name}
              </Text>
            </Button> */}
            <UserStyle>
              <UnstyledButton className="user">
                <Group>
                  <Avatar src={user?.profile_img} radius="xl" />

                  <div style={{ flex: 1 }}>
                    <Text size="sm" fw={500}>
                      {user?.first_name}{" "}
                      {user?.last_name !== "." && user?.last_name}
                    </Text>

                    <Text c="dimmed" size="xs">
                      {user?.email}
                    </Text>
                  </div>
                </Group>
              </UnstyledButton>
            </UserStyle>
          </Popover.Target>
          <Popover.Dropdown>
            <Button
              onClick={() => logout()}
              color={"red"}
              variant="subtle"
              leftIcon={<DoorExit />}
              fullWidth
            >
              Log out
            </Button>
          </Popover.Dropdown>
        </Popover>
      </Navbar>
    </SideBarStyle>
  );
}

const SideBarStyle = styled.div`
  .sidebarWrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    .logo {
      height: 40px;
      margin-top: 0.5rem;
      img {
        border-radius: 0.25rem;
      }
      :hover {
        background-color: initial;
      }
    }
    .growComp {
      display: flex;
      align-items: flex-start;
      grid-gap: 1rem;
      padding: 0 1.1rem;
      button,
      a {
        display: flex;
        align-items: flex-start;
        margin-bottom: 0.25rem;
        font-weight: 500;
        border-radius: 0.15rem;
      }
      .currentPage {
        border: 1px solid #aaa;
      }
      .companyDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        p {
          padding: 0;
          margin: 0;
          font-size: 15px;
        }
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 0.25rem;
        }
      }
      .label {
        color: #888;
        font-size: 14px;
        margin: 0.5rem 0;
      }
    }
    .footer {
      margin: 0.5rem 0 0.75rem 0;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const UserStyle = styled.div`
  .user {
    display: block;
    width: 100%;
    padding: 10px 20px;
    color: black;

    &:hover {
      background-color: lightgray;
    }
  }
`;
