import {
  Center,
  Container,
  Grid,
  Group,
  Image,
  Table,
  Text,
} from "@mantine/core";
import tabs from "../Assets/Images/figures2.png";
import useSetCurrentRoute from "../Hooks/useSetCurrentRoute";
import { useContext } from "react";
import { AppContext } from "../Helpers/Context";
import styled from "styled-components";
import CustomPieChart from "./Dashboard/CustomPieChart";
import { useQuery } from "@tanstack/react-query";
import axios from "../Helpers/Axios";
import { SuspenseSpinner } from "./Loaders/SuspenseSpinner";
import Loader from "./Common/Loader";

export const EmptyDash = () => {
  useSetCurrentRoute([{ title: "Dashboard", to: "/" }]);
  const { user } = useContext(AppContext);
  const userType = user?.user_type.type;

  const { data, isLoading } = useQuery({
    queryKey: ["dashboard-data"],
    queryFn: async () => {
      const response = await axios.get(`/steam/steam/own_steams/statistics`);
      return response.data;
    },
  });

  console.log('data', data);

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  return (
    <>
      {userType === "Agent" || userType === "Admin" || data?.age_of_childrens === "No age data available" || data?.school_types === "No school type availalable" ? (
        <Center mt={150}>
          <Container>
            <Center>
              <Image src={tabs} width="300px" />
            </Center>
            <Text color={"dimmed"} mt={30} weight={500} size={30}>
              Choose one of the tabs on the left to start
            </Text>
          </Container>
        </Center>
      ) : (
        // university dashboard
        // <DashboardStyle>
        //   <Grid>
        //     <Grid.Col span={4}>
        //       <StatisticCard
        //         title="Total Applications"
        //         icon={sIcon1}
        //         amount={40.689}
        //         percent={8.5}
        //         up={true}
        //         bg="#e4e4ff"
        //       />
        //     </Grid.Col>
        //     <Grid.Col span={4}>
        //       <StatisticCard
        //         title="Admitted Students"
        //         icon={sIcon2}
        //         amount={10293}
        //         percent={1.3}
        //         up={true}
        //         bg="#fef2d6"
        //       />
        //     </Grid.Col>
        //     <Grid.Col span={4}>
        //       <StatisticCard
        //         title="Admission Rate"
        //         icon={sIcon3}
        //         amount="7%"
        //         percent={4.3}
        //         up={false}
        //         bg="#d9f7e7"
        //       />
        //     </Grid.Col>
        //   </Grid>
        //   <PieChartWrapper>
        //     <Group style={{padding : "20px 25px"}} position="apart" align="center" noWrap>
        //       <div style={{ height: "289px", width: "45%" }}>
        //         <Text color="#364152" size={20} weight={500} >Top 5 countries</Text>
        //         <CustomPieChart />
        //       </div>
        //       <Line />
        //       <div style={{ height: "289px", width: "45%" }}>
        //         <Text color="#364152" size={20} weight={500} >Top 5 specialities</Text>
        //         <CustomPieChart />
        //       </div>
        //     </Group>
        //   </PieChartWrapper>
        // </DashboardStyle>
        <DashboardStyle>
          {data?.age_of_childrens !== "No age data available" &&
            data?.school_types !== "No school type availalable" && (
              <PieChartWrapper>
                <Group
                  style={{ padding: "20px 25px" }}
                  position="apart"
                  align="center"
                  noWrap
                >
                  {data?.age_of_childrens && (
                    <div style={{ height: "289px", width: "45%" }}>
                      <Text color="#364152" size={20} weight={500}>
                        Age of children
                      </Text>
                      <CustomPieChart
                        data={data?.age_of_childrens}
                        type="age"
                      />
                    </div>
                  )}
                  <Line />
                  {data?.school_types && (
                    <div style={{ height: "289px", width: "45%" }}>
                      <Text color="#364152" size={20} weight={500}>
                        School type
                      </Text>
                      <CustomPieChart data={data?.school_types} type="school" />
                    </div>
                  )}
                </Group>
              </PieChartWrapper>
            )}
          <Text size={20} weight={500} mt={20} mb={20}>
            The most enrolled STEAM project
          </Text>
          <TableWrapper>
            <Table verticalSpacing="md">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Count of students</th>
                </tr>
              </thead>
              <tbody>
                {data?.top_steams.map((project, index) => (
                  <tr key={index}>
                    <td>{project?.steam_name}</td>
                    <td>{project?.apply_count}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        </DashboardStyle>
      )}
    </>
  );
};

const DashboardStyle = styled.div`
  max-width: 80%;
`;

const PieChartWrapper = styled.div`
  width: 100%;
  height: 349px;
  margin-top: 30px;
  background-color: #fff;
  box-shadow: 6px 6px 54px 0px #0000000d;
  border-radius: 20px;
`;

const Line = styled.div`
  height: 227px;
  width: 2px;
  background-color: #bcbcbc;
`;

const TableWrapper = styled.div`
  max-width: 408px;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0px 45px 112px 0px #0000000f;
  border-radius: 10px;
  margin-bottom: 20px;

  // Scroll default olaraq gizli olsun
  &::-webkit-scrollbar {
    width: 4px; // scroll qalınlığı azaldıldı
    display: none; // default olaraq gizlət
  }

  // Hover zamanı scroll göstər
  &:hover::-webkit-scrollbar {
    display: block;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  th,
  td {
    padding: 12px;
    border-bottom: 1px solid #eee;
  }

  th {
    color: #364152;
    font-weight: 500;
    background-color: #f8f9fa; /* Header background color */
  }

  tbody tr {
    background-color: #fff; /* Body background color */
  }

  /* Optional: Add hover effect on table rows */
  tbody tr:hover {
    background-color: #f9f9f9;
  }
`;
