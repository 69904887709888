import { useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../Helpers/Context";
import axios from "../../Helpers/Axios";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { NotificationsProvider } from "@mantine/notifications";
import { SkeletonLoader } from "../Loaders/SkeletonLoader";
import { ProgramInfo } from "../ProgramInfo/ProgramInfo";
import { ManageProgram } from "../ProgramInfo/ManageProgram";
import { motion } from "framer-motion";
import { AnimateOpa } from "../AnimateOpa";
import { ProgramSyllabus } from "./Syllabus/_Syllabus";
import Loader from "../Common/Loader";
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";

export const Program = ({ props }) => {
  const { type } = props;

  const { id } = useParams();
  const { user } = useContext(AppContext);
  const [entryData, setEntryData] = useState([]);

  useSetCurrentRoute(entryData ? [
    {
      title: type === 'steam' ? "STEAM" : "Bootcamp",
      to: type === 'steam' ? "/steams" : "/bootcamps"
    },
    {
      title: entryData[`${type}_name`] || '',
      to: type === 'steam' ? `/steam/${id}` : `/bootcamps/${id}`
    }
  ] : []);

  window.scrollTo(0, 0, "smooth");

  let link =
    type === "bootcamp"
      ? `/bootcamp/${id}/`
      : type === "steam" 
      ? `steam/steam/${id}/`
      : type === "university"
      && `/university/byid/${id}/`

  const { data, isLoading, isError } = useQuery([`program`, id], async () => await axios.get(link), {
    onSuccess: (data) => {
      setEntryData((type=== "steam" || type === "bootcamp") ? data.data : type === "university" ? data?.data.University[0] : data.data[0]);
    },
  });

  console.log('data',data)

  if (isLoading || entryData.length === 0) {
    return <Loader isLoading={isLoading} />;
  }

  if (isError) {
    return <div>Error loading data</div>;
  }

  if (entryData?.is_shared === undefined && (type !== "steam" && type !== "bootcamp")) {
    return (
      <NotificationsProvider>
        <LoadContent>
          <div className="loadWrap">
            <div className="loadX">
              {[...Array(3)].map((e, i) => {
                return (
                  <motion.div
                    key={i + "y"}
                    animate={{ y: [100, 0] }}
                    transition={{ ease: "easeOut", duration: 0.3 }}
                  >
                    <SkeletonLoader
                      key={i}
                      width="100%"
                      height={100}
                      mb={40}
                      visible={true}
                    />
                  </motion.div>
                );
              })}
            </div>
            
            <div className="loadX">
              {[...Array(4)].map((e, i) => {
                return (
                  <motion.div
                    key={i + "x"}
                    animate={{ y: [100, 0] }}
                    transition={{ ease: "easeOut", duration: 0.3 }}
                  >
                    <SkeletonLoader
                      key={i + "x"}
                      width="100%"
                      height={140}
                      mb={40}
                      visible={true}
                    />
                  </motion.div>
                );
              })}
            </div>
          </div>
        </LoadContent>
      </NotificationsProvider>
    );
  }

  if (entryData?.is_shared !== undefined || (type === "steam" || type === "bootcamp")) {
    return (
      <AnimateOpa>
        <NotificationsProvider>
          <div
            style={{
              borderTop: "1px solid #eee",
              backgroundColor: "#fff",
              borderRadius: "0.75rem",
              width: "100%",
            }}
          >
            <ManageProgram props={{ user, type, entryData, setEntryData }} />
            <ProgramInfo props={{ user, type, entryData,id }} />
            {type !== "university" && type !== "steam" && type !== "bootcamp" &&  <ProgramSyllabus props={{ user, type, entryData }} />}
          </div>
        </NotificationsProvider>
      </AnimateOpa>
    );
  }
};

const LoadContent = styled.div`
  border-top: 1px solid #eee;
  .loadWrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    justify-content: center;
    width: 90%;
    padding: 3rem 0;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    .loadWrap {
      grid-template-columns: 1fr;
      grid-gap: 0rem;
      padding: 2rem 0;
    }
  }
`;
