import React, { useEffect, useState } from "react";
import {} from "@mantine/core";
import styled from "styled-components";
import CreateWeekModal from "../../Components/Steam/Modals/CreateWeekModal";
import UnitListSection from "../../Components/Steam/UnitListSection";
import DaysSection from "../../Components/Steam/DaysSection";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { showNotification } from "@mantine/notifications";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";
const CreateContent = ({props}) => {
  const { type } = props
  const tSubUnit = type === "steam" ? "day" : "lesson"
  const tSubUnits = type === "steam" ? "days" : "lessons"
  const tUnit = type === "steam" ? "week" : "section"
  const tUnits = type === "steam" ? "weeks" : "sections"
  const link = type === "steam" ? 
  {
   program : "steam/steam",
   program_create : "steam/steam_create"
  } 
  :
  {
    program : "bootcamp",
    program_create : "bootcamp/create-bootcamp"
  }
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [programData, setProgramData] = useState(
    JSON.parse(localStorage.getItem("programData"))
  );
  const [unitList, setUnitList] = useState([]);
  const [currentUnit, setCurrentUnit] = useState();
  const [currentSubUnit, setCurrentSubUnit] = useState();
  const [activeTab, setActiveTab] = useState("");
  const [activeSubUnitTab, setActiveSubUnitTab] = useState(`${type === "steam" ? "day" : "lesson"}-1`);
  const navigate = useNavigate();
  const [opened, setOpened] = useState(true);
  const [toolBtnClick, setToolBtnClick] = useState()
  const [editUnit, setEditUnit] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isOnsubmit , setIsOnsubmit] = useState(false)
  const [isContentSave, setIsContentSave] = useState(false)


  

  const handleSubUnitContent = (key, value, subUnitIndex) => {
    const index = subUnitIndex.split("-")[1] - 1;
    if (currentUnit && currentUnit[tSubUnits][index]) {
      const updatedSubUnits = [...currentUnit[tSubUnits]];
      if (key === "tasks") {
        if (!("tasks" in updatedSubUnits[index])) {
          // Eğer "tasks" key'i yoksa, yarat ve boş bir array ata.
          updatedSubUnits[index] = { ...updatedSubUnits[index], tasks: null };
        } else {
          // Eğer "tasks" key'i varsa, mevcut array'e boş bir obje ekle.
          updatedSubUnits[index].tasks = [value];
        }
      } else {
        // Eğer key "tasks" değilse, diğer key-value çiftini güncelle.
       updatedSubUnits[index] = { ...updatedSubUnits[index], [key]: value };
      }
      const updatedUnit = { ...currentUnit, [`${tSubUnits}`]: updatedSubUnits };
      setCurrentUnit(updatedUnit);
      setCurrentSubUnit(updatedSubUnits[index]);

      const updatedUnitList = unitList.map((unit) =>
        unit[`${tUnit}_name`] === currentUnit[`${tUnit}_name`] ? updatedUnit : unit
      );
      setUnitList(updatedUnitList);
      setToolBtnClick(key)
    }
  };

  const handleDeleteSubUnitContent = (subUnitIndex,value, contentKey) => {
    const index = subUnitIndex.split("-")[1] - 1;
    if (currentUnit && currentUnit[tSubUnits][index]) {
      const updatedSubUnits = [...currentUnit[tSubUnits]];
      updatedSubUnits[index][contentKey] = value;
      const updatedUnit = { ...currentUnit, [`${tSubUnits}`]: updatedSubUnits };
      setCurrentUnit(updatedUnit);
      setCurrentSubUnit(updatedSubUnits[index]);

      const updatedUnitList = unitList.map((unit) =>
        unit[`${tUnit}_name`] === currentUnit[`${tUnit}_name`] ? updatedUnit : unit
      );
      setUnitList(updatedUnitList);
    }
  };

  const handleDeleteSubUnit = (subUnitIndex) => {
    const index = subUnitIndex.split("-")[1] - 1;
    if (currentUnit && currentUnit[tSubUnits][index]) {
      const updatedSubUnits = currentUnit[tSubUnits].filter((_, i) => i !== index);
      const updatedUnit = { ...currentUnit, [`${tSubUnits}`]: updatedSubUnits };
      setCurrentUnit(updatedUnit);

      const updatedUnitList = unitList.map((unit) =>
        unit[`${tUnit}_name`] === currentUnit[`${tUnit}_name`] ? updatedUnit : unit
      );
      setUnitList(updatedUnitList);

      // İlk günü seçin, əgər qalan günlər varsa
      setActiveSubUnitTab(`${tSubUnit}-1`);
    }
  };

  const handleAddSubUnit = () => {
    if (currentUnit) {
      const newSubUnit = {
        [`${tSubUnit}_content`]: `${type === "steam" ? "Day" : "Lesson"} Content`,
        skills_ids: [],
        [`${tSubUnit}_photo`]: null,
        file_limit: 5,
      };
      const updatedSubUnits = [...currentUnit[tSubUnits], newSubUnit];
      console.log('updatedSubUnits',updatedSubUnits)
      const updatedUnit = { ...currentUnit, [`${tSubUnits}`]: updatedSubUnits };
      setCurrentUnit(updatedUnit);

      const updatedUnitList = unitList.map((unit) =>
        unit[`${tUnit}_name`] === currentUnit[`${tUnit}_name`] ? updatedUnit : unit
      );
      setUnitList(updatedUnitList);

      // Yeni günü ekledikten sonra otomatik olarak yeni eklenen güne geçiş yapma
      setActiveSubUnitTab(`${type === "steam" ? "day" : "lesson"}-${updatedSubUnits.length}`);
    }
  };

  const handleUnitContent = (key, value, unitIndex) => {
    const index = unitIndex;
    if (unitList[index]) {
      const updatedUnitList = [...unitList];
      updatedUnitList[index] = { ...unitList[index], [key]: value };
      setUnitList(updatedUnitList);
    }
  };

  const { data: program, isLoading } = useQuery({
    queryKey: ["program", id],
    queryFn: async () => {
      return await axios.get(`${link.program}/${id}/`);
    },
    enabled: !!id,
  });

  const { mutate: createContent, isLoading: createLoading } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(`${link.program_create}/`, data);
      return response;
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(["programList"]);
      navigate(`/${type}/${data.message.id}/content-update`);
      showNotification({
        title: "Success",
        message: "Content created successfully",
        color: "teal",
      });
    },
    onError: (error) => {
      showNotification({
        title: "Error",
        message: "An error occurred while creating content",
        color: "red",
      });
    },
  });

  const { mutate: editContent, isLoading: updateLoading } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.put(`${link.program}/${id}/`, data);
      return response;
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ["program", id] });
      showNotification({
        title: "Success",
        message: "Content updated successfully",
        color: "teal",
      });
    },
    onError: (error) => {
      showNotification({
        title: "Error",
        message: "An error occurred while updating content",
        color: "red",
      });
    },
  });

  const handleCreateContent = () => {
    if (id) {
      const sendEditData = { ...programData, [`${tUnits}`]: unitList };
      editContent(sendEditData);
      setIsOnsubmit(false)
      setIsContentSave(false)
    } else {
      const sendData = { ...programData, [`${tUnits}`]: unitList };
      createContent(sendData);
      setIsContentSave(false)

    }
  };

  useEffect(() => {
    if (unitList.length > 0) {
      setOpened(false);
    }
  }, [unitList]);

  useEffect(() => {
    if (unitList.length > 0) {
      setActiveTab(`${tUnit}-${unitList.length}`);
    }
  }, [unitList?.length,tUnit]);

  useEffect(() => {
    if (activeTab) {
      const index = activeTab.split("-")[1] - 1;
      setCurrentUnit(unitList[index]);
      // Yeni hafta seçildiğinde ilk günün otomatik olarak aktif olması
    }
  }, [activeTab, unitList]);

  useEffect(() => {
    const index = activeSubUnitTab.split("-")[1] - 1;
    if (currentUnit) {
      const updatedSubUnits = [...currentUnit[tSubUnits]];
      setCurrentSubUnit(updatedSubUnits[index]);
    }
  }, [currentUnit, activeSubUnitTab,tSubUnits]);

  useEffect(() => {
    if (id) {
      queryClient.invalidateQueries(["program", id]);
    }
  }, [id]);

  useEffect(() => {
    if (program) {
      const programObj = {
        [`${type}_name`]: program.data[`${type}_name`],
        author_name: program.data.author_name,
        [`${type}_about`]: program.data[`${type}_about`],
        author_about: program.data.author_about,
        lang_id: program.data.lang_id,
        duration_time: program.data.duration_time,
        price: program.data.price,
        syllabus: program.data.syllabus_file,
        author_image: program.data.author_image_file,
        [`${type}_image`]: program.data[`${type}_image_file`],
      };
      const updatedUnits = program.data[`${tUnits}_output`].map((unit) => ({
        ...unit,
        [`${tSubUnits}`]: unit[`${tSubUnits}_output`]
          .sort((a, b) => a.id - b.id) // 'id'ye göre sıralama
          .map((subUnit) => ({
            ...subUnit,
            [`${tSubUnit}_photo`]: subUnit[`${tSubUnit}_photo_output`],
            files: subUnit.files.map((file) => ({
              id: file.id,
              file_name: file.file_name,
              file_field: file.file_field_output, // eski key 'file_field_output' yeni key 'file_field'
            })),
          })),
      }));
      setProgramData(programObj);
      setUnitList(updatedUnits);
    }
  }, [program,type,tUnits,tSubUnits]);

  useSetCurrentRoute(
    !id 
      ? [
          {
            title: type === 'steam' ? "STEAM" : "Bootcamp",
            to: type === 'steam' ? "/steams" : "/bootcamps"
          },
          {
            title: "Create Content",
            to: `/${type === 'steam' ? 'steam' : 'bootcamp'}/create-content`
          }
        ]
      : [
          {
            title: type === 'steam' ? "STEAM" : "Bootcamp",
            to: type === 'steam' ? "/steams" : "/bootcamps"
          },
          {
            title: `${program && program.data[`${type}_name`]}`,
            to: `/${type === 'steam' ? 'steam' : 'bootcamps'}/${id}`
          },
          {
            title: "Update Content",
            to: `/${type === 'steam' ? 'steam' : 'bootcamp'}/${id}/content-update`
          }
        ]
  );

  console.log('unitList',unitList)

  if (createLoading || updateLoading) {
    return <Loader isLoading={createLoading || updateLoading} />;
  }

  return (
    <ContentStyle>
      <UnitListSection
        type={type}
        link={link}
        pUnits={tUnits}
        pUnit={tUnit}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        unitList={unitList}
        setOpened={setOpened}
        handleUnitContent={handleUnitContent}
        currentUnit={currentUnit}
        programId={id}
        setUnitList={setUnitList}
        setEditUnit={setEditUnit}
        setEditMode={setEditMode}
        handleCreateContent={handleCreateContent}
        isContentSave={isContentSave}
        setIsContentSave={setIsContentSave}
      />

      <DaysSection
        type={type}
        link={link}
        pUnits={tUnits}
        pUnit={tUnit}
        pSubUnits={tSubUnits}
        pSubUnit={tSubUnit}
        handleSubUnitContent={handleSubUnitContent}
        activeSubUnitTab={activeSubUnitTab}
        setActiveSubUnitTab={setActiveSubUnitTab}
        currentUnit={currentUnit}
        currentSubUnit={currentSubUnit}
        handleAddSubUnit={handleAddSubUnit}
        handleCreateContent={handleCreateContent}
        handleDeleteSubUnitContent={handleDeleteSubUnitContent}
        handleDeleteSubUnit={handleDeleteSubUnit}
        id={id}
        createLoading={createLoading}
        updateLoading={updateLoading}
        toolBtnClick={toolBtnClick}
        isContentSave={isContentSave}
        setIsContentSave={setIsContentSave}
      />
      <CreateWeekModal
        pUnit={tUnit}
        pUnits={tUnits}
        pSubUnits={tSubUnits}
        pSubUnit={tSubUnit}
        setUnitList={setUnitList}
        unitList={unitList}
        opened={opened}
        setOpened={setOpened}
        editUnit={editUnit}
        editMode={editMode}
        setEditMode={setEditMode}
        handleCreateContent={handleCreateContent}
        setIsOnsubmit={setIsOnsubmit}
        isOnsubmit={isOnsubmit}
      />
    </ContentStyle>
  );
};

export default CreateContent;

const ContentStyle = styled.div``;
