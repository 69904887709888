import { Button } from "@mantine/core";
import axios from "../../Helpers/Axios";
import { React, useState, useEffect } from "react";
import {
  AlignJustified,
  File,
  Notes,
  Section as VideoPlus,
} from "tabler-icons-react";

export function RightSide({ props }) {
  const [id, setId] = useState(0);
  const {
    entryType,
    links,
    setShowQuizModal,
    setPendingContent,
    sectionContent,
    setSectionContent,
    sentUrls,
    fetchSection,
    setEditSectionModalOpen,
    setRemoveSectionModalOpen,
    pendingContent,
    setSentUrls,
    handleVideo,
  } = props;

  const [sendingItems, setSendingItems] = useState(false);

  function addItem(item) {
    function addPending(type) {
      setPendingContent([
        ...pendingContent,
        {
          title: "",
          id: id,
          description: "",
          file: "",
          section:
            sectionContent?.section?.id ||
            sectionContent?.id ||
            sectionContent[0]?.section?.id || sectionContent[0]?.section,
          type: type,
          is_active: true,
        },
      ]);
      setId(id + 1);
    }

    switch (item) {
      case "video":
        addPending("video");
        break;
      case "chart":
        addPending("chart");
        break;
      case "text":
        addPending("text");
        break;
      default:
        break;
    }
  }

  function sendPending() {
    setSendingItems(true);

    Promise.all(
      pendingContent.map(async (item, index) => {
        if (item.type === "video") {
          const uploadedVideo = await handleVideo(item.file);
          if (uploadedVideo) {
            setSentUrls((prev) => [
              ...prev,
              {
                title: item.title,
                description: item.description,
                section: item.section,
                type: item.type,
                is_active: true,
                file: uploadedVideo?.data?.player_embed_url,
                is_question: item.is_question,
                // file: uploadedVideo
                // file: "testLink"
              },
            ]);
          }
        } else if (item.type === "chart") {
          setSentUrls((prev) => [
            ...prev,
            {
              title: item.title,
              description: item.description,
              section: item.section,
              type: item.type,
              is_active: true,
              file: item.file,
              is_question: item.is_question,
            },
          ]);
        } else if (item.type === "text") {
          setSentUrls((prev) => [
            ...prev,
            {
              title: item.title,
              description: item.description,
              section: item.section,
              type: item.type,
              is_active: true,
              is_question: item.is_question,
            },
          ]);
        }
      })
    );
    // .then(() => {
    //   // setFinishedSendingUrls(true)
    //   postPending()
    // })
  }

  useEffect(() => {
    if (sentUrls.length > 0) {
      Promise.all(
        sentUrls.map(async (item) => {
          async function postPendingItem(type, video) {
            const formX = new FormData();

            formX.append("title", item.title);
            formX.append("description", item.description);
            formX.append("section", item.section);
            formX.append(
              "is_question",
              item.is_question ? item.is_question : false
            );

            if (type !== "text" && entryType!=="steam") {
              formX.append("file", item.file);
            }
            

            try {
              if(entryType=="course")
              {
                const response = await axios.post(
                  `${links.sectionDashed}${type}/`,
                  formX
                );
              }
              else{
                if(type=="text")
                {
                  formX.append("is_text", true);
                }
                else if(type=="video")
                {
                  formX.append("is_video_lesson", true);
                  formX.append("video", item.file);
                }
                else{
                  formX.append("is_chart", true);
                  formX.append("video", item.file);
                }

                const response = await axios.post(
                  `${links.sectionDashed}/`,
                  formX
                );
              }
 

              // if (response.status === 201) {
              setSectionContent([]);
              setPendingContent([]);
              fetchSection(item.section);
              setSentUrls([]);
              // }
            } catch (error) {}
          }

          switch (item.type) {
            case "video":
              postPendingItem("video", "");
              break;
            case "chart":
              postPendingItem("chart", "");
              break;
            case "text":
              postPendingItem("text", "");
              break;

            default:
              break;
          }
          setSendingItems(false);
        })
      );
    }
    // return () => {
    //   setSentUrls([])
    // }
  }, [sentUrls]);

  return (
    <div className="rightSide">
      <div className="manageSections">
        <div className="currentlyEditingTitle">
          <h3>Current editing</h3>
          <h4>
            <ins>Section:</ins>{" "}
            {sectionContent[0]?.section?.title || sectionContent?.title}
          </h4>
          {sectionContent[0]?.section?.title ? (
            <h4>
              <ins>Description:</ins>{" "}
              {sectionContent[0]?.section?.description.length > 100
                ? sectionContent[0]?.section?.description?.substring(0, 100) +
                  "..."
                : sectionContent[0]?.section?.description}
            </h4>
          ) : (
            <h4>
              <ins>Description:</ins>{" "}
              {sectionContent?.description?.length > 100
                ? sectionContent?.description?.substring(0, 100) + "..."
                : sectionContent?.description}
            </h4>
          )}
          <h4>
            <ins>Is final section:</ins>{" "}
            {JSON.stringify(sectionContent?.is_final_project) ||
              JSON.stringify(sectionContent[0]?.section?.is_final_project)}{" "}
          </h4>
          <button
            className="editSection"
            onClick={() => setEditSectionModalOpen(true)}
          >
            Edit section
          </button>
          <button
            className="removeSection"
            onClick={() => setRemoveSectionModalOpen(true)}
          >
            Remove section
          </button>
        </div>
      </div>

      <hr />

      <div className="tools">
        <h3>Tools</h3>
        <div className="wrapper">
          <button onClick={() => addItem("video")}>
            <VideoPlus />
            <span>Add video</span>
          </button>
          <button onClick={() => addItem("chart")}>
            <File />
            <span>Add File</span>
          </button>
          <button onClick={() => addItem("text")}>
            <AlignJustified />
            <span>Add text</span>
          </button>
          <button onClick={() => setShowQuizModal(true)}>
            <Notes />
            <span>Add quiz</span>
          </button>
        </div>
      </div>

      <hr />

      <div className="manageSections">
        {<h3>Pending content: {pendingContent.length}</h3>}
        <div className="wrapper">
          {pendingContent.length > 0 && !sendingItems ? (
            <Button className="publish" onClick={() => sendPending()}>
              Publish
            </Button>
          ) : !sendingItems ? (
            <Button
              className="publish"
              style={{
                backgroundColor: "#eee",
                cursor: "default",
                fontWeight: "400",
              }}
            >
              Nothing to publish
            </Button>
          ) : (
            <Button loading className="publish" onClick={() => sendPending()}>
              Publish
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
