import React, { useContext } from "react";
import { Avatar, Button, Card, Grid, Group, Text } from "@mantine/core";
import styled from "styled-components";
import user from "../../Assets/Svgs/header/user.png";
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";
import { Link } from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import { AppContext } from "../../Helpers/Context";
import axios from '../../Helpers/Axios'
import UniversityCard from "../../Components/University/UniversityCard";
import { Plus } from "tabler-icons-react";
import LottieLoader from "../../Components/Loaders/LottieLoader";
import { DatabaseOff } from "tabler-icons-react";
import { Stack, Title } from "@mantine/core";
import CustomButton from "../../Components/Common/CustomButton";

const Universities = () => {
  useSetCurrentRoute([{title: "Universities", to: "/universities"}]);
  const queryClient = useQueryClient()
  const {user} = useContext(AppContext)

  const {data : universities} = useQuery({
    queryKey: ['universities'],
    queryFn: async () => {
      const { data } = await axios.get(`/university/universities/${user?.id}/`);
      return data?.University;
    }
  })
  const {mutate : deleteUniversity} = useMutation({
    mutationFn: async (id) =>
      await axios.delete(`/university/edit/${id}/`).then((res) => res?.data),
    onSuccess: () => {
      queryClient.invalidateQueries(["universities"]);
    },
  })

  const renderCreateButton = () => (
    <Group style={{width : "100%"}} position={universities?.length ? "left" : "center"}>
       <CustomButton
          onClick={() => {}}
          bgColor="#00BD90"
          color="#fff"
          text="Create university"
          isLink={true}
          href="/create-university"
          leftIcon={<Plus />}
          height="45px"
        />
    </Group>
  );

  if (!universities?.length) {
    return (
      <>
        <Stack align="center" spacing="xl" mt={40}>
        {renderCreateButton()}

          <Stack align="center" spacing="xs" pt={20}>

            <DatabaseOff size={32} color="#868E96" />
            <Title order={3} color="dimmed">Məlumat tapılmadı</Title>
            <Text color="dimmed" size="sm">
              Hazırda heç bir Universitet mövcud deyil. Yeni Universitet yaratmaq üçün yuxarıdakı düyməni istifadə edin.
            </Text>
          </Stack>
        </Stack>
      </>
    );
  }

  return (
    <Container>
      {renderCreateButton()}
      <Grid>
        {universities?.map((university, index) => (
          <Grid.Col key={university?.id} span={12}>
            <UniversityCard handleDeleteUniversity={deleteUniversity} university={university} />
          </Grid.Col>
        ))}
      </Grid>
    </Container>
  );
};

export default Universities;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .line {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
  }
`;

const StyledCard = styled(Card)`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: 0px 1px 10px 0px #0000001A;
  .see-more-btn{
    background : #fe6027;
    font-weight: 500;
    font-size: 16px;
  }

`;
