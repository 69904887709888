import {
  BookDownload,
  Certificate,
  Edit,
  SquarePlus,
} from "tabler-icons-react";
import parse from "html-react-parser";
import { Button, Timeline } from "@mantine/core";

export function SyllabusInfo({ props }) {
  const {
    type,
    setShowCertificate,
    user,
    entryData,
    syllabus,
    showEditModal,
    setShowEditModal,
  } = props;

  let userOwnsProgram =
    user?.id !== undefined &&
    (user?.id === entryData?.user?.id || user?.id === entryData?.teacher?.id);

  let usersFinished = [42, 61, 125, 190, 200, 239, 454, 680];
  let canDownload = false;
  if (
    (usersFinished?.includes(user?.id) && entryData?.id === 50) ||
    (user?.id === 591 && entryData?.id === 55)
  ) {
    canDownload = true;
  }

  return (
    <div
      className={
        showEditModal === true
          ? "wrapperSyllabus scrollhidden"
          : "wrapperSyllabus"
      }
    >
      <div className="syllabusHeader">
        <div className="edit">
          <h1>Syllabus</h1>
          {userOwnsProgram && (
            <Button onClick={() => setShowEditModal(true)} leftIcon={<Edit />}>
              Edit
            </Button>
          )}
        </div>
        <div className="syllabusButtons">
          <Button
            className="downloadDesktop"
            variant="default"
            color=""
            size="md"
            leftIcon={<Certificate color={canDownload ? "red" : "gray"} />}
            onClick={() => setShowCertificate(true)}
            disabled={!canDownload}
          >
            View certificate
          </Button>
          <Button
            className="downloadDesktop"
            component="a"
            target="_blank"
            variant="default"
            color=""
            size="md"
            href={entryData?.file}
            leftIcon={<BookDownload color="red" />}
          >
            Download full syllabus
          </Button>
        </div>
      </div>
      {syllabus.length > 0 ? (
        <div className="syllabus">
          <Timeline>
            {syllabus.map((item) => {
              return (
                <Timeline.Item key={item.id} color="violet" active>
                  <div className="in">
                    <div className="text">
                      {item?.content && parse(item?.content)}
                      {/* for steam */}
                      {item?.description && parse(item?.description)}
                    </div>
                    {item?.content_video && (
                      <video controls>
                        <source src={item?.content_video} type="video/mp4" />
                      </video>
                    )}
                  </div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        </div>
      ) : userOwnsProgram && syllabus.length < 1 ? (
        <div className="noSectionsAdded" onClick={() => setShowEditModal(true)}>
          <SquarePlus size={40} />
          <h2>Add sections to the {type}</h2>
        </div>
      ) : (
        <div className="noSectionsAdded solid">
          <h2>No section to see</h2>
        </div>
      )}
      <div>
        <Button
          fullWidth
          className="downloadMobile"
          variant="default"
          color=""
          size="md"
          leftIcon={<Certificate color={canDownload ? "red" : "gray"} />}
          onClick={() => setShowCertificate(true)}
          disabled={!canDownload}
        >
          View certificate
        </Button>
        <Button
          className="downloadMobile"
          component="a"
          target="_blank"
          variant="default"
          color=""
          size="md"
          href={entryData?.file}
          leftIcon={<BookDownload color="red" />}
        >
          Download full syllabus
        </Button>
      </div>
    </div>
  );
}
