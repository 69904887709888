import React, { useContext, useState } from "react";
import styled from "styled-components";
import logo from "../../../Assets/Svgs/logo/bgLogo.svg";
import { Group, Stack } from "@mantine/core";
import { AdminNavLinks, NavLinks, UniversitiesNavLinks } from "./data";
import NavLink from "./NavLink";
import Dropdown from "./Dropdown";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../../Helpers/Context";

const Navbar = () => {
  const [currentDropdown, setCurrentDropdown] = useState(null);
  const { user } = useContext(AppContext);
  const userType = user?.user_type.type;
  const { pathname } = useLocation();
  const studentsDropData = [
    { title: "Contacted students", value: "contacted-students" },
    { title: "Admitted students", value: "admitted-students" },
  ];
  const companyDropData = [
    {
      title: "Universities",
      value: "universities",
    },
    {
      title: "Students",
      value: "admitted-students",
    },
  ];

  return (
    <NavbarStyle>
      <Group position="center">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </Group>

      <Stack my={40} spacing={5}>
        {userType === "Agent"
          ? NavLinks.map((navLink, index) => (
              <Stack key={index}>
                <NavLink
                  props={{
                    title: navLink.title,
                    to: navLink.to,
                    icon: navLink.icon,
                    iconChevron: navLink.iconChevron,
                    currentDropdown,
                    pathname,
                    tabOne: navLink.tabOne,
                    tabTwo: navLink.tabTwo,
                    pathOne: navLink.pathOne,
                    isDropdown: navLink.isDropdown,
                    setCurrentDropdown,
                  }}
                />
                {(currentDropdown === navLink.title ||
                  pathname === navLink.tabOne ||
                  pathname === navLink.tabTwo ||
                  pathname === navLink.pathOne) && (
                  <Dropdown
                    props={
                      (currentDropdown === "Students" ||
                        pathname === "/contacted-students" ||
                        pathname === "/admitted-students" ||
                        pathname === "/student-profile") && {
                        tabData: studentsDropData,
                      }
                    }
                  />
                )}
              </Stack>
            ))
          : userType === "Company"
          ? UniversitiesNavLinks.map((navLink, index) => (
              <Stack  key={index}>
                <NavLink
                  props={{
                    title: navLink.title,
                    to: navLink.to,
                    icon: navLink.icon,
                    iconChevron: navLink.iconChevron,
                    currentDropdown,
                    pathname,
                    tabOne: navLink.tabOne,
                    tabTwo: navLink.tabTwo,
                    pathOne: navLink.pathOne,
                    isDropdown: navLink.isDropdown,
                    setCurrentDropdown,
                  }}
                />
                {(currentDropdown === navLink.title ||
                  pathname === navLink.tabOne ||
                  pathname === navLink.tabTwo) && (
                  <Dropdown
                    props={
                      (currentDropdown === "Study Abroad" ||
                        pathname === "/universities" ||
                        pathname === "/admitted-students") && {
                        tabData: companyDropData,
                      }
                    }
                  />
                )}
              </Stack>
            ))
          : AdminNavLinks.map((navLink, index) => (
              <Stack key={index}>
                <NavLink
                  props={{
                    title: navLink.title,
                    to: navLink.to,
                    icon: navLink.icon,
                    currentDropdown,
                    pathname,
                    isDropdown: navLink.isDropdown,
                    setCurrentDropdown,
                  }}
                />
              </Stack>
            ))}
      </Stack>
    </NavbarStyle>
  );
};

export default Navbar;

const NavbarStyle = styled.div`
  width: 100%;
`;
