import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, Group, Modal, Button, Text, Stack, Title } from "@mantine/core";
import { Plus, DatabaseOff } from "tabler-icons-react";
import CustomButton from "../../Components/Common/CustomButton";
import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import InfiniteScroll from "../../Components/Common/InfiniteScroll";
import ProgramCard from "../../Components/Program/ProgramCard";
import Loader from "../../Components/Common/Loader";
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";

// process.env.NODE_ENV === 'production' 
//   ? process.env.REACT_APP_BACKEND_URL_PROD 
//   : process.env.REACT_APP_BACKEND_URL_DEV;


const ProgramList = ({props}) => {
  const {type} = props
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    programId: null
  });

  const link = type === 'steam' ? 
  {
    own_programs : 'steam/steam/own_steams',
    delete_program : 'steam/steam/'
  } : 
  {
    own_programs : 'bootcamp/own_bootcamps',
    delete_program : 'bootcamp/'
  }

  const url =  `${process.env.REACT_APP_BACKEND_URL}${link.own_programs}`;

  const handleDeleteConfirm = (id) => {
    setDeleteModal({ isOpen: true, programId: id });
  };

  const handleDeleteCancel = () => {
    setDeleteModal({ isOpen: false, programId: null });
  };

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } =
    useInfiniteQuery({
      queryKey: ["programList",type],
      queryFn: async ({ pageParam = url }) => {
        const { data } = await axios.get(pageParam);
        return data;
      },
      getNextPageParam: (lastPage) => {
        return lastPage.next || undefined;
      },
    });
    const queryClient = useQueryClient();

    const deleteMutation = useMutation({
      mutationFn: (programId) => axios.delete(`${link.delete_program}${programId}`),
      onSuccess: () => {
        queryClient.invalidateQueries(["programList"]);
        setDeleteModal({ isOpen: false, programId: null });
      },
      onError: (error) => {
      console.error(`Error deleting ${type}:`, error);
      },
    });

  useSetCurrentRoute([{
    title: type === 'steam' ? "STEAM" : "Bootcamp", 
    to: type === 'steam' ? "/steams" : "/bootcamps"
  }]);

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  if (!data?.pages?.[0]?.results?.length) {
    return (
      <ProgramListContainer>
        <Stack align="center" spacing="xl" mt={40}>
          <Group mb={20} align="left">
        <CustomButton
          onClick={() => {}}
          bgColor="#00BD90"
          color="#fff"
          text={type === 'steam' ? "Create STEAM" : "Create Bootcamp"}
          isLink={true}
          href={`/${type}/create`}
          leftIcon={<Plus />}
          height="45px"
        />
      </Group>
          <Stack align="center" spacing="xs">
            <DatabaseOff size={32} color="#868E96" />
            <Title order={3} color="dimmed">Məlumat tapılmadı</Title>
            <Text color="dimmed" size="sm">
              Hazırda heç bir {type === 'steam' ? "STEAM" : "Bootcamp"} proqramı mövcud deyil. Yeni {type === 'steam' ? "STEAM" : "Bootcamp"} yaratmaq üçün yuxarıdakı düyməni istifadə edin.
            </Text>
          </Stack>
        </Stack>
      </ProgramListContainer>
    );
  }

  return (
    <ProgramListContainer>
      <Modal
        opened={deleteModal.isOpen}
        onClose={handleDeleteCancel}
        title="Təsdiqləmə"
        centered
      >
        <Text size="sm">Bu proqramı silmək istədiyinizə əminsiniz?</Text>
        <Group position="right" mt="md">
          <Button variant="outline" onClick={handleDeleteCancel}>
            Ləğv et
          </Button>
          <Button 
            color="red" 
            onClick={() => deleteMutation.mutate(deleteModal.programId)}
          >
            Sil
          </Button>
        </Group>
      </Modal>

      <Group mb={20} align="left">
        <CustomButton
          onClick={() => {}}
          bgColor="#00BD90"
          color="#fff"
          text={type === 'steam' ? "Create STEAM" : "Create Bootcamp"}
          isLink={true}
          href={`/${type}/create`}
          leftIcon={<Plus />}
          height="45px"
        />
      </Group>

      <InfiniteScroll
        isFetching={isFetching}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      >
        <Grid>
          {data?.pages.map((page) =>
            page.results.map((program) => (
              <Grid.Col md={3} key={program.id}>
                <ProgramCard 
                  type={type}
                  applied={program.apply} 
                  name={program[`${type}_name`]} 
                  id={program.id}
                  onDeleteClick={handleDeleteConfirm}
                />
              </Grid.Col>
            ))
          )}
        </Grid>
      </InfiniteScroll>
    </ProgramListContainer>
  );
};

export default ProgramList;

const ProgramListContainer = styled.div`
  padding: 20px;
`;

const ProgramItem = styled.div`
  padding: 10px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  border-radius: 4px;
`;