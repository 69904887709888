import React, { useState, useMemo } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';

const CustomPieChart = ({ data, type }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  console.log('data',data);
  // Process data based on type (age or school)
  const processedData = useMemo(() => {
    if (!data) return [];
    

    if (type === 'age') {
      // Count occurrences of each age
      const ageCounts = data?.reduce((acc, age) => {
        acc[age] = (acc[age] || 0) + 1;
        return acc;
      }, {});

      // Convert to chart format
      return Object.entries(ageCounts).map(([age, count]) => ({
        name: `${age} years`,
        value: count
      }));
    } else if (type === 'school' ) {
      // Count school types (0 and 1)
      const schoolCounts = data?.reduce((acc, type) => {
        acc[type] = (acc[type] || 0) + 1;
        return acc;
      }, {});

      // Convert to chart format
      return Object.entries(schoolCounts).map(([type, count]) => ({
        name: type === '0' ? 'Private' : 'Public',
        value: count,
        fill: type === '0' ? '#fab007' : '#fa5352'
      }));
    }

    return [];
  }, [data, type]);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
          {`Count: ${value}`}
        </text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={400} height={400}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={processedData}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
          {...(type === 'school' && { fill: null })}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
