import React, { useState, useEffect, useRef } from "react";
import {
  ActionIcon,
  Button,
  Group,
  Progress,
  Stack,
  Text,
} from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import styled from "styled-components";
import uploadIcon from "../../../../Assets/Svgs/steam/uploadIcon.svg";
import checkIcon from "../../../../Assets/Svgs/steam/checkIcon.svg";
import deleteIcon from "../../../../Assets/Svgs/steam/deleteIcon.svg";
import pageIcon from "../../../../Assets/Svgs/steam/pageIcon.svg";
import SaveButton from "../../Common/SaveButton";
import { convertToBase64 } from "../../../../Helpers";
import { IconMinus } from "@tabler/icons-react";
import CustomButton from "../../../Common/CustomButton";
import { Download, Eye, Trash } from "tabler-icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showNotification } from "@mantine/notifications";
import axios from '../../../../Helpers/Axios'
const getBase64FileSize = (base64String) => {
  // Base64 header'ı (örn: "data:image/jpeg;base64,") kaldır
  const base64WithoutHeader = base64String?.split(',')[1];
  
  // Base64 stringinin byte boyutunu hesapla
  const padding = base64WithoutHeader?.endsWith('==') ? 2 : base64WithoutHeader?.endsWith('=') ? 1 : 0;
  const fileSize = (base64WithoutHeader?.length * 3) / 4 - padding;
  
  // Boyutu okunaklı formata çevir
  if (fileSize < 1024) return `${fileSize.toFixed(2)} B`;
  else if (fileSize < 1024 * 1024) return `${(fileSize / 1024).toFixed(2)} KB`;
  else return `${(fileSize / (1024 * 1024)).toFixed(2)} MB`;
};
const FileContent = ({
  type,
  contentValue,
  link,
  pUnit,
  pSubUnit,
  pSubUnits,
  activeSubUnitTab,
  handleSubUnitContent,
  handleDeleteSubUnitContent,
  programId,
  unitId,
  subUnitId,
  setIsContentSave
}) => {
  const [prewFiles, setPrewFiles] = useState([]);
  const [downloadedFiles, setDownloadedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const queryClient = useQueryClient()

  const [showSaveButton, setShowSaveButton] = useState(false); // Save butonu durumu

  useEffect(() => {
    // Tüm dosyalar yüklendiyse "Save" butonunu göster
    if (
      prewFiles.length > 0 &&
      prewFiles.every((file) => file.progress === 100)
    ) {
      setShowSaveButton(true);
    }
  }, [prewFiles]);



  const handleFileUpload = async (files) => {
    // Yeni dosyaları base64'e çevir
    const newFiles = await Promise.all(
      files.map(async (file) => {
        const base64File = await convertToBase64(file);
        return {
          file_name: file.name,
          file_field: base64File,
        };
      })
    );

    // Yeni dosyaları prewFiles formatında hazırla
    const newPrewFiles = files.map(file => ({
      name: file.name,
      type: file.type,
      size: file.size < 1024 
        ? `${file.size} B` 
        : file.size < 1024 * 1024 
        ? `${(file.size / 1024).toFixed(2)} KB` 
        : `${(file.size / (1024 * 1024)).toFixed(2)} MB`,
      progress: 0
    }));

    // Mevcut prewFiles'ın progress değerlerini koru
    const currentPrewFiles = prewFiles.map(file => ({
      ...file,
      progress: 100 // Mevcut dosyaların progress'i 100
    }));

    // Yeni ve mevcut dosyaları birleştir
    setPrewFiles([...currentPrewFiles, ...newPrewFiles]);
    setUploadedFiles([...uploadedFiles, ...newFiles]);

    // Sadece yeni dosyalar için progress simülasyonu
    const startIndex = currentPrewFiles.length;
    newPrewFiles.forEach((_, index) => {
      let uploadProgress = 0;
      const interval = setInterval(() => {
        uploadProgress += 10;
        setPrewFiles(prevFiles => {
          const updatedFiles = [...prevFiles];
          updatedFiles[startIndex + index] = {
            ...updatedFiles[startIndex + index],
            progress: uploadProgress
          };
          return updatedFiles;
        });
        if (uploadProgress >= 100) clearInterval(interval);
      }, 100);
    });
  };

  const handleFileChange = async (id, file) => {
    setShowSaveButton(true);

    const base64File = await convertToBase64(file);
    const updatedFiles = [...downloadedFiles];
    downloadedFiles[id] = {
      ...updatedFiles[id],
      file_name: file.name,
      file_field: base64File,
    };
  };

  const fileInputRef = useRef(null);

  const {mutate:deleteFile ,isLoading} = useMutation({
  mutationFn: async (fileId) => {
    const response = await axios.delete(`/${link.program}/${programId}/${pUnit}/${unitId}/${type === "steam" ? pSubUnit : pSubUnits}/${subUnitId}/file/${fileId}/`);
    return response.data;
  },
  onSuccess: () => {
    showNotification({
      title: "Success",
      message: "File deleted successfully",
      color: "green",
    });
  //  queryClient.invalidateQueries(['program'],programId)
  },
  onError: (error) => {
    showNotification({
      title: "Error",
      message: "Failed to delete file: " + error.message,
      color: "red",
    });
  }
  })

  const deleteContent = () => {
    downloadedFiles?.forEach((file) => {
      if (file.id) {
        deleteFile(file.id);
      }
    });
    handleDeleteSubUnitContent(activeSubUnitTab,[],"files"); // Call the parent function to delete the image
  };

  const handleUploadedFileDelete = (index) => {
    // Yeni array'ləri yaradaq
    const newPrewFiles = prewFiles.filter((_, i) => i !== index);
    const newUploadedFiles = uploadedFiles.filter((_, i) => i !== index);
    
    setPrewFiles(newPrewFiles);
    setUploadedFiles(newUploadedFiles);
    
    // Əgər bütün fayllar silindisə, Save button'u gizlədək
    if (newPrewFiles.length === 0 && newUploadedFiles.length === 0) {
      setShowSaveButton(false);
    } else {
      setShowSaveButton(true);
    }
  };

  const handleDownloadedFileDelete = (fileId,index) => {
    if(fileId){
      deleteFile(fileId);
      setDownloadedFiles(downloadedFiles.filter((_, i) => i !== index));
    }else if(index || index === 0){
      setDownloadedFiles(downloadedFiles.filter((_, i) => i !== index));
      setShowSaveButton(true);  
    }
  };

  const handleSave = () => {
    const combineFiles = [...uploadedFiles, ...downloadedFiles];
    handleSubUnitContent("files", combineFiles, activeSubUnitTab);
    setShowSaveButton(false); // Save butonunu gizle
    setIsContentSave(true)
  };





  useEffect(() => {
    if (contentValue && contentValue.length > 0 && contentValue[0] !== 'empty') {
      if (Array.isArray(contentValue) && contentValue.length > 0) {
        const downloadedFiles = contentValue.filter((file) => file.id);
        setDownloadedFiles(downloadedFiles);
      } else {
        setDownloadedFiles([]);
        setUploadedFiles([]);
        setPrewFiles([]);
      }
    }
  }, [contentValue, activeSubUnitTab]);
  

  return (
    <FileContentStyle>
      <Group align="center" position="apart">
        <Text mb={10} size={16} weight={500} color="#121926">
          Files
        </Text>
        <ActionIcon onClick={() => deleteContent()} color="red">
          <IconMinus size={16} />
        </ActionIcon>
      </Group>

      <Dropzone
        onDrop={handleFileUpload}
        onReject={(files) => console.log("rejected files", files)}
        maxSize={3 * 1024 ** 2}
        accept={{
          'application/pdf': ['.pdf'],
          'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
          'text/*': ['.txt', '.md'],
          'application/msword': ['.doc'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
          'application/zip': ['.zip']
        }}
        multiple
      >
        <Group
          position="center"
          spacing="xl"
          style={{ minHeight: 126, pointerEvents: "none" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img width={40} height={40} src={uploadIcon} alt="Upload Icon" />
            <Text size="14" weight={400} color="#475467">
              <span style={{ fontWeight: "500", color: "#F79009" }}>
                Click to upload
              </span>{" "}
              or drag and drop{" "}
            </Text>
            <Text size="14" weight={400} color="#475467" mt={7}>
              Any file type (max. 3MB){" "}
            </Text>
          </div>
        </Group>
      </Dropzone>
      {downloadedFiles?.length > 0 && (
        <>
          <Text mt={8} color="#00bd90" size={14} weight={500}>
            Download files / {downloadedFiles.length}
          </Text>
          <div className="uploaded-file-styled">
            {downloadedFiles.map((file, index) => (
              <div key={index} className="uploaded-file-container">
                <Group spacing={25} noWrap>
                  <div style={{ position: "relative" }}>
                    <img
                      width={32}
                      height={40}
                      src={pageIcon}
                      alt="File Icon"
                    />
                  </div>
                  <Stack style={{ width: "100%" }}>
                    <Group position="apart">
                      <div>
                        <Text>...{file?.file_name?.slice(-20)}</Text>
                      </div>
                      <Group>
                        <CustomButton
                          variant="outline"
                          leftIcon={<Trash size={14} />}
                          onClick={() => 
                            handleDownloadedFileDelete(file?.id,index)}
                          text="Delete"
                          border="1px solid #029199"
                          color="#029199"
                          size="sm"
                          fs="14px"
                        />
                        <CustomButton
                          variant="outline"
                          leftIcon={<Eye size={14} />}
                          onClick={() => {}}
                          isA={true}
                          href={file.file_field}
                          text="Preview"
                          border="1px solid #cdd5df"
                          color="#1f2a37"
                          size="sm"
                          fs="14px"
                        />
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleFileChange(index, e.target.files[0])
                          }
                        />
                      </Group>
                    </Group>
                  </Stack>
                </Group>
              </div>
            ))}
          </div>
        </>
      )}
      {prewFiles.length > 0 && (
        <>
          <Text mt={8} color="#00bd90" size={14} weight={500}>
            Uploaded Files / {prewFiles.length}
          </Text>
          <div className="uploaded-file-styled">
            {prewFiles.map((file, index) => (
              <div key={index} className="uploaded-file-container">
                <Group spacing={25} noWrap>
                  <div style={{ position: "relative" }}>
                    <img
                      width={32}
                      height={40}
                      src={pageIcon}
                      alt="File Icon"
                    />
                    <div className="badge">
                      <Text
                        size={10}
                        weight={600}
                        color="#fff"
                        style={{ padding: "1px 6px" }}
                      >
                        {file.type.split("/")[1].toUpperCase() || "FILE"}
                      </Text>
                    </div>
                  </div>
                  <Stack style={{ width: "100%" }}>
                    <Group position="apart">
                      <div>
                        <Text>{file.name}</Text>
                        <Text>{file.size}</Text>
                      </div>
                      <Group>
                        <img
                          src={deleteIcon}
                          alt="Delete Icon"
                          onClick={() => handleUploadedFileDelete(index)}
                          style={{ cursor: "pointer" }}
                        />
                        <img src={checkIcon} alt="Check Icon" />
                      </Group>
                    </Group>
                    {file.progress < 100 && file.progress >= 0 && (
                      <Progress size="sm" value={file.progress} />
                    )}
                  </Stack>
                </Group>
              </div>
            ))}
          </div>
        </>
      )}
      {showSaveButton && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        >
          <SaveButton onClick={handleSave} />
        </div>
      )}
    </FileContentStyle>
  );
};

export default FileContent;

const FileContentStyle = styled.div`
  .mantine-Dropzone-root {
    border: 1px solid #eaecf0;
    border-radius: 16px;
  }
  .uploaded-file-styled {
    border: 1px solid #e3e8ef;
    background: #fff;
    border-radius: 16px;
    margin-top: 10px;
    .uploaded-file-container {
      padding: 20px;
      .badge {
        border-radius: 4px;
        background: #d92d20;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: -10px;
        bottom: 10px;
      }
    }
  }
`;
