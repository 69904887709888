import React, { useState, useMemo, useCallback, useEffect } from "react";
import styled from "styled-components";
import {
  Table,
  TextInput,
  Accordion,
  ScrollArea,
  Group,
  Stack,
} from "@mantine/core";
import FilterModal from "../../Components/Steam/Modals/FilterModal";
import CustomButton from "../../Components/Common/CustomButton";
import {
  IconAdjustmentsHorizontal,
  IconChevronLeft,
  IconChevronRight,
  IconFileExport,
  IconSearch,
} from "@tabler/icons-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import Loader from "../../Components/Common/Loader";
import { debounce } from "lodash"; // Make sure to import lodash or implement your own debounce function
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";

// Styled components
const Container = styled.div`
  padding: 20px;
  thead {
    tr {
      th {
        padding: 15px 10px !important;
        white-space: nowrap; /* Prevent text wrapping */
      }
    }
  }
  tbody {
    tr {
      td {
        background: #fff;
        font-size: 16px;
        white-space: nowrap; /* Prevent text wrapping */
        word-break: keep-all; /* Prevent text breaking */
      }
    }
  }
  .mantine-Accordion-item.mantine-v4lv9f {
    border: none !important;
  }
  .mantine-Accordion-item[data-active="true"] {
    background-color: #ddf2f3;
    padding: 0px 10px;
    border-radius: 5px;
  }
  .mantine-UnstyledButton-root.mantine-Accordion-control.mantine-xizyzk {
    padding: 16px 0px;
    border-bottom: none;
    &:hover {
      background-color: transparent;
    }
  }
  .mantine-ukbbnm.mantine-Accordion-content {
    padding: 0px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledScrollArea = styled(ScrollArea)`
  .mantine-ScrollArea-scrollbar[data-orientation="horizontal"] {
    margin-top: 10px;
    height: 8px;

    .mantine-ScrollArea-thumb {
      background-color: #1e578e;
      height: 8px;
    }
  }
`;

const AccordionContent = styled.div`
  padding: 0px 0px 16px 0px;
  div {
    padding: 0px 0px 16px 0px;
  }
  div:last-child {
    padding-bottom: 0;
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  margin-top: 10px;
  padding-bottom: 10px; // Add padding to create space for the scrollbar
`;

const StyledTable = styled(Table)`
  min-width: 1200px;
`;

const StudentDashboard = ({type}) => {
  const { id: initialId } = useParams();
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState(initialId);
  const [searchParams, setSearchParams] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [accordionValue, setAccordionValue] = useState(null);
  const [emailSearch, setEmailSearch] = useState("");
  const [debouncedEmailSearch, setDebouncedEmailSearch] = useState("");
  useSetCurrentRoute([{title: type === 'steam' ? "STEAM" : "Bootcamp", to: type === 'steam' ? "/steams" : "/bootcamps"},{title: "Applied Students", to: type === 'steam' ? `/steam/${initialId}/user-list` : `/bootcamps/${initialId}/user-list`}]);

  const {
    data: paginatedData,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      "student-list",
      selectedProgramId,
      searchParams,
      debouncedEmailSearch,
      currentPage,
    ],
    queryFn: async () => {
      let queryString = "";
      if (searchParams) {
        queryString = new URLSearchParams(searchParams).toString();
      }
      if (debouncedEmailSearch) {
        queryString +=
          (queryString
            ? "&"
            : `${type}=${selectedProgramId ? selectedProgramId : initialId}&`) +
          `email=${encodeURIComponent(debouncedEmailSearch)}`;
      }

      if (queryString) {
        const response = await axios.post(
          `/${type === "steam" ? "steam/steam" : "bootcamp"}/own_${type}s/search?page=${currentPage}${
            queryString ? `&${queryString}` : ""
          }`
        );
        return response.data;
      } else {
        const response = await axios.get(
          `/${type === "steam" ? "steam/steam" : "bootcamp"}/own_${type}s/${selectedProgramId}/users?page=${currentPage}`
        );
        return response.data;
      }
    },
  });
  const studentList = paginatedData?.results || [];

  // Handle pagination
  const handleNextPage = useCallback(() => {
    if (paginatedData?.next) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [paginatedData?.next]);

  const handlePreviousPage = useCallback(() => {
    if (paginatedData?.previous) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [paginatedData?.previous]);


  const columns = useMemo(
    () => [
      { id: 'name', label: 'Students (name, surname)' },
      { id: `${type}`, label: `${type === "steam" ? "STEAM" : "Bootcamp"}` },
      { id: 'email', label: 'E-mail' },
      { id: 'price', label: 'Price' },
      { id: 'promo', label: 'Promo-code' },
      { id: 'tasks', label: 'Completed Tasks' },
      { id: 'status', label: `${type === "steam" ? "STEAM Status" : "Bootcamp Status"}` },
    ],
    []
  );

  const handleProgramClick = useCallback((programId, type) => {
    if ((type === "steam" || type === "bootcamp")) {
      setSelectedProgramId(programId);
    } else {
    }
  }, []);
  const handleCompleteTaskClick = useCallback(
    (program_id, task, studentId) => {
      if (accordionValue) {
        const unit = type === "steam" ? "week" : "section";
        const subUnit = type === "steam" ? "day" : "lesson";
        localStorage.setItem(`${subUnit}Id`, task[`${subUnit}_id`]);
        localStorage.setItem(`${unit}Id`, task[`${unit}_id`]);
        navigate(`/${type}/${program_id}/task/${task.id}/user/${studentId}`);
      }
    },
    [navigate, accordionValue,type]
  );

  const renderAccordion = (
    items,
    valuePrefix,
    renderItem,
    studentId,
    currentId,
    type
  ) => {
    const currentItem =
      (type === "steam" || type === "bootcamp") ? items.find((item) => item.id == currentId) : items[0];
    const otherItems =
      (type === "steam" || type === "bootcamp")
        ? items.filter((item) => item.id != currentId)
        : items.slice(1);

    return (
      <Accordion value={accordionValue} onChange={setAccordionValue}>
        <Accordion.Item value={`${valuePrefix}-${currentId}`}>
          <Accordion.Control>
            {(type === "steam" || type === "bootcamp") && currentItem
              ? renderItem(currentItem)
              : type === "task" &&
                currentItem && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleCompleteTaskClick(currentId, currentItem, studentId)
                    }
                  >
                    {renderItem(currentItem)}
                  </div>
                )}
          </Accordion.Control>
          {otherItems.length > 0 && (
            <Accordion.Panel>
              <AccordionContent>
                {otherItems.map((item, i) =>
                  (type === "steam" || type === "bootcamp") ? (
                    <div
                      key={`${type}-${item.id}-${i}`}
                      onClick={() => {
                        handleProgramClick(item.id, type);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {renderItem(item)}
                    </div>
                  ) : (
                    <Stack key={`task-${item.id}-${i}`}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleCompleteTaskClick(currentId, item, studentId)
                        }
                      >
                        {renderItem(item)}
                      </div>
                    </Stack>
                  )
                )}
              </AccordionContent>
            </Accordion.Panel>
          )}
        </Accordion.Item>
      </Accordion>
    );
  };

  const renderStudentRow = (student, index) => {
    const selectedProgram = student[`applied_${type}s`].find(
      (program) => program.id == selectedProgramId
    );

    return (
      <tr key={index}>
        <td>{`${student.first_name} ${student.last_name}`}</td>
        <td>
          {renderAccordion(
            student[`applied_${type}s`],
            `${type}-${index}`,
            (program) => program[`${type}_name`],
            student?.id,
            selectedProgramId,
            type
          )}
        </td>
        <td>{student.email}</td>
        <td>{selectedProgram?.price}</td>
        <td >{student?.promoCode ? student?.promoCode : "N/A"}</td>
        <td>
          {student.completed_tasks && student.completed_tasks.length > 0 ? (
            renderAccordion(
              student.completed_tasks || [],
              `tasks-${index}`,
              (task) => task.task_name,
              student?.id,
              selectedProgramId,
              "task"
            )
          ) : (
            "N/A"
          )}
        </td>
        <td>
          <div
            style={{
              textAlign: "center",
              padding: "6px 10px",
              borderRadius: "10px",
              background: selectedProgram?.is_completed ? "#E2F9FA" : "#9E9E9E",
              color: selectedProgram?.is_completed ? "#029199" : "#F5F5F5",
            }}
          >
            {selectedProgram?.is_completed ? "Completed" : "In Progress"}
          </div>
        </td>
      </tr>
    );
  };

  const handleSearch = (params) => {
    setSearchParams(params);
    setFilterOpen(false);
  };

  const handleResetSearch = () => {
    setSearchParams(null);
    queryClient.invalidateQueries(["student-list", selectedProgramId]);
  };

  // Create a debounced function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setDebouncedEmailSearch(value);
    }, 1000), // 500ms delay
    []
  );

  // Effect to trigger debounced search
  useEffect(() => {
    debouncedSearch(emailSearch);
    // Cleanup function to cancel the debounce on unmount
    return () => debouncedSearch.cancel();
  }, [emailSearch, debouncedSearch]);

  const handleEmailSearch = (value) => {
    setEmailSearch(value);
  };

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Container>
      <Header>
        <Group style={{ width: "50%" }} align="center" spacing={10}>
          <CustomButton
            height="42px"
            leftIcon={<IconAdjustmentsHorizontal />}
            text="Filter"
            bgColor="#029199"
            color="#fff"
            onClick={() => setFilterOpen(true)}
          />
          <TextInput
            height="43px"
            radius={12}
            style={{ flexGrow: 1 }}
            icon={<IconSearch size={18} />}
            styles={() => ({
              root: { borderRadius: "10px", flexGrow: 1 },
              input: { height: "43px" },
            })}
            placeholder="Search mail"
            value={emailSearch}
            onChange={(event) => handleEmailSearch(event.currentTarget.value)}
          />
        </Group>
        <Group
          style={{ width: "50%" }}
          position="right"
          align="center"
          spacing={10}
        >
          {searchParams && (
            <CustomButton
              text="Reset Search"
              variant="outline"
              onClick={handleResetSearch}
              height="42px"
              border="1px solid #646464"
              color="#646464"
              bgColor="transparent"
            />
          )}
          <CustomButton
            text="Previous"
            variant="variant"
            leftIcon={<IconChevronLeft />}
            onClick={handlePreviousPage}
            height="42px"
            border="1px solid #646464"
            color="#646464"
            bgColor="transparent"
            disabled={!paginatedData?.previous}
          />
          <CustomButton
            text="Next"
            variant="variant"
            rightIcon={<IconChevronRight />}
            onClick={handleNextPage}
            height="42px"
            border="1px solid #646464"
            color="#646464"
            bgColor="transparent"
            disabled={!paginatedData?.next}
          />
          <CustomButton
            text="Export"
            variant="variant"
            leftIcon={<IconFileExport />}
            onClick={() => {}}
            height="42px"
            color="#fff"
            bgColor="#646464"
          />
        </Group>
      </Header>

      <StyledScrollArea style={{ borderRadius: "10px" }} type="always">
        <TableWrapper>
          <StyledTable>
            <thead style={{ padding: "10px", background: "#f5f5f5" }}>
              <tr>
                {columns.map((column) => (
                  <th key={column.id}>{column.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>{studentList?.map(renderStudentRow)}</tbody>
          </StyledTable>
        </TableWrapper>
      </StyledScrollArea>
      <FilterModal
        type={type}
        opened={filterOpen}
        onClose={() => setFilterOpen(false)}
        onSearch={handleSearch}
      />
    </Container>
  );
};

export default StudentDashboard;
