import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, QueryClient, QueryClientProvider, useMutation } from "@tanstack/react-query";
import {
  TextInput,
  Button,
  Textarea,
  Group,
  FileInput,
  Select,
  Grid,
  Stack,
  Text,
} from "@mantine/core";
import { RichTextEditor } from "@mantine/rte";
import styled from "styled-components";
import UploadFileInput from "../../Components/University/UploadFileInput";
import axios from "../../Helpers/Axios";
import { Confetti, MapPin } from "tabler-icons-react";
import linkIcon from "../../Assets/Svgs/university/LinkIcon.svg";
import { AppContext } from "../../Helpers/Context";
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";
import { showNotification } from "@mantine/notifications";
import CustomCheckbox from "../../Components/CustomCheckbox";


const queryClient = new QueryClient();

export const UniversityForm = ({ type }) => {
  const { universityId } = useParams();

  useSetCurrentRoute([{ title: "Universities", to: "/universities" },{ title:type === "edit" ? "Edit form" : "Create form", to: type === "edit" ? `/edit-university/${universityId}` : "/create-university" }]);
  const navigate = useNavigate();
  const { register, handleSubmit, control, setValue } = useForm();
  const { user } = useContext(AppContext);
  const [placeName, setPlaceName] = useState("");
  const [isScholarship, setIsScholarship] = useState(false);




  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: async () => await axios.get(`/api/countries/`).then((res) => res?.data),
  });

  const { data: university, isSuccess: isUniversityLoaded } = useQuery({
    queryKey: ["university", universityId],
    queryFn: async () => await axios.get(`/university/byid/${universityId}/`).then((res) => res?.data.University[0]),
    enabled: !!universityId,
  });
  console.log('u',university);

  useEffect(() => {
    if (type === "edit" && university) {
      setValue("title", university.title);
      setValue("website", university.website);
      setValue("qs_world", university.qs_world);
      setValue("times_higher", university.times_higher);
      setValue("active_students", university.active_students);
      setValue("foreign_students", university.foreign_students);
      setValue("placeName", university.placeName);
      setValue("description", university.description);
      setValue("alumni", university.alumni);
      setValue("country", university.country);
      setValue("status", university.status);
      setValue("latitude", university.latitude);
      setValue("longitude", university.longitude);
      setIsScholarship(university.goverment_scholarship)
      setPlaceName(university.place_name);
    }
  }, [type, university, setValue]);

  const { mutate: createUniversity } = useMutation(
    ["createUniversity"],
    async (e) => {
      return await axios.post("/university/create/", e);
    },
    {
      onSuccess: () => {
        showNotification({
          icon: <Confetti />,
          title: "Congratulations!",
          message: "You have successfully updated",
          color: "teal",
        });
        queryClient.invalidateQueries(['universities']);
        navigate("/universities");
      },
      onError: (error) => {
        console.log("error", error);
      },
    }
  );

  const { mutate: updateUniversity } = useMutation(
    ["updateUniversity"],
    async ({ id, data }) => {
      return await axios.patch(`/university/edit/${id}/`, data);
    },
    {
      onSuccess: () => {
        showNotification({
          icon: <Confetti />,
          title: "Congratulations!",
          message: "You have successfully updated",
          color: "teal",
        });
        queryClient.invalidateQueries(['universities']);
        navigate("/universities");
      },
      onError: (error) => {
        console.log("error", error);
      },
    }
  );

  const onSubmit = (data) => {
    const formData = new FormData();
console.log('data',data);
    data.title && formData.append("title", data.title);
    data.website && formData.append("website", data.website);
    data.qs_world && formData.append("qs_world", data.qs_world);
    data.times_higher && formData.append("times_higher", data.times_higher);
    data.active_students && formData.append("active_students", data.active_students);
    data.foreign_students && formData.append("foreign_students", data.foreign_students);
    placeName && formData.append("place_name", placeName);
    data.description && formData.append("description", data.description);
    data.alumni && formData.append("alumni", data.alumni);
    formData.append("goverment_scholarship",isScholarship)
    formData.append("latitude", data.latitude);
    formData.append("longitude", data.longitude);
    formData.append("company", user?.id);
    formData.append("is_shared", true);
    formData.append("active", true);
    data.country && formData.append("country", data.country);
    data.status && formData.append("status", data.status);
    data.logo && formData.append("logo", data.logo);
    data.head_image && formData.append("head_image", data.head_image);
    data.images && data.images.forEach((image) => formData.append("images", image));

    if (type === "edit" && universityId) {
      updateUniversity({ id: universityId, data: formData });
    } else {
      createUniversity(formData);
    }
  };

  const fetchCoordinates = async (placeName) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${placeName}`);
      const data = await response.json();
      if (data && data.length > 0) {
        const { lat, lon } = data[0];
        console.log('place',placeName)
        setValue("latitude", parseFloat(lat));
        setValue("longitude", parseFloat(lon));
        setValue("place_name", placeName);
      } else {
        alert("Place not found");
      }
    } catch (error) {
      console.error("Error fetching geocode:", error);
    }
  };

  useEffect(() => {
    if (placeName) {
      const delayDebounceFn = setTimeout(() => {
        fetchCoordinates(placeName);
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [placeName]);

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              placeholder="Name"
              size="sm"
              className="form-input"
              radius={10}
              label="University Name"
              {...register("title", { required: true })}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <UploadFileInput
              id="logo"
              name="logo"
              label="University logo"
              setValue={setValue}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <UploadFileInput
              id="head_image"
              name="head_image"
              label="University background"
              setValue={setValue}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select
                  searchable
                  {...field}
                  size="sm"
                  className="form-input"
                  placeholder="Country"
                  label="Country"
                  radius={10}
                  data={countries ? countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                  })) : []}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </Grid.Col>

        

          <Grid.Col span={6}>
            <TextInput
              size="sm"
              className="form-input location"
              radius={10}
              name="place_name"
              label="Location name"
              icon={<MapPin size={16} strokeWidth={1.75} />}
              placeholder="Wellington Square, Oxford OX1 2JD, Birləşmiş Krallıq"
              value={placeName}
              onChange={(e) => setPlaceName(e.target.value)}
            />
            {/* Hidden input to store coordinates */}
            <input type="hidden" {...register("coordinates")} />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              size="sm"
              className="form-input"
              placeholder="Website"
              radius={10}
              icon={<img width={19} height={19} src={linkIcon} alt="link" />}
              label="University Website Link"
              {...register("website")}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
          <Group  wrap  mt={31} noWrap spacing={5}>
            <CustomCheckbox
              checked={isScholarship}
              onChange={() => setIsScholarship(!isScholarship)}
            />
            <div
              style={{
                height: "40px",
                width: "100%",
                border: "1px solid #D6D5D5",
                display: "flex",
                alignItems: "center",
                background: "#fff",
                padding: "0 10px",
                borderRadius: "10px",
              }}
            >
              Scholarship
            </div>
          </Group>
        </Grid.Col>

          <Grid.Col span={12}>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  searchable
                  {...field}
                  size="sm"
                  className="form-input"
                  placeholder="Pick one.."
                  label="Status"
                  radius={10}
                  data={[
                    { value: "1", label: "Private" },
                    { value: "2", label: "Public" },
                  ]}
                />
              )}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <Stack spacing={0}>
              <Text className="editor-label">About</Text>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <RichTextEditor {...field} radius={10} label="About" />
                )}
              />
            </Stack>
          </Grid.Col>

          <Grid.Col span={12}>
            <Stack>
              <Text>Ranking and Ratings</Text>
              <Line />
            </Stack>
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              size="sm"
              className="form-input"
              placeholder="Count"
              radius={10}
              label="QS World University Rankings"
              {...register("qs_world")}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              size="sm"
              className="form-input"
              placeholder="Count"
              radius={10}
              label="Times Higher Education"
              {...register("times_higher")}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <UploadFileInput
              id="images"
              name="images"
              label="Videos and media"
              setValue={setValue}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <Stack>
              <Text>Students</Text>
              <Line />
            </Stack>
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              size="sm"
              className="form-input"
              radius={10}
              label="Active Students"
              placeholder="Count"
              {...register("active_students")}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              size="sm"
              className="form-input"
              radius={10}
              label="International Students"
              placeholder="Count"
              {...register("foreign_students")}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <Stack spacing={0}>
              <Text className="editor-label">Places where alumni work</Text>
              <Controller
                name="alumni"
                control={control}
                render={({ field }) => (
                  <RichTextEditor
                    {...field}
                    radius={10}
                    label="Places where alumni work"
                  />
                )}
              />
            </Stack>
          </Grid.Col>

          <Grid.Col span={12}>
            <Button 
              size="lg"
              style={{
                width: "100%",
                background: "#FE6027",
                color: "#fff",
                borderRadius: "10px",
                fontSize: "16px",
                fontWeight: "500",
                "&:hover": {
                  background: "#FE6027",
                },
              }}
              type="submit"
            >
              {type === "edit" ? "Update" : "Create"}
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </FormContainer>
  );
};

export default UniversityForm;

const FormContainer = styled.div`
  padding: 20px 100px 20px 20px;
  max-width: 100%;
  margin: auto;
  .form-input {
    textarea::placeholder {
      color: #697586;
      font-size: 16px;
      font-weight: 400;
    }
    input {
      height: 40px;
    }
    input::placeholder {
      color: #697586;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .location {
    input {
      padding-left: 30px;
    }
    input::placeholder {
      color: #697586;
      font-size: 16px;
      font-weight: 400;
    }
  }
  #images,
  #head_image,
  #logo {
    height: 56px;
    border-radius: 10px;
  }
  #images,
  #head_image,
  #logo {
    span {
      color: #697586;
      font-size: 15px;
      font-weight: 400;
    }
  }
  .upload-btn {
    margin-right: 130px;
    background: #f8fafc;
    color: #1f2a37;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #cdd5df;
  }
  .editor-label,
  label {
    color: #364152;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 6px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: #979797;
`;
