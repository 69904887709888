import React, { useState, useRef, useEffect } from 'react';
import { FileInput, Button, Group, Text } from '@mantine/core';
import styled from 'styled-components';
import { IconEye, IconPlus } from '@tabler/icons-react';

const FileUploadContainer = styled.div`
 position: relative;
`;

const FileUploadButton = styled(Button)`
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 6px 16px;
  cursor: pointer;
  color: #1F2A37;
  text-decoration: none;
  position: absolute;
  bottom: 3px;
  right: 4px;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background-color: #e5e7eb;
  }
`;

const CustomFileInput = ({ disabled, label, placeholder, accept, value, onChange, error }) => {
  const [filePreview, setFilePreview] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (value instanceof File) {
      setFilePreview(URL.createObjectURL(value));
    } else if (typeof value === 'string') {
      setFilePreview(value);
    } else {
      setFilePreview(null);
    }
  }, [value]);

  const handleFileChange = (file) => {
    onChange(file);
    if (file) {
      setFilePreview(URL.createObjectURL(file));
    } else {
      setFilePreview(null);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <FileUploadContainer>
      <FileInput
        ref={fileInputRef}
        label={label}
        placeholder="PDF file"
        accept={accept}
        value={disabled ? null : value}
        onChange={handleFileChange}
        style={{ flex: 1 }}
        radius={10}
        size="md"
        disabled={disabled}
      />
      {filePreview ? (
        <FileUploadButton rightIcon={<IconEye size={16} />} component="a" href={filePreview} target="_blank" rel="noopener noreferrer">
          View file
        </FileUploadButton>
      ) : (
        <FileUploadButton rightIcon={<IconPlus size={16} />} onClick={handleButtonClick}>
          Upload file
        </FileUploadButton>
      )}
      {error && (
        <Text color="red" size="sm" mt="sm">
          {error}
        </Text>
      )}
    </FileUploadContainer>
  );
};

export default CustomFileInput;