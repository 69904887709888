// import { DatePicker } from "@mantine/dates";
// import {
//   Button,
//   Divider,
//   FileInput,
//   Image,
//   Input,
//   MultiSelect,
//   NumberInput,
//   Select,
//   Text,
//   TextInput,
// } from "@mantine/core";
// import { RichTextEditor } from "@mantine/rte";
// import styled from "styled-components";

// export const EditProgramForm = ({ props }) => {
//   const {
//     newCreatedInternshipId,
//     addDay,
//     sendingAnswer,
//     type,
//     categories,
//     specialities,
//     schools,
//     partnersList,
//     languages,
//     programGroups,
//     entryData,
//     setEntryData,
//     mutateSend,
//   } = props;

//   const {
//     duration_time,
//     partners,
//     programGroup,
//     speciality,
//     start_date,
//     requirement,
//     school,
//     file,
//     category,
//     course_deadline,
//     description,
//     image,
//     language,
//     price,
//     title,
//     video,
//     duration,
//   } = entryData ? entryData : [];

//   return (
//     <FormStyle>
//       {!newCreatedInternshipId && (
//         <Text mb={20} size={18} weight={600}>
//           Edit program information
//         </Text>
//       )}

//       <form onSubmit={(e) => mutateSend(e)}>
//         <div className="titleAndImage">
//           <div className="inputs">
//             <TextInput
//               required
//               placeholder="Program Title"
//               label={`Title (${title?.length}/150)`}
//               value={title}
//               onChange={(e) =>
//                 setEntryData({ ...entryData, title: e.target.value })
//               }
//             />

//             <FileInput
//               required
//               label={
//                 !image?.name
//                   ? image?.startsWith("http")
//                     ? `Replace image`
//                     : "Image"
//                   : "Replace image"
//               }
//               value={null}
//               placeholder={
//                 image?.name
//                   ? image?.name
//                   : image?.startsWith("http")
//                   ? image
//                   : "Choose an image"
//               }
//               onChange={(e) => {
//                 setEntryData({ ...entryData, image: e });
//               }}
//             />
//           </div>

//           <Image
//             withPlaceholder
//             radius="sm"
//             width={"100%"}
//             height={"300px"}
//             src={image && image?.name ? URL.createObjectURL(image) : image}
//             alt="file"
//           />
//         </div>

//         <Divider my={20} color={"gray.3"} />

//         <div className="groups">
//           <div className="cuties">
//             <NumberInput
//               className="fullWidth"
//               required
//               label="Price"
//               max={1000}
//               min={0}
//               value={price ? parseFloat(price) : 0}
//               placeholder={"Price"}
//               precision={2}
//               onChange={(e) => setEntryData({ ...entryData, price: e })}
//             />

//             {type === "course" && (
//               <NumberInput
//                 className="fullWidth"
//                 required
//                 placeholder="Enter the duration"
//                 label="Duration (weeks)"
//                 max={1000}
//                 min={0}
//                 value={duration && JSON.parse(duration)}
//                 onChange={(e) => setEntryData({ ...entryData, duration: e })}
//               />
//             )}

//             {type === "internship" && (
//               <NumberInput
//                 className="fullWidth"
//                 required
//                 placeholder="Enter the duration"
//                 label="Duration (weeks)"
//                 max={1000}
//                 min={0}
//                 value={duration_time && JSON.parse(duration_time)}
//                 onChange={(e) =>
//                   setEntryData({ ...entryData, duration_time: e })
//                 }
//               />
//             )}

//             {type === "internship" && (
//               <DatePicker
//                 className="fullWidth"
//                 required
//                 label="Start date"
//                 value={start_date ? new Date(start_date) : new Date()}
//                 placeholder={"Start date"}
//                 onChange={(e) =>
//                   setEntryData({
//                     ...entryData,
//                     start_date: JSON.stringify(addDay(e)).substring(1, 11),
//                   })
//                 }
//               />
//             )}

//             {type === "course" && (
//               <DatePicker
//                 className="fullWidth"
//                 required
//                 label="Start date"
//                 value={course_deadline ? new Date(course_deadline) : new Date()}
//                 placeholder={"Start date"}
//                 onChange={(e) =>
//                   setEntryData({
//                     ...entryData,
//                     course_deadline: JSON.stringify(addDay(e)).substring(1, 11),
//                   })
//                 }
//               />
//             )}
//           </div>

//           <div className="cuties">
//             <Select
//               className="fullWidth"
//               required
//               label="Program group"
//               data={
//                 programGroups
//                   ? programGroups?.map((group) => ({
//                       value: group.id,
//                       label: group.name,
//                     }))
//                   : []
//               }
//               value={programGroup?.id ? programGroup.id : programGroup}
//               placeholder={`Choose a ${type} group`}
//               onChange={(e) => setEntryData({ ...entryData, programGroup: e })}
//             />

//             <Select
//               className="fullWidth"
//               required
//               label="Language"
//               data={
//                 languages
//                   ? languages?.map((lang) => ({
//                       value: lang.id,
//                       label: lang.language,
//                     }))
//                   : []
//               }
//               value={language ? language?.id : undefined}
//               placeholder={"Select a language"}
//               onChange={(e) => setEntryData({ ...entryData, language: e })}
//             />

//             {type === "course" && (
//               <Select
//                 className="fullWidth"
//                 required
//                 label="Category"
//                 data={
//                   categories
//                     ? categories?.map((category) => ({
//                         value: category?.id,
//                         label: category?.title,
//                       }))
//                     : []
//                 }
//                 value={category?.id}
//                 placeholder={
//                   category?.content ? category?.content : "Select a category"
//                 }
//                 onChange={(e) => setEntryData({ ...entryData, category: e })}
//               />
//             )}

//             {type === "internship" && (
//               <Select
//                 className="fullWidth"
//                 required
//                 label="Speciality"
//                 data={
//                   specialities
//                     ? specialities?.map((speciality) => ({
//                         value: speciality.id,
//                         label: speciality.content,
//                       }))
//                     : []
//                 }
//                 value={speciality ? speciality[0]?.id : undefined}
//                 placeholder={
//                   speciality?.length > 0
//                     ? speciality[0]?.content
//                     : "Select a speciality"
//                 }
//                 onChange={(e) => setEntryData({ ...entryData, speciality: e })}
//               />
//             )}
//           </div>

//           <div className="cuties">
//             <TextInput
//               className="fullWidth"
//               placeholder="Enter the link"
//               label="YouTube link"
//               value={video === "null" ? undefined : video}
//               onChange={(e) =>
//                 setEntryData({ ...entryData, video: e.target.value })
//               }
//             />

//             <FileInput
//               label={`PDF file`}
//               className="fullWidth"
//               value={null}
//               placeholder={file?.name ? file?.name : "Choose a file"}
//               onChange={(e) => {
//                 setEntryData({ ...entryData, file: e });
//               }}
//             />

//             <Select
//               label="School"
//               className="fullWidth"
//               data={
//                 schools
//                   ? schools?.map((school) => ({
//                       value: school.id,
//                       label: school.title,
//                     }))
//                   : []
//               }
//               value={school ? school?.id : ""}
//               placeholder={school ? school?.title : "Select a school"}
//               onChange={(e) => setEntryData({ ...entryData, school: e })}
//             />
//           </div>

//           <div>
//             <MultiSelect
//               label="Partners"
//               data={
//                 partnersList
//                   ? partnersList?.map((e) => ({ value: e.id, label: e.name }))
//                   : []
//               }
//               value={
//                 partners && partners[0]?.id
//                   ? partners.map((e) => e.id)
//                   : partners
//               }
//               placeholder="Choose a partner"
//               onChange={(e) => {
//                 setEntryData({ ...entryData, partners: e });
//               }}
//             />
//           </div>
//         </div>

//         <Divider my={20} color={"gray.3"} />

//         <div className="richtext">
//           <Input.Wrapper label="Description">
//             <RichTextEditor
//               value={description}
//               onChange={(e) => setEntryData({ ...entryData, description: e })}
//             />
//           </Input.Wrapper>

//           {type === "internship" && (
//             <Input.Wrapper label="Requirement">
//               <RichTextEditor
//                 value={requirement}
//                 onChange={(e) => setEntryData({ ...entryData, requirement: e })}
//               />
//             </Input.Wrapper>
//           )}
//         </div>

//         <Button
//           mt={20}
//           mb={50}
//           size="md"
//           fullWidth
//           loading={sendingAnswer}
//           type="submit"
//           variant="gradient"
//           gradient={{ from: "teal", to: "lime", deg: 105 }}
//         >
//           Finish
//         </Button>
//       </form>
//     </FormStyle>
//   );
// };

// const FormStyle = styled.div`
//   form {
//     display: flex;
//     flex-direction: column;
//     grid-gap: 1rem;
//   }
//   .richtext {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     grid-gap: 1rem;
//   }
//   .groups {
//     display: grid;
//     grid-template-columns: 1fr;
//     grid-gap: 1rem;
//   }
//   .wrapper {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     grid-gap: 1rem;
//   }
//   .titleAndImage {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     grid-gap: 1rem;
//     align-items: center;
//     .inputs {
//       display: flex;
//       flex-direction: column;
//       grid-gap: 0.5rem;
//     }
//   }
//   .cuties {
//     display: flex;
//     grid-gap: 1rem;
//   }
//   .fullWidth {
//     width: 100%;
//   }
// `;

import { DatePicker } from "@mantine/dates";
import {
  Button,
  Divider,
  FileInput,
  Image,
  Input,
  MultiSelect,
  NumberInput,
  Select,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { RichTextEditor } from "@mantine/rte";
import styled from "styled-components";
import { Carousel } from "@mantine/carousel";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

export const EditProgramForm = ({ props }) => {
  const {
    newCreatedInternshipId,
    addDay,
    sendingAnswer,
    type,
    categories,
    specialities,
    schools,
    partnersList,
    countries,
    languages,
    programGroups,
    entryData,
    setEntryData,
    mutateSend,
    uploadedImages,
    setUploadedImages,
  } = props;

  const {
    duration_time,
    partners,
    programGroup,
    speciality,
    start_date,
    requirement,
    school,
    file,
    category,
    course_deadline,
    description,
    country,
    statistics,
    head_image,
    image,
    language,
    price,
    title,
    video,
    duration,
  } = entryData ? entryData : [];

  return (
    <FormStyle>
      {!newCreatedInternshipId && (
        <Text mb={20} size={18} weight={600}>
          Edit program information
        </Text>
      )}

      <form onSubmit={(e) => mutateSend(e)}>
        {type === "university" && (
          <div className="head-image">
            <Image
              withPlaceholder
              radius="sm"
              width={"100%"}
              height={"140px"}
              src={
                head_image && head_image?.name
                  ? URL.createObjectURL(head_image)
                  : head_image
              }
              alt="file"
            />
            <FileInput
              required
              label="Background Image"
              value={null}
              placeholder="Choose Image for background"
              onChange={(e) => {
                setEntryData({ ...entryData, head_image: e });
              }}
            />
          </div>
        )}
        {type === "university" && (
          <div className="uploaded-images">
            {uploadedImages.length > 0 && (
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
                spaceBetween={5}
                slidesPerView={4}
              >
                {uploadedImages?.map((images) => {
                  return images.map((img, index) => (
                    <SwiperSlide>
                      <Image
                        key={index}
                        radius="sm"
                        width={"100%"}
                        height={"140px"}
                        src={URL.createObjectURL(img)}
                        alt="file"
                      />
                    </SwiperSlide>
                  ));
                })}
              </Swiper>
            )}
            <FileInput
              required
              multiple
              label="Uploaded Images"
              value={null}
              placeholder="Choose university images"
              onChange={(e) => {
                setUploadedImages((prev) => [...prev, e]);
              }}
            />
          </div>
        )}
        {type !== "university" && (
          <div className="titleAndImage">
            <div className="inputs">
              <TextInput
                required
                placeholder="Program Title"
                label={`Title (${title?.length}/150)`}
                value={title}
                onChange={(e) =>
                  setEntryData({ ...entryData, title: e.target.value })
                }
              />

              <FileInput
                required
                label={
                  !image?.name
                    ? image?.startsWith("http")
                      ? `Replace image`
                      : "Image"
                    : "Replace image"
                }
                value={null}
                placeholder={
                  image?.name
                    ? image?.name
                    : image?.startsWith("http")
                    ? image
                    : "Choose an image"
                }
                onChange={(e) => {
                  setEntryData({ ...entryData, image: e });
                }}
              />
            </div>

            <Image
              withPlaceholder
              radius="sm"
              width={"100%"}
              height={"300px"}
              src={image && image?.name ? URL.createObjectURL(image) : image}
              alt="file"
            />
          </div>
        )}
        <Divider my={20} color={"gray.3"} />

        <div className="groups">
          {type === "university" && (
            <Textarea
              placeholder="Description"
              label="Description"
              radius="md"
              withAsterisk
              onChange={(e) => setEntryData({ ...entryData, description: e.target.value })}
            />
          )}


          <div className="cuties">
            {type !== "university" && (
              <NumberInput
                className="fullWidth"
                required
                label="Price"
                max={1000}
                min={0}
                value={price ? parseFloat(price) : 0}
                placeholder={"Price"}
                precision={2}
                onChange={(e) => setEntryData({ ...entryData, price: e })}
              />
            )}

            {type === "university" && (
              <Select
                className="fullWidth"
                required
                label="Countries"
                data={
                  countries ? 
                     countries?.map((country) => ({
                        value: country.id,
                        label: country.name,
                      }))
                      : []
                }
                // value={country?.id ? country.id : country}
                placeholder="Choose a country"
                onChange={(e) =>
                  setEntryData({ ...entryData, programGroup: e })
                }
              />
            )}

            {type === "course" && (
              <NumberInput
                className="fullWidth"
                required
                placeholder="Enter the duration"
                label="Duration (weeks)"
                max={1000}
                min={0}
                value={duration && JSON.parse(duration)}
                onChange={(e) => setEntryData({ ...entryData, duration: e })}
              />
            )}

            {type === "steam" && (
              <NumberInput
                className="fullWidth"
                required
                placeholder="Enter the duration"
                label="Duration (weeks)"
                max={1000}
                min={0}
                value={duration_time && JSON.parse(duration_time)}
                onChange={(e) =>
                  setEntryData({ ...entryData, duration_time: e })
                }
              />
            )}

            {type === "internship" && (
              <NumberInput
                className="fullWidth"
                required
                placeholder="Enter the duration"
                label="Duration (weeks)"
                max={1000}
                min={0}
                value={duration_time && JSON.parse(duration_time)}
                onChange={(e) =>
                  setEntryData({ ...entryData, duration_time: e })
                }
              />
            )}

            {type === "internship" && (
              <DatePicker
                className="fullWidth"
                required
                label="Start date"
                value={start_date ? new Date(start_date) : new Date()}
                placeholder={"Start date"}
                onChange={(e) =>
                  setEntryData({
                    ...entryData,
                    start_date: JSON.stringify(addDay(e)).substring(1, 11),
                  })
                }
              />
            )}

            {type === "course" && (
              <DatePicker
                className="fullWidth"
                required
                label="Start date"
                value={course_deadline ? new Date(course_deadline) : new Date()}
                placeholder={"Start date"}
                onChange={(e) =>
                  setEntryData({
                    ...entryData,
                    course_deadline: JSON.stringify(addDay(e)).substring(1, 11),
                  })
                }
              />
            )}

            {type === "steam" && (
              <DatePicker
                className="fullWidth"
                required
                label="Start date"
                value={start_date ? new Date(start_date) : new Date()}
                placeholder={"Start date"}
                onChange={(e) =>
                  setEntryData({
                    ...entryData,
                    start_date: JSON.stringify(addDay(e)).substring(1, 11),
                  })
                }
              />
            )}
          </div>
          {type === "university" && (
              <TextInput
                label="Statistics"
                placeholder="Statistics"
                onChange={(e) =>
                  setEntryData({ ...entryData, statistics: e.target.value })
                }
              />
            )}

          <div className="cuties">
            {type !== "university" && (
              <Select
                className="fullWidth"
                required
                label="Language"
                data={
                  languages
                    ? languages?.map((lang) => ({
                        value: lang.id,
                        label: lang.language,
                      }))
                    : []
                }
                value={language ? language?.id : undefined}
                placeholder={"Select a language"}
                onChange={(e) => setEntryData({ ...entryData, language: e })}
              />
            )}

            {type === "course" && (
              <Select
                className="fullWidth"
                required
                label="Category"
                data={
                  categories
                    ? categories?.map((category) => ({
                        value: category?.id,
                        label: category?.title,
                      }))
                    : []
                }
                value={category?.id}
                placeholder={
                  category?.content ? category?.content : "Select a category"
                }
                onChange={(e) => setEntryData({ ...entryData, category: e })}
              />
            )}

            {type === "steam" && (
              <Select
                className="fullWidth"
                required
                label="Category"
                data={
                  categories
                    ? categories?.map((category) => ({
                        value: category?.id,
                        label: category?.title,
                      }))
                    : []
                }
                value={category?.id}
                placeholder={
                  category?.content ? category?.content : "Select a category"
                }
                onChange={(e) => setEntryData({ ...entryData, category: e })}
              />
            )}

            {type === "internship" && (
              <Select
                className="fullWidth"
                required
                label="Speciality"
                data={
                  specialities
                    ? specialities?.map((speciality) => ({
                        value: speciality.id,
                        label: speciality.content,
                      }))
                    : []
                }
                value={speciality ? speciality[0]?.id : undefined}
                placeholder={
                  speciality?.length > 0
                    ? speciality[0]?.content
                    : "Select a speciality"
                }
                onChange={(e) => setEntryData({ ...entryData, speciality: e })}
              />
            )}
          </div>

          {type !== "university" && (
            <div className="cuties">
              <TextInput
                className="fullWidth"
                placeholder="Enter the link"
                label="YouTube link"
                value={video === "null" ? undefined : video}
                onChange={(e) =>
                  setEntryData({ ...entryData, video: e.target.value })
                }
              />

              <FileInput
                label={`PDF file`}
                className="fullWidth"
                value={null}
                placeholder={file?.name ? file?.name : "Choose a file"}
                onChange={(e) => {
                  setEntryData({ ...entryData, file: e });
                }}
              />

              <Select
                label="School"
                className="fullWidth"
                data={
                  schools
                    ? schools?.map((school) => ({
                        value: school.id,
                        label: school.title,
                      }))
                    : []
                }
                value={school ? school?.id : ""}
                placeholder={school ? school?.title : "Select a school"}
                onChange={(e) => setEntryData({ ...entryData, school: e })}
              />
            </div>
          )}

          <div>
            {type !== "university" && (
              <MultiSelect
                label="Partners"
                data={
                  partnersList
                    ? partnersList?.map((e) => ({ value: e.id, label: e.name }))
                    : []
                }
                value={
                  partners && partners[0]?.id
                    ? partners.map((e) => e.id)
                    : partners
                }
                placeholder="Choose a partner"
                onChange={(e) => {
                  setEntryData({ ...entryData, partners: e });
                }}
              />
            )}
          </div>
        </div>

        <Divider my={20} color={"gray.3"} />

        {type !== "university" && (
          <div className="richtext">
            <Input.Wrapper label="Description">
              <RichTextEditor
                value={description}
                onChange={(e) => setEntryData({ ...entryData, description: e })}
              />
            </Input.Wrapper>

            {type === "internship" && (
              <Input.Wrapper label="Requirement">
                <RichTextEditor
                  value={requirement}
                  onChange={(e) =>
                    setEntryData({ ...entryData, requirement: e })
                  }
                />
              </Input.Wrapper>
            )}
          </div>
        )}

        <Button
          mt={20}
          mb={50}
          size="md"
          fullWidth
          loading={sendingAnswer}
          type="submit"
          variant="gradient"
          gradient={{ from: "teal", to: "lime", deg: 105 }}
        >
          Finish
        </Button>
      </form>
    </FormStyle>
  );
};

const FormStyle = styled.div`
  form {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
  }
  .richtext {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
  .groups {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
  .titleAndImage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    align-items: center;
    .inputs {
      display: flex;
      flex-direction: column;
      grid-gap: 0.5rem;
    }
  }
  .cuties {
    display: flex;
    grid-gap: 1rem;
  }
  .fullWidth {
    width: 100%;
  }
`;
