import axios from "../../Helpers/Axios"
import { useState, useEffect } from "react"
import { DatePicker, DateRangePicker } from "@mantine/dates"
import { useMutation, useQuery } from "@tanstack/react-query"
import { SkeletonLoader } from "../../Components/Loaders/SkeletonLoader"
import { Rotate, Search } from "tabler-icons-react"
import { Button, Divider, Modal, NumberInput, Select, Space, Text, TextInput } from "@mantine/core"

export function TalantFilter({ props }){

  // const { filterOpen, setFilterOpen, reFetch, setReportData, setReportLoading } = props

  // const [ resetForm, setResetForm ] = useState(false)

  const { data:internships } = useQuery([`internships`],
    async () => await axios.get(`/internship/activeinternshiplist/`).then(res => res.data)
  )

  const { data:specialities } = useQuery([`specialities`],
    async () => await axios.get(`/api/v1/speciality/`).then(res => res.data)
  )

  const { data:skillsList } = useQuery(["skillsList"],
    async () => { return await axios.get(`/api/skill_list/`).then(res => res.data) },
  )

  console.log('specialities',specialities);

  // const { mutate:searchData, isLoading } = useMutation([`searchData`],
  //   async (e) => {
  //     e.preventDefault()

  //     setFilterOpen(false)

  //     let { skill, internship, firstName, lastName, grade, dateRange, specificDate, payment, speciality, promocode, ulduzum, taskCompleted  } = e.target

  //     let linksObj = {
  //       internshipLink: internship.value && `intershipinfo=${internship.value}&`,
  //       firstNameLink: firstName.value && `first_name=${firstName.value}&`,
  //       gradeLink: grade.value && `grade=${grade.value}&`,
  //       lastNameLink: lastName.value && `last_name=${lastName.value}&`,
  //       paymentLink: payment.value === "1" ? `successfuly_paid=True&` : payment.value === "2" ? `is_free=True&` : "",
  //       specialityLink: speciality.value && `speciality=${speciality.value}&`,
  //       startDateLink: dateRange.value && `begin_date=${dateRange.value.substring(0, 10)}&`,
  //       endDateLink: dateRange.value && `end_date=${dateRange.value.substring(13, 23)}&`,
  //       specificDateLink: specificDate.value && `date=${specificDate.value}`,
  //       promocodeLink: promocode.value && `promocode=${promocode.value}`,
  //       ulduzumLink: ulduzum.value === "1" ? `is_campaign=True` : ulduzum.value === "2" ? `is_campaign=False` : "",
  //       taskCompletedLink: taskCompleted.value === "1" ? `task_completed=True` : taskCompleted.value === "2" ? `task_completed=False` : "",
  //       skillLink: skill.value && `skill=${skill.value}`,
  //     }

  //     let { skillLink, internshipLink, firstNameLink, gradeLink, lastNameLink, paymentLink, specialityLink, startDateLink, endDateLink, specificDateLink, promocodeLink, ulduzumLink, taskCompletedLink } = linksObj

  //     let response = await axios.get(
  //       `https://backend.taskilled.com/reports/users_order_internship_for_admin/?${skillLink}${paymentLink}${internshipLink}${firstNameLink}${lastNameLink}${specialityLink}${gradeLink}${startDateLink}${endDateLink}${specificDateLink}${promocodeLink}${ulduzumLink}${taskCompletedLink}`
  //     ).then(res => res.data)

  //     setReportData(response)
  //   },
  //   { onSuccess: setReportLoading(false) }
  // )

  // useEffect(() => {
  //   setReportLoading(true)
  // }, [isLoading])


  // function resetSearch(){
  //   setResetForm(true)
  //   setFilterOpen(false)
  //   setTimeout(() => {
  //     setResetForm(false)
  //     reFetch()
  //   }, 250);
  // }

  // if(resetForm){
  //   return(
  //     <SkeletonLoader height={267} my={15} visible={true}/>
  //   )
  // }

  return(
    // <Modal
    //   opened={filterOpen}
    //   onClose={() => setFilterOpen(false)}
    //   withCloseButton={false}
    //   size="1000px"
    //   overlayColor="#aaaaaa99"
    // >

      <div className="filterStyle" style={{ backgroundColor:"#fefefe", border:"1px solid #aaa", borderRadius:"0.25rem", padding:"1rem" }}>
        <form onSubmit={(e) => {}} id="searchForm">

          {/* <Divider size={2} label="FILTER" labelPosition="center" labelProps={{ size:20, weight:600, color:"dimmed" }}/> */}

          <div className="talantWrapper">

            <div className="group">
              <Select
                clearable
                name="program"
                label="Program type"
                placeholder="Pick a type"
                searchable
                data={["Skill building", "Internship" ]}
              />
            </div>

            <Space my={15} />

            <div className="group">

              <Select
                name="education"
                searchable
                clearable
                label="Education"
                placeholder="Search for facilities"
                data={[]}
              />

              {/* <Select
                clearable
                name="industry"
                label="Industry"
                placeholder="Pick an industry"
                searchable
                data={specialities ? specialities?.map(speciality => { return { value: speciality?.id, label: speciality?.content} } ) : []}
              /> */}

              <NumberInput
                min={0}
                clearable
                name="experience"
                label="Experience"
                placeholder="Number of years"
                searchable
                data={[]}
              />

              {/* <Select
                name="certs"
                searchable
                clearable
                label="Certifications"
                placeholder="Search for certifications"
                data={[]}
              /> */}

            </div>

          </div>

          <Divider mt={25} label="Skills" labelProps={{ size:18, weight:600, color:"gray" }}/>
          <div className="group">
            <Select
              name="softSkills"
              searchable
              clearable
              label="Soft skills"
              placeholder="Search for soft skills"
              data={ skillsList ? skillsList?.map(skill => { return { value: skill?.id, label: skill?.skill, group: skill?.header?.header } }) : [] }
            />

            <Select
              name="languages"
              searchable
              clearable
              label="Language"
              placeholder="Search for languages"
              data={[]}
            />

            <Select
              name="technicals"
              searchable
              clearable
              label="Technical skills"
              placeholder="Search for technical skill"
              data={[]}
            />
          </div>

          <Divider mt={25} label="Open to work" labelProps={{ size:18, weight:600, color:"gray" }}/>
          <div className="group">
            <Select
              name="industry"
              searchable
              clearable
              label="Industry"
              placeholder="Search for industries"
              data={ skillsList ? skillsList?.map(skill => { return { value: skill?.id, label: skill?.skill, group: skill?.header?.header } }) : [] }
            />

            <Select
              name="location"
              searchable
              clearable
              label="Locations"
              placeholder="Search for locations"
              data={[]}
            />

            <DatePicker
              name="startDate"
              searchable
              clearable
              label="Date"
              placeholder="Pick the start date"
              data={[]}
            />

            <NumberInput
              min={0}
              name="salary"
              searchable
              clearable
              label="Hourly salary"
              placeholder="Pick hourly salary"
              data={[]}
            />

            <Select
              name="employment"
              searchable
              clearable
              label="Employment"
              placeholder="Pick employment type"
              data={["Full-time", "Part-time", "Internship"]}
            />

            <Select
              name="workPlace"
              searchable
              clearable
              label="Work place"
              placeholder="Pick the work condition"
              data={["On-site", "Remote"]}
            />

          </div>

          <div className="buttons">
            <Button leftIcon={<Rotate size={18}/>} type="button" variant="outline" color="dark" onClick={(e) => {}}>Reset</Button>
            <Button leftIcon={<Search size={18}/>} type="submit" variant="filled" color="dark">Search</Button>
          </div>
        </form>
      </div>
    // </Modal>
  )
}