import React from "react";
import { LoadingOverlay } from "@mantine/core";

const Loader = ({isLoading}) => {
  return (
    <LoadingOverlay
      color="yellow"
      visible={isLoading}
      loaderProps={{ size: "lg", color: "yellow.4", variant: "bars" }}
    />
  );
};

export default Loader;
