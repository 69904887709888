import { useState, useEffect } from "react"
import axios from "../../../Helpers/Axios"
import styled from "styled-components"
import { CreateQuestion } from "./CreateQuestion"
import { QuestionsReadyToPost } from "./QuestionsReadyToPost"

const CreateQuizModal = ({sectionId, props}) => {

  let sectionIdX =
    sectionId.id ? sectionId.id
    : sectionId[0].id ? sectionId[0].section.id
    : sectionId

  const { setShowQuizModal, fetchSection } = props;

  const [ quizName, setQuizName ] = useState("")
  let defaultVariants = ["A", "B", "C", "D", "E"]
  const [ questions, setQuestions ] = useState([])
  const [ currentEditing, setCurrentEditing ] = useState(null)
  const [ variants, setVariants ] = useState(defaultVariants)
  const [ pendingQuestion, setPendingQuestion ] = useState({
    question:"",
    options: { "A": "", "B": "", "C": "", "D": "", "E": "" },
    correctAnswer: ""
  })
  const [ quizId, setQuizId ] = useState(null)
  const [ emptyName, setEmptyName ] = useState(false)

  useEffect(() => {
    if(emptyName === true){
      setEmptyName(false)
    }
    return () => {
      setEmptyName(false)
    }
  }, [quizName])

  async function postQuiz(){
    if(quizName){
      const response = await axios.post("quiz/", {
        name: quizName,
        deadline: new Date(),
        section: sectionIdX
      })

      setQuizId(response.data.data.id)
      // setShowQuizModal(false)
    }
    else{
      setEmptyName(true)
    }
  }

  return (
    <CreateQuizModalStyle>
      <div className="quizHeader">
        <h3>Quiz name: <ins>*</ins></h3>
        <input required type="text" placeholder="Enter the name..."
          style={{border: emptyName ? "1px solid red" : "1px solid #ccc", backgroundColor: emptyName ? "rgba(255, 0, 0, 0.1)" : "white"}}
          value={quizName}
          onChange={(e) => setQuizName(e.target.value)}
        />
        {
          quizName ?
          <button className="finishQuiz" onClick={postQuiz}>Finish</button>
          :
          <button className="finishQuiz" style={{color:"#bfa91b", backgroundColor:"#ffe11bbd", cursor:"default"}} onClick={postQuiz}>Finish</button>
        }
      </div>
      <div className="insideWrapper">
        <div className="left">
          <CreateQuestion props={{ defaultVariants, pendingQuestion, setPendingQuestion, variants, setVariants, questions, setQuestions }}/>
        </div>
        <div className="right">
          <QuestionsReadyToPost props={{ fetchSection, quizId, quizName, sectionIdX, questions, setQuestions, currentEditing, setCurrentEditing, variants }}/>
        </div>
      </div>
      <button className="closeModal" onClick={() => setShowQuizModal(false)}>Exit</button>
    </CreateQuizModalStyle>
  )
}

export default CreateQuizModal

const CreateQuizModalStyle = styled.div`
  width: 95%;
  margin: 2rem auto;
  .closeModal{
    /* position: absolute;
    left: 0;
    right: 0; */
    display: flex;
    background: none;
    max-width: min-content;
    margin: 0 auto -1rem auto;
    padding: 0;
    font-size: 18px;
    border: none;
    color: blue;
    cursor: pointer;
  }
  .finishQuiz{
    display: flex;
    margin-left: auto;
    background-color: var(--yellow);
    border: none;
    font-size: 15px;
    border-radius: 0.25rem;
    padding: 0.5rem 3rem;
    min-width: max-content;
    cursor: pointer;
    transition: all 0.15s;
    &:hover{
      background-color: #ffd600;
    }
  }
  .quizHeader{
    display: flex;
    align-items: center;
    h3{
      min-width: max-content;
      ins{
        color: #e61717;
        text-decoration: none;
      }
    }
    input{
      margin: 0 .75rem;
      /* background: ; */
      border: 1px solid #ccc;
      border-radius: 0.2rem;
      font-size: 14px;
      width: 100%;
      padding: 0.5rem 1rem 0.5rem 0.5rem;
    }
  }
  .insideWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    .left, .right{
      padding: 1rem;
      border: 1px solid #ccc;
      border-radius: .25rem;
      margin: 1rem 0;
    }
    .left{
      margin-right: 0.5rem;
    }
    .right{
      margin-left: 0.5rem;
    }
    h3{
      font-weight: 500;
      margin-bottom: 1rem;
    }
    .existing{
      div{
        div{
          ul{
            /* padding: 0; */
            li{
              list-style: none;
              margin-bottom: .75rem;
            }
          }
        }
      }
    }
    .pending{
      .options{
        display: flex;
        align-items: center;
        p{
          margin-right: 0.5rem;
        }
      }
    }
  }
`