// CurrentRouteContext.js
import { createContext, useState } from 'react';

export const CurrentRouteContext = createContext();

export const CurrentRouteProvider = ({ children }) => {
    const [currentRoute, setCurrentRoute] = useState([]);
    const [isModalOpen ,setIsModalOpen] = useState(false)

    return (
        <CurrentRouteContext.Provider value={{ currentRoute,isModalOpen ,setIsModalOpen, setCurrentRoute }}>
            {children}
        </CurrentRouteContext.Provider>
    );
};
