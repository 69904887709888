import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Grid, Group, Text, TextInput } from "@mantine/core";
import styled from "styled-components";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { BorderRadius, Check, Edit, Plus, Trash, X } from "tabler-icons-react";
import { AppContext } from "../../Helpers/Context";
import PackageCard from "../../Components/Agents/Package/PackageCard";
import { Link } from "react-router-dom";
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";

const Package = () => {
  useSetCurrentRoute([{title: "Packages", to: "/packages"}]);
  const { user } = useContext(AppContext);

  const queryClient = useQueryClient();
  const { data: packages } = useQuery({
    queryKey: ["packages"],
    queryFn: async () =>
      await axios.get(`/agent/package_list/${user.id}`).then((res) => res?.data),
  });

  const { mutate: deletePackage } = useMutation({
    mutationFn: async (id) =>
      await axios.delete(`/agent/packages/${id}/`).then((res) => res?.data),
    onSuccess: () => {
      queryClient.invalidateQueries(["packages"]);
    },
  });

  console.log('packages',packages);

  return (
    <Container>
      <Group style={{ width: "100%" }} position="right" mb={20}>
        <Button
          component={Link}
          to="/packages/create-form"
          leftIcon={<Plus strokeWidth={1.75} />}
          radius={10}
          size="md"
          variant="filled"
          styles={() => ({
            root: {
              backgroundColor: "#FE6027",
              color: "#fff",
              fontWeight: "400",
              "&:hover": {
                backgroundColor: "#FE6027",
              },
            },
          })}
        >
          Create package
        </Button>
      </Group>
      <Grid>
        {packages?.map((pck) => (
          <Grid.Col sm={4}>
            <PackageCard handleDeletePackage={deletePackage} data={pck} />
          </Grid.Col>
        ))}
      </Grid>
    </Container>
  );
};

export default Package;

const Container = styled.div`
  padding: 20px;
`;
