import axios from "../../../Helpers/Axios";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { EditProgramForm } from "./EditProgramForm";
import { LoadingOverlay } from "@mantine/core";

export const EditProgramInfo = ({ props }) => {
  const { type, newCreatedInternshipId } = props;

  const { id } = useParams();

  let idx = newCreatedInternshipId ? newCreatedInternshipId : id;

  const [entryData, setEntryData] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  const { isLoading: loadingEntry } = useQuery(
    [
      `${
        type === "course"
          ? "course-info"
          : type === "internship"
          ? "internship-info"
          : type === "university"
          ? "university-info"
          : "steam-info"
      }`,
      idx,
    ],
    async () =>
      await axios.get(
        type === "course"
          ? `/api/course/${idx}/`
          : type === "internship"
          ? `/internship/${idx}/`
          : type === "university"
          ? `/university/byid/${idx}/`
          : `/steam/${idx}/`
      ),
    {
      onSuccess: (data) => {
        console.log('data',data);
        setEntryData(type === "course" ? data.data[0] : type === "university" ? data.data.University[0] : data.data);
      },
    }
  );

  const {
    duration_time,
    partners,
    programGroup,
    speciality,
    start_date,
    requirement,
    school,
    file,
    category,
    course_deadline,
    description,
    image,
    country,
    statistics,
    head_image,
    language,
    price,
    title,
    video,
    duration,
  } = entryData ? entryData : [];

  function addDay(currentDate) {
    let startDate = new Date(currentDate);
    let endDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24);
    return endDate;
  }

  const { data: schools } = useQuery(
    ["schools"],
    async () => await axios.get("/school/school_list/").then((res) => res?.data)
  );

  const { data: languages } = useQuery(
    ["languages"],
    async () => await axios.get("api/languages/").then((res) => res?.data)
  );

  const { data: categories } = useQuery(
    ["categories"],
    async () => await axios.get("/api/categories/  ").then((res) => res?.data)
  );

  const { data: specialities } = useQuery(
    ["specialities"],
    async () =>
      await axios.get("/api/v1/speciality/  ").then((res) => res?.data)
  );

  const { data: programGroups } = useQuery(
    ["groups"],
    async () =>
      await axios.get("/api/program_group_list/").then((res) => res?.data)
  );

  const { data: partnersList } = useQuery(
    ["partners"],
    async () => await axios.get("/api/partner_list/").then((res) => res?.data)
  );
  const { data: countries } = useQuery(
    ["countries"],
    async () => await axios.get("/api/countries/").then((res) => res?.data)
  );

  const navigate = useNavigate();

  const { mutate: sendFiles } = useMutation(["sendFiles"], async (e) => {
    const formX = new FormData();
    if(type !== "university"){
      file && file?.name && formX.append("file", file, file?.name);
      image && image?.name && formX.append("image", image, image?.name);
    }
    else{
      head_image && head_image?.name && formX.append("head_image", head_image, head_image?.name);
      uploadedImages.forEach((file, index) => {
        console.log('file', file);
        formX.append(`uploaded_images`, file);
    });
      uploadedImages && image?.name && formX.append("image", image, image?.name);
    }


    if (type === "course") {
      const response = await axios.patch(`/api/course/${entryData.id}/`, formX);
      navigate("/course/created");
      return response.data;
    }
    if (type === "university") {
      const response = await axios.patch(`/university/edit/${entryData.id}/`, formX);
      // navigate("/university/created");
      console.log('response', response);
      return response.data;
    }

    if (type === "internship") {
      const response = await axios.patch(`/internship/${entryData.id}/`, formX);
      navigate("/internship/created");
      return response.data;
    }

    if (type === "steam") {
      const response = await axios.patch(`/steam/${entryData.id}/`, formX);
      navigate("/steam/created");
      return response.data;
    }
  });

  const {
    mutate: mutateSend,
    isLoading: sendingAnswer,
    isError: errorSendingAnswer,
    error: errorAnswer,
  } = useMutation(
    ["sendAnswer"],
    async (e) => {
      e.preventDefault();

      const formX = new FormData();
      if (type !== "university") {
        title && formX.append("title", title);
        formX.append("price", price === null ? 0 : price);
        description && formX.append("description", description);
        school && formX.append("school", school?.id ? school?.id : school);
        video && formX.append("video", video);
        programGroup &&
          (programGroup?.id
            ? formX.append("program_group", JSON.parse(programGroup.id))
            : formX.append("program_group", programGroup));
        language &&
          (language?.id
            ? formX.append("language", language?.id)
            : formX.append("language", language));
      }
      if (type === "university") {
        description && formX.append("description", description);
        country && formX.append("country", country)
        statistics && formX.append("statistics", statistics);

        const response = await axios.patch(
          `/university/edit/${entryData.id}/`,
          formX
        );
        return response.data;
      }

      if (type === "course") {
        let objX = {
          category: category && (category?.id ? category?.id : category),
          partners:
            partners &&
            (partners[0]?.name ? partners.map((e) => e.id) : partners),
        };

        duration && formX.append("duration", duration);
        course_deadline && formX.append("course_deadline", course_deadline);

        for (var pair of formX.entries()) {
          objX[pair[0]] = pair[1];
        }

        const response = await axios.patch(`/api/course/${entryData.id}/`, {
          ...objX,
        });
        return response.data;
      }

      if (type === "internship") {
        let objX = {
          speciality:
            speciality?.length > 0
              ? speciality[0]?.id
                ? [speciality[0]?.id]
                : [speciality]
              : [1],
          partners: partners[0]?.name ? partners.map((e) => e.id) : partners,
        };

        duration_time && formX.append("duration_time", duration_time);
        start_date && formX.append("start_date", start_date);
        requirement && formX.append("requirement", requirement);

        for (var pairx of formX.entries()) {
          objX[pairx[0]] = pairx[1];
        }

        const response = await axios.patch(`/internship/${entryData.id}/`, {
          ...objX,
        });
        return response.data;
      }

      if (type == "steam") {
        let objX = {
          category: category && (category?.id ? category?.id : category),
          partners:
            partners &&
            (partners[0]?.name ? partners.map((e) => e.id) : partners),
        };

        duration_time && formX.append("duration_time", duration_time);
        start_date && formX.append("start_date", start_date);

        for (var pair of formX.entries()) {
          objX[pair[0]] = pair[1];
        }

        const response = await axios.patch(`/steam/${entryData.id}/`, {
          ...objX,
        });
        return response.data;
      }

      // if (type === "steam") {
      //   let objX = {
      //     speciality:
      //       speciality?.length > 0
      //         ? speciality[0]?.id
      //           ? [speciality[0]?.id]
      //           : [speciality]
      //         : [1],
      //     partners: partners[0]?.name ? partners.map((e) => e.id) : partners,
      //   };

      //   duration_time && formX.append("duration_time", duration_time);
      //   start_date && formX.append("start_date", start_date);
      //   requirement && formX.append("requirement", requirement);

      //   for (var pairx of formX.entries()) {
      //     objX[pairx[0]] = pairx[1];
      //   }

      //   const response = await axios.patch(`/steam/${entryData.id}/`, {
      //     ...objX,
      //   });
      //   return response.data;
      // }
    },
    {
      onSuccess: (data) => {
        sendFiles();
      },
    }
  );

  if (loadingEntry) {
    return (
      <LoadingOverlay
        color="yellow"
        visible={true}
        loaderProps={{ size: "lg", color: "yellow.4", variant: "bars" }}
      />
    );
  }

  return (
    <EditProgramForm
      props={{
        newCreatedInternshipId,
        addDay,
        sendingAnswer,
        type,
        categories,
        specialities,
        schools,
        partnersList,
        countries,
        languages,
        programGroups,
        entryData,
        setEntryData,
        mutateSend,
        uploadedImages,
        setUploadedImages,
      }}
    />
  );
};

// course ? category : speciality
// course ? duration : duration_time
// course ? course_deadline : start_date
// course ? [x] : requirement
