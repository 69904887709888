import React, { useContext, useState } from "react";
import { Group, Input, Table, Text, Modal } from "@mantine/core";
import styled from "styled-components";
import { Search } from "tabler-icons-react";
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";
import { useQuery } from '@tanstack/react-query';
import axios from '../../Helpers/Axios';
import { useDebouncedState } from '@mantine/hooks';
import { CurrentRouteContext } from "../../Helpers/HeaderContext";

const ContactedStudents = () => {
  useSetCurrentRoute([{ title: "Contacted students", to: "/contacted-students" }]);
  const { setIsModalOpen } = useContext(CurrentRouteContext);

  const [value, setValue] = useDebouncedState('', 200);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState('');

  const fetchStudents = async () => {
    const url = value
      ? `/university/agent/contacted_students/?email=${value}`
      : `/university/agent/contacted_students/`;
    const { data } = await axios.get(url);
    return data;
  };

  const { data: students, isLoading, error } = useQuery(
    ['contacted_students', value],
    fetchStudents
  );

  const handleInfoClick = (info) => {
    setSelectedInfo(info);
    setModalOpened(true);
    setIsModalOpen(true);
  };

  return (
    <ContactedStudentsStyle>
      <Input
        size="md"
        className="search-input"
        radius={24}
        icon={<Search size={20} color="#75767a" strokeWidth={1.75} />}
        placeholder="Search mail"
        onChange={(event) => setValue(event.currentTarget.value)}
      />
      <Table style={{ marginTop: "30px" }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>NAME</th>
            <th>SURNAME</th>
            <th>EMAIL</th>
            <th>PHONE</th>
            <th>USER PROFILE</th>
            <th>ADDITIONAL INFO</th>
          </tr>
        </thead>
        <tbody>
          {students?.map((item) => (
            <tr key={item.id}>
              {console.log('item', item)}
              <td>{item.id}</td>
              <td>{item.student.first_name}</td>
              <td>{item.student.last_name}</td>
              <td>{item.student.email}</td>
              <td>{item.student.number}</td>
              <td>
                <a href="/">See more</a>
              </td>
              <td onClick={() => handleInfoClick(item.additional_info)}>
                {item.additional_info?.length > 20
                  ? `${item.additional_info?.slice(0, 20)}...`
                  : item.additional_info}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        opened={modalOpened}
        onClose={() => {setModalOpened(false) ; setIsModalOpen(false)}}
        title="Additional Information"
      >
        <Text>{selectedInfo}</Text>
      </Modal>
    </ContactedStudentsStyle>
  );
};

export default ContactedStudents;

const ContactedStudentsStyle = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  table {
    thead {
      tr {
        th {
          padding: 11px 10px;
          font-size: 14px;
          font-weight: 700;
          color: #202224;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 20px 10px;
          font-size: 14px;
          font-weight: 400;
          color: #202224;
          cursor: pointer; /* Cursor to indicate clickable text */
        }
      }
    }
  }
  .search-input {
    width: 40%;
    border-radius: 14px;
    input::placeholder {
      color: #75767a;
      font-size: 14px;
      font-weight: 400;
    }
    &:focus {
      outline: none;
    }
    .mantine-1hseney {
      top: 3px;
      left: 3px;
    }
  }
`;
