import React from "react";
import styled from "styled-components";
import { Button, Grid, Text } from "@mantine/core";
import task from "../../../Assets/Svgs/program/Task.svg";
import link from "../../../Assets/Svgs/program/Link-left.svg";
import file from "../../../Assets/Svgs/program/File.svg";
import plus from "../../../Assets/Svgs/program/plus.svg";
import video from "../../../Assets/Svgs/program/video.svg";
import photo from "../../../Assets/Svgs/program/photo.svg";
import CustomButton from "../../Common/CustomButton";

const Tools = ({
  currentSubUnit,
  handleSubUnitContent,
  activeSubUnitTab,
  handleCreateContent,
  isUpdate,
  createLoading,
  updateLoading,
  handleAddSubUnit,
  pSubUnit,
  isContentSave,
  setIsContentSave
}) => {
  const handleToolButton = (key, value) => {
    if (currentSubUnit) {
      // Açarın mövcudluğunu və dəyərinin boş olub-olmadığını yoxlayırıq
      const keyExists = currentSubUnit.hasOwnProperty(key);
      const isEmpty =
        currentSubUnit[key] === "" ||
        currentSubUnit[key] === null ||
        (Array.isArray(currentSubUnit[key]) &&
          currentSubUnit[key].length === 0);

      if (keyExists && !isEmpty) {
        console.log(
          `${key} already exists in currentDay and is not empty. No action taken.`
        );
        return;
      }
    }
    handleSubUnitContent(key, value, activeSubUnitTab);
  };

  return (
    <div style={{ position: "sticky", top: "100px" }}>
      <ToolsStyle>
        <div className="container">
          <Text mb={15} size={16} weight={500} color="#121926">
            Tools
          </Text>
          <Grid>
            <Grid.Col span={6}>
              <Button
                leftIcon={<img src={plus} alt="plus-icon" />}
                className="tool-btn"
                onClick={handleAddSubUnit}
              >
                Add {pSubUnit}
              </Button>
            </Grid.Col>
            <Grid.Col span={6}>
              <Button
                onClick={() => handleToolButton(`${pSubUnit}_photo`, null)}
                leftIcon={<img src={photo} alt="photo_icon" />}
                className="tool-btn"
              >
                Photo
              </Button>
            </Grid.Col>
            <Grid.Col span={6}>
              <Button
                onClick={() => handleToolButton(`${pSubUnit}_video_link`, null)}
                leftIcon={<img src={video} alt="video-icon" />}
                className="tool-btn"
              >
                Video
              </Button>
            </Grid.Col>
            <Grid.Col span={6}>
              <Button
                onClick={() => handleToolButton("files", ["empty"])}
                leftIcon={<img src={file} alt="file-icon" />}
                className="tool-btn"
              >
                File
              </Button>
            </Grid.Col>
            <Grid.Col span={6}>
              <Button
                onClick={() => handleToolButton(`${pSubUnit}_link`, null)}
                leftIcon={<img src={link} alt="link-icon" />}
                className="tool-btn"
              >
                Link
              </Button>
            </Grid.Col>
            <Grid.Col span={6}>
              <Button
                onClick={() => handleToolButton("tasks", null)}
                leftIcon={<img src={task} alt="task-icon" />}
                className="tool-btn"
              >
                Task
              </Button>
            </Grid.Col>
          </Grid>
        </div>
      </ToolsStyle>
      <CustomButton
        bgColor="#FE6027"
        color="#fff"
        text={`${isUpdate ? "Update" : "Create"} Contents`}
        isLink={false}
        disabled={createLoading || updateLoading || !isContentSave}
        width="100%"
        height="50px"
        fs="14px"
        onClick={handleCreateContent}
      />
    </div>
  );
};

export default Tools;

const ToolsStyle = styled.div`
  border: 1px solid #ecedf0;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  .container {
    padding: 15px;
  }
  .tool-btn {
    height: 50px;
    width: 100%;
    background-color: #eff3f4;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #121926;
    .mantine-Button-inner {
      display: flex;
      justify-content: left;
      align-items: center;
    }
  }
`;
