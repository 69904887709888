import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Text } from "@mantine/core";
import SaveButton from "../../Common/SaveButton";

const DayContent = ({ 
  activeSubUnitTab, 
  handleSubUnitContent, 
  contentValue,
  pSubUnit,
  setIsContentSave
}) => {
  const [value, setValue] = useState(contentValue || "");
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setValue(contentValue);
    setIsChanged(false);
  }, [activeSubUnitTab, contentValue]);

  const handleChange = (event, editor) => {
    const data = editor.getData();
    const cleanContentValue = contentValue.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ').trim();
    const cleanData = data.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ').trim();
    
    
    setValue(data);
    setIsChanged(cleanContentValue !== cleanData);
  };

  const handleSave = () => {
    handleSubUnitContent(`${pSubUnit}_content`, value, activeSubUnitTab);
    setIsChanged(false);
    setIsContentSave(true)
  };

  return (
    <ContentStyle>
      <Text mb={10} size={16} weight={500} color="#121926">
        {pSubUnit.charAt(0).toUpperCase() + pSubUnit.slice(1)} Content
      </Text>
      <CKEditor editor={ClassicEditor} data={value} onChange={handleChange} />
      {isChanged && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        >
          <SaveButton onClick={handleSave} />
        </div>
      )}
    </ContentStyle>
  );
};

export default DayContent;

const ContentStyle = styled.div`
  .ck-editor__editable {
    min-height: 200px;
  }
  .ck-editor__editable_inline {
    padding: 0 10px;
  }
  .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border-radius: 10px;
  }
  .ck.ck-editor__main > .ck-editor__editable {
    border-radius: 0 0 10px 10px;
  }
  .ck.ck-toolbar {
    border-radius: 10px 10px 0 0;
  }
`;
