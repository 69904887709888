import axios from "../../Helpers/Axios";
import * as tus from "tus-js-client";
import { LoadingOverlay } from "@mantine/core";
import { Link, useParams } from "react-router-dom";
import { Logout, BoxPadding } from "tabler-icons-react";
import { useEffect, useState } from "react";
import { PendingContent } from "./PendingContent";
import { FetchedContent } from "./FetchedContent";
import { RightSide } from "./RightSide";
import { SectionList } from "./SectionList";
import { CourseContentStyle } from "./Style";
import CreateQuizModal from "./Quiz/CreateQuizModal";
import { RingProgress, Text, Modal } from "@mantine/core";
// Modals
import { AddSectionModal } from "./Modals/AddSectionModal";
import { ZeroSectionModal } from "./Modals/ZeroSectionModal";
import { EditSectionModal } from "./Modals/EditSectionModal";
import { RemoveSectionModal } from "./Modals/RemoveSectionModal";
import { FinishBeforeChaningSectionModal } from "./Modals/ChangeSectionModal";

export const ContentEditor = ({ props }) => {
  const { entryType } = props;

  const links =
    entryType === "internship"
      ? {
          entry: "internship/",
          sectionDetail: "internshipsection/section-detail/",
          sectionList: "internshipsection/section-list/",
          section: "internshipsection/",
          sectionDashed: "internshipsection/",
        }
      : entryType === "course"
      ? {
          entry: "api/course-section/",
          sectionDetail: "api/section/",
          sectionList: "api/section-detail/",
          section: "api/section/",
          sectionDashed: "api/section-",
        }
        : entryType === "steam" ?
        {
          entry: "steam/section/",
          sectionDetail: "steam/section_parts_list/",
          sectionList: "steam/section/list/",
          section: "steam/section/create/",
          sectionDashed: "steam/section_part_create",
          sectionEdit:"steam/section_part_edit"
        }
      : {};

  const { internshipId, courseId, id } = useParams();

  // const entryId = entryType === "internship" ? internshipId : courseId;
  const entryId = id;

  const [loaderVisible, setLoaderVisible] = useState(false);

  const [createfirstSection, setCreateFirstSection] = useState(false);

  const [courseData, setCourseData] = useState([]);

  const [allSections, setAllSections] = useState([]);
  const [otherSections, setOtherSections] = useState([]);

  const [sectionContent, setSectionContent] = useState([]);
  const [sectionContentFiltered, setSectionContentFiltered] = useState([]);

  const [pendingContent, setPendingContent] = useState([]);

  const [editSectionModalOpen, setEditSectionModalOpen] = useState(false);
  const [removeSectionModalOpen, setRemoveSectionModalOpen] = useState(false);
  const [addSectionModalOpen, setAddSectionModalOpen] = useState(false);
  const [changeSectionModal, setChangeSectionModal] = useState(false);
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [editOrUpdateSignal, setEditOrUpdateSignal] = useState(false);

  const [showVideoProgress, setShowVideoProgress] = useState(false);

  // Get all sections
  useEffect(() => {
    fetchCourseContent(entryId);
    return () => {
      setAllSections([]);
      setOtherSections([]);
      setSectionContent([]);
      setSectionContentFiltered([]);
      setPendingContent([]);
    };
  }, [
    editOrUpdateSignal,
    // removeSectionModalOpen,
    // editSectionModalOpen,
    addSectionModalOpen,
    createfirstSection,
  ]);

  // fetch section function
  async function fetchSection(id) {
    setLoaderVisible(true);
    setSectionContentFiltered([]);

    let idX = id?.id ? id?.id : id;

    try {
      const response = await axios.get(`${links.sectionDetail}${idX}/`);

      // check if section is empty
      if (response.data.length > 0) {
        setSectionContent(response.data);
        setLoaderVisible(false);
      } else {
        // setSectionContentFiltered([])
        otherSections.map((section) => {
          if (section.id === idX) {
            setSectionContent(section);
          }
        });
        setLoaderVisible(false);
      }

      return response;
    } catch (error) {
      setLoaderVisible(false);
    }
  }

  // useEffect(() => {
  //   fetchSection(id);
  // }, [removeSectionModalOpen]);

  // fetch course content
  async function fetchCourseContent(id) {
    setLoaderVisible(true);
    try {
      const response = await axios.get(`${links.entry}${id}/`);
      setAllSections(response?.data);
      return response;
    } catch (error) {}
  }

  // filter content based on their type
  async function filterContent() {
    setSectionContentFiltered([]);

    function filterX(section, type) {
      console.log("section->",section)
      if(typeof section.section=="object")
      {
        setSectionContentFiltered((sectionContentFiltered) => [
          ...sectionContentFiltered,
          {
            ...section[type],
            type: type,
            section: section.section,
          },
        ]);
      }

      else
      {
        setSectionContentFiltered((sectionContentFiltered) => [
          ...sectionContentFiltered,
          {
            ...section,
            type: type,
            section: section.section,
          },
        ]);
      }

    }

    // filter based on type
    if (sectionContent.length > 0) {
      sectionContent.map((section) => {
        section.video || section.is_video_lesson
          ? filterX(section, "video")
          : section.chart || section.is_chart
          ? filterX(section, "chart")
          : section.text || section.is_text
          ? filterX(section, "text")
          : filterX(section, "quiz");
      });
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${links.entry}${entryId}/`);
        setCourseData(response.data.title);
        setLoaderVisible(false);
      } catch (error) {}
    })();
    return () => {
      setCourseData([]);
    };
  }, []);

  // Get all the existing section ids and titles
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${links.sectionList}${entryId}/`);
        if (response?.data.length < 1) {
          setCreateFirstSection(true);
        }
        const sortedSections = response?.data?.sort((a, b) => a.id - b.id);
        setOtherSections(sortedSections);
      } catch (error) {}
    })();
    return () => {
      setOtherSections([]);
    };
  }, [allSections]);

  // Get section data
  useEffect(() => {
    (async () => {
      // check if any sections exist
      if (otherSections[0]?.id !== undefined) {
        try {
          const response = await fetchSection(
            otherSections[otherSections?.length - 1]?.id
          );
          response.data.length > 0
            ? setSectionContent(response?.data?.reverse())
            : setSectionContent(otherSections[otherSections?.length - 1]);
        } catch (error) {}
      }
    })();
    return () => {
      setSectionContent([]);
    };
  }, [otherSections]);

  // Filtering raw data to remove null entries
  useEffect(() => {
    filterContent();
    return () => {
      setSectionContentFiltered([]);
    };
  }, [sectionContent]);

  const [sentUrls, setSentUrls] = useState([]);

  const [uploadPercentage, setUploadPercentage] = useState(0);

  // Vimeo Upload
  const handleVideo = async (e) => {
    if (e !== undefined) {
      setShowVideoProgress(true);
      const accessTokenX = "38c6f5ca7dab2349d12ad6d5e716c759";

      const headerPost = {
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer ${accessTokenX}`,
        "Content-Type": "application/json",
      };

      // Get the selected file from the Input element
      const file = e;
      const fileSize = file.size.toString();

      try {
        const response = await axios({
          method: "post",
          url: `https://api.vimeo.com/me/videos`,
          headers: headerPost,
          data: {
            upload: {
              approach: "tus",
              size: fileSize,
            },
          },
        });

        // Create a new tus upload
        const upload = new tus.Upload(file, {
          endPoint: "https://api.vimeo.com/me/videos",
          uploadUrl: response.data.upload.upload_link,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          headers: {},
          onError: function (error) {
            console.error("Upload failed", error);
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);

            // Update your UI with the current upload percentage
            setUploadPercentage(percentage);

            console.log(`Upload Progress: ${percentage}%`);
          },
          onSuccess: function () {
            setShowVideoProgress(false);
            console.log("Upload successful");
            setUploadPercentage(0);
          },
          onAfterResponse: function (response) {},
        });

        // Start the upload
        upload.start();
        console.log('response',response);
        return response;
      } catch (error) {
        console.error("Error initiating upload", error);
      }
    }
  };

  return (
    <CourseContentStyle>
      <RemoveSectionModal
        props={{
          entryType,
          setEditOrUpdateSignal,
          links,
          setLoaderVisible,
          sectionContent,
          fetchCourseContent,
          entryId,
          setRemoveSectionModalOpen,
          removeSectionModalOpen,
        }}
      />
      <EditSectionModal
        props={{
          entryType,
          setEditOrUpdateSignal,
          links,
          setLoaderVisible,
          sectionContent,
          fetchCourseContent,
          entryId,
          setEditSectionModalOpen,
          editSectionModalOpen,
        }}
      />
      <AddSectionModal
        props={{
          entryType,
          links,
          setLoaderVisible,
          sectionContent,
          fetchCourseContent,
          entryId,
          setAddSectionModalOpen,
          addSectionModalOpen,
          setSectionContentFiltered,
        }}
      />
      <FinishBeforeChaningSectionModal
        props={{
          links,
          setSectionContentFiltered,
          setPendingContent,
          setChangeSectionModal,
          fetchSection,
          changeSectionModal,
        }}
      />
      {createfirstSection && (
        <ZeroSectionModal
          props={{
            entryType,
            links,
            entryId,
            setCreateFirstSection,
            setSectionContentFiltered,
            createfirstSection,
            fetchCourseContent,
          }}
        />
      )}
      <LoadingOverlay
        visible={loaderVisible}
        loaderProps={{ color: "var(--yellow)" }}
      />
      {/* <LoadingOverlay
        visible={showVideoProgress}
        loaderProps={{ color: "var(--yellow)" }}
      /> */}
      {/* Use Modal to show the progress ring */}
      {showVideoProgress != false && (
        <Modal
          fullScreen
          padding="lg"
          withCloseButton={false}
          opened={showVideoProgress}
        >
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              height: "90vh",
              alignItems: "center",
            }}
          >
            <RingProgress
              size={300}
              sections={[
                {
                  value: uploadPercentage,
                  color: uploadPercentage !== 100.0 ? "yellow" : "teal",
                },
              ]}
              label={
                <Text
                  c={uploadPercentage !== 100.0 ? "yellow" : "teal"}
                  fw={700}
                  ta="center"
                  // size="xl"
                  style={{ fontSize: "40px" }}
                >
                  {`${uploadPercentage}%`}
                </Text>
              }
            />
            {uploadPercentage != 100.0 ? (
              <Text>
                Please do not refresh page until uploading the video finished.
              </Text>
            ) : (
              <Text>Video was uploaded successfully!</Text>
            )}
          </div>
        </Modal>
      )}

      <div className="header">
        <h3>
          <ins>Add/Edit contents of:</ins>
          {courseData}
        </h3>
        <div className="exit">
          <Link
            to={
              entryType === "course"
                ? `/course/${entryId}`
                : `/internship/${entryId}`
            }
          >
            <Logout color="black" /> Exit
          </Link>
        </div>
      </div>
      {showQuizModal && (
        <div className="quizModal">
          <div className="quizContainer">
            <CreateQuizModal
              sectionId={sectionContent}
              props={{ links, showQuizModal, setShowQuizModal, fetchSection }}
            />
          </div>
        </div>
      )}
      {createfirstSection === false && (
        <div>
          <SectionList
            props={{
              links,
              pendingContent,
              setChangeSectionModal,
              setSectionContentFiltered,
              fetchSection,
              otherSections,
              sectionContent,
              setAddSectionModalOpen,
            }}
          />
          <div className="container">
            <div className="leftSide">
              <h3>Current section content</h3>
              <div className="section">
                {!sectionContentFiltered.length > 0 &&
                  !pendingContent.length > 0 && (
                    <div className="noContent">
                      <h3>
                        <BoxPadding /> You haven't added any content yet
                      </h3>
                    </div>
                  )}
                <FetchedContent
                  props={{
                    entryType,
                    links,
                    setLoaderVisible,
                    fetchSection,
                    sectionContentFiltered,
                    setSectionContentFiltered,
                    handleVideo,
                  }}
                />
                <PendingContent
                  props={{ links, pendingContent, setPendingContent }}
                />
              </div>
            </div>
            <RightSide
              props={{
                entryType,
                links,
                setShowQuizModal,
                setPendingContent,
                sectionContent,
                setEditSectionModalOpen,
                setRemoveSectionModalOpen,
                pendingContent,
                setSentUrls,
                handleVideo,
                setSectionContent,
                sentUrls,
                fetchSection,
                setLoaderVisible,
              }}
            />
          </div>
        </div>
      )}
      <br />
    </CourseContentStyle>
  );
};
