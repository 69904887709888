import styled from "styled-components"
import { Link } from "react-router-dom"
import { Button, Divider } from "@mantine/core"
import { Edit, Tools } from "tabler-icons-react"
import { ProgramPrivacy } from "./ProgramPrivacy"
import { ManagaMentors } from "./ManagaMentors"
import { DeleteProgramModal } from "./DeleteProgramModal"

export function ManageProgram({ props }) {

  const { user, type, entryData, setEntryData } = props

  return (
    ( (user?.id === entryData?.company?.id || user?.id === entryData?.teacher?.id || user?.id === entryData?.user?.id) || (user?.id === 56 || user?.user_type.type === "Company") ) &&
    <ManageStyle>
      <div className="manageWrapper">
        
        <ProgramPrivacy props={{ user, type, entryData, setEntryData }} />
        <Divider mt={15}/>
        <div className="editProgram">
          <Button variant="default" component={Link} to={type === "bootcamp" ? `/bootcamp/edit/${entryData.id}` :  type === "steam" ?  `/steam/edit/${entryData.id}` : type === "university" ? `/university/edit/${entryData.id}`:`/internship/edit/${entryData.id}/`} leftIcon={<Edit/>}>{type === "university" ? "Edit University" : "Edit program info"}</Button>
          {type !== "university" && <Button variant="default" component={Link} to={type === "bootcamp" ? `/bootcamp/${entryData.id}/content-update` : type === "steam" ? `/steam/${entryData.id}/content-update` :`/internship/add/${entryData.id}/`} leftIcon={<Tools/>}>Add/Edit program content</Button>}
          <DeleteProgramModal props={{ type, entryData }}/>
        </div>
       {type !== "university" && type !== "bootcamp" && type !== "steam" && <ManagaMentors props={{ type, entryData }}/>}
      </div>
    </ManageStyle>
  )
}

const ManageStyle = styled.div`
  padding: 1.5rem 1.5rem;
  button,a{
    font-weight: 500;
  }
  .manageWrapper{
    .editProgram{
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 1rem;
    }
  }
`