import React from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { RichTextEditor } from "@mantine/rte";
import { TextInput, Textarea, Button, Group, Grid } from "@mantine/core";
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";

const Profile = () => {
  useSetCurrentRoute([{title: "My Profile", to: "/profile"}]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Col span={6}>
            <FormGroup>
              <FormLabel>Name</FormLabel>
              <TextInput
                radius={10}
                {...register("name", { required: "Name is required" })}
                placeholder="Name"
              />
              {errors.name && <span>{errors.name.message}</span>}
            </FormGroup>
          </Grid.Col>
          <Grid.Col span={6}>
            <FormGroup>
              <FormLabel>Email</FormLabel>
              <TextInput
                radius={10}
                {...register("email", { required: "Email is required" })}
                placeholder="Email"
              />
              {errors.email && <span>{errors.email.message}</span>}
            </FormGroup>
          </Grid.Col>
        </Grid>
        <FormGroup>
          <FormLabel>Phone number</FormLabel>
          <TextInput
            radius={10}
            {...register("phoneNumber", {
              required: "Phone number is required",
            })}
            placeholder="+994"
          />
          {errors.phoneNumber && <span>{errors.phoneNumber.message}</span>}
        </FormGroup>
        <FormGroup>
          <FormLabel>About</FormLabel>
          <RichTextEditor
            onChange={(value) => setValue("about", value)}
            value={""}
            placeholder="About"
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Students</FormLabel>
          <RichTextEditor
            onChange={(value) => setValue("students", value)}
            value={""}
            placeholder="Students"
          />
        </FormGroup>
        <Button
          styles={() => ({
            root: {
              background: "#FE6027",
            },
          })}
          size="md"
          fullWidth
          type="submit"
          color="orange"
        >
          Save
        </Button>
      </form>
    </FormWrapper>
  );
};

export default Profile;

const FormWrapper = styled.div`
  padding: 20px;
  border-radius: 10px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const FormLabel = styled.label`
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #364152;
`;
