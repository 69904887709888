import axios from "../Helpers/Axios";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Plus } from "tabler-icons-react";
import { AppContext } from "../Helpers/Context";
import {
  Button,
  Center,
  Container,
  LoadingOverlay,
  Text,
  Tabs,
  Badge,
  Image,
  Card,
  Grid,
  TextInput,
  ActionIcon,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import moment from "moment";
import { Search as IconSearch } from "tabler-icons-react";

export const CreatedPrograms = ({ props }) => {
  const { type } = props;

  const { user } = useContext(AppContext);
  console.log("type---",type)
  const endpoint =
    type === "course"
      ? `api/usercourselist/${user.id}/`
      : type === "hackathon"
      ? `hackathon/hackathonbyuserid/${user.id}`
      : type === "internship" 
      ? `internship/userinternshiplist/${user.id}/`
      : type === "university"
      ? `university/universities/${user.id}/`
      : `/steam/usersteamlist/${user.id}/`;

  const [createdContentAmount, setCreatedContentAmount] = useState(7);

  const { data: createdContent, isLoading } = useQuery(
    [
      type === "course"
        ? "created courses"
        : type === "hackathon"
        ? "created hackathons"
        : type === "internship"
        ? "created internships"
        : type === "university"
        ? "created university"
        :"created steam",
    ],
    async () => await axios.get(endpoint).then((res) => type === "university" ? res.data.University : res.data)
  );
  console.log('created content',createdContent);

  const sharedContents =  createdContent?.filter(
    (content) => content?.is_shared === true
  )
  console.log('shared content',sharedContents);

  const unSharedContents = createdContent?.filter(
    (content) => content?.is_shared !== true
  )

  return (
    <Container p={0} fluid>
      <LoadingOverlay
        color="yellow"
        visible={isLoading}
        loaderProps={{ size: "lg", color: "yellow.4", variant: "bars" }}
      />

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          color={"dark"}
          rightIcon={<Plus />}
          component={Link}
          radius={"md"}
          mb={20}
          to={
            type === "course"
              ? "/course/create"
              : type === "hackathon"
              ? "/hackathon/create"
              : type === "university"
              ? "/university/create"
              : type==="steam"
              ? "/steam/create"
              :  "/internship/create"
          }
        >
          Create {type}
        </Button>
      </div>

      <Tabs variant="pills" color="yellow" defaultValue="published">
        <Tabs.List>
          <Tabs.Tab value="published">
            Published{" "}
            <Badge color="yellow" size="md" circle>
              {sharedContents?.length}
            </Badge>
          </Tabs.Tab>
          <Tabs.Tab value="unpublished">
            Unpublished{" "}
            <Badge color="yellow" size="md" circle>
              {unSharedContents?.length}
            </Badge>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="published">
          <Container p={0} my={30} fluid>
            <Text size={24} weight={600}>
              Published {type}s
            </Text>
            {sharedContents?.length > 0 && (
              <div style={{ width: "456px" }}>
                <TextInput
                  radius="md"
                  size="lg"
                  mt={20}
                  placeholder={`Search ${type}`}
                  rightSectionWidth={42}
                  rightSection={
                    <ActionIcon
                      size={32}
                      radius="xl"
                      color="blue"
                      variant="filled"
                    >
                      <IconSearch
                        style={{ width: "1.1rem", height: "1.1rem" }}
                      />
                    </ActionIcon>
                  }
                />
              </div>
            )}

            {sharedContents?.length == 0 && (
              <Text>There is no published {type}s.</Text>
            )}

            <Grid mt={20}>
              {createdContent
                ?.slice(0, createdContentAmount)
                ?.map((content) => {
                  return (
                    content?.is_shared === true && (
                      <Grid.Col span={3}>
                        <CardStyle>
                          <Card
                            key={content?.id}
                            p="md"
                            to={
                              type === "course"
                                ? `/course/${content.id}`
                                : type==="internship" ?
                                `/internship/${content.id}`
                                : type==="university"
                                ? `/university/${content.id}`
                                :`/steam/${content.id}`
                            }
                            component={Link}
                            className="card"
                          >
                            <Card.Section>
                              <Image
                                fullwidth
                                className="image"
                                src={type !== "university" ? content?.image : content?.logo}
                                fit={`${type === "university" ? 'contain' : ''}`}
                                height={200}
                                alt="Card"
                                withPlaceholder
                              />
                            </Card.Section>
                            <Text
                              c="dimmed"
                              size="xs"
                              tt="uppercase"
                              fw={700}
                              mt="md"
                            >
                              {moment(content?.created_at)?.format(
                                "DD.MM.YYYY"
                              )}
                            </Text>
                            <Text className="title" mt={5}>
                              {content?.title?.length > 48
                                ? content?.title.substring(0, 48) + "..."
                                : content?.title}
                            </Text>
                          </Card>
                        </CardStyle>
                      </Grid.Col>
                    )
                  );
                })}
            </Grid>
            <Center>
              {createdContent && createdContent[createdContentAmount] && (
                <Button
                  color={"dark"}
                  onClick={() =>
                    setCreatedContentAmount(createdContentAmount + 8)
                  }
                >
                  See more
                </Button>
              )}
            </Center>
          </Container>
        </Tabs.Panel>

        <Tabs.Panel value="unpublished">
          <Container p={0} my={30} fluid>
            <Text size={24} weight={600}>
              Unpublished {type}s
            </Text>
            {unSharedContents?.length > 0 && (
              <div style={{ width: "456px" }}>
                <TextInput
                  radius="md"
                  size="lg"
                  mt={20}
                  placeholder={`Search ${type}`}
                  rightSectionWidth={42}
                  rightSection={
                    <ActionIcon
                      size={32}
                      radius="xl"
                      color="blue"
                      variant="filled"
                    >
                      <IconSearch
                        style={{ width: "1.1rem", height: "1.1rem" }}
                      />
                    </ActionIcon>
                  }
                />
              </div>
            )}
            {unSharedContents?.length == 0 && (
              <Text>There is no unpublished {type}s.</Text>
            )}
            <Grid mt={20}>
              {createdContent
                ?.slice(0, createdContentAmount)
                ?.map((content) => {
                  return (
                    content?.is_shared === false && (
                      <Grid.Col span={3}>
                        <CardStyle>
                          <Card
                            key={content?.id}
                            p="md"
                            to={
                              type === "course"
                              ? `/course/${content.id}`
                              : type==="internship" ?
                              `/internship/${content.id}`
                              : type==="university"
                              ? `/university/${content.id}`
                              :`/steam/${content.id}`
                            }
                            component={Link}
                            className="card"
                          >
                            <Card.Section>
                              <Image
                                fullwidth
                                className="image"
                                src={type !== "university" ? content?.image : content?.logo}
                                height={200}
                                fit={`${type === "university" ? 'contain' : ''}`}
                                alt="Card"
                                withPlaceholder
                              />
                            </Card.Section>
                            <Text
                              c="dimmed"
                              size="xs"
                              tt="uppercase"
                              fw={700}
                              mt="md"
                            >
                              {moment(content?.created_at)?.format(
                                "DD.MM.YYYY"
                              )}
                            </Text>
                            <Text className="title" mt={5}>
                              {content?.title?.length > 48
                                ? content?.title.substring(0, 48) + "..."
                                : content?.title}
                            </Text>
                          </Card>
                        </CardStyle>
                      </Grid.Col>
                    )
                  );
                })}
            </Grid>
            <Center>
              {createdContent && createdContent[createdContentAmount] && (
                <Button
                  color={"dark"}
                  onClick={() =>
                    setCreatedContentAmount(createdContentAmount + 8)
                  }
                >
                  See more
                </Button>
              )}
            </Center>
          </Container>
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};

const CardStyle = styled.div`
  .card {
    transition: transform 150ms ease, box-shadow 150ms ease;
    border-radius: 10px;
    padding: 50px;

    .image {
      border-radius: 10px;
    }

    &:hover {
      transform: scale(1.01);
      box-shadow: 20px;
    }
  }

  .title {
    font-weight: bold;
  }
`;
