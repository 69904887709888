import React from "react";
import styled from "styled-components";
import { CheckIcon, Group, Stack, Text, Title } from "@mantine/core";
import check from "../../../Assets/Svgs/agents/check.svg";
import { Edit, Trash } from "tabler-icons-react";
import { Link } from "react-router-dom";

const PackageCard = ({ data,handleDeletePackage }) => {
  return (
    <CardContainer>
      <Group position="right" align="center" spacing={8}>
        <Link to={`/packages/edit-form/${data?.id}`}>
          <Circle color="#D6F4F6">
            <Edit size={15} color="#029199" strokeWidth={1.5} />
          </Circle>
        </Link>
        <Circle onClick={() => handleDeletePackage(data?.id)} color="#FFE0E0">
          <Trash  size={15} color="#E41D1D" strokeWidth={1.5} />
        </Circle>
      </Group>
      <Stack spacing={10}>
        <Text size={20} weight={700} color="#1A1A1A">
          {data?.name}
        </Text>
        <Group spacing={4} noWrap>
          <Text size={20} weight={700} color="#1A1A1A">
            ${data?.price}
          </Text>
          <Text span size={12} weight={400} color="#1a1a1a">
            /
          </Text>
          <Text span size={12} weight={400} color="#1a1a1a">
            price
          </Text>
        </Group>
        <Line />
        <Stack>
          {data?.features?.map((item, index) => (
            <Group key={index} noWrap>
              <Circle color="#D3F4F6">
                <img src={check} alt="check" />
              </Circle>
              <Text>{item.name}</Text>
            </Group>
          ))}
        </Stack>
      </Stack>
    </CardContainer>
  );
};

export default PackageCard;

const CardContainer = styled.div`
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 1.52px 3.05px -1.52px #1018280f;
  padding: 20px;
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  margin: 10px 0;
`;
const Circle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.color};
  cursor: pointer;
`;
