import React from "react";
import { Button } from "@mantine/core";
import { Link } from "react-router-dom";

const NavLink = ({ props }) => {
  const {isDropdown, title, to, icon, iconChevron , setCurrentDropdown,pathname,tabOne,tabTwo ,pathOne} = props;
  return (
    <>
      {(title === "Students" || title === "Study Abroad") && isDropdown ? (
        <Button
          size="lg"
          radius={10}
          leftIcon={icon}
          rightIcon={iconChevron && iconChevron}
          component={Link}
          to={tabOne}
          onClick={() => setCurrentDropdown(title) }
          style={(pathname === tabOne || pathname === tabTwo || pathname === pathOne ) ? {backgroundColor: '#CCF075'} : {backgroundColor: "transparent"}}
          styles={(theme) => ({
            root: {
              color: "#364152",
              display: "flex",
              justifyContent: "flex-start",
              fontSize: "16px",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#CCF075",
              },
            },
          })}
        >
          {title}
        </Button>
      ) : (
        <Button
          size="lg"
          radius={10}
          component={Link}
          to={to}
          leftIcon={icon}
          onClick={() => setCurrentDropdown(null)}
          style={pathname === to ? {backgroundColor: '#CCF075'} : {backgroundColor: "transparent"}}
          styles={(theme) => ({
            root: {
              color: "#364152",
              display: "flex",
              justifyContent: "flex-start",
              fontSize: "16px",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#CCF075"
              },
            },
          })}
        >
          {title}
        </Button>
      )}
    </>
  );
};

export default NavLink;
