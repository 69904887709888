import axios from "../../Helpers/Axios";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { X } from "tabler-icons-react";
import { Button, Text, TextInput } from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const ManagaMentors = ({ props }) => {
  const { entryData, type } = props;
  const [pendingUser, setPendingUser] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [noUserWithThisEmail, setNoUserWithThisEmail] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setNoUserWithThisEmail(false);
    }, 5000);
  }, [noUserWithThisEmail]);

  let links =
    type === "course"
      ? {
          mentorList: `/mentor/course/mentors_list/${entryData?.id}/`,
          createEmail: `/mentor/course/create/?email=${userEmail}`,
          postMentor: `/mentor/course/create/`,
          patchMentor1: `/mentor/course/`,
          patchMentor2: `/?course=${entryData.id}`,
          removeMentor1: `/mentor/course/`,
          removeMentor2: `/?course=${entryData?.id}`,
        }
      : type === "steam"
      ? {
          mentorList: `/steam/mentor/list/${entryData?.id}/`,
          createEmail: `/steam/mentor/create/?email=${userEmail}`,
          postMentor: `/mentor/intern/create/`,
          patchMentor1: `/mentor/intern/`,
          patchMentor2: `/?internship=${entryData.id}`,
          removeMentor1: `/mentor/intern/`,
          removeMentor2: `/?internship=${entryData?.id}`,
        }
      : {
          mentorList: `/mentor/interns/mentors_list/${entryData?.id}/`,
          createEmail: `/mentor/intern/create/?email=${userEmail}`,
          postMentor: `/mentor/intern/create/`,
          patchMentor1: `/mentor/intern/`,
          patchMentor2: `/?internship=${entryData.id}`,
          removeMentor1: `/mentor/intern/`,
          removeMentor2: `/?internship=${entryData?.id}`,
        };

  const queryClient = useQueryClient();

  const { data: mentors } = useQuery(["program-mentors"], 
  async () => {
    return await axios.get(links.mentorList).then((res) => res.data);
  },
  {
    enabled: type!== 'steam'
  }
);

  const { mutate: addMentor, isLoading: addMentorLoading } = useMutation(
    ["mentors"],
    async (e) => {
      e.preventDefault();
      return await axios.get(links.createEmail).then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        let objX = {};
        objX.email = userEmail;
        if (!data.id) {
          objX.id = data ? data : null;
        }
        if (data.user) {
          objX.user = data.user ? data.user : null;
        }

        setPendingUser({ ...objX });
      },
      onError: (error) => {
        setNoUserWithThisEmail(true);
      },
      enabled : type !== 'steam'
    }
  );

  const { mutate: removeMentor, isLoading: removeLoading } = useMutation(
    ["remove-mentor"],
    async (index) => {
      return await axios
        .delete(
          links.removeMentor1 + mentors[index].email + links.removeMentor2
        )
        .then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["program-mentors"]);
      },
    }
  );

  async function postOrPatch(type) {
    if (type === "post") {
      return await axios.post(links.postMentor, {
        user: pendingUser.id,
        intern: entryData.id,
      });
    }
    if (type === "patch") {
      let response = await axios.patch(
        links.patchMentor1 + pendingUser?.email + links.patchMentor2,
        { user: pendingUser.id, intern: entryData.id }
      );
      queryClient.invalidateQueries(["program-mentors"]);
      return response;
    }
  }

  const { mutate: handleMentor, isLoading: handleLoading } = useMutation(
    ["postMentor"],
    (type) => postOrPatch(type),
    {
      onSuccess: (data) => {
        setUserEmail("");
        setPendingUser(null);
        queryClient.invalidateQueries(["program-mentors"]);
        postOrPatch("patch");
      },
      onError: (err) => {
        setUserEmail("");
        setPendingUser(null);
        queryClient.invalidateQueries(["program-mentors"]);
      },
    }
  );

  useEffect(() => {
    if (pendingUser !== null) {
      if (!pendingUser?.user) {
        handleMentor("post");
      }
      if (pendingUser?.user) {
        handleMentor("patch");
      }
    }
  }, [pendingUser]);

  return (
    <ManagaMentorsStyle>
      <h3>Invite mentors to help you</h3>

      <div className="check">
        <form onSubmit={(e) => addMentor(e)}>
          <TextInput
            name="currentEmail"
            placeholder="Enter user's email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          <Button
            loading={addMentorLoading || handleLoading}
            type="submit"
            variant="default"
          >
            Add
          </Button>
        </form>
        {noUserWithThisEmail && (
          <Text color={"red"}>No user with this email</Text>
        )}
      </div>

      <div className="addedMentors">
        {mentors?.map((mentor, index) => {
          return (
            <div key={mentor.id}>
              <p>{mentor?.email}</p>
              <Button
                loading={removeLoading}
                variant="outline"
                size="xs"
                color="dark"
                onClick={() => removeMentor(index)}
              >
                <X size={15} />
              </Button>
            </div>
          );
        })}
      </div>
    </ManagaMentorsStyle>
  );
};

const ManagaMentorsStyle = styled.div`
  border-radius: 0.4rem;
  padding: 0 1rem;
  h3 {
    font-weight: 500;
    margin: 0;
    margin-bottom: 1rem;
  }
  .check {
    display: flex;
    align-items: center;
    .error {
      p {
        transition: all 0.6s;
        color: #f00;
        margin: 0;
        padding: 0;
        margin-left: 1rem;
      }
    }
    form {
      display: flex;
      grid-gap: 0.5rem;
    }
  }
  .addedMentors {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    max-width: 570px;
    div {
      display: flex;
      align-items: center;
      background-color: #eee;
      border-radius: 0.25rem;
      margin: 0.25rem;
      button {
        background-color: #eee;
        padding: 0.25rem;
        margin: 0.25rem 0.25rem 0.25rem 0;
        display: flex;
        align-items: center;
      }
      p {
        margin: 0;
        padding: 0.5rem 0.75rem;
        font-size: 14px;
      }
    }
  }
`;
