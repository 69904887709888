import axios from "../../Helpers/Axios";
import styled from "styled-components";
import { useState, useContext } from "react";
import { AppContext } from "../../Helpers/Context";
import { Button, FileInput, TextInput } from "@mantine/core";
import { ProgramCard } from "../ProgramCard";
import { Upload } from "tabler-icons-react";

export const CreateProgramForm = ({ props }) => {
  const { user } = useContext(AppContext);
  const { type, nextStep, setNewCreatedInternshipId } = props;

  const [values, setValues] = useState({ title: "", image: "" });
  const [isLoading, setIsLoading] = useState(false);
  const { title, image } = values;

  let link =
    type === "course"
      ? "api/course/"
      : type === "internship"
      ? "internship/create/"
      : type === "hackathon" 
      ? "/hackathon/create/"
      : type === "university"
      ? "/university/create/" 
      : "/steam/create/";

  const submitAssignment = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    
    const formX = new FormData();
    formX.append("title", title);
    type === "university" ? 
    formX.append("logo", image) :
    formX.append("image", image, image.name);


    type !== "university" && formX.append("is_active", true);
    // New addded active status
    formX.append("active", true);

    formX.append("is_shared", false);
    // formX.append("price", 0);
    // formX.append("description", "");
    // formX.append("language", 1);
    type === "course"
      ? formX.append("teacher", user?.id)
      : type === "university" 
      ? formX.append("company" , user?.id)
      : formX.append("user", user?.id);

      console.log("link-->",link)
      console.log("form data this->",formX)
    const response = await axios.post(link, formX);
    console.log("Response: ", response.status);
    console.log("Response data: ", response.data);
    if (response.status === 201) {
      setIsLoading(false);
      setNewCreatedInternshipId(response.data.id);
      nextStep();
    }
  };

  return (
    <ProgramDetailFormStyle>
      <form onSubmit={(e) => submitAssignment(e)}>
        <ProgramCard props={{ image, title, link: "", type: "" }} />
        <div className="inputs">
          <FileInput
            rightSection={<Upload style={{ color: "lightgray" }} />}
            required
            label="Image"
            placeholder="Upload an image"
            accept={["image/png", "image/jpeg"]}
            onChange={(e) => {
              setValues({ ...values, image: e });
            }}
          />
          <TextInput
            error={title?.length > 150}
            required
            placeholder="Program Title"
            label={`Title (${title?.length}/150)`}
            value={title}
            onChange={(e) => setValues({ ...values, title: e.target.value })}
          />
          <Button
            disabled={title?.length < 1 || !image?.name}
            mt={10}
            color={"dark"}
            type="submit"
            loading={isLoading}
            size="sm"
          >
            Create
          </Button>
        </div>
      </form>
    </ProgramDetailFormStyle>
  );
};

const ProgramDetailFormStyle = styled.div`
  width: 80%;
  margin: 1rem auto;
  form {
    padding: 1rem 0;
    display: flex;
    grid-gap: 2rem;
    .inputs {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
      grid-gap: 0.75rem;
    }
  }
  @media screen and (max-width: 1024px) {
    form {
      padding: 1rem 0;
      margin: 0rem;
      grid-template-columns: 1fr;
      grid-gap: 0rem;
    }
  }
`;
