import React, { useEffect, useState } from "react";
import { ActionIcon, Button, Group, Stack, Text } from "@mantine/core";
import styled from "styled-components";
import previewImg from "../../../../Assets/Svgs/steam/previewImage.svg";
import SaveButton from "../../Common/SaveButton";
import { convertToBase64 } from "../../../../Helpers";


const PhotoContent = ({ contentValue, activeSubUnitTab, handleSubUnitContent,pSubUnit, setIsContentSave }) => {
  const [image, setImage] = useState({
    src: previewImg, // Default preview image
    name: "Workspace Icon", // Default name
  });
  const [uploadImage, setUploadImage] = useState(null); // Track the uploaded image
  const [isChanged, setIsChanged] = useState(false); // Track if the image has changed

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    event.target.value = '';

    if (file) {
      const base64File = await convertToBase64(file);
      setUploadImage(base64File);

      // Get file format from base64
      const fileFormat = base64File.split(';')[0].split('/')[1];
      
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage({
          src: reader.result,
          name: `${file.name}`, // Original file name
          format: fileFormat  // You can use this if needed
        });
        setIsChanged(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    if (uploadImage) {
      console.log('activeSubUnitTab',activeSubUnitTab)
      handleSubUnitContent(`${pSubUnit}_photo`, uploadImage, activeSubUnitTab); // Call the parent function with the uploaded image
      setIsChanged(false); // Reset the change state after saving
      setIsContentSave(true)
    }
  };



  useEffect(() => {
    if (contentValue) {
      setUploadImage(contentValue);
      
      // Check if contentValue is base64 or URL
      const isBase64 = contentValue.startsWith('data:image');
      
      if (isBase64) {
        // Handle base64 image
        const fileFormat = contentValue.split(';')[0].split('/')[1];
        setImage(prev => ({
          ...prev,
          src: contentValue,
          name: `Image.${fileFormat}`
        }));
      } else {
        // Handle URL image
        // Extract filename and format from URL
        const fileName = contentValue.split('/').pop() || 'Image';
        const lastDotIndex = fileName.lastIndexOf('.');
        const format = lastDotIndex !== -1 ? fileName.slice(lastDotIndex + 1) : '';
        
        setImage(prev => ({
          ...prev,
          src: contentValue,
          name: `Image.${format}`
        }));
      }
    } else {
      setUploadImage(null);
      setImage({
        src: previewImg,
        name: "Workspace Icon",
      });
    }
  }, [contentValue, activeSubUnitTab])

  return (
    <PhotoContentStyle>
      <Group align="center" position="apart" >
        <Text mb={10} size={16} weight={500} color="#121926">
          Photo
        </Text>
      </Group>
      <Group spacing={20}>
        <img
          width={120}
          height={90}
          style={{ borderRadius: "8px", objectFit: "cover" }}
          src={uploadImage ? uploadImage : image.src}
          alt="Preview"
        />
        <Stack align="flex-start" spacing={3}>
          <Text size={16} weight={400} color="#000">
            {image.name}
          </Text>
          <Text size={16} weight={400} color="#697586">
            400px, JPG,JPEG, WEBP or PNG, max 200kb
          </Text>
          <Button mt={6} className="upload-btn">
            <label htmlFor="upload-photo" style={{ cursor: "pointer" }}>
              Upload Image
            </label>
            <input
              type="file"
              id="upload-photo"
              style={{ display: "none" }}
              accept="image/png, image/jpeg, image/jpg, image/webp" // Updated to accept PNG, JPEG, JPG, and WEBP formats
              onChange={handleImageUpload}
            />
          </Button>
        </Stack>
      </Group>
      {isChanged && ( // Only show the Save button if an image has been uploaded
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        >
          <SaveButton onClick={handleSave} />
        </div>
      )}
    </PhotoContentStyle>
  );
};

export default PhotoContent;

const PhotoContentStyle = styled.div`
  .upload-btn {
    background: #edf9f0;
    color: #5eaf8a;
    border-radius: 6px;
    padding: 0 10px;
  }

  input[type="file"] {
    display: none;
  }
`;
