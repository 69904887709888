import React from "react";
import { Avatar, Button, Card, Grid, Group, Input, Text } from "@mantine/core";
import styled from "styled-components";
import user from "../../Assets/Svgs/header/user.png";
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { Search } from "tabler-icons-react";
import { useDebouncedState } from "@mantine/hooks";
import LottieLoader from "../../Components/Loaders/LottieLoader";
import { DatabaseOff } from "tabler-icons-react";
import { Stack, Title } from "@mantine/core";
import Loader from "../../Components/Common/Loader";

const AdmittedStudents = ({type}) => {
  useSetCurrentRoute([
    { title: "Admitted students", to: "/admitted-students" },
  ]);

  const [value, setValue] = useDebouncedState("", 200);
  const link = type === "university" ? '/university/applied_users/' : '/university/agent/applied_users/';

  const fetchStudents = async () => {
    const url = value
      ? `${link}?name=${value}`
      : `${link}`;
    const { data } = await axios.get(url);
    return data;
  };

  const {
    data: students,
    isLoading,
    error,
  } = useQuery(["admitted_students", value], fetchStudents);

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  if (!students?.length) {
    return (
      <Wrapper style={{marginTop : "100px"}}> 
        <Stack align="center" spacing="xl" mt={40}>
          <Stack align="center" spacing="xs">
            <DatabaseOff size={32} color="#868E96" />
            <Title order={3} color="dimmed">Məlumat tapılmadı</Title>
            <Text color="dimmed" size="sm">
              Hal hazırda heç bir universitetə müraciət edən tələbə yoxdur.
            </Text>
          </Stack>
        </Stack>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Input
        size="md"
        className="search-input"
        radius={24}
        icon={<Search size={20} color="#75767a" strokeWidth={1.75} />}
        placeholder="Search name..."
        onChange={(event) => setValue(event.currentTarget.value)}
      />
      <Container>
        <Grid>
          {students?.map((item, index) => (
            <Grid.Col span={3}>
              <StyledCard key={index} shadow="sm" padding="lg" radius="md">
                <Group align="center">
                  <Avatar src={user} alt={item.name} size={40} radius="xl" />
                  <Text size={18} color="#364152" weight={500}>
                    {item.first_name} {item.last_name}
                  </Text>
                </Group>
                <div className="line"></div>
                <Group align="center" noWrap>
                  <Text size={16} weight={400} color="#676767">
                    University: {item.applied_uni_count}
                  </Text>
                  <Text size={16} weight={400} color="#676767">
                    Admitted: {item.addmited_count}
                  </Text>
                </Group>
                <Button
                  component={Link}
                  to={`/student-profile/${item.id}`}
                  className="see-more-btn"
                  radius={10}
                  size="md"
                  variant="filled"
                  color="orange"
                >
                  See more
                </Button>
              </StyledCard>
            </Grid.Col>
          ))}
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default AdmittedStudents;

const Wrapper = styled.div`
  .search-input {
    width: 40%;
    border-radius: 14px;
    input::placeholder {
      color: #75767a;
      font-size: 14px;
      font-weight: 400;
    }
    &:focus {
      outline: none;
    }
    .mantine-1hseney {
      top: 3px;
      left: 3px;
    }
  }
`;

const Container = styled.div`
  margin-top: 30px;
  .line {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
  }
`;

const StyledCard = styled(Card)`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: 0px 1px 10px 0px #0000001a;
  .see-more-btn {
    background: #fe6027;
    font-weight: 500;
    font-size: 16px;
  }
`;
