import { Modal, Select, Textarea, TextInput } from "@mantine/core";
import axios from "../../../Helpers/Axios";
import { useState } from "react";

export function ZeroSectionModal({ props }) {
  const {
    entryType,
    links,
    entryId,
    setCreateFirstSection,
    setSectionContentFiltered,
    createfirstSection,
    fetchCourseContent,
  } = props;

  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");
  const [sectionIsFinal, setSectionIsFinal] = useState("false");

  async function addSection() {
    const formX = new FormData();
    formX.append("title", sectionTitle);
    formX.append("description", sectionDescription);
    formX.append("is_active", true);
    formX.append("is_final_project", sectionIsFinal === "true" ? true : false);
    entryType === "internship"
      ? formX.append("internship", entryId)
      : entryType === "course"
      ? formX.append("course", entryId)
      : entryType === "steam"
      ? formX.append("steam", entryId)
      : formX.append("hackathon", entryId);

    // post new section
    try {
      await axios.post(links.section, formX);

      // refetch

      // close modal

      setCreateFirstSection(false);
      setSectionContentFiltered([]);
    } catch (error) {}
  }

  return (
    <Modal
      opened={createfirstSection}
      onClose={() => setCreateFirstSection(false)}
      title="Add first section"
      centered
    >
      <div>
        <p>Please choose a name for the new section.</p>
        <div
          className="buttons"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <TextInput
            label="Section Title"
            value={sectionTitle}
            onChange={(e) => setSectionTitle(e.target.value)}
          />
          <br />
          <Textarea
            label="Section Description"
            value={sectionDescription}
            onChange={(e) => setSectionDescription(e.target.value)}
          />
          <Select
            label="Is Final Project?"
            value={sectionIsFinal}
            onChange={(e) => setSectionIsFinal(e)}
            data={[
              { value: "false", label: "No" },
              { value: "true", label: "Yes" },
            ]}
          />

          <button
            style={{
              backgroundColor: "var(--yellow)",
              fontWeight: "500",
              border: "none",
              cursor: "pointer",
              padding: "0.6rem 1rem",
              margin: "1rem 0",
              borderRadius: "0.2rem",
            }}
            className="submit"
            onClick={() => addSection()}
          >
            Add Section
          </button>
        </div>
      </div>
    </Modal>
  );
}
