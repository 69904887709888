import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Paper,
  Text,
  Group,
  Button,
  TextInput,
  Checkbox,
  Select,
  FileInput,
  Divider,
  Avatar,
  Stack,
  Title,
  Grid,
  Box,
} from "@mantine/core";
import styled from "styled-components";
import userImg from "../../Assets/Svgs/header/user.png";
import file from "../../Assets/Svgs/agents/file.svg";
import upload from "../../Assets/Svgs/agents/upload.svg";
import linkImg from "../../Assets/Svgs/agents/link.svg";
import { showNotification } from "@mantine/notifications";

import CustomCheckbox from "../../Components/CustomCheckbox";
import { DatePicker } from "@mantine/dates";
import {
  Calendar,
  Confetti,
  Download,
  Eye,
  Paperclip,
  Plus,
  Trash,
  Upload,
  X,
} from "tabler-icons-react";
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { useForm, Controller } from "react-hook-form";
import { formatDate } from "../../Helpers";
import { useParams } from "react-router-dom";

const infoFiles = [
  { label: "CV", key: "cv" },
  { label: "Passport", key: "passport" },
  { label: "Attestat", key: "attestat" },
  { label: "Certificate of graduation", key: "certificate_graduation" },
  { label: "Letter of recommendation", key: "reference_file" },
];

const AdmittedUserProfile = ({ type }) => {
  const queryClient = useQueryClient();
  const [selectedApply, setSelectedApply] = useState();
  const [selectedProgram, setSelectedProgram] = useState(null);

  const [filterApply, setFilterApply] = useState();
  const [isAddIconClicked, setIsAddIconClicked] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { uId } = useParams();
  console.log("agent", type);
  const link =
    type === "agent"
      ? `/agent/applieduserinfoByuserid/${uId}/`
      : `/agent/company/applieduserinfoByuserid/${uId}/`;

  useSetCurrentRoute([
    { title: "Admitted students", to: "/admitted-students" },
    { title: "Student profile", to: "/student-profile" },
  ]);

  const { data: profilData } = useQuery({
    queryKey: ["profilData"],
    queryFn: async () => {
      const { data } = await axios.get(link);
      return data.ProgramApply;
    },
  });
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const [newStatus, setNewStatus] = useState({
    status: "",
    date: "",
    link: "",
    file: null,
  });

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      process: "",
      date: null,
      link: "",
      file: null,
    },
  });

  const { mutate: createProcess } = useMutation({
    mutationFn: async (e) =>
      await axios.post(`/university/apply_process/create/`, e),
    onSuccess: () => {
      queryClient.invalidateQueries(["profilData"]);
      reset();
      showNotification({
        icon: <Confetti />,
        title: "Congratulations!",
        message: "You have successfully create status",
        color: "teal",
      });
    },
  });

  const { mutate: deleteProcess } = useMutation({
    mutationFn: async (id) =>
      await axios.delete(`/university/apply_process/edit/${id}/`),
    onSuccess: () => {
      queryClient.invalidateQueries(["profilData"]);
      showNotification({
        icon: <Confetti />,
        title: "Congratulations!",
        message: "You have successfully delete status",
        color: "teal",
      });
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append("program", selectedProgram);
    formData.append("apply", selectedApply);
    formData.append("process", data.process);
    formData.append("link", data.link);
    data.file && formData.append("file", data.file);
    formData.append("date", formatDate(data.date));
    createProcess(formData);
  };

  useEffect(() => {
    if (selectedApply) {
      const filteredData = profilData.find((data) => data.id === selectedApply);
      setFilterApply(filteredData);
    }
  }, [selectedApply, profilData])

  console.log("filter", filterApply);

  return (
    <ProfileContainer>
      <UserProfile>
        <Group spacing={20} align="center">
          <Avatar src={userImg} alt="user" size={88} radius={50} />
          <Stack spacing={2}>
            <Title order={3} size={20} weight={700} color="#364152">
              {profilData && profilData[0]?.user?.first_name}{" "}
              {profilData && profilData[0]?.user?.last_name}
            </Title>
          </Stack>
        </Group>

        <Grid mt={20} style={{ width: "80%" }}>
          <Grid.Col span={4}>
            <CredentialsContainer>
              <Text size={14} weight={500} color="#979797">
                Email
              </Text>
              <Text size={16} weight={400} color="#364152">
                {profilData && profilData[0]?.user?.email}
              </Text>
            </CredentialsContainer>
          </Grid.Col>
          <Grid.Col span={4}>
            <CredentialsContainer>
              <Text size={14} weight={500} color="#979797">
                Phone
              </Text>
              <Text size={16} weight={400} color="#364152">
                {profilData && profilData[0]?.user?.number}
              </Text>
            </CredentialsContainer>
          </Grid.Col>
          <Grid.Col span={4}>
            <CredentialsContainer>
              <Text size={14} weight={500} color="#979797">
                Location
              </Text>
              {profilData && (
                <Text size={16} weight={400} color="#364152">
                  {
                    (profilData[0]?.user?.address
                      ? profilData[0]?.user?.address
                      : profilData[0]?.user?.country.name,
                    profilData[0]?.user.city.name)
                  }
                </Text>
              )}
            </CredentialsContainer>
          </Grid.Col>
        </Grid>
      </UserProfile>

      <UserProfile>
        <SectionTitle>Student applications</SectionTitle>
        <Line />
        <Grid mt={10}>
          {profilData &&
            profilData.map((data, index) => (
              <Grid.Col span={3}>
                <Button
                  leftIcon={<img src={file} alt="file" />}
                  style={{
                    background: "#EDEDED",
                    borderRadius: "10px",
                    width: "100%",
                    height: "48px",
                    color: "#029199",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  key={data.id}
                  onClick={() => setSelectedApply(data.id)}
                >
                  Application {index + 1}{" "}
                </Button>
              </Grid.Col>
            ))}
        </Grid>
      </UserProfile>

      {selectedApply && (
        <UserProfile>
          <SectionTitle>Student Information</SectionTitle>
          <Line />
          {selectedApply && (
            <Stack spacing={2} mt={10} align="flex-start">
              {infoFiles.map((infoFile, index) => (
                <Button
                  styles={{
                    root: {
                      color: "#029199",
                      fontWeight: 400,
                    },
                  }}
                  variant="subtle"
                  leftIcon={<img src={file} alt="file" />}
                  rightIcon={<img src={upload} alt="upload" />}
                  component="a"
                  href={profilData && filterApply?.info[infoFile.key]}
                  target="_blank"
                  download
                  key={index}
                >
                  {index + 1}. {infoFile.label}
                </Button>
              ))}
              {filterApply?.additional_files?.map((fileItem) => (
                <Button
                  styles={{
                    root: {
                      color: "#029199",
                      fontWeight: 400,
                    },
                  }}
                  variant="subtle"
                  leftIcon={<img src={file} alt="file" />}
                  rightIcon={<img src={upload} alt="upload" />}
                  component="a"
                  href={fileItem.file}
                  target="_blank"
                  download
                  key={fileItem.id}
                >
                  {fileItem.file_name}
                </Button>
              ))}
              {filterApply?.endorsed_files?.map((fileItem) => (
                <Button
                  styles={{
                    root: {
                      color: "#029199",
                      fontWeight: 400,
                    },
                  }}
                  variant="subtle"
                  leftIcon={<img src={file} alt="file" />}
                  rightIcon={<img src={upload} alt="upload" />}
                  component="a"
                  href={fileItem.certificate}
                  target="_blank"
                  download
                  key={fileItem.id}
                >
                  {fileItem.skill.skill}
                </Button>
              ))}
            </Stack>
          )}
        </UserProfile>
      )}

      {selectedApply && (
        <UserProfile>
          <SectionTitle>Information about himself</SectionTitle>
          <Line />
          <Text mt={10} size={16} weight={300} color="#364152">
            {profilData && filterApply?.info.motivation_letter}
          </Text>
        </UserProfile>
      )}

      {selectedApply && (
        <UserProfile>
          <SectionTitle>University Programs</SectionTitle>
          <Line />

          {filterApply?.programs?.map((program, index) => (
            <>
              <UniversityProgram key={program.id}>
                <UniversityProgramHeader>
                  <CustomCheckbox
                    checked={selectedProgram === program.id}
                    onChange={() => {
                      setSelectedProgram(
                        selectedProgram === program.id ? null : program.id
                      );
                      setIsAddIconClicked(false);
                    }}
                  />
                  <CredentialsContainer>
                    <Text size={14} weight={500} color="#979797">
                      University
                    </Text>
                    <Title order={3} size={16} weight={400} color="#364152">
                      {program?.university.title}
                    </Title>
                  </CredentialsContainer>
                  <CredentialsContainer>
                    <Text size={14} weight={500} color="#979797">
                      Program
                    </Text>
                    <Title order={3} size={16} weight={400} color="#364152">
                      {program?.profession}
                    </Title>
                  </CredentialsContainer>
                </UniversityProgramHeader>

                {selectedProgram === program.id ? (
                  <Stack>
                    {program?.application_processes.length > 0 &&
                      program.application_processes.map((process) => (
                        <UniversityProgramBody>
                          <Group direction="column" spacing="xs">
                            <div
                              onClick={() => setIsAddIconClicked(true)}
                              style={{
                                width: "24px",
                                height: "24px",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#029199",
                                cursor: "pointer",
                              }}
                            >
                              <Plus size={16} strokeWidth={2} color="#fff" />
                            </div>
                            <CredentialsContainer
                              type="select"
                              newStatus={process.process}
                            >
                              <Text size={14} weight={500} color="#979797">
                                Status
                              </Text>
                              <Select
                                placeholder="Pick one"
                                className="credential-input"
                                disabled={true}
                                defaultValue={process?.process}
                                data={[
                                  {
                                    value: 1,
                                    label: "Submitted Application",
                                  },
                                  {
                                    value: 2,
                                    label: "Additional Documents Required",
                                  },
                                  { value: 3, label: "Interview" },
                                  { value: 4, label: "Additional Test" },
                                  { value: 5, label: "Accepted" },
                                  { value: 6, label: "Rejected" },
                                ]}
                                value={process.process}
                              />
                            </CredentialsContainer>

                            <CredentialsContainer type="nonDisabled">
                              <Text size={14} weight={500} color="#979797">
                                Date
                              </Text>
                              <DatePicker
                                disabled={true}
                                icon={<Calendar size={14} />}
                                placeholder="Pick date"
                                defaultValue={new Date(process?.date)}
                                className="credential-input calendar"
                              />
                            </CredentialsContainer>

                            {(process?.process === 2 ||
                              process?.process === 3 ||
                              process?.process === 4) && (
                              <CredentialsContainer type="nonDisabled">
                                <Text size={14} weight={500} color="#979797">
                                  Link
                                </Text>

                                <TextInput
                                  disabled={true}
                                  icon={
                                    <img
                                      style={{ width: "14px", height: "14px" }}
                                      src={linkImg}
                                      alt="link"
                                    />
                                  }
                                  placeholder="http://example.com"
                                  className="credential-input link"
                                  defaultValue={process.link}
                                />
                              </CredentialsContainer>
                            )}

                            {process?.process === 2 ? (
                              <Button
                                leftIcon={<Download strokeWidth={1.7} />}
                                size="lg"
                                radius={17}
                                target="_blank"
                                component="a"
                                href={process.file}
                                styles={() => ({
                                  root: {
                                    background: "#4880FF",
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    "&:hover": { background: "#4880FF" },
                                  },
                                })}
                              >
                                Download file
                              </Button>
                            ) : process?.process === 3 ||
                              process?.process === 4 ||
                              process?.process === 5 ||
                              process.process === 6 ? (
                              <Button
                                rightIcon={<Eye strokeWidth={1.5} size={18} />}
                                component="a"
                                target="_blank"
                                href={process.file}
                                style={{
                                  border: "1px solid #CDD5DF",
                                  background: "#F8FAFC",
                                  color: "#1F2A37",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  height: "45px",
                                  borderRadius: "10px",
                                }}
                              >
                                Preview
                              </Button>
                            ) : null}

                            <div
                              style={{
                                width: "28px",
                                height: "28px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid #FFF3F3",
                                background: "#FFE0E0",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                              onClick={() => deleteProcess(process.id)}
                            >
                              <Trash
                                color="#FF0000"
                                strokeWidth={1.75}
                                size={16}
                              />
                            </div>
                          </Group>
                        </UniversityProgramBody>
                      ))}
                    {(isAddIconClicked ||
                      program?.application_processes.length === 0) && (
                      <UniversityProgramBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Group direction="column" spacing="xs">
                            <div
                              style={{
                                width: "24px",
                                height: "24px",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#029199",
                                cursor: "pointer",
                              }}
                              onClick={() => setIsAddIconClicked(false)}
                            >
                              <X color="#fff" size={16} />
                            </div>
                            <CredentialsContainer
                              newStatus={selectedStatus}
                              type="select"
                            >
                              <Text size={14} weight={500} color="#979797">
                                Status
                              </Text>

                              <Select
                                name="process"
                                onChange={(e) => {
                                  setValue("process", e);
                                  setSelectedStatus(e);
                                }}
                                placeholder="Pick one"
                                className="credential-input"
                                data={[
                                  {
                                    value: 1,
                                    label: "Submitted Application",
                                  },
                                  {
                                    value: 2,
                                    label: "Additional Documents Required",
                                  },
                                  { value: 3, label: "Interview" },
                                  { value: 4, label: "Additional Test" },
                                  { value: 5, label: "Accepted" },
                                  { value: 6, label: "Rejected" },
                                ]}
                              />
                            </CredentialsContainer>
                            <CredentialsContainer type="nonDisabled">
                              <Text size={14} weight={500} color="#979797">
                                Date
                              </Text>
                              <Controller
                                name="date"
                                control={control}
                                render={({ field }) => (
                                  <DatePicker
                                    {...field}
                                    icon={<Calendar size={14} />}
                                    className="credential-input calendar "
                                    placeholder="Pick date"
                                  />
                                )}
                              />
                            </CredentialsContainer>

                            {(selectedStatus === 2 ||
                              selectedStatus === 3 ||
                              selectedStatus === 4) && (
                              <CredentialsContainer type="nonDisabled">
                                <Text size={14} weight={500} color="#979797">
                                  Link
                                </Text>
                                <Controller
                                  name="link"
                                  control={control}
                                  render={({ field }) => (
                                    <TextInput
                                      {...field}
                                      icon={
                                        <img
                                          style={{
                                            width: "14px",
                                            height: "14px",
                                          }}
                                          src={linkImg}
                                          alt="link"
                                        />
                                      }
                                      placeholder="http://example.com"
                                      className="credential-input link"
                                    />
                                  )}
                                />
                              </CredentialsContainer>
                            )}

                            {(selectedStatus === 3 ||
                              selectedStatus === 4 ||
                              selectedStatus === 5 ||
                              selectedStatus === 6) && (
                              <Button
                                leftIcon={<Upload strokeWidth={1.7} />}
                                size="lg"
                                radius={17}
                                onClick={handleButtonClick}
                                styles={() => ({
                                  root: {
                                    background: "#FEC53D",
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    "&:hover": { background: "#FEC53D" },
                                  },
                                })}
                              >
                                Upload file
                              </Button>
                            )}

                            <input
                              ref={fileInputRef}
                              type="file"
                              style={{ display: "none" }}
                              onChange={(event) =>
                                setValue("file", event.target.files[0])
                              }
                            />
                            <Button
                              size="lg"
                              style={{
                                background: "#029199",
                                color: "#fff",
                                fontSize: "16px",
                                fontWeight: "500",
                                borderRadius: "17px",
                              }}
                              type="submit"
                            >
                              Add
                            </Button>
                          </Group>
                        </form>
                      </UniversityProgramBody>
                    )}
                  </Stack>
                ) : (
                  <Stack>
                    {program?.application_processes.length > 0 &&
                      program.application_processes.map((process) => (
                        <UniversityProgramBody>
                          <Group direction="column" spacing="xs">
                            <CredentialsContainer
                              type="disabled"
                              newStatus={process.process}
                            >
                              <Text size={14} weight={500} color="#979797">
                                Status
                              </Text>
                              <Select
                                placeholder="Pick one"
                                className="credential-input"
                                disabled={true}
                                defaultValue={process?.process}
                                data={[
                                  {
                                    value: 1,
                                    label: "Submitted Application",
                                  },
                                  {
                                    value: 2,
                                    label: "Additional Documents Required",
                                  },
                                  { value: 3, label: "Interview" },
                                  { value: 4, label: "Additional Test" },
                                  { value: 5, label: "Accepted" },
                                  { value: 6, label: "Rejected" },
                                ]}
                                value={process.process}
                                // onChange={(value) =>
                                //   setNewStatus({ ...newStatus, status: value })
                                // }
                              />
                            </CredentialsContainer>

                            <CredentialsContainer type="disabled">
                              <Text size={14} weight={500} color="#979797">
                                Date
                              </Text>
                              <DatePicker
                                disabled={true}
                                icon={<Calendar size={14} />}
                                placeholder="Pick date"
                                defaultValue={new Date(process?.date)}
                                className="credential-input calendar"
                                onChange={(event) =>
                                  setNewStatus({ ...newStatus, date: event })
                                }
                              />
                            </CredentialsContainer>

                            {(process?.process === 2 ||
                              process?.process === 3 ||
                              process?.process === 4) && (
                            <CredentialsContainer type="disabled">
                              <Text size={14} weight={500} color="#979797">
                                Link
                              </Text>

                              <TextInput
                                disabled={true}
                                icon={
                                  <img
                                    style={{ width: "14px", height: "14px" }}
                                    src={linkImg}
                                    alt="link"
                                  />
                                }
                                placeholder="http://example.com"
                                className="credential-input link"
                                defaultValue={process.link}
                                onChange={(event) =>
                                  setNewStatus({
                                    ...newStatus,
                                    link: event.target.value,
                                  })
                                }
                              />
                            </CredentialsContainer>
                            )}

{process?.process === 2 ? (
                              <Button
                                leftIcon={<Download strokeWidth={1.7} />}
                                size="lg"
                                radius={17}
                                target="_blank"
                                component="a"
                                disabled={true}
                                styles={() => ({
                                  root: {
                                    background: "#4880FF",
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    "&:hover": { background: "#4880FF" },
                                  },
                                })}
                              >
                                Download file
                              </Button>
                            ) : process?.process === 3 ||
                            process?.process === 4 ||
                            process?.process === 5 ||
                            process.process === 6 ?  (
                              <Button
                                component="a"
                                target="_blank"
                                href={process.link}
                                disabled={true}
                              >
                                Preview
                              </Button>
                            ) : null}
                          </Group>
                        </UniversityProgramBody>
                      ))}
                  </Stack>
                )}
              </UniversityProgram>
              {filterApply?.programs.length !== index + 1 && (
                <div
                  style={{
                    width: "100%",
                    height: "5px",
                    background: "#D9D9D9",
                    margin: "20px 0",
                  }}
                ></div>
              )}
            </>
          ))}
        </UserProfile>
      )}
    </ProfileContainer>
  );
};

export default AdmittedUserProfile;

const ProfileContainer = styled.div`
  margin-top: 20px;
`;

const CredentialsContainer = styled.div`
  background-color: ${(props) =>
    props.type === "disabled"
      ? "#f9fafc"
      : props.type === "select"
      ? "#EDF9F0"
      : "#fff"};
  border-radius: 17px;
  border: ${(props) =>
    props.type === "select" ? "1px solid #9DB5A9" : "1px solid #e7e9ed"};
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  width: ${(props) =>
    props.newStatus === 1
      ? "220px"
      : props.newStatus === 2
      ? "275px"
      : props.newStatus === 3
      ? "135px"
      : props.newStatus === 4
      ? "180px"
      : props.newStatus === 5
      ? "140px"
      : props.newStatus === 6
      ? "140px"
      : ""};

  .credential-input {
    input {
      border: none;
      background-color: transparent;
      padding: 0;
      min-height: 30px;
      height: 30px;
      color: ${(props) => (props.type === "select" ? "#5EAF8A" : "")};
      &::placeholder {
        color: ${(props) => (props.type === "select" ? "#5EAF8A" : "")};
      }
    }
  }
  .link,
  .calendar {
    input {
      width: 150px;
      padding-left: 18px;
    }
    .mantine-yxyjyg.mantine-Input-icon.mantine-TextInput-icon,
    .mantine-yxyjyg.mantine-Input-icon.mantine-DatePicker-icon {
      left: -12px;
    }
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e1e1e1;
`;

const UserProfile = styled(Paper)`
  padding: 20px;
  margin-bottom: 20px;
`;

const SectionTitle = styled(Text)`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #364152;
`;

const StatusContainer = styled.div`
  margin-top: 10px;
`;

const StatusItem = styled(Paper)`
  padding: 10px;
  margin-top: 10px;
`;

const UniversityProgram = styled(Paper)`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;
const UniversityProgramBody = styled.div`
  margin-left: 33px;
`;

const UniversityProgramHeader = styled(Group)`
  align-items: center;
  margin 10px 0 20px 0;
`;
