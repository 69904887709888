import parse from "html-react-parser";
import styled from "styled-components";
import { Badge, Group, Spoiler, Text } from "@mantine/core";
import { Link } from "react-router-dom";

export function AboutProgram({ props }) {
  const {
    user,
    teacher,
    partners,
    title,
    program_group,
    description,
    requirement,
    bootcamp_name,
    bootcamp_about,
  } = props.entryData;
  const { author_name, steam_name, steam_about } =
    props.entryData;
  const { type } = props;
  const isSteam = type === "steam";
  const isBootcamp = type === "bootcamp";
  return (
    <AboutProgramStyle>
      <div style={{ margin: "0.25rem 0 1.75rem 0" }}>
        <Text size={32} weight={500}>
          {isSteam ? steam_name : isBootcamp ? bootcamp_name : title}
        </Text>
        <Group>
          {user ? (
            <Badge
              styles={{ root: { cursor: "pointer" } }}
              p={11}
              color={"gray.7"}
              variant="filled"
              radius={"xs"}
              component={Link}
              to={`/company/${user?.guid}`}
            >{`${user?.first_name} ${
              user?.last_name === "." ? "" : user?.last_name
            }`}</Badge>
          ) : teacher ? (
            <Badge
              styles={{ root: { cursor: "pointer" } }}
              p={11}
              color={"gray.7"}
              variant="filled"
              radius={"xs"}
              component={Link}
              to={`/company/${teacher?.guid}`}
            >{`${teacher?.first_name} ${
              teacher?.last_name === "." ? "" : teacher?.last_name
            }`}</Badge>
          ) : (
            author_name && (
              <Badge
                styles={{ root: { cursor: "pointer" } }}
                p={11}
                color={"gray.7"}
                variant="filled"
                radius={"xs"}
              >{`${author_name}`}</Badge>
            )
          )}
          {program_group?.name && (
            <Badge p={11} color={"gray.7"} variant="light" radius={"xs"}>
              {program_group?.name}
            </Badge>
          )}
        </Group>
      </div>

      <Group style={{ gap: "0.5rem" }}>
        <Group
        align="center"
          p={15}
          my={0}
          style={{ backgroundColor: "#F8F9FA", borderRadius: "0.75rem" }}
        >
          <Text color={"dimmed"}  size={18} weight={500}>
            About:
          </Text>
          <Spoiler
            styles={{ control: {} }}
            maxHeight={150}
            showLabel={
              <Text
                size={"sm"}
                color="black"
                style={{
                  border: "1px solid #222",
                  borderRadius: ".25rem",
                  padding: ".25rem .5rem",
                  marginTop: "1rem",
                }}
              >
                See More
              </Text>
            }
            hideLabel={
              <Text
                size={"sm"}
                color="black"
                style={{
                  border: "1px solid #222",
                  borderRadius: ".25rem",
                  padding: ".25rem .5rem",
                  marginTop: "1rem",
                }}
              >
                See Less
              </Text>
            }
          >
            {isSteam && steam_about
              ? parse(steam_about)
              : isBootcamp && bootcamp_about
              ? parse(bootcamp_about)
              : description && parse(description)}
          </Spoiler>
        </Group>

        {requirement && (
          <Group
            p={15}
            my={0}
            style={{ backgroundColor: "#F8F9FA", borderRadius: "0.75rem" }}
            classNames={"showNo"}
          >
            <Text color={"dimmed"} mb={-20} size={18} weight={500}>
              Requirements:
            </Text>
            <Spoiler
              styles={{ control: {} }}
              maxHeight={150}
              showLabel={
                <Text
                  size={"sm"}
                  color="black"
                  style={{
                    border: "1px solid #222",
                    borderRadius: ".25rem",
                    padding: ".25rem .5rem",
                    marginTop: "1rem",
                  }}
                >
                  See More
                </Text>
              }
              hideLabel={
                <Text
                  size={"sm"}
                  color="black"
                  style={{
                    border: "1px solid #222",
                    borderRadius: ".25rem",
                    padding: ".25rem .5rem",
                    marginTop: "1rem",
                  }}
                >
                  See Less
                </Text>
              }
            >
              {requirement && parse(requirement)}
            </Spoiler>
          </Group>
        )}
      </Group>

      {partners?.length > 0 && (
        <div className="partnerDiv">
          <h2>Supported by</h2>
          <div className="partners">
            {partners?.map((partner) => (
              <img key={partner?.id} src={partner?.logo} alt={partner?.name} />
            ))}
          </div>
        </div>
      )}
    </AboutProgramStyle>
  );
}

const AboutProgramStyle = styled.div`
  h1 {
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 2.2rem;
  }
  button,
  a {
    font-weight: 500;
  }
  .partnerDiv {
    border-radius: 0.75rem;
    margin-bottom: 1.5rem;
    h2 {
      text-align: center;
      font-weight: 500;
    }
    img {
      object-fit: contain;
      width: 150px;
      height: 80px;
    }
    .partners {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      grid-gap: 1rem;
      padding: 1rem;
    }
  }
  .program {
    margin: 0.25rem 0;
    font-weight: 400;
    color: #444;
    font-size: 17px;
    background-color: #eee;
    max-width: max-content;
    padding: 0.1rem 0.25rem;
    border-radius: 0.5rem;
  }
  .userInfo {
    display: flex;
    align-items: center;
    border: 1px solid #bbb;
    border-radius: 0.5rem;
    padding: 0.3rem 0.8rem 0.3rem 0.4rem;
    width: max-content;
    /* background-color: #fff; */
    text-decoration: none;
    color: #000;
    img {
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 20%;
      margin-right: 0.8rem;
      object-fit: cover;
    }
    .nameAndPoints {
      p {
        margin: 0;
        /* margin-top: .2rem; */
        /* margin-bottom: -.2rem; */
        font-size: 16px;
        font-weight: 400;
      }
      .stars {
        display: flex;
        align-items: center;
        transform: scale(0.7);
        margin-left: -1.3rem;
        p {
          margin: 0 0.1rem;
        }
      }
    }
    transition: all 0.15s;
    :hover {
      background: #fff;
    }
  }
  .about {
    background-color: #f8f8f8;
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
    border-radius: 0.5rem;
    margin: 2.5rem 0 2rem 0;
    h3 {
      font-weight: 500;
      font-size: 17px;
      margin: 1.5rem 0 0.5rem 0;
    }
    .school {
      display: flex;
      align-items: center;
      grid-gap: 0.5rem;
      border: 1px solid #bbb;
      padding: 0.35rem;
      max-width: max-content;
      border-radius: 0.4rem;
      cursor: default;
      img {
        width: 35px;
        height: 35px;
        object-fit: cover;
        border-radius: 0.4rem;
      }
      p {
        font-size: 16px;
        padding: 0;
        margin: 0;
        margin: 0 0.3rem 0 0.2rem;
      }
    }
    p {
      padding-bottom: 1rem;
      font-size: 17px;
      line-height: 1.6rem;
    }
  }
  .editCourse {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
  }
  @media screen and (max-width: 1024px) {
    .partnerDiv {
      display: none;
    }
    .showNo {
      display: none;
    }
    .about {
      margin: 1rem 0 3rem 0;
    }
    h1 {
      font-size: 26px;
      /* margin: 0; */
    }
  }
`;
