import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button, TextInput, Textarea } from "@mantine/core";
import styled from "styled-components";

const CreateWeekModal = ({ 
  setUnitList, 
  unitList, 
  pUnit,
  pUnits,
  pSubUnits,
  pSubUnit,
  opened, 
  setOpened, 
  editUnit, 
  editMode, 
  setEditMode,
  handleCreateContent,
  setIsOnsubmit,
  isOnsubmit
 }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue // Add setValue function here
  } = useForm();




  useEffect(() => {
    if (editMode && editUnit) {
      setValue(`${pUnit}_name`, editUnit[`${pUnit}_name`]);
      setValue(`${pUnit}_title`, editUnit[`${pUnit}_title`]);
      setValue("duration_time", editUnit.duration_time);
      setValue("description", editUnit.description);
    } else {
      reset();
    }
  }, [editMode, editUnit, setValue, reset,pUnit]);

  const onSubmit = (data) => {
    data[pSubUnits] = editMode ? editUnit[pSubUnits] : [{ [`${pSubUnit}_content`]: `${pSubUnit.charAt(0).toUpperCase() + pSubUnit.slice(1)} content`, skills_ids: [], [`${pSubUnit}_photo`]: "", file_limit: 5 }];
    if (editMode) {
      const updatedUnitList = unitList.map((unit) =>
        unit[`${pUnit}_name`] === editUnit[`${pUnit}_name`] ? { ...editUnit, ...data } : unit
      );
      setUnitList(updatedUnitList);
      setIsOnsubmit(true)
    } else {
      setUnitList([...unitList, data]);
    }
    setOpened(false);
    setEditMode(false);
    reset();
  };

  useEffect(() => {
    if (isOnsubmit) {
      handleCreateContent()
    }
  }, [isOnsubmit])


  return (
    <ModalStyle>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened((unitList.length > 0 ? false : true));
          setEditMode(false);
        }}
        title={editMode ? `Edit ${pUnit.charAt(0).toUpperCase() + pUnit.slice(1)}` : `Add ${pUnit.charAt(0).toUpperCase() + pUnit.slice(1)}`}
        className="create-week-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            radius={8}
            size="md"
            className="create-week-modal-form-field"
            label={`${pUnit.charAt(0).toUpperCase() + pUnit.slice(1)} name`}
            withAsterisk
            placeholder={`${pUnit.charAt(0).toUpperCase() + pUnit.slice(1)} 1`}
            {...register(`${pUnit}_name`, { required: `${pUnit.charAt(0).toUpperCase() + pUnit.slice(1)} name is required` })}
            error={errors[`${pUnit}_name`] && errors[`${pUnit}_name`].message}
          />

          <TextInput
            radius={8}
            mt={10}
            size="md"
            className="create-week-modal-form-field"
            label={`${pUnit.charAt(0).toUpperCase() + pUnit.slice(1)} title`}
            withAsterisk
            placeholder="Name"
            {...register(`${pUnit}_title`, { required: `${pUnit.charAt(0).toUpperCase() + pUnit.slice(1)} title is required` })}
            error={errors[`${pUnit}_title`] && errors[`${pUnit}_title`].message}
          />

          <TextInput
            radius={8}
            size="md"
            className="create-week-modal-form-field"
            label="Duration"
            withAsterisk
            placeholder="Duration"
            {...register("duration_time", { required: "Duration is required" })}
            error={errors.duration_time && errors.duration_time.message}
            mt="md"
          />

          <Textarea
            radius={8}
            size="md"
            className="create-week-modal-form-field"
            label="Description"
            withAsterisk
            placeholder="Description"
            {...register("description", { required: "Description is required" })}
            error={errors.description && errors.description.message}
            mt="md"
            minRows={3}
          />

          <div className="form-buttons">
            <Button
              className="create-week-modal-form-btn cancel-btn"
              variant="outline"
              onClick={() => {
                if (unitList && unitList.length > 0) {
                  setOpened(false);
                  setEditMode(false);
                }
              }}
              mt="md"
            >
              Cancel
            </Button>
            <Button
              className="create-week-modal-form-btn create-btn"
              type="submit"
              mt="md"
              ml="sm"
              color="lime"
            >
              {editMode ? "Save" : "Create"}
            </Button>
          </div>
        </form>
      </Modal>
    </ModalStyle>
  );
};

export default CreateWeekModal;

const ModalStyle = styled.div`
  


`;
