import axios from "../../Helpers/Axios"
import styled from "styled-components"
import { useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Button, LoadingOverlay, Tabs, Text } from "@mantine/core"
import { GitCompare, Notes, ShoppingCart, UserPlus, Users, UserSearch } from "tabler-icons-react"
import { UserFlyer } from "./UserFlyer"
import { AllUsers } from "./AllUsers"
import { Wishlist } from "./Wishlist"

export const People = ({ props }) => {

  const { program } = props

  const [ reportData, setReportData ] = useState({})
  const [ selectedAccounts, setSelectedAccounts ] = useState([])
  const [ wishlistedUsers, setWishlistedUsers ] = useState([])

  let domain = program === "taskool" ? "/reports/users_order_tip_for_admin/" : "/reports/users_order_internship_for_admin/"

  const { isLoading, isFetching } = useQuery([`reports`],
    async () => await axios.get(domain).then(res => res.data),
    { onSuccess: (data) => { setReportData(data) } }
  )

  const { mutate:reFetch, isLoading:refetching } = useMutation([`reports`],
    async () => await axios.get(domain).then(res => res.data),
    { onSuccess: (data) => { setReportData(data) }
  })

  const { mutate:fetchPage, isLoading:pageFetchLoading } = useMutation([`reports`],
    async (link) => {
      let linkWithS = link.replace("http", "https")
      return await axios.get(linkWithS).then(res => res.data)
    },
    { onSuccess: (data) => { setReportData(data) }
  })

  if(isLoading || isFetching){
    return <LoadingOverlay visible={true} loaderProps={{ color: 'var(--yellow)' }}/>
  }

  return (
    <ReportStyle>

      <Tabs color={"gray.6"} defaultValue="all" className="wrapper">
        <Tabs.List grow>
          <Tabs.Tab value="all" icon={<Users size={24} />}>
            <Text size={18} weight={600} color="dark">All: {reportData?.count}</Text>
          </Tabs.Tab>
          <Tabs.Tab value="wishlist" icon={<UserSearch size={24} />}>
            <Text size={18} weight={600} color="dark">Wishlist: {wishlistedUsers?.length}</Text>
          </Tabs.Tab>
          <Tabs.Tab value="purchased" icon={<UserPlus size={24} />}>
            <Text size={18} weight={600} color="dark">Purchsed: 0</Text>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="all">
          <AllUsers props={{ reportData, setReportData, selectedAccounts, setSelectedAccounts, setWishlistedUsers }}/>
        </Tabs.Panel>

        <Tabs.Panel value="wishlist">
          <Wishlist props={{ wishlistedUsers, setWishlistedUsers }}/>
        </Tabs.Panel>

      </Tabs>


    </ReportStyle>
  )
}

const ReportStyle = styled.div`
  .wrapper{
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    overflow: hidden;
    position: relative;
    padding: 0 .5rem;
    .header{
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
    }
    .selectionFilter{
      display: flex;
      flex-direction: column;
      grid-gap: .5rem;
      .boxes{
        display: flex;
        flex-wrap: wrap;
        grid-gap: 1rem;
      }
    }
    .selected{
      margin-bottom: 1rem;
      .selectOrNot{
        display: flex;
        grid-gap: .5rem;
        align-items: center;
        margin-bottom: 0.5rem;
      }
      .buttonsManageSelected{
        display: flex;
        flex-wrap: wrap;
        grid-gap: .5rem;
      }
    }
    .navigate{
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 1rem;
      button{
        font-weight: 500;
      }
    }
    .users{
      /* margin-top: 10rem; */
      .wishlist{
        border: 1px solid #CED4DA;
        border-radius: 0.25rem;
        padding: 1rem;
        background-color: #E9ECEF;
        display: flex;
        flex-direction: column;
        grid-gap: .75rem;
        h3{
          font-weight: 500;
          margin: 0;
        }
        li{
          list-style: none;
          background: #DEE2E6;
          padding: 0.25rem 0.5rem;
          border-radius: 0.25rem;

        }
      }
      .reportData{
        /* width: 99%;
        max-width: 99%; */
        margin: 0 auto;
        height: 100%;
        max-height: 100%;
        overflow: scroll;
        .identificationTitle{
          min-width: 350px;
        }
        .reportTable{
          display: flex;
          flex-direction: column;
          grid-gap: 1rem;
          .thead{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          }
          .tbody{
            display: flex;
            flex-direction: column;
            grid-gap: .5rem;
          }
        }
        ::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          /* margin-top: 1rem; */
        }
        ::-webkit-scrollbar-track {
          margin-top: 2.2rem;
          border-radius: 1rem;
          background: rgba(126, 126, 126, 0);
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 1rem;
          background: rgba(126, 126, 126, 0.5);
        }

        ::-webkit-scrollbar-thumb:hover {
          background: rgba(126, 126, 126, 1);
        }
      }
    }
  }
`