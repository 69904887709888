import React from "react";
import { Button } from "@mantine/core";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CustomButton = ({
  onClick,
  bgColor,
  color,
  text,
  isLink,
  href,
  leftIcon,
  rightIcon,
  height,
  border,
  width,
  variant,
  size = "md",
  fs = "16px",
  isA,
  disabled = false,
}) => {
  const commonProps = {
    size,
    radius: 8,
    onClick,
    leftIcon,
    rightIcon,
    variant,
    disabled,
    styles: {
      root: {
        backgroundColor: bgColor,
        color,
        fontSize: fs,
        fontWeight: "500",
        height,
        border,
        width,
        "&:hover": {
          backgroundColor: bgColor,
        },
      },
    },
  };

  if (isLink) {
    return (
      <Button {...commonProps} component={Link} to={href}>
        {text}
      </Button>
    );
  }

  if (isA) {
    return (
      <Button {...commonProps} component="a" href={href} target="_blank">
        {text}
      </Button>
    );
  }

  return <Button {...commonProps}>{text}</Button>;
};

export default CustomButton;
