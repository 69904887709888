import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Grid,
  Card,
  Text,
  Image,
  Group,
  Modal,
  Textarea,
  TextInput,
  FileInput,
  MultiSelect,
  NumberInput,
} from "@mantine/core";
import { Edit, Trash, InfoSquare, Upload } from "tabler-icons-react";
import { DatePicker } from "@mantine/dates";
import { AppContext } from "../../Helpers/Context";
import axios from "../../Helpers/Axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import Modals from "./Modals";
import { useNavigate } from "react-router-dom";
import RichTextEditor from "@mantine/rte";

export const Olympics = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [publishedOlympics, setPublishedOlympics] = useState();
  const [unpublishedOlympics, setUnpublishedOlympics] = useState();
  const [itemId, setItemId] = useState(0);
  const { user } = useContext(AppContext);

  const navigate = useNavigate();

  const { data: allUsers } = useQuery(
    ["all users"],
    async () => await axios.get(`/user/all_users_list/`).then((res) => res.data)
  );

  const [values, setValues] = useState({
    title: "",
    image: null,
    description: "",
    price: "",
    start_date: new Date(),
    end_date: new Date(),
  });

  const submitAssignment = async (e) => {
    const { title, image, description, start_date, end_date, mentors, price } =
      values;
    e.preventDefault();

    const formX = new FormData();
    formX.append("title", title);
    formX.append("description", description);
    formX.append("price", price);
    formX.append("start_date", start_date);
    formX.append("end_date", end_date);
    formX.append("deadline", null);
    formX.append("video", "1");
    // formX.append("promocode", 1);
    formX.append("duration", 1);
    formX.append("language", 2);
    formX.append("image", image);
    formX.append("active", true);
    formX.append("is_shared", false);
    formX.append("user", user?.id);
    mentors.forEach((mentor) => {
      formX.append("mentors", mentor);
    });

    console.log(formX);

    const response = await axios.post("/olympic/create/", formX);
    console.log("response data", response.data);
    getData();
  };

  const handleCreateClick = () => {
    setShowCreateModal(true);
  };

  const handleEditClick = (item, id) => {
    setItemId(id);
    setValues(item);
    setShowEditModal(true);
  };

  const handleRemoveClick = (id) => {
    setItemId(id);
    setShowRemoveModal(true);
  };

  const handleDetailsClick = (id) => {
    setItemId(id);
    setShowDetailsModal(true);
  };

  const getData = () => {
    // await axios
    //   .get("/olympic/activate_olympics_list")
    //   .then((res) => res.data)
    //   .then((data) => setPublishedOlympics(data));

    axios
      .get(`/olympic/olympicbyuserid/${user.id}/`)
      .then((res) => res.data)
      .then((data) => data.filter((item) => item.is_shared === true))
      .then((data) => setPublishedOlympics(data));

    axios
      .get(`/olympic/olympicbyuserid/${user.id}`)
      .then((res) => res.data)
      .then((data) => data.filter((item) => item.is_shared === false))
      .then((data) => setUnpublishedOlympics(data));
  };
  useEffect(() => {
    getData();
  }, [showRemoveModal, showCreateModal, showEditModal]);
  return (
    <div>
      <Button
        fullWidth
        style={{ backgroundColor: "#333" }}
        onClick={handleCreateClick}
      >
        Create new olympic
      </Button>
      <Modal
        fullScreen
        opened={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        title="Create new olympic"
      >
        <form onSubmit={submitAssignment}>
          <Grid>
            <Grid.Col span={12}>
              <MultiSelect
                placeholder="Select mentors"
                searchable
                clearable
                data={
                  allUsers?.map((user) => ({
                    value: user?.id,
                    label: user?.email,
                  })) ?? []
                }
                onChange={(value) => setValues({ ...values, mentors: value })}
                required
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <TextInput
                placeholder="Title"
                onChange={(e) =>
                  setValues({ ...values, title: e.target.value })
                }
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <DatePicker
                valueFormat="YYYY MMM DD"
                placeholder="Start date"
                maw={400}
                onChange={(e) =>
                  setValues({
                    ...values,
                    start_date: new Date(e).toISOString().replace(/\.000/, ""),
                  })
                }
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <DatePicker
                valueFormat="YYYY MMM DD"
                placeholder="End date"
                maw={400}
                onChange={(e) =>
                  setValues({
                    ...values,
                    end_date: new Date(e).toISOString().replace(/\.000/, ""),
                  })
                }
              />
            </Grid.Col>
            <Grid.Col span={12}>
              {/* <Textarea
                autosize
                minRows={8}
                placeholder="Description"
                onChange={(e) => {
                  setValues({ ...values, description: e.target.value });
                }}
              /> */}
              <RichTextEditor
                value={values.description}
                onChange={(e) => {
                  console.log(e);
                  setValues({ ...values, description: e });
                }}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <NumberInput
                placeholder="Enter price"
                onChange={(e) => {
                  setValues({ ...values, price: e });
                }}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <FileInput
                placeholder="Upload an image"
                icon={<Upload size="14px" />}
                onChange={(e) => {
                  setValues({ ...values, image: e });
                }}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Button
                fullWidth
                style={{ backgroundColor: "#333" }}
                type="submit"
                onClick={() => setShowCreateModal(false)}
                disabled={values.mentors == undefined}
              >
                Create
              </Button>
            </Grid.Col>
          </Grid>
        </form>
      </Modal>

      <div style={{ marginTop: "10px" }} className="olympics-published">
        <p style={{ fontWeight: 400, fontSize: "18px" }}>Published olympics</p>
        <Grid>
          {publishedOlympics?.length > 0 &&
            publishedOlympics?.map((item) => (
              <Grid.Col span={4}>
                <div style={{ position: "relative" }}>
                  {/* Details Icon */}
                  <Button
                    size="xs"
                    variant="outline"
                    onClick={() => handleDetailsClick(item.id)}
                    style={{
                      position: "absolute",
                      bottom: "30px",
                      right: "70px",
                      zIndex: 100,
                      border: "none",
                    }}
                  >
                    <InfoSquare size={18} />
                  </Button>

                  {/* Edit Icon */}
                  <Button
                    size="xs"
                    variant="outline"
                    onClick={() => handleEditClick(item, item.id)}
                    style={{
                      position: "absolute",
                      bottom: "30px",
                      right: "40px",
                      zIndex: 100,
                      border: "none",
                      color: "gray",
                    }}
                  >
                    <Edit size={18} />
                  </Button>

                  {/* Remove Icon */}
                  <Button
                    size="xs"
                    variant="outline"
                    onClick={() => handleRemoveClick(item.id)}
                    style={{
                      position: "absolute",
                      bottom: "30px",
                      right: "10px",
                      zIndex: 100,
                      color: "red",
                      border: "none",
                    }}
                  >
                    <Trash size={18} />
                  </Button>

                  <Card
                    key={item?.id}
                    p="lg"
                    radius="lg"
                    withBorder
                    style={{
                      border: "1px solid #DEE2E6",
                    }}
                  >
                    <Card.Section>
                      <Image
                        src={item?.image}
                        height={180}
                        alt="Card"
                        withPlaceholder
                      />
                    </Card.Section>

                    <Group
                      position="apart"
                      mt="md"
                      mb="0"
                      style={{ minHeight: "48px" }}
                    >
                      <Text size={16} weight={500}>
                        {item?.title?.length > 30
                          ? item?.title?.substring(0, 30) + "..."
                          : item?.title}
                      </Text>
                    </Group>
                  </Card>

                  {/* Modals go here, conditionally rendered based on state */}
                  <Modals
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    showRemoveModal={showRemoveModal}
                    setShowRemoveModal={setShowRemoveModal}
                    showDetailsModal={showDetailsModal}
                    setShowDetailsModal={setShowDetailsModal}
                    item={item}
                    itemId={itemId}
                    setValues={setValues}
                    values={values}
                    user={user}
                  />
                </div>
              </Grid.Col>
            ))}
        </Grid>
      </div>
      <div style={{ marginTop: "40px" }} className="olympics-unpublished">
        <p style={{ fontWeight: 400, fontSize: "18px" }}>
          Unpublished Olympics
        </p>
        <Grid>
          {unpublishedOlympics?.length > 0 &&
            unpublishedOlympics?.map((item) => (
              <Grid.Col span={4}>
                <div style={{ position: "relative" }}>
                  {/* Details Icon */}
                  <Button
                    size="xs"
                    variant="outline"
                    onClick={() => handleDetailsClick(item.id)}
                    style={{
                      position: "absolute",
                      bottom: "30px",
                      right: "70px",
                      zIndex: 100,
                      border: "none",
                    }}
                  >
                    <InfoSquare size={18} />
                  </Button>

                  {/* Edit Icon */}
                  <Button
                    size="xs"
                    variant="outline"
                    onClick={() => handleEditClick(item, item.id)}
                    style={{
                      position: "absolute",
                      bottom: "30px",
                      right: "40px",
                      zIndex: 100,
                      border: "none",
                      color: "gray",
                    }}
                  >
                    <Edit size={18} />
                  </Button>

                  {/* Remove Icon */}
                  <Button
                    size="xs"
                    variant="outline"
                    onClick={() => handleRemoveClick(item.id)}
                    style={{
                      position: "absolute",
                      bottom: "30px",
                      right: "10px",
                      zIndex: 100,
                      color: "red",
                      border: "none",
                    }}
                  >
                    <Trash size={18} />
                  </Button>

                  <Card
                    key={item?.id}
                    p="lg"
                    radius="lg"
                    withBorder
                    style={{
                      border: "1px solid #DEE2E6",
                    }}
                  >
                    <Card.Section>
                      <Image
                        src={item?.image}
                        height={180}
                        alt="Card"
                        withPlaceholder
                      />
                    </Card.Section>

                    <Group
                      position="apart"
                      mt="md"
                      mb="0"
                      style={{ minHeight: "48px" }}
                    >
                      <Text size={16} weight={500}>
                        {item?.title?.length > 30
                          ? item?.title?.substring(0, 30) + "..."
                          : item?.title}
                      </Text>
                    </Group>
                  </Card>

                  {/* Modals go here, conditionally rendered based on state */}
                  <Modals
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    showRemoveModal={showRemoveModal}
                    setShowRemoveModal={setShowRemoveModal}
                    showDetailsModal={showDetailsModal}
                    setShowDetailsModal={setShowDetailsModal}
                    item={item}
                    itemId={itemId}
                    setValues={setValues}
                    values={values}
                    user={user}
                  />
                </div>
              </Grid.Col>
            ))}
        </Grid>
      </div>
    </div>
  );
};
