import { Modal } from "@mantine/core";
import axios from "../../../Helpers/Axios";

export function RemoveSectionModal({ props }) {
  const {
    entryType,
    links,
    setLoaderVisible,
    sectionContent,
    fetchCourseContent,
    entryId,
    setRemoveSectionModalOpen,
    removeSectionModalOpen,
    setEditOrUpdateSignal,
  } = props;

  async function removeSection() {
    setLoaderVisible(true);
    console.log("sectionContent", sectionContent);
    const requestUrl =
      entryType === "course"
        ? `/api/edit-section/${
            !sectionContent[0]?.section?.id
              ? sectionContent?.id
              : sectionContent[0]?.section.id
          }/`
:
          entryType === "steam"
          ? `/steam/section/edit/${
              !sectionContent[0]?.section?.id
                ? sectionContent?.id
                : sectionContent[0]?.section.id
            }/`
        : `/internshipsection/${
            !sectionContent[0]?.section?.id
              ? sectionContent?.id
              : sectionContent[0]?.section.id
          }/`;

    try {
      await axios.delete(requestUrl).then((data) => {
        setLoaderVisible(false);
        setRemoveSectionModalOpen(false);
      });
      // if (response.status === 204) {
      // await fetchCourseContent(entryId);
      // if (response.status === 200) {
      // }
      // }
      window.location.reload(false);
    } catch (error) {
      setLoaderVisible(false);
    }
  }

  return (
    <Modal
      opened={removeSectionModalOpen}
      onClose={() => setRemoveSectionModalOpen(false)}
      title="Remove Section"
    >
      <div>
        <p style={{ color: "red" }}>
          Are you sure you want to remove this section? If you remove this
          section, all it's content will be removed.
        </p>
        <div
          className="buttons"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <button
            style={{
              backgroundColor: "#eee",
              border: "none",
              cursor: "pointer",
              padding: "0.3rem 1rem",
              margin: "0 .5rem",
              borderRadius: "0.2rem",
            }}
            className="cancel"
            onClick={() => setRemoveSectionModalOpen(false)}
          >
            <span>Cancel</span>
          </button>
          <button
            style={{
              backgroundColor: "#dd4a4a",
              color: "white",
              border: "none",
              cursor: "pointer",
              padding: "0.3rem 1rem",
              margin: "0 .5rem",
              borderRadius: "0.2rem",
            }}
            className="submit"
            onClick={() => removeSection()}
          >
            <span>Delete</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}
