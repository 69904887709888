import React, { useState, useRef } from "react";
import styled from "styled-components";
import {
  Button,
  Grid,
  Tabs,
  Text,
  Modal,
  Menu,
  ActionIcon,
  Group,
} from "@mantine/core";
import { Plus, DotsVertical } from "tabler-icons-react";
import LabelInput from "./Common/LabelInput";
import IconList from "./IconsSection";
import axios from "../../Helpers/Axios";
import { showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const UnitListSection = ({
  type,
  link,
  pUnits,
  pUnit,
  activeTab,
  setActiveTab,
  unitList,
  setOpened,
  handleUnitContent,
  currentUnit,
  setUnitList, // Add this prop
  programId,
  setEditUnit, // Add this prop
  setEditMode, // Add this prop
  handleCreateContent,
  isContentSave,
  setIsContentSave
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [unitToDelete, setUnitToDelete] = useState(null);
  const [isLocalState, setIsLocalState] = useState("");
  const queryClient = useQueryClient();
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const weekListRef = useRef(null);

  const handleDeleteClick = (unitId, event, type) => {
    event.stopPropagation();
    setUnitToDelete(unitId);
    setIsLocalState(type);
    setDeleteModalOpen(true);
  };

  const { mutate: deleteUnit, isLoading } = useMutation({
    mutationFn: async () => {
      const response = await axios.delete(
        `${link.program}/${programId}/${pUnit}/${unitToDelete}`
      );
      return response;
    },
    onSuccess: () => {
      setDeleteModalOpen(false);
      setUnitToDelete(null);
      queryClient.invalidateQueries(["program", programId]);
      showNotification({
        title: "Success",
        message: `${pUnit.charAt(0).toUpperCase() + pUnit.slice(1)} deleted successfully`,
        color: "teal",
      });
    },
    onError: () => {
      setDeleteModalOpen(false);
      setUnitToDelete(null);
      showNotification({
        title: "Error",
        message: `An error occurred while deleting ${pUnit}`,
        color: "red",
      });
    },
  });

  const handleDeleteUnit = () => {
    if (isLocalState === "local") {
      setUnitList((prev) => prev.filter((_, index) => index !== unitToDelete));
      setDeleteModalOpen(false);
      setUnitToDelete(null);
      showNotification({
        title: "Success",
        message: `${pUnit.charAt(0).toUpperCase() + pUnit.slice(1)} deleted successfully`,
        color: "teal",
      });
    } else {
      deleteUnit();
    }
  };

  const handleEditClick = (unit) => {
    setEditUnit(unit);
    setEditMode(true);
    setOpened(true);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const slider = weekListRef.current;
    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const slider = weekListRef.current;
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2; // Scroll-un sürətini artırmaq üçün 2-yə vururuq
    slider.scrollLeft = scrollLeft - walk;
  };

  return (
    <WeekListStyle>
      <Grid align="center">
        <Grid.Col span={9}>
          <div 
            className="week-list"
            ref={weekListRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
          >
            <Tabs value={activeTab} onTabChange={setActiveTab} variant="pills">
              <Tabs.List className="week-tab-list">
                {unitList &&
                  unitList.length > 0 &&
                  unitList.map((unit, index) => (
                    <Tabs.Tab
                      key={index}
                      className="custom-tab"
                      value={`${pUnit}-${index + 1}`}
                    >
                      <Group position="apart" align="center">
                        {unit[`${pUnit}_name`]}
                        <Menu withinPortal position="bottom-right" shadow="sm">
                          <Menu.Target>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <DotsVertical size={16} />
                            </div>
                          </Menu.Target>
                          <Menu.Dropdown>
                            <Menu.Item
                              onClick={(e) =>
                                handleDeleteClick(
                                  unit.id ? unit.id : index,
                                  e,
                                  unit.id ? "server" : "local"
                                )
                              }
                            >
                              Delete
                            </Menu.Item>
                            <Menu.Item onClick={() => handleEditClick(unit)}>
                              Edit
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      </Group>
                    </Tabs.Tab>
                  ))}
              </Tabs.List>
            </Tabs>
          </div>
        </Grid.Col>
        <Grid.Col span={3}>
          <Button
            onClick={() => {
              setEditMode(false);
              setOpened(true);
            }}
            fullWidth
            rightIcon={<Plus strokeWidth={1.75} color="#fff" />}
            className="add-week-btn"
          >
            Add {pUnit.charAt(0).toUpperCase() + pUnit.slice(1)}
          </Button>
        </Grid.Col>
      </Grid>

      <Modal
        opened={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="Confirm Deletion"
      >
        <Text mb={5}>Are you sure you want to delete this {pUnit}?</Text>
        <Button onClick={() => setDeleteModalOpen(false)} mr={10}>
          Cancel
        </Button>
        <Button color="red" onClick={handleDeleteUnit} loading={isLoading}>
          Delete
        </Button>
      </Modal>

      {currentUnit && (
        <>
          <Text mt={22} mb={10} size={20} weight={600} color="#121926">
            {pUnit.charAt(0).toUpperCase() + pUnit.slice(1)} Title
          </Text>
          <LabelInput value={currentUnit[`${pUnit}_title`]} />
          <IconList
            handleUnitContent={handleUnitContent}
            activeTab={activeTab}
            unitList={unitList}
            pUnit={pUnit}
            iconSrc={currentUnit && currentUnit[`${pUnit}_icon`]}
            isContentSave={isContentSave}
            setIsContentSave={setIsContentSave}
          />
        </>
      )}
    </WeekListStyle>
  );
};

export default UnitListSection;

const WeekListStyle = styled.div`
  .week-list {
    background-color: #e3e8ef;
    border-radius: 10px;
    padding: 5px;
    overflow-x: auto;
    cursor: grab;
    user-select: none; /* Mətn seçimini deaktiv edir */
    
    &:active {
      cursor: grabbing;
    }
    
    &::-webkit-scrollbar {
      height: 0px;
      width: 0px;
      background: transparent;
    }
    
    &:hover::-webkit-scrollbar {
      height: 3px;
      width: 3px;
      border-radius: 10px;

    }
    
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
      
      &:hover {
        background: #555;
      }
    }
    
    .week-tab-list {
      min-height: 40px;
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      
      button {
        background-color: #fff;
        border: 1px solid #cdd5df;
        border-radius: 8px;
        font-weight: 600;
        font-size: 13px;
        color: #202939;
        height: 40px;
        flex-shrink: 0;
      }
      button[data-active] {
        background-color: #202939;
        border: 1px solid #202939;
        color: #fff;
        font-weight: 600;
      }
    }
  }
  .add-week-btn {
    background-color: #fe6027;
    color: #fff;
    border: none;
    height: 50px;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    border-radius: 10px;
  }
  .custom-tab {
    position: relative;

    .delete-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      opacity: 0.7;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }
`;
