import React from "react";
import { Button } from "@mantine/core";
import styled from "styled-components";

const SaveButton = ({onClick,bgColor="#FE6027",color="#fff",text="Save",isLink = false,href="",leftIcon=null}) => {
  return <Button
  size="md"
  radius={8}
  onClick={onClick}
  styles={() => ({
    root : {
        backgroundColor: bgColor,
        color: color,
        fontSize : "16px",
        fontWeight: "500",
        "&:hover": {
          backgroundColor: bgColor,
        },
    }
  })}
  >
    {text}
  </Button>;
};

export default SaveButton;
