import { Button, Modal, Select, Textarea, TextInput } from "@mantine/core";
import axios from "../../../Helpers/Axios";
import { useState } from "react";

export function AddSectionModal({ props }) {
  const {
    entryType,
    links,
    setLoaderVisible,
    fetchCourseContent,
    setAddSectionModalOpen,
    addSectionModalOpen,
    entryId,
    setSectionContentFiltered,
  } = props;
  console.log("sss", entryType);
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");
  const [sectionIsFinal, setSectionIsFinal] = useState("false");

  // add new section
  async function addSection() {
    setLoaderVisible(true);

    const formX = new FormData();
    formX.append("title", sectionTitle);
    formX.append("description", sectionDescription);
    formX.append("is_active", true);
    formX.append("is_final_project", sectionIsFinal === "true" ? true : false);
    entryType === "internship"
      ? formX.append("internship", entryId)
      : entryType === "course"
      ? formX.append("course", entryId)
      : entryType === "steam"
      ? formX.append("steam", entryId)
      : formX.append("hackathon", entryId);

    // post new section
    try {
      await axios.post(links.section, formX);

      // refetch
      setSectionContentFiltered([]);
      setLoaderVisible(false);
      setAddSectionModalOpen(false);
      setSectionTitle("");
      setSectionDescription("");
    } catch (error) {
      setLoaderVisible(false);
    }
  }

  return (
    <Modal
      opened={addSectionModalOpen}
      onClose={() => setAddSectionModalOpen(false)}
      title="Add New Section"
    >
      <div>
        <p>Please choose a name for the new section.</p>
        <div
          className="buttons"
          style={{
            display: "flex",
            gap: ".5rem",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <TextInput
            required
            label="Section Title"
            value={sectionTitle}
            onChange={(e) => setSectionTitle(e.target.value)}
          />
          <Textarea
            required
            label="Section Description"
            value={sectionDescription}
            onChange={(e) => setSectionDescription(e.target.value)}
          />
          <Select
            label="Is Final Project?"
            value={sectionIsFinal}
            onChange={(e) => setSectionIsFinal(e)}
            data={[
              { value: "false", label: "No" },
              { value: "true", label: "Yes" },
            ]}
          />
          <Button
            color={"gray.8"}
            disabled={
              sectionTitle?.length < 1 || sectionDescription?.length < 1
            }
            className="submit"
            onClick={() => addSection()}
          >
            Add Section
          </Button>
        </div>
      </div>
    </Modal>
  );
}
