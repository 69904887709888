import React, { useState, useEffect } from 'react';
import { ActionIcon, Button, Group, Input, Text } from '@mantine/core';
import styled from 'styled-components';
import linkIcon from '../../../../Assets/Svgs/steam/linkIcon.svg';
import SaveButton from '../../Common/SaveButton';
import { IconMinus } from "@tabler/icons-react";


const LinkContent = ({ pSubUnit, contentValue, activeSubUnitTab, handleSubUnitContent, handleDeleteSubUnitContent, setIsContentSave }) => {
  const [link, setLink] = useState(contentValue || '');
  const [initialLink, setInitialLink] = useState(contentValue || '');
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    // Ensure we always set a string value, even if contentValue is null or undefined
    const newValue = contentValue || '';
    setInitialLink(newValue);
    setLink(newValue);
    setIsDirty(false); // Reset dirty state when contentValue changes
  }, [contentValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setLink(newValue);
    setIsDirty(newValue !== initialLink);
  };

  const handleSave = () => {
    handleSubUnitContent(`${pSubUnit}_link`, link, activeSubUnitTab); // Call the parent function with the updated value
    setInitialLink(link); // Update the initial value after saving
    setIsDirty(false); // No longer dirty after saving
    setIsContentSave(true)
  };

  const deleteContent = () => {
    handleDeleteSubUnitContent(activeSubUnitTab,"",`${pSubUnit}_link`); // Call the parent function to delete the image
  };
  return (
    <LinkContentStyle>

      <Group align="center" position="apart" >
      <Text mb={10} size={16} weight={500} color="#121926">
        Link
      </Text>
        <ActionIcon onClick={() => deleteContent()} color="red" >
          <IconMinus  size={16} />
        </ActionIcon>
      </Group>
      <Input
        icon={<img src={linkIcon} alt="Link Icon" />}
        placeholder="Link"
        radius={10}
        size="md"
        value={link} // This will now always be a string
        onChange={handleChange} // Değişiklikleri handleChange fonksiyonuna yönlendir
      />
      {isDirty && (
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
          <SaveButton onClick={handleSave} />
        </div>
      )}
    </LinkContentStyle>
  );
};

export default LinkContent;

const LinkContentStyle = styled.div`
  input::placeholder {
    color: #697586;
    font-size: 16px;
    font-weight: 400;
  }
`
