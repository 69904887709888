import React, { useEffect, useState } from "react";
import { ActionIcon, Button, Group, Modal, RingProgress, Stack, Text } from "@mantine/core";
import styled from "styled-components";
import videoUpload from "../../../../Assets/Svgs/steam/videoYpload.svg";
import axios from "../../../../Helpers/Axios";
import * as tus from "tus-js-client";
import SaveButton from "../../Common/SaveButton";
import { IconMinus, IconPlayerPlay } from "@tabler/icons-react";


const VideoContent = ({ pSubUnit, contentValue, activeSubUnitTab, handleSubUnitContent,handleDeleteSubUnitContent, setIsContentSave }) => {
  const [videoPreview, setVideoPreview] = useState(videoUpload); // Default video upload icon
  const [videoName, setVideoName] = useState("Workspace Video"); // Default name
  const [videoUrl, setVideoUrl] = useState(null);
  const [showVideoProgress, setShowVideoProgress] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false); // Track if a video has been uploaded
  const [showVideoModal, setShowVideoModal] = useState(false); // Add this new state

  const handleVideo = async (e) => {
    if (e !== undefined) {
      setShowVideoProgress(true);
      const accessTokenX = "38c6f5ca7dab2349d12ad6d5e716c759";

      const headerPost = {
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer ${accessTokenX}`,
        "Content-Type": "application/json",
      };

      const file = e;
      const fileSize = file.size.toString();

      try {
        const response = await axios({
          method: "post",
          url: `https://api.vimeo.com/me/videos`,
          headers: headerPost,
          data: {
            upload: {
              approach: "tus",
              size: fileSize,
            },
          },
        });

        const upload = new tus.Upload(file, {
          endPoint: "https://api.vimeo.com/me/videos",
          uploadUrl: response.data.upload.upload_link,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          headers: {},
          onError: function (error) {
            console.error("Upload failed", error);
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);

            setUploadPercentage(percentage);

          },
          onSuccess: function () {
            setShowVideoProgress(false);
            setUploadPercentage(0);
          },
        });

        upload.start();

        return response;
      } catch (error) {
        console.error("Error initiating upload", error);
      }
    }
  };

  const handleVideoUpload = async (event) => {
    const file = event.target.files[0];
    const uploadedVideo = await handleVideo(file);
    let returnedLink = uploadedVideo?.data?.player_embed_url;
    setVideoUrl(returnedLink);
    if (file) {
      setVideoName(file.name); // Set video name
      const videoURL = URL.createObjectURL(file); // Create preview URL
      setVideoPreview(videoURL); // Update video preview
      setIsVideoUploaded(true); // Mark video as uploaded
    }
  };

  const handleSave = () => {
    handleSubUnitContent(`${pSubUnit}_video_link`, videoUrl, activeSubUnitTab);
    setIsVideoUploaded(false); // Hide the Save button after saving
    setIsContentSave(true)
  };



  const deleteContent = () => {
    handleDeleteSubUnitContent(activeSubUnitTab,"",`${pSubUnit}_video_link`); // Call the parent function to delete the image
  };

  useEffect(() => {
   if(contentValue){
    setVideoPreview(contentValue);
    setVideoName('Video')
   } else {
    setVideoPreview(videoUpload);
    setVideoUrl(null);
    setVideoName("Workspace Video");
    setIsVideoUploaded(false);
   }
  },[contentValue])

  return (
    <>
      <VideoContentStyle>

        <Group align="center" position="apart" >
        <Text mb={10} size={16} weight={500} color="#121926">
          Video
        </Text>
        <ActionIcon onClick={() => deleteContent()} color="red" >
          <IconMinus  size={16} />
        </ActionIcon>
      </Group>
        <Group spacing={20}>
        <div
  style={{
    width: "120px",
    height: "90px",
    borderRadius: "8px",
    backgroundColor: "#EAEAEA",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    cursor: "pointer",
    position: "relative",
  }}
  onClick={() => setShowVideoModal(true)}
>
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 1,
    }}
  >
    <IconPlayerPlay size={24} color="white" />
  </div>
  
  {/* Vimeo video iframe embed */}
  {videoPreview && videoPreview.includes('vimeo.com') ? (
  <iframe
  src={`${videoPreview}`}
  width="310px"
  height="220px"
  frameBorder="0"
  allowFullScreen
  title="Video Preview"
  style={{ borderRadius: "8px", objectFit: "cover" }}
></iframe>
  ) : (
    <video
      style={{
        borderRadius: "8px",
        objectFit: "cover",
        width: "100%",
        height: "100%",
      }}
      src={videoPreview}
      alt="Preview"
    />
  )}
</div>

          <Stack align="flex-start" spacing={3}>
            <Text size={16} weight={400} color="#000">
              {videoName}
            </Text>
            <Text size={16} weight={400} color="#697586">
              MP4 max 1 GB
            </Text>
            <Button mt={6} className="upload-btn">
              <label htmlFor="upload-video" style={{ cursor: "pointer" }}>
                Upload Video
              </label>
              <input
                id="upload-video"
                type="file"
                accept="video/mp4"
                onChange={handleVideoUpload}
              />
            </Button>
          </Stack>
        </Group>
        {isVideoUploaded && (
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
        <SaveButton onClick={handleSave} />
      </div>
        )}
      </VideoContentStyle>
      <Modal
        size="xl"
        opened={showVideoModal}
        onClose={() => setShowVideoModal(false)}
        title="Video Player"
        centered
      >
        <div style={{ aspectRatio: "16/9", width: "100%" }}>
          {videoPreview && videoPreview.includes('vimeo.com') ? (
            <iframe
              src={`${videoPreview}`}
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
              title="Video Preview"
              style={{ borderRadius: "8px" }}
            />
          ) : (
            <video
              style={{
                borderRadius: "8px",
                width: "100%",
                height: "100%",
              }}
              src={videoPreview}
              controls
              autoPlay
            />
          )}
        </div>
      </Modal>
      {showVideoProgress && (
        <Modal
          fullScreen
          padding="lg"
          withCloseButton={false}
          opened={showVideoProgress}
        >
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              height: "90vh",
              alignItems: "center",
            }}
          >
            <RingProgress
              size={300}
              sections={[
                {
                  value: uploadPercentage,
                  color: uploadPercentage !== 100.0 ? "yellow" : "teal",
                },
              ]}
              label={
                <Text
                  c={uploadPercentage !== 100.0 ? "yellow" : "teal"}
                  fw={700}
                  ta="center"
                  style={{ fontSize: "40px" }}
                >
                  {`${uploadPercentage}%`}
                </Text>
              }
            />
            {uploadPercentage !== 100.0 ? (
              <Text>
                Please do not refresh page until uploading the video finished.
              </Text>
            ) : (
              <Text>Video was uploaded successfully!</Text>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default VideoContent;

const VideoContentStyle = styled.div`
  .upload-btn {
    background: #edf9f0;
    color: #5eaf8a;
    border-radius: 6px;
    padding: 0 10px;
  }

  input[type="file"] {
    display: none;
  }

`;
