import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import styled from "styled-components";
import parse from "html-react-parser";
import {
  Group,
  Text,
  Radio,
  Stack,
  TextInput,
  Button,
  NumberInput,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../Helpers/Context";
import { useMediaQuery } from "@mantine/hooks";
import CustomFileInput from "../../Components/Common/CustomFileInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "../../Components/Common/Loader";
import useSetCurrentRoute from "../../Hooks/useSetCurrentRoute";

export const badgeData = [
  {
    color: "#6941C6",
    bgColor: "#F9F5FF",
  },
  {
    color: "#3538CD",
    bgColor: "#EEF4FF",
  },
  {
    color: "#C11574",
    bgColor: "#FDF2FA",
  },
];

const SteamTask = ({type}) => {
  const { id, taskId, userId } = useParams();
  const program = type === "steam" ? "steam/steam" : "bootcamp";
  const unit = type === "steam" ? "week" : "section";
  const subUnit = type === "steam" ? "day" : "lesson";

  const unitId = localStorage.getItem(`${unit}Id`);
  const subUnitId = localStorage.getItem(`${subUnit}Id`);
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const queryClient = useQueryClient();
  const xs = useMediaQuery("(max-width: 768px)");
  const [creatorComment, setCreatorComment] = useState("");
  const [score, setScore] = useState(null);

  const { data: subUnitContent, isLoading } = useQuery({
    queryKey: ["subUnitContent", id, unitId, subUnitId],
    queryFn: async () => {
      const response = await axios.get(`/${program}/${id}/${unit}/${unitId}/${type === "steam" ? "day" : "lessons"}/${subUnitId}/`);
      return response.data.data;
    },
  });


  const { data: solvedTask, isLoading: solvedLoading } = useQuery({
    queryKey: ["solvedTask"],
    queryFn: async () => {
      const response = await axios.get(`/${program}/task/${taskId}/user/${userId}/`);
      return response.data;
    },
    enabled: !!user?.id && !!subUnitContent?.tasks[0]?.id,
  });


  // New query for fetching review data
  const { data: reviewData, isLoading: isReviewLoading } = useQuery({
    queryKey: ["reviewData", subUnitContent?.tasks?.[0]?.id, user?.id],
    queryFn: async () => {
      const response = await axios.get(`/${program}/task/${taskId}/user/${userId}/review`);
      return response.data;
    },
    enabled: !!subUnitContent?.tasks?.[0]?.id && !!user?.id,
  });


  // Use effect to update state when reviewData changes
  useEffect(() => {
    if (reviewData && reviewData.data) {
      setCreatorComment(reviewData.data.comment);
      setScore(parseFloat(reviewData.data.score));
    }
  }, [reviewData]);

  const reviewMutation = useMutation({
    mutationFn: (reviewData) => {
      return axios.post(`/${program}/task/${taskId}/user/${userId}/review`, reviewData);
    },
    onSuccess: () => {
      // Invalidate and refetch review data
      queryClient.invalidateQueries([
        "reviewData",
        subUnitContent?.tasks?.[0]?.id,
        user?.id,
      ]);
    },
    onError: (error) => {
      console.error("Error submitting review:", error);
    },
  });

  const handleReviewSubmit = () => {
    if (!creatorComment || score === null) {
      alert("Please provide both a comment and a score.");
      return;
    }

    const reviewData = {
      comment: creatorComment,
      score: score.toString(),
    };

    reviewMutation.mutate(reviewData);
  };

  const renderAnswers = () => {
    const answers = type === "steam" ? solvedTask.student_answers : solvedTask.bootcamp_student_answers;
    if (!solvedTask || !answers) return null;

    return answers.map((answer, index) => (
      <div key={index} style={{ marginTop: "20px" }} className="blog-container">
        <div className="blog-content">
          <Text mb={20} color="#364152" size={18} weight={500}>
            <span>Question {index + 1}</span>{" "}
            {parse(answer.task_question.question_text)}
          </Text>
          {solvedTask.is_quiz ? (
            <OptionWrapper>
              {answer.task_question.answers.map((option, optionIndex) => {
                const isSelected = option.answer_text === answer.answer_text;
                const isCorrect = option.answer_text === answer.correct_answer;
                let backgroundColor = "transparent";
                // if (isSelected) {
                //   backgroundColor = isCorrect ? "#e6ffe6" : "#ffe6e6";
                // } else if (isCorrect) {
                //   backgroundColor = "#e6ffe6";
                // }

                return (
                  <Stack key={optionIndex} mb={10} mt={10} spacing={1}>
                    <OptionItem style={{ backgroundColor }}>
                      <Radio
                        checked={isSelected}
                        readOnly
                        color={
                          isSelected && isCorrect
                            ? "green"
                            : isSelected && !isCorrect
                            ? "red"
                            : isCorrect && "green"
                        }
                      />
                      <TextInput
                        value={option.answer_text}
                        radius={10}
                        style={{
                          flexGrow: 1,
                          height: "50px",
                          background:
                            isSelected && isCorrect
                              ? "#e6ffe6"
                              : isSelected && !isCorrect
                              ? "#ffe6e6"
                              : isCorrect && "#e6ffe6",
                        }}
                        disabled
                        height={50}
                      />
                    </OptionItem>
                  </Stack>
                );
              })}
            </OptionWrapper>
          ) : (
            <>
              <Stack spacing={2}>
                {/* <Text mb={10} size={14} weight={500} color="#121926">
                  File
                </Text> */}
                {answer.answer_files.length > 0 &&
                  answer.answer_files.map((file, index) => (
                    <CustomFileInput
                      key={index}
                      label="File"
                      placeholder="Upload file"
                      accept=".pdf"
                      value={file.file_output}
                      disabled={true}
                      onChange={(e) => {}}
                    />
                  ))}
              </Stack>
              <Stack mt={20} spacing={2}>
                <Text mb={10} size={14} weight={500} color="#121926">
                  Answer
                </Text>
                <CKEditor
                  editor={ClassicEditor}
                  style={{
                    cursor: "not-allowed",
                    background: "#f5f5f5",
                    color: "#697586",
                    borderRadius: "10px",
                  }}
                  data={answer.answer_text}
                  readOnly
                  disabled={true}
                />
              </Stack>
            </>
          )}
        </div>
      </div>
    ));
  };

  useSetCurrentRoute([
    {
      title: type === 'steam' ? "STEAM" : "Bootcamp",
      to: type === 'steam' ? "/steams" : "/bootcamps"
    },
    {
      title : "Applied Student",
      to : `/${type === 'steam' ? 'steam' : 'bootcamp'}/${id}/user-list`
    },
    {
      title : "Answer to Task",
      to : `/${type === 'steam' ? 'steam' : 'bootcamp'}/${id}/task/${taskId}/user/${userId}`
    }
  ]);

  if (isLoading) {
    return <Loader isLoading={isLoading || solvedLoading || isReviewLoading} />;
  }
  console.log('subUnitContent', subUnitContent);
  return (
    <TaskBlogStyle hasReviewData={!!reviewData?.data}>
      <div className="blog-container">
        <div className="blog-content">
          <img
            style={{
              width: "100%",
              height: xs ? "224px" : "441px",
              objectFit: "fill",
              borderRadius: "11px",
            }}
            src={subUnitContent && subUnitContent[`${subUnit}_photo_output`]}
            alt="Blog img"
          />
          <Text mt={4} mb={6} size={xs ? 13 : 14} weight={500} color="#6941C6">
            Sunday , 1 Jan 2023
          </Text>
          <Text mt={10} size={xs ? 18 : 24} weight={500} color="#1A1A1A">
            {subUnitContent?.tasks?.[0]?.task_name}
          </Text>
          <Group mt={15} mb={25}>
            {subUnitContent?.skills_ids?.map((data, index) => (
              <BadgeStyle
                key={index}
                color={badgeData[index % badgeData.length].color}
                bg={badgeData[index % badgeData.length].bgColor}
              >
                {data}
              </BadgeStyle>
            ))}
          </Group>
          <Text
            style={{ lineHeight: "24px" }}
            size={xs ? 14 : 16}
            weight={400}
            color="#667085"
          >
            {subUnitContent?.tasks?.[0]?.task_description && parse(subUnitContent.tasks[0].task_description)}
          </Text>
        </div>
      </div>

      {solvedTask && renderAnswers()}
      <div style={{ marginTop: "20px" }} className="blog-container">
        <div className="blog-content">
          <Text
            style={{ display: "flex", alignItems: "center" }}
            mb={20}
            color="#364152"
            size={18}
            weight={500}
          >
            {type === "steam" ? "STEAM" : "Bootcamp"} Creator Comment
          </Text>
          <CKEditor
            editor={ClassicEditor}
            data={creatorComment}
            onChange={(event, editor) => {
              const data = editor.getData();
              setCreatorComment(data);
            }}
            disabled={!!reviewData?.data}
          />
        </div>
      </div>
      <div style={{ marginTop: "20px" }} className="blog-container">
        <div className="blog-content">
          <Text
            style={{ display: "flex", alignItems: "center" }}
            mb={20}
            color="#364152"
            size={18}
            weight={500}
          >
            Score
          </Text>
          <NumberInput
            placeholder="Enter score"
            min={0}
            max={100}
            precision={2}
            step={0.1}
            value={score}
            onChange={(value) => setScore(value)}
            disabled={!!reviewData?.data}
            styles={(theme) => ({
              input: {
                height: "50px",
                borderRadius: "10px",
                fontSize: "16px",
                backgroundColor: reviewData?.data ? "#f5f5f5" : "white",
              },
            })}
          />
        </div>
      </div>
      {!reviewData?.data && (
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <Button
            styles={() => ({
              root: {
                background: "#FE6027",
                color: "white",
                fontWeight: 500,
                height: "56px",
                width: "100%",
                borderRadius: "10px",
                fontSize: "16px",
                "&:hover": {
                  background: "#FE6027",
                },
              },
            })}
            onClick={handleReviewSubmit}
            disabled={reviewMutation.isLoading || !!reviewData?.data}
          >
            {reviewData?.data
              ? "Review Submitted"
              : reviewMutation.isLoading
              ? "Submitting Review..."
              : "Submit Review"}
          </Button>
        </div>
      )}
    </TaskBlogStyle>
  );
};

export default SteamTask;

const TaskBlogStyle = styled.div`
  max-width: 96%;
  background-color: #eff3f4;
  margin: 25px auto;

  .blog-container {
    background-color: white;
    border-radius: 13px;
    .blog-content {
      padding: 30px;
      .ck-editor__editable {
        min-height: 200px;
      }
      .ck-editor__editable_inline {
        padding: 0 10px;
      }
      .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
        border-left: none;
        border-right: none;
      }
      .ck.ck-editor__main > .ck-editor__editable {
        border-radius: 10px;
      }
      .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused,
      .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
        padding-bottom: 40px;
      }
      .ck.ck-toolbar {
        border-radius: 10px 10px 0 0;
        background: ${(props) => (props.hasReviewData ? "#f5f5f5" : "initial")};
        color: #697586;
      }
      .ck-read-only {
        background: #f5f5f5;
        color: #697586;
        border-radius: 10px;
      }
      .ck.ck-editor__top.ck-reset_all {
        position: absolute;
        bottom: 5px;
        width: 99%;
        left: 0.5%;
        z-index: 1;
      }
      .ck-placeholder {
        color: #697586;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .mantine-FileInput-placeholder.mantine-167ugbe {
    color: #004;
  }
  .mantine-FileInput-input.mantine-Input-input.mantine-FileInput-input.mantine-Input-disabled.mantine-FileInput-disabled.mantine-unuyvl {
    background-color: #f5f5f5;
  }
  .mantine-InputWrapper-root.mantine-TextInput-root.mantine-1q12vd2 {
    .mantine-Input-wrapper.mantine-TextInput-wrapper.mantine-12sbrde {
      border-radius: 10px;
      height: 50px;
      input {
        border-radius: 10px;
        height: 50px;
      }
    }
    border-radius: 10px;
    height: 50px;
  }
  .option-wrapper {
    .mantine-Group-root.mantine-jl3r5k[role="radiogroup"] {
      display: flex;
      flex-direction: column;
      align-items: normal;
      gap: 10px;
    }
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OptionItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    .text-input{
    input {
    height: 50px;
  `;

const BadgeStyle = styled.div`
  color: ${(props) => props.color};
  background: ${(props) => props.bg};
  border-radius: 16px;
  padding: 2px 16px;
  font-size: 14px;
  font-weight: 500;
`;

