// import { useState } from "react";
// import axios from "axios";
// import { Plus } from "tabler-icons-react";
// import { Button } from "@mantine/core";
// import { useClickOutside } from "@mantine/hooks";
// import { ExisitingContent } from "./ExistingContent";
// import { PendingContent } from "./PendingContent";
// import { ModalStyle } from "../SyllabusStyle";

// export const EditSyllabus = ({ props }) => {
//   const {
//     type,
//     entryData,
//     fetchSyllabus,
//     syllabus,
//     setSyllabus,
//     setShowEditModal,
//   } = props;

//   const refModal = useClickOutside(() => setShowEditModal(false));

//   const [updatedSections, setUpdatedSections] = useState([]);
//   const [pendingItems, setPendingItems] = useState([]);

//   const [updatingExisting, setUpdatingExisting] = useState(false);
//   const [postingPending, setPostingPending] = useState(false);

//   const [nothingToChange, setNothingToChange] = useState(true);

//   // Add new section to exisiting syllabus
//   function addSection() {
//     if (type === "course") {
//       const updateItems = [
//         ...pendingItems,
//         { content: "", course: entryData?.id },
//       ];
//       setPendingItems(updateItems);
//     }
//     if (type === "internship") {
//       const updateItems = [
//         ...pendingItems,
//         { content: "", internship: entryData?.id },
//       ];
//       setPendingItems(updateItems);
//     }
//   }

//   function postNewEntries() {
//     if (pendingItems.length > 0) {
//       setPostingPending(true);

//       const config = {
//         onUploadProgress: (progressEvent) => {
//           const percentCompleted = Math.round(
//             (progressEvent.loaded * 100) / progressEvent.total
//           );

//           if (percentCompleted === 100) {
//             setPostingPending(false);
//             setPendingItems([]);
//             setNothingToChange(true);
//           }
//         },
//       };

//       // post pending items to database
//       pendingItems.forEach(async (item) => {
//         let formX = new FormData();
//         formX.append("title", item.title);
//         if (type === "course") {
//           formX.append("course", entryData?.id);
//         }
//         if (type === "internship") {
//           formX.append("intershipinfo", entryData?.id);
//         }
//         formX.append("content", item.content);
//         item?.content_video?.name &&
//           formX.append("content_video", item.content_video);

//         try {
//           const response = await axios.post(
//             type === "course"
//               ? `/syllabus/createcoursesyllabus/`
//               : `/syllabus/createinternshipsyllabus/`,
//             formX,
//             config
//           );
//           if (response.status === 201) {
//             fetchSyllabus();
//           }
//         } catch (error) {}
//       });
//     }
//   }

//   function updateExistingEntries() {
//     if (updatedSections.length > 0) {
//       setUpdatingExisting(true);

//       const config = {
//         onUploadProgress: (progressEvent) => {
//           const percentCompleted = Math.round(
//             (progressEvent.loaded * 100) / progressEvent.total
//           );
//           if (percentCompleted === 100) {
//             setUpdatingExisting(false);
//             setUpdatedSections([]);
//             setNothingToChange(true);
//           }
//         },
//       };

//       // return item if the id is in the updatedSections array
//       const updateItems = syllabus.filter((item) =>
//         updatedSections.includes(item.id)
//       );

//       // for each item in the updateItems array, update the item in the database
//       updateItems.forEach(async (item) => {
//         let formX = new FormData();
//         formX.append("title", item.title);
//         if (type === "course") {
//           formX.append("course", entryData?.id);
//         }
//         if (type === "internship") {
//           formX.append("intershipinfo", entryData?.id);
//         }
//         formX.append("content", item.content);
//         item?.content_video?.name &&
//           formX.append("content_video", item.content_video);

//         try {
//           const response = await axios.patch(
//             type === "course"
//               ? `/syllabus/coursesyllabus/${item?.id}/`
//               : `/syllabus/internshipsyllabus/${item?.id}/`,
//             formX,
//             config
//           );
//           if (response.status === 200) {
//             fetchSyllabus();
//           }
//         } catch (error) {}
//       });
//     }
//   }

//   async function updateSyllabus() {
//     if (nothingToChange === false) {
//       updateExistingEntries();
//       postNewEntries();
//     } else {
//       setShowEditModal(false);
//     }
//   }

//   return (
//     <ModalStyle center>
//       <div className="wrapper" id="modalX" ref={refModal}>
//         <ExisitingContent
//           props={{
//             type,
//             nothingToChange,
//             setNothingToChange,
//             fetchSyllabus,
//             updatedSections,
//             setUpdatedSections,
//             syllabus,
//             setSyllabus,
//             axios,
//           }}
//         />
//         <PendingContent
//           props={{
//             type,
//             setNothingToChange,
//             setPendingContent: setPendingItems,
//             pendingContent: pendingItems,
//             syllabus,
//           }}
//         />

//         <Button
//           variant="outline"
//           color="teal"
//           fullWidth
//           size="md"
//           onClick={() => addSection()}
//         >
//           {" "}
//           <Plus style={{ marginRight: ".5rem" }} /> Add section{" "}
//         </Button>
//         <br />
//         <Button
//           loading={updatingExisting || postingPending ? true : false}
//           variant="outline"
//           color="dark"
//           fullWidth
//           size="md"
//           onClick={() => updateSyllabus()}
//         >
//           {nothingToChange === true ? "Close" : "Submit"}
//         </Button>
//       </div>
//     </ModalStyle>
//   );
// };

import { useState } from "react";
import axios from "axios";
import { Plus } from "tabler-icons-react";
import { Button } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import { ExisitingContent } from "./ExistingContent";
import { PendingContent } from "./PendingContent";
import { ModalStyle } from "../SyllabusStyle";

export const EditSyllabus = ({ props }) => {
  const {
    type,
    entryData,
    fetchSyllabus,
    syllabus,
    setSyllabus,
    setShowEditModal,
  } = props;

  const refModal = useClickOutside(() => setShowEditModal(false));

  const [updatedSections, setUpdatedSections] = useState([]);
  const [pendingItems, setPendingItems] = useState([]);

  const [updatingExisting, setUpdatingExisting] = useState(false);
  const [postingPending, setPostingPending] = useState(false);

  const [nothingToChange, setNothingToChange] = useState(true);

  // Add new section to exisiting syllabus
  function addSection() {
    if (type === "course") {
      const updateItems = [
        ...pendingItems,
        { content: "", course: entryData?.id },
      ];
      setPendingItems(updateItems);
    }
    if (type === "internship") {
      const updateItems = [
        ...pendingItems,
        { content: "", internship: entryData?.id },
      ];
      setPendingItems(updateItems);
    }

    if (type === "steam") {
      const updateItems = [
        ...pendingItems,
        { content: "", steam: entryData?.id },
      ];
      setPendingItems(updateItems);
    }
  }

  function postNewEntries() {
    if (pendingItems.length > 0) {
      setPostingPending(true);

      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (percentCompleted === 100) {
            setPostingPending(false);
            setPendingItems([]);
            setNothingToChange(true);
          }
        },
      };

      // post pending items to database
      pendingItems.forEach(async (item) => {
        let formX = new FormData();
        formX.append("title", item.title);
        if (type === "course") {
          formX.append("course", entryData?.id);
          formX.append("content", item.content)
        }
        if (type === "internship") {
          formX.append("intershipinfo", entryData?.id);
          formX.append("content", item.content)
        }

        if (type === "steam") {
          formX.append("steam", entryData?.id);
          formX.append("is_active", true);
          formX.append("content", item.content)
        }

        //Old version code
        // formX.append("content", item.content);
        item?.content_video?.name &&
          formX.append("content_video", item.content_video);

        try {
          const response = await axios.post(
            type === "course"
              ? `/syllabus/createcoursesyllabus/`
              :  type === "steam" ?
              `/steam/createsyllabus/` 
              : `/syllabus/createinternshipsyllabus/`,
            formX,
            config
          );
          if (response.status === 201) {
            fetchSyllabus();
          }
        } catch (error) {}
      });
    }
  }

  function updateExistingEntries() {
    if (updatedSections.length > 0) {
      setUpdatingExisting(true);

      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (percentCompleted === 100) {
            setUpdatingExisting(false);
            setUpdatedSections([]);
            setNothingToChange(true);
          }
        },
      };

      // return item if the id is in the updatedSections array
      const updateItems = syllabus.filter((item) =>
        updatedSections.includes(item.id)
      );

      // for each item in the updateItems array, update the item in the database
      updateItems.forEach(async (item) => {
        let formX = new FormData();
        formX.append("title", item.title);
        if (type === "course") {
          formX.append("course", entryData?.id);
          formX.append("content", item.content);
        }
        if (type === "internship") {
          formX.append("intershipinfo", entryData?.id);
          formX.append("content", item.content);
        }
        if (type === "steam") {
          // formX.append("description", entryData?.id);
          formX.append("steam", entryData?.id);
          formX.append("content", item.content);
        }
        //old code
        // formX.append("content", item.content);
        item?.content_video?.name &&
          formX.append("content_video", item.content_video);

        try {
          const response = await axios.patch(
            type === "course"
              ? `/syllabus/coursesyllabus/${item?.id}/`
              : type==="steam" ?
               `/steam/syllabus/${item?.id}/` :
               `/syllabus/internshipsyllabus/${item?.id}/`,
            formX,
            config
          );
          if (response.status === 200) {
            fetchSyllabus();
          }
        } catch (error) {}
      });
    }
  }

  async function updateSyllabus() {
    if (nothingToChange === false) {
      updateExistingEntries();
      postNewEntries();
    } else {
      setShowEditModal(false);
    }
  }

  return (
    <ModalStyle center>
      <div className="wrapper" id="modalX" ref={refModal}>
        <ExisitingContent
          props={{
            type,
            nothingToChange,
            setNothingToChange,
            fetchSyllabus,
            updatedSections,
            setUpdatedSections,
            syllabus,
            setSyllabus,
            axios,
          }}
        />
        <PendingContent
          props={{
            type,
            setNothingToChange,
            setPendingItems,
            pendingItems,
            syllabus,
          }}
        />

        <Button
          variant="outline"
          color="teal"
          fullWidth
          size="md"
          onClick={() => addSection()}
        >
          {" "}
          <Plus style={{ marginRight: ".5rem" }} /> Add section{" "}
        </Button>
        <br />
        <Button
          loading={updatingExisting || postingPending ? true : false}
          variant="outline"
          color="dark"
          fullWidth
          size="md"
          onClick={() => updateSyllabus()}
        >
          {nothingToChange === true ? "Close" : "Submit"}
        </Button>
      </div>
    </ModalStyle>
  );
};
