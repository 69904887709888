import axios from "../../Helpers/Axios";
import { Chip } from "@mantine/core";
import { Lock, LockOpen } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export function ProgramPrivacy({ props }) {
  const queryClient = useQueryClient();

  const { type, user, entryData, setEntryData } = props;
  const link = type === 'bootcamp' ? 'bootcamp' : type === 'steam' && 'steam/steam'
  const unit = type === 'bootcamp' ? 'section' : type === 'steam' && 'week'
  const subUnit = type === 'bootcamp' ? 'lesson' : type === 'steam' && 'day'
  const subUnits = type === 'bootcamp' ? 'lessons' : type === 'steam' && 'days'

  const { mutate: editSteam } = useMutation({
    mutationFn: async (data) => {
      return await axios.put(`/${link}/${entryData?.id}/`, data);
    },
    onSuccess: () => {
       queryClient.invalidateQueries({queryKey : ['program']})
    },
  });

  async function handleEdit(privacyType) {
    const formX = {};
    const updateEntryData = { ...entryData }; // Cloning the entryData object
    // Rename syllabus_file to syllabus
    if (updateEntryData.syllabus_file) {
      updateEntryData.syllabus = updateEntryData.syllabus_file; // Create new key 'syllabus'
      delete updateEntryData.syllabus_file; // Delete the old key 'syllabus_file'
    }
    if (updateEntryData.author_image_file) {
      updateEntryData.author_image = updateEntryData.author_image_file; // Create new key 'syllabus'
      delete updateEntryData.author_image_file; // Delete the old key 'syllabus_file'
    }
    if (updateEntryData[`${type}_image_file`]) {
      updateEntryData[`${type}_image`] = updateEntryData[`${type}_image_file`]; // Create new key 'syllabus'
      delete updateEntryData[`${type}_image_file`]; // Delete the old key 'syllabus_file'
    }
    
    const updatedUnits = entryData[`${unit}s_output`].map((u) => ({
      ...u,
      [`${subUnits}`]: u[`${subUnits}_output`].map((sU) => ({
        ...sU,
        [`${subUnit}_photo`]: sU[`${subUnit}_photo_output`],
        files: sU.files.map((file) => ({
          id: file.id,
          file_name: file.file_name,
          file_field: file.file_field_output, // eski key 'file_field_output' yeni key 'file_field'
        })),
      })),
    }));
    updateEntryData[`${unit}s`] = updatedUnits;

    if (privacyType === "public" && (type === "steam" || type === "bootcamp")) {
      updateEntryData.is_published = true;
      updateEntryData.is_private = false;
    }
    if (privacyType === "unlisted" && (type === "steam" || type === "bootcamp")) {
      updateEntryData.is_published = false;
      updateEntryData.is_private = true;
    }
    if (privacyType === "private" && (type === "steam" || type === "bootcamp")) {
      updateEntryData.is_published = false;
      updateEntryData.is_private = false;
    }


    if (privacyType === "public" && (type !== "steam" && type !== "bootcamp")) {
      formX.is_shared = true;
      formX.is_unlisted = false;
    }
    if (privacyType === "unlisted" && (type !== "steam" && type !== "bootcamp")) {
      formX.is_shared = false;
      formX.is_unlisted = true;
    }
    if (privacyType === "private" && (type !== "steam" && type !== "bootcamp")) {
      formX.is_shared = false;
      formX.is_unlisted = false;
    }

    if (type === "steam" || type === "bootcamp") {
       editSteam(updateEntryData);
    } else {
      const response = await axios.patch(
        type === "course"
          ? `/api/course/${entryData.id}/`
          : type === "internship"
          ? `/internship/${entryData.id}/`
          : type === "university"
          ? `/university/edit/${entryData.id}/`
          : `/steam/steam/${entryData.id}/edit/`,
        type === "steam" ? updateEntryData : formX
      );
      if (response.status === 200) {
        if (privacyType === "private" && (type !== "steam" && type !== "bootcamp")) {
          setEntryData({ ...entryData, is_shared: false, is_unlisted: false });
        }
        if (privacyType === "unlisted" && (type !== "steam" && type !== "bootcamp")) {
          setEntryData({ ...entryData, is_shared: false, is_unlisted: true });
        }
        if (privacyType === "public" && (type !== "steam" && type !== "bootcamp")) {
          setEntryData({ ...entryData, is_shared: true, is_unlisted: false });
        }
      }
    }

    showNotification({
      icon:
        privacyType === "public" ? (
          <LockOpen />
        ) : privacyType === "unlisted" ? (
          <LockOpen />
        ) : (
          <Lock />
        ),
      title:
        privacyType === "public"
          ? "Program is now public"
          : privacyType === "unlisted"
          ? "Program is now unlisted"
          : "Program is now private",
      message:
        privacyType === "public"
          ? "Now everyone can see this content"
          : privacyType === "unlisted"
          ? "Anyone with the link can see this content"
          : "Now only you can see this content",
      color:
        privacyType === "public"
          ? "teal"
          : privacyType === "unlisted"
          ? "blue"
          : "yellow",
    });
  }

  function returnType() {
    if (entryData?.is_shared === false && entryData?.is_unlisted === false) {
      return "private";
    }
    if (entryData?.is_shared === false && entryData?.is_unlisted === true) {
      return "unlisted";
    }
    if (entryData?.is_shared === true && entryData?.is_unlisted === false) {
      return "public";
    }
    if (entryData?.is_published === false && entryData?.is_private === false) {
      return "private";
    }
    if (entryData?.is_published === false && entryData?.is_private === true) {
      return "unlisted";
    }
    if (entryData?.is_published === true && entryData?.is_private === false) {
      return "public";
    }
  }

  if (
    (entryData?.user?.id ||
      entryData?.teacher?.id ||
      entryData?.company?.id ||
      user?.id === 56 || user?.user_type.type === "Company") &&
    (entryData?.user?.id == user?.id ||
      entryData?.teacher?.id == user?.id ||
      entryData?.company?.id === user?.id ||
      user?.id === 56 || user?.user_type.type === "Company")
  ) {
    return (
      <div className="draftDiv">
        <Chip.Group
          position="center"
          value={returnType()}
          onChange={(e) => handleEdit(e)}
        >
          <Chip color="yellow" size="lg" radius="xs" value="private">
            Private
          </Chip>
          <Chip color="blue" size="lg" radius="xs" value="unlisted">
            Unlisted
          </Chip>
          <Chip color="green" size="lg" radius="xs" value="public">
            Public
          </Chip>
        </Chip.Group>
      </div>
    );
  }
}
