import React, { useEffect, useState } from "react";
import { Group, Text, Select, Button } from "@mantine/core";
import styled from "styled-components";
import { X } from "tabler-icons-react";
import useChoicesData from "../../../../Hooks/useChoicesData";
import SaveButton from "../../Common/SaveButton";

const getSkillIds = (skillList) => {
  return skillList.map((skill) => skill.id);
};

const SkillContent = ({ 
  contentValue, 
  activeSubUnitTab, 
  handleSubUnitContent,
  setIsContentSave
}) => {
  const [selectedSkill, setSelectedSkill] = useState(""); // Seçili beceri için state
  const [skillList, setSkillList] = useState([]); // Seçilen becerilerin listesi
  const [isChanged, setIsChanged] = useState(false); // Track if skills have changed
  const { skills } = useChoicesData(); // Skill verilerini hook'tan al

  // Sayfa ilk yüklendiğinde contentValue'deki id'lere göre skillList'i güncelle
  useEffect(() => {
    if (contentValue && skills) {
      const initialSkills = skills.filter((skill) =>
        contentValue.includes(skill.id)
      );
      setSkillList(initialSkills);
      setIsChanged(false); // Reset the change state when contentValue changes
    }
  }, [contentValue, skills]); // contentValue veya skills değiştiğinde çalışır

  const handleAddSkill = (value) => {
    if (value && !skillList.find((skill) => skill.id === value)) {
      const skill = skills.find((skill) => skill.id === value);
      if (skill) {
        setSkillList([...skillList, skill]); // Beceriyi listeye ekle
        setIsChanged(true); // Mark as changed
        setSelectedSkill(""); // Seçimi temizle
      }
    }
  };

  const handleRemoveSkill = (id) => {
    const newSkills = skillList.filter((skill) => skill.id !== id);
    console.log('newSkills',newSkills)
    setSkillList(newSkills);
    
    // Əgər yeni skill siyahısı ilkin contentValue ilə eynidirsə, setIsChanged false olmalıdır
    if (contentValue && skills) {
      console.log('contentValue',contentValue)
      const initialSkills = skills.filter((skill) => contentValue.includes(skill.id));
      const areSkillsEqual = 
        newSkills.length === initialSkills.length && 
        newSkills.every(skill => initialSkills.some(initSkill => initSkill.id === skill.id));
      
      setIsChanged(!areSkillsEqual);
    } else {
      setIsChanged(true);
    }
  };

  const handleSave = () => {
    const skillIds = getSkillIds(skillList);
    handleSubUnitContent("skills_ids", skillIds, activeSubUnitTab); // Call the parent function with the updated value
    setIsChanged(false); // Reset the change state after saving
    setIsContentSave(true)
  };

  return (
    <SkillContentStyle>
      <Text mb={10} size={16} weight={500} color="#121926">
        Skills
      </Text>

      <div className="skill-content-style">
        <div className="skill-content-container">
          <Select
            size="md"
            radius={8}
            placeholder="Select a skill"
            className="skill-select"
            value={selectedSkill}
            onChange={handleAddSkill} // Seçilen beceriyi listeye ekle
            data={
              skills
                ? skills.map((selectData) => ({
                    value: selectData.id, // Value olarak skill id kullanılıyor
                    label: selectData.skill, // Label olarak skill adı kullanılıyor
                  }))
                : []
            } // Hook'tan alınan beceri verilerini buraya ver
            clearable
          />
          <Group spacing={8} mt={10}>
            {skillList.map((skill) => (
              <div key={skill.id} className="skill">
                {skill.skill}
                <div
                  className="icon-x"
                  onClick={() => handleRemoveSkill(skill.id)}
                >
                  <X
                    style={{ width: "12px", height: "12px" }}
                    color="#fff"
                    strokeWidth={2}
                  />
                </div>
              </div>
            ))}
          </Group>
        </div>
      </div>

      {isChanged && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        >
          <SaveButton onClick={handleSave} />
        </div>
      )}
    </SkillContentStyle>
  );
};

export default SkillContent;

const SkillContentStyle = styled.div`
  .skill-content-style {
    border: 1px solid #e3e8ef;
    border-radius: 10px;
    .skill-content-container {
      padding: 20px;
      .skill-select {
        input {
        
        }
      }
      .skill {
        background-color: #eafeff;
        color: #029199;
        font-size: 13px;
        font-weight: 500;
        padding: 14px 9px;
        position: relative;
        border: 1px solid #029199;
        border-radius: 13px;
        margin-right: 8px; // Beceri kutularının arası boşluk bırak
        display: inline-flex;
        align-items: center;

        .icon-x {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background-color: #029199;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -7px;
          right: -7px;
          cursor: pointer; // Fare üzerine geldiğinde el işareti göster
        }
      }
    }
  }
`;
