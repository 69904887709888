import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Tabs } from "@mantine/core";
import {useNavigate,useLocation} from 'react-router-dom'

const Dropdown = ({props}) => {
  const {tabData} = props;
  const [activeTab, setActiveTab] = useState(tabData[0].value);
  const navigate = useNavigate()
  const { pathname } = useLocation();

  const handleTabChange = (value) => {
    setActiveTab(value);
    navigate(`/${value}`)
  };

  useEffect(() =>{
   setActiveTab(pathname?.slice(1))
  },[pathname])
  
  return (
    <DropdownStyle>
      <Tabs
        className="custom-tabs"
        orientation="vertical"
        value={activeTab}
        onTabChange={(e) => handleTabChange(e)}
      >
        <Tabs.List className="custom-list" position="left">
          {
            tabData?.map((item, index) => {
              return (
                <Tabs.Tab  key={index} className="custom-tab" value={item.value}>
                  {item.title}
                </Tabs.Tab>
              );
            })
          }
        </Tabs.List>
      </Tabs>
    </DropdownStyle>
  );
};

export default Dropdown;

const DropdownStyle = styled.div`
  width: 100%;
  .custom-tabs {
    justify-content: center;
  }
  .custom-list {
    border-right: 0;
    gap: 14px;
  }
  .custom-tab[data-active]{
    border-left: 3px solid #ccf075;
  }
  .custom-tab {

    border-right: 0;
    margin-left: 2px;
    border-radius: 0;
    padding: 2px 12px;
    font-size: 16px;
    color: #364152;
    font-weight: 400;
  }
`;
