


import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Grid, Group, Text, TextInput } from "@mantine/core";
import styled from "styled-components";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { Edit, Trash } from "tabler-icons-react";
import { AppContext } from "../../../Helpers/Context";
import { useNavigate, useParams } from "react-router-dom";
import useSetCurrentRoute from "../../../Hooks/useSetCurrentRoute";

// Styled Components
const FormWrapper = styled.div`
  padding: 20px;

  .form-btn {
    font-size: 16px;
    font-weight: 400;
  }

  .save-btn {
    background-color: #00bd90;
    color: #fff;
  }
  .edit-btn {
    background-color: #d6f4f6;
    color: #029199;
    border: 1px solid #029199;
  }
  .cancel-btn {
    background-color: #6c757d;
    color: #fff;
  }
  .delete-btn {
    background-color: #ffe0e0;
    color: #e41d1d;
    border: 1px solid #e41d1d;
  }
  .create-btn {
    background-color: #fe6027;
    color: #fff;
  }
`;

const PackageForm = ({ type }) => {
  const { pId } = useParams();

  useSetCurrentRoute([
    { title: "Packages", to: "/packages" },
    { title: type === "edit" ? "Edit form" : "Create form", to: type === "edit" ? `/packages/edit-form/${pId}` : "/packages/create-form" },
  ]);

  const queryClient = useQueryClient();
  const [selectedFeature, setSelectedFeature] = useState(null);
  const { user } = useContext(AppContext);
  const navigate = useNavigate();

  const { data: pack, isLoading: isLoadingPack } = useQuery({
    queryKey: ["packages", pId],
    queryFn: async () => {
      if (type === "edit") {
        const response = await axios.get(`/agent/packages/${pId}`);
        return response.data;
      }
      return null;
    },
    enabled: type === "edit"
  });

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: "",
      price: "",
    },
  });

  const {
    handleSubmit: handleServiceSubmit,
    control: serviceControl,
    setValue: setServiceValue,
    reset: resetServiceForm,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    if (type === "edit" && pack) {
      reset({
        name: pack.name,
        price: pack.price,
      });
    }
  }, [type, pack, reset]);

  const { data: features } = useQuery({
    queryKey: ["features"],
    queryFn: async () =>
      await axios.get("/agent/features/").then((res) => res?.data),
  });

  const { mutate: createFeature } = useMutation({
    mutationFn: async (data) => await axios.post("/agent/features/", data),
    onSuccess: () => {
      queryClient.invalidateQueries(["features"]);
      resetServiceForm();
    },
  });

  const { mutate: updateFeature } = useMutation({
    mutationFn: async ({ id, data }) =>
      await axios.patch(`/agent/features/${id}/`, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["features"]);
      setSelectedFeature(null);
      resetServiceForm();
    },
  });

  const { mutate: deleteFeature } = useMutation({
    mutationFn: async (id) => await axios.delete(`/agent/features/${id}/`),
    onSuccess: () => {
      queryClient.invalidateQueries(["features"]);
    },
  });

  const { mutate: createPackage } = useMutation({
    mutationFn: async (data) => await axios.post("/agent/packages/", data),
    onSuccess: () => {
      queryClient.invalidateQueries(["packages"]);
      reset();
      navigate("/packages");
    },
  });

  const { mutate: updatePackage } = useMutation({
    mutationFn: async ({ id, data }) =>
      await axios.patch(`/agent/packages/${id}/`, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["packages"]);
      navigate("/packages");
    },
  });

  const featureIds = features?.map((feature) => feature.id);

  const onPackageSubmit = (data) => {
    data.features = featureIds;
    data.agent = user?.id;

    if (type === "edit") {
      updatePackage({ id: pId, data });
    } else {
      createPackage(data);
    }
  };

  const onServiceSubmit = (data) => {
    if (selectedFeature) {
      updateFeature({ id: selectedFeature.id, data });
    } else {
      createFeature(data);
    }
  };

  const handleEditFeature = (feature) => {
    setSelectedFeature(feature);
    setServiceValue("name", feature.name);
  };

  const handleCancelEdit = () => {
    setSelectedFeature(null);
    resetServiceForm();
  };

  if (isLoadingPack && type === "edit") {
    return <div>Loading...</div>;
  }

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(onPackageSubmit)}>
        <Grid>
          <Grid.Col span={6}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextInput
                  size="md"
                  radius={10}
                  label="Package Name"
                  {...field}
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextInput
                  size="md"
                  radius={10}
                  label="Package Price"
                  {...field}
                />
              )}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <Group noWrap align="flex-end">
              <Controller
                name="name"
                control={serviceControl}
                render={({ field }) => (
                  <TextInput
                    size="md"
                    radius={10}
                    style={{ width: "100%" }}
                    label="Feature"
                    {...field}
                  />
                )}
              />
              <Button
                style={{ width: "10%" }}
                onClick={handleServiceSubmit(onServiceSubmit)}
                size="md"
                radius={10}
                className={
                  selectedFeature ? "form-btn edit-btn" : "form-btn save-btn"
                }
              >
                {selectedFeature ? "Update" : "Save"}
              </Button>
              {selectedFeature && (
                <Button
                  style={{ width: "10%" }}
                  onClick={handleCancelEdit}
                  size="md"
                  radius={10}
                  className="form-btn cancel-btn"
                >
                  Cancel
                </Button>
              )}
            </Group>
          </Grid.Col>

          <Text mt={20} size={16} weight={500} color="green.6">
            Created features {features ? features.length : 0}/
            {features ? features.length : 0}
          </Text>
          {features &&
            features.map((feature) => (
              <Grid.Col span={12} key={feature.id}>
                <Group noWrap align="flex-end">
                  <Text
                    style={{
                      border: "1px solid #11AF22",
                      borderRadius: "10px",
                      padding: "10px",
                      background: "#FFFFFF",
                      width: "100%",
                    }}
                    size="md"
                  >
                    {feature.name}
                  </Text>

                  <>
                    <Button
                      style={{ width: "10%" }}
                      onClick={() => handleEditFeature(feature)}
                      leftIcon={<Edit />}
                      size="md"
                      mb={3}
                      radius={10}
                      className="form-btn edit-btn"
                    >
                      Edit
                    </Button>
                    <Button
                      style={{ width: "15%" }}
                      leftIcon={<Trash />}
                      size="md"
                      radius={10}
                      mb={3}
                      onClick={() => deleteFeature(feature.id)}
                      className="form-btn delete-btn"
                    >
                      Delete
                    </Button>
                  </>
                </Group>
              </Grid.Col>
            ))}
        </Grid>

        <Button
          className="form-btn create-btn"
          mt={30}
          size="lg"
          radius={10}
          fullWidth
          type="submit"
        >
          {type === "edit" ? "Update Package" : "Create Package"}
        </Button>
      </form>
    </FormWrapper>
  );
};

export default PackageForm;
