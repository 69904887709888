import { useContext, useEffect } from 'react';
import { CurrentRouteContext } from '../Helpers/HeaderContext';
import { useLocation } from 'react-router-dom'

const useSetCurrentRoute = (routes) => {
  const { setCurrentRoute } = useContext(CurrentRouteContext);
  const { pathname } = useLocation()

  useEffect(() => {
    if (routes) {
      setCurrentRoute(routes);
    } else {
      setCurrentRoute('');
    }
  }, [JSON.stringify(routes)]);
};

export default useSetCurrentRoute;