import React, { createContext, useState } from 'react';

// Create the context
const ProgramContext = createContext();

// Create the provider component
const ProgramProvider = ({ children }) => {
    const [programData, setProgramData] = useState({});

    // Update the context state
    const updateProgramData = (newData) => {
        setProgramData((prevState) => ({
            ...prevState,
            ...newData,
        }));
    };

    return (
        <ProgramContext.Provider value={{ programData, updateProgramData }}>
            {children}
        </ProgramContext.Provider>
    );
};

export { ProgramContext, ProgramProvider };
